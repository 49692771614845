import { useFetcher } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { type ClickHouseCluster, type Project } from 'api-client'
import invariant from 'tiny-invariant'

import {
  clickHouseClustersQuery,
  DELETE_CLICKHOUSE,
  ProviderIcons,
  useClickHouseClusterMetrics,
} from '~/entities/integrations/clickhouse'

import { formatSize } from '~/shared/lib/formatSize'
import { useBoolean } from '~/shared/lib/useBoolean'

import { formatDate, formatNumber } from '~/i18n/utils'

import { Box } from '~/components/Box'
import { Button } from '~/components/Button'
import { Card } from '~/components/Card'
import { Icon } from '~/components/Icon'
import { Cpu, Database, Layers, Server } from '~/components/Icons'
import { Loading } from '~/components/Loading'
import { Modal } from '~/components/Modal'
import { ProgressCircle } from '~/components/ProgressCircle'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import * as styles from './ClickHouseClusterInfo.css'

type ClickHouseClusterInfoProps = {
  projectId: Project['id']
  clusterId: ClickHouseCluster['id']
}

export function ClickHouseClusterInfo({ projectId, clusterId }: ClickHouseClusterInfoProps) {
  const fetcher = useFetcher()
  const [isOpen, { on: openModal, off: closeModal }] = useBoolean(false)

  const { data: cluster } = useQuery({
    ...clickHouseClustersQuery(projectId),
    select: data => data.find(cl => cl.id === clusterId),
  })
  invariant(cluster)

  const {
    id,
    status,
    disks,
    provider,
    name,
    region,
    createdAt,
    shards,
    replicas,
    machine,
    isExternal,
  } = cluster

  const { data: metrics } = useClickHouseClusterMetrics(projectId, name)

  const numberOfDisks = metrics
    ? metrics.disks.length / new Set(metrics?.disks.map(disk => disk.host)).size
    : undefined

  const accumulatedDisksMetrics = metrics?.disks.reduce(
    (acc, disk) => ({
      totalSpace: acc.totalSpace + disk.totalSpace,
      freeSpace: acc.freeSpace + disk.freeSpace,
    }),
    { totalSpace: 0, freeSpace: 0 },
  )

  const isChanging = ['creating', 'deleting'].some(s => s === status)

  const infoItems = [
    {
      id: 'layers',
      icon: <Layers />,
      topText: `${shards} ${shards === 1 ? 'SHARD' : 'SHARDS'}`,
      bottomText: `${replicas} ${replicas === 1 ? 'REPLICA' : 'REPLICAS'}`,
    },
    {
      id: 'cpu',
      icon: <Cpu />,
      topText: machine ? `${machine.cpu} vCPU` : '---',
      bottomText: machine ? `${machine?.ram} ${machine.ramUnitName ?? 'N/A'} RAM` : '---',
    },
    {
      id: 'disks',
      icon: <Server />,
      topText: numberOfDisks ? `${numberOfDisks} ${numberOfDisks === 1 ? 'DISK' : 'DISKS'}` : '---',
      bottomText: accumulatedDisksMetrics
        ? `${formatSize(accumulatedDisksMetrics.totalSpace, {
            binary: disks?.[0].unitType === 'Binary',
          })}`
        : '---',
    },
    {
      id: 'metrics',
      icon: <Database />,
      topText: metrics ? `${formatNumber(metrics.totalRows)} ROWS` : `N/A`,
      bottomText: accumulatedDisksMetrics
        ? formatSize(accumulatedDisksMetrics.totalSpace - accumulatedDisksMetrics.freeSpace, {
            binary: disks?.[0].unitType === 'Binary',
          })
        : '---',
    },
  ]

  return (
    <Card>
      <Box position="relative" display="flex" flexDirection="column" height="full">
        <Box className={styles.neonWrapper}>
          <Box className={styles.neon({ status })} />
        </Box>
        <Card.Section>
          <Stack direction="horizontal" justify="between" align="center" wrap>
            <Stack direction="horizontal" align="start" space="small">
              <Icon
                color={{ lightMode: 'gray800', darkMode: 'gray600' }}
                size="xxxlarge"
                aria-label={provider}
              >
                {ProviderIcons[provider]}
              </Icon>
              <Stack space="xxsmall">
                <Stack direction="horizontal" space="small" justify="start" align="center">
                  <Text size="xlarge" truncate={1}>
                    {name}
                  </Text>
                  <Box as="span" className={styles.tag}>
                    {status}
                  </Box>
                </Stack>
                {region && (
                  <Text size="small">
                    {region.name} ({region.code})
                  </Text>
                )}
                <Text size="xxsmall" color="gray600">
                  {formatDate(createdAt)}
                </Text>
              </Stack>
            </Stack>
            {accumulatedDisksMetrics && (
              <ProgressCircle
                label="Disk"
                value={Math.round(
                  (accumulatedDisksMetrics.freeSpace / accumulatedDisksMetrics.totalSpace) * 100,
                )}
              />
            )}
          </Stack>
        </Card.Section>
        <Card.Divider />
        <Card.Section>
          <Stack direction="horizontal" justify="between" wrap>
            {infoItems.map(item => (
              <Box className={styles.clusterInfoItem} key={item.id}>
                <Stack direction="horizontal" align="center" space="small">
                  <Icon size="xxlarge">{item.icon}</Icon>
                  <Stack space="none">
                    <Text size="xsmall">{item.topText}</Text>
                    <Text size="xsmall">{item.bottomText}</Text>
                  </Stack>
                </Stack>
              </Box>
            ))}
          </Stack>
        </Card.Section>
        <Card.Footer>
          <Stack direction="horizontal" justify="between">
            <Button
              size="medium"
              color="outline"
              onPress={openModal}
              isDisabled={fetcher.state !== 'idle' || isChanging}
            >
              {isExternal ? 'Disconnect cluster' : 'Delete cluster'}
            </Button>
            <Modal isOpen={isOpen} onClose={closeModal} dialogProps={{ id: 'delete-cluster' }}>
              <Modal.Header title={`Are you sure you want to delete the cluster ${name}?`} />
              <fetcher.Form method="post">
                <input hidden name="clusterId" defaultValue={id} />
                <Modal.Footer>
                  <Modal.Action autoFocus onPress={closeModal}>
                    Cancel
                  </Modal.Action>
                  <Modal.Action type="submit" name="intent" value={DELETE_CLICKHOUSE}>
                    <Text as="div" color="gigagreen">
                      {fetcher.state !== 'idle' ? <Loading /> : 'Delete Cluster'}
                    </Text>
                  </Modal.Action>
                </Modal.Footer>
              </fetcher.Form>
            </Modal>
          </Stack>
        </Card.Footer>
      </Box>
    </Card>
  )
}
