import { useStore } from 'zustand'

import { authStore } from './authStore'

export const useUser = () => useStore(authStore, state => state.user)
export const useAuthActions = () => useStore(authStore, state => state.actions)
export const useDecodedToken = () => useStore(authStore, state => state.decodedToken)!

export const useDecodedTokenData = () => {
  const { keyCloakClient } = useStore(authStore, state => state)
  return keyCloakClient?.idTokenParsed
}
