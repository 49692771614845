import { type Key } from 'react'
import { type DiskType, type ProvidersSlug } from 'api-client'
import { type ValueOf } from 'plunger'
import { create } from 'zustand'

type DiskFields = {
  name: string
  type: string
  size: number
  unit: DiskType['unitNames'][number]
}
type State = {
  provider?: ProvidersSlug
  region: string
  machine: string
  shards: number
  replicas: number
  disks: Record<string, DiskFields>
  actions: {
    updateProvider: (provider: string) => void
    updateRegion: (region: Key) => void
    updateMachine: (machine: Key) => void
    updateShards: (shards: number) => void
    updateReplicas: (replicas: number) => void
    updateDisk: (id: string, key: keyof DiskFields, value: ValueOf<DiskFields>) => void
    addDisk: () => void
    deleteDisk: (id: string) => void
  }
}

const defaultDisk: DiskFields = { name: 'default', type: '', size: 150, unit: 'GB' }
const defaultMachines = ['m5_large', 'n2_standard_2', ''] as const
const defaultShards = 1
const defaultReplicas = 2

export function isDefaultCluster(state: Pick<State, 'machine' | 'shards' | 'replicas' | 'disks'>) {
  if (
    defaultMachines.includes(state.machine) &&
    defaultShards === state.shards &&
    defaultReplicas === state.replicas &&
    Object.keys(state.disks).length === 1 &&
    Object.values(state.disks).every(
      disk => disk.size === defaultDisk.size && ['GB', 'GiB'].includes(disk.unit),
    )
  ) {
    return true
  }
  return false
}

export const useCreateMCHClusterForm = create<State>()((set, get) => ({
  provider: undefined,
  region: '',
  machine: '',
  shards: defaultShards,
  replicas: defaultReplicas,
  disks: {
    [crypto.randomUUID()]: { ...defaultDisk },
  },
  actions: {
    updateProvider: provider => {
      set(state => ({
        ...state,
        provider: provider as ProvidersSlug,
        region: '',
        machine: '',
        disks: {
          [crypto.randomUUID()]: {
            ...defaultDisk,
          },
        },
      }))
    },
    updateRegion: region => {
      set(state => ({ ...state, region: String(region) }))
    },
    updateMachine: machine => {
      set(state => ({ ...state, machine: String(machine) }))
    },
    updateShards: shards => {
      set(state => ({ ...state, shards }))
    },
    updateReplicas: replicas => {
      set(state => ({ ...state, replicas }))
    },
    updateDisk: (id, key, value) => {
      set(state => ({
        ...state,
        disks: {
          ...state.disks,
          [id]: {
            ...state.disks[id],
            [key]: value,
          },
        },
      }))
    },
    addDisk: () => {
      const id = crypto.randomUUID()
      const name = `disk_${id.split('-')[0]}`

      set(state => ({
        ...state,
        disks: { ...state.disks, [id]: { ...defaultDisk, name } },
      }))
    },
    deleteDisk: id => {
      const { [id]: toDelete, ...newDisks } = get().disks

      set(state => ({ ...state, disks: { ...newDisks } }))
    },
  },
}))

export const useCreateMCHClusterFormActions = () => useCreateMCHClusterForm(state => state.actions)
