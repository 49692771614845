import { useFetcher } from 'react-router-dom'
import { type Project } from 'api-client'

import { UPDATE_FINGERPRINTS_LIMIT } from '~/entities/integrations/qryn'

import { Box } from '~/components/Box'
import { Button } from '~/components/Button'
import { Message } from '~/components/Message'
import { NumberInput } from '~/components/Number'
import { Stack } from '~/components/Stack'
import { Switch } from '~/components/Switch'

import { FINGERPRINTS_NUMBER_STEP, FINGERPRINTS_SPEND_STEP, useFingerprintsLimit } from '../model'

type ManageQrynFingerprintsLimitProps = {
  projectId: Project['id']
  projectSlug: Project['slug']
}

export function ManageQrynFingerprintsLimit({
  projectId,
  projectSlug,
}: ManageQrynFingerprintsLimitProps) {
  const fetcher = useFetcher()

  const {
    fingerprintsNumberLimit,
    fingerprintsSpendLimit,
    hasFingerprintsLimitEnabled,
    handleLimitUpdate,
    handleFeatureToggle,
  } = useFingerprintsLimit({ projectSlug, projectId })

  return (
    <Stack space="small">
      <Switch defaultSelected={hasFingerprintsLimitEnabled} onChange={handleFeatureToggle}>
        Fingerprints limit feature
      </Switch>
      <Message type="warning" size="small">
        The ingestion of data with new unique fingerprints will stop once the limit is reached
        however any data received matching an existing fingerprint will still be ingested.
      </Message>
      <fetcher.Form method="post" action={`/${projectSlug}/stack/qryn`}>
        <Box opacity={hasFingerprintsLimitEnabled ? '100' : '30'}>
          <Stack
            direction={{ mobile: 'vertical', tablet: 'horizontal' }}
            space="small"
            align={{ mobile: 'stretch', tablet: 'end' }}
            justify="start"
          >
            <input
              hidden
              name="fingerprintLimit"
              key={fingerprintsNumberLimit}
              defaultValue={fingerprintsNumberLimit}
            />
            <NumberInput
              label="Montly fingerprints limit"
              value={fingerprintsNumberLimit}
              onChange={value => handleLimitUpdate({ newNumberLimit: value })}
              step={FINGERPRINTS_NUMBER_STEP}
              isReadOnly={!hasFingerprintsLimitEnabled}
            />
            <NumberInput
              label="Monthly spend limit"
              value={fingerprintsSpendLimit}
              onChange={value => handleLimitUpdate({ newSpendLimit: value })}
              step={FINGERPRINTS_SPEND_STEP}
              formatOptions={{
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
              }}
              isReadOnly={!hasFingerprintsLimitEnabled}
            />
            <Button
              type="submit"
              color="brand"
              name="intent"
              value={UPDATE_FINGERPRINTS_LIMIT}
              isDisabled={
                !hasFingerprintsLimitEnabled || !fingerprintsNumberLimit || fetcher.state !== 'idle'
              }
            >
              Save
            </Button>
          </Stack>
        </Box>
      </fetcher.Form>
    </Stack>
  )
}
