import { memo, type SVGProps } from 'react'

const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.667 5.333H5.333A2.667 2.667 0 0 0 2.667 8v18.667a2.667 2.667 0 0 0 2.666 2.666H24a2.667 2.667 0 0 0 2.667-2.666v-9.334m-2-14a2.828 2.828 0 1 1 4 4L16 20l-5.333 1.333L12 16 24.667 3.333Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgEdit)
export default Memo
