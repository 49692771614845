import { memo, type SVGProps } from 'react'

const SvgGoogle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.335 29.67a13.334 13.334 0 1 1 9.43-22.764.58.58 0 0 1-.82.82 12.175 12.175 0 1 0 3.552 9.189H16.335a.58.58 0 1 1 0-1.16H29.09a.58.58 0 0 1 .58.58A13.35 13.35 0 0 1 16.335 29.67Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
)
const Memo = memo(SvgGoogle)
export default Memo
