import { useFetcher } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { grafanaConfigQuery, TOGGLE_GRAFANA_AUTO_ADD_USERS } from '~/entities/integrations/grafana'

import { useDecodedToken } from '~/shared/model/auth/useAuth'

import { Loading } from '~/components/Loading'
import { Switch } from '~/components/Switch'

type ToogleAutoAddUsersProps = {
  projectId: Project['id']
}

export function ToogleAutoAddUsers({ projectId }: ToogleAutoAddUsersProps) {
  const decodedToken = useDecodedToken()
  const { data: config } = useQuery(grafanaConfigQuery(projectId))
  const fetcher = useFetcher()

  return (
    <Switch
      onChange={() => {
        fetcher.submit(
          { intent: TOGGLE_GRAFANA_AUTO_ADD_USERS },
          { method: 'post', encType: 'application/x-www-form-urlencoded' },
        )
      }}
      isSelected={config?.autoAddUsers}
      isDisabled={fetcher.state !== 'idle' || !decodedToken?.organization_id}
      size="large"
    >
      {fetcher.state === 'submitting' && <Loading />}
      Automatically add users in the current project to Grafana.
    </Switch>
  )
}
