import { useRouteError } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { apiClient } from '~/shared/api'
import { ErrorModal } from '~/shared/ui/ErrorModal'

import { Box } from '~/components/Box'
import { Button } from '~/components/Button'
import { HeadTag } from '~/components/HeadTag'
import { Icon } from '~/components/Icon'
import { Mail } from '~/components/Icons'
import { Link } from '~/components/Link'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import * as styles from '~/styles/login-signup.css'

export default function Verify() {
  const { mutate } = useMutation({ mutationFn: apiClient.users.sendVerificationEmail })

  return (
    <>
      <HeadTag tag="title" headId="title">
        {import.meta.env.MODE !== 'production' ? `(${import.meta.env.VITE_ENVIRONMENT}) ` : ''}
        Verify your account - Gigapipe
      </HeadTag>
      <Box className={styles.container}>
        <Box className={[styles.column, styles.leftColumn]}>
          <Stack space="small" align={{ mobile: 'center', tablet: 'start' }}>
            <img
              src="/assets/img/gigapipe-full-logo.svg"
              alt="Gigapipe"
              className={styles.logoContainer}
            />
            <Text className={styles.headline} weight="bold">
              Account verification
            </Text>
          </Stack>
        </Box>
        <Box className={styles.column}>
          <Stack align="center">
            <Icon color="white" size="xxxlarge">
              <Mail />
            </Icon>
            <Text size="xxxlarge" align="center">
              Your account is not yet verified
            </Text>
            <Stack direction="vertical">
              <Text align="center" size="large">
                Please check your inbox for the verification email. Once verified you can come back
                and proceed to login
              </Text>
              <Link to="/login" reloadDocument type="button" buttonColor="brand">
                Login
              </Link>
            </Stack>

            <Box margin="xlarge">
              <Text size="large" align="center">
                Get a new verification email?
              </Text>
              <Box margin="medium">
                <Button
                  type="submit"
                  color="outline"
                  name="send-verification-email"
                  onPress={() => mutate()}
                >
                  Re-send verification email
                </Button>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  )
}

export function ErrorBoundary() {
  const error = useRouteError() as Error

  return <ErrorModal error={error} />
}
