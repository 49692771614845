import { apiClient } from '~/shared/api'

const grafanaQueryKeys = {
  grafanaConfig: (projectId: string) =>
    [{ scope: 'integrations', type: 'grafana', item: 'config', projectId }] as const,
  grafanaDatasources: (projectId: string) =>
    [{ scope: 'integrations', type: 'grafana', item: 'datasources', projectId }] as const,
  grafanaQrynDatasources: (projectId: string) =>
    [{ scope: 'integrations', type: 'grafana-qryn', item: 'datasources', projectId }] as const,
  grafanaUsers: (projectId: string) =>
    [{ scope: 'integrations', type: 'grafana', item: 'users', projectId }] as const,
}

/**
 * Query to fetch the `Grafana` integration configuration
 */
export const grafanaConfigQuery = (projectId: string) => ({
  queryKey: grafanaQueryKeys.grafanaConfig(projectId),
  queryFn: () => apiClient.grafana.getGrafanaConfig(projectId),
})

/**
 * Query to fetch existing `Grafana` datasources
 */
export const grafanaDataSourcesQuery = (projectId: string) => ({
  queryKey: grafanaQueryKeys.grafanaDatasources(projectId),
  queryFn: () => apiClient.grafana.getGrafanaDatasource(projectId),
  enabled: Boolean(projectId),
})

/**
 * Query to fetch existing `Grafana` datasources for `qryn`
 */
export const grafanaQrynDataSourcesQuery = (projectId: string) => ({
  queryKey: grafanaQueryKeys.grafanaQrynDatasources(projectId),
  queryFn: () => apiClient.grafana.getGrafanaQrynDatasource(projectId),
  enabled: Boolean(projectId),
})

/**
 * Query to get all users added to Grafana
 */
export const grafanaUsersQuery = (projectId: string, isOrgAccount: boolean) => ({
  queryKey: grafanaQueryKeys.grafanaUsers(projectId),
  queryFn: () => apiClient.grafana.getGrafanaUsers(projectId),
  enabled: Boolean(projectId) && isOrgAccount,
})
