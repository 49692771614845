import { useRef } from 'react'
import { type AriaSwitchProps, useFocusRing, useSwitch, VisuallyHidden } from 'react-aria'
import { type ToggleProps, useToggleState } from 'react-stately'

import { Box } from '../Box'
import { Stack } from '../Stack'
import { Text } from '../Text'

import { type ShapeVariants } from './Switch.css'
import * as styles from './Switch.css'

export type SwitchProps = ShapeVariants & AriaSwitchProps & ToggleProps

/**
 * Component that renders a toggle button with on/off values.
 */
export const Switch = (props: SwitchProps) => {
  const { children, size, isDisabled } = props
  const state = useToggleState(props)
  const ref = useRef(null)
  const { inputProps } = useSwitch(props, state, ref)
  const { isFocusVisible, focusProps } = useFocusRing()

  return (
    <Stack space="xsmall" direction="horizontal" align="center">
      <Box as="label" display="flex" alignItems="center">
        <VisuallyHidden>
          <input {...inputProps} {...focusProps} ref={ref} />
        </VisuallyHidden>

        <Stack direction="horizontal" space="xsmall" align="center">
          <Box
            className={styles.shape({
              size,
              isFocused: isFocusVisible,
              disabled: isDisabled,
            })}
          >
            <Box
              className={styles.handle({
                size,
                selected: state.isSelected,
              })}
            />
            <Box className={styles.innerLabels}>
              <Stack direction="horizontal" space="xxsmall" justify="between" align="center">
                <Box className={styles.innerLabel({ selected: state.isSelected })}>
                  <Text size="xxsmall">ON</Text>
                </Box>

                <Box className={styles.innerLabel({ selected: !state.isSelected })}>
                  <Text size="xxsmall">OFF</Text>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Box>
      {children}
    </Stack>
  )
}
