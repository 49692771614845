import { transformCamelObjectKeysToSnake } from 'plunger'
import { z } from 'zod'

import { type Rooquest } from '../../lib/rooquest'
import { type ProjectId } from '../../projects/model'
import { type MessageResponse } from '../../utils/types'

import {
  loggenConfigPayload,
  type LoggenConfigPayloadData,
  loggenConfigResponse,
  type LoggenEnableData,
  loggenEnableSchema,
  loggenLogsCategoriesResponse,
  loggenLogsConfigSchema,
} from './model'

const loggenLogsConfigOptions = z.array(loggenLogsConfigSchema)

export default class LoggenService {
  private readonly rooquest: Rooquest

  constructor(rooquest: Rooquest) {
    this.rooquest = rooquest
  }

  /**
   * Add `Loggen` integration to a project
   */
  public enableLoggen = async (data: LoggenEnableData) => {
    const payload = loggenEnableSchema.parse(data)
    const res = await this.rooquest.post('integrations/loggen/', {
      query: transformCamelObjectKeysToSnake(payload),
    })

    return res as MessageResponse
  }

  /**
   * Get `Loggen` configuration for a project
   */
  public getLoggenConfig = async (projectId: ProjectId) => {
    const res = await this.rooquest.get('integrations/loggen/config', {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return loggenConfigResponse.parse(res)
  }

  /**
   * Update `Loggen` configuration
   */
  public updateLoggenConfig = async (data: LoggenConfigPayloadData) => {
    const { projectId, ...payload } = loggenConfigPayload.parse(data)
    const res = await this.rooquest.patch('integrations/loggen/config', {
      body: transformCamelObjectKeysToSnake(payload),
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return res as MessageResponse
  }

  /**
   * Get `Loggen` logs configuration
   */
  public getLoggenLogsConfig = async (categories?: string[] | string) => {
    const res = await this.rooquest.get('integrations/loggen/config/logs', {
      query: {
        categories: categories
          ? Array.isArray(categories)
            ? categories
            : [categories]
          : undefined,
      },
    })

    return loggenLogsConfigOptions.parse(res)
  }

  /**
   * Get `Loggen` categories
   */
  public getLoggenLogsCategories = async () => {
    const res = await this.rooquest.get('integrations/loggen/config/logs/categories')

    return loggenLogsCategoriesResponse.parse(res)
  }

  /**
   * Run `Loggen`
   */
  public runLoggen = async ({
    projectId,
    onChunkRead,
  }: {
    projectId: string
    onChunkRead?: (chunk: string) => void
  }) => {
    const res = await this.rooquest.post('integrations/loggen/run', {
      query: transformCamelObjectKeysToSnake({ projectId }),
      headers: {
        'Transfer-Encoding': 'chunked',
      },
      onChunkRead,
    })

    return res as MessageResponse
  }
}
