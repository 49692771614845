import { memo, type SVGProps } from 'react'

const SvgLayers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.667 22.667 16 29.333l13.333-6.666M2.667 16 16 22.667 29.333 16M16 2.667 2.667 9.333 16 16l13.333-6.667L16 2.667Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgLayers)
export default Memo
