import { type ApiClientOptions } from '../client'
import { type CustomRequestInit } from '../types'

/**
 * A spicy wrapper around the fetch API to make all your requests more tasty
 */
export class Rooquest {
  private readonly baseUrl: ApiClientOptions['baseUrl']

  private readonly enableAuth: ApiClientOptions['enableAuth']

  private readonly getAuthHeader: ApiClientOptions['getAuthHeader']

  private readonly onErrorResponse: ApiClientOptions['onErrorResponse']

  constructor(options: ApiClientOptions) {
    this.baseUrl = options.baseUrl
    this.getAuthHeader = options.getAuthHeader
    this.onErrorResponse = options.onErrorResponse
    this.enableAuth = options.enableAuth ?? true
  }

  static processResponseText(responseText: string) {
    try {
      return JSON.parse(responseText)
    } catch (error) {
      return responseText
    }
  }

  private async request(endpoint: string, options?: CustomRequestInit) {
    const { headers, query, body, onChunkRead, ...extraOpts } = options ?? {}

    let authHeader
    if (this.enableAuth) {
      authHeader = await this.getAuthHeader()
    }

    const reqOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json',
        'Transfer-Encoding': 'chunked',
        ...authHeader,
        ...headers,
      },
      ...extraOpts,
    }

    if (body) {
      reqOptions.body = typeof body === 'object' ? JSON.stringify(body) : body
    }
    let queryString = ''
    if (query) {
      const parsedQuery = Object.entries(query)
        .reduce(
          (acc, [key, value]) => {
            if (value === null || typeof value === 'undefined') return acc
            if (typeof value === 'boolean' || typeof value === 'number') {
              acc.push([key, value.toString()])
              return acc
            }
            if (Array.isArray(value)) {
              const entries = value.map(v => [key, v.toString()])
              acc.push(...entries)
              return acc
            }
            acc.push([key, value])
            return acc
          },
          [[]] as string[][],
        )
        .filter(q => q.length)

      queryString = new URLSearchParams(parsedQuery).toString()
      queryString = queryString && `?${queryString}`
    }
    if (this.enableAuth && !authHeader) {
      return new Promise(res => res([]))
    }
    const res = await fetch(`${this.baseUrl}${endpoint}${queryString}`, reqOptions)

    if (!res.ok) {
      await this.onErrorResponse(res, options)
    }

    if (onChunkRead) {
      const reader = res.body?.pipeThrough(new TextDecoderStream()).getReader()
      if (!reader) throw new Error('Streaming not supported')

      let isDone = false
      while (!isDone) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { done, value } = await reader?.read()

        if (done) {
          isDone = true
          return { done: true }
        }
        onChunkRead(value)
      }
    }

    return Rooquest.processResponseText(await res.text())
  }

  public async get(endpoint: string, options?: CustomRequestInit) {
    return this.request(endpoint, { ...options, method: 'GET' })
  }

  public async post(endpoint: string, options?: CustomRequestInit) {
    return this.request(endpoint, { ...options, method: 'POST' })
  }

  public async patch(endpoint: string, options?: CustomRequestInit) {
    return this.request(endpoint, { ...options, method: 'PATCH' })
  }

  public async delete(endpoint: string, options?: CustomRequestInit) {
    return this.request(endpoint, { ...options, method: 'DELETE' })
  }
}
