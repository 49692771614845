import { type ReactNode } from 'react'

import { Box } from '../Box'
import { Text } from '../Text'

import { type Variants } from './Message.css'
import * as styles from './Message.css'

export type MessageProps = {
  /**
   * If you pass a string as a children of Message it will be wrapped in a Text with the matching color from the side indicator
   * Otherwise you are free to render any component inside the Message and only the side indicator will retain the color matching the type prop
   */
  children: ReactNode
} & Variants

export const Message = ({ children, size, type }: MessageProps) => (
  <Box
    className={styles.variants({
      type,
      size,
    })}
  >
    {typeof children === 'string' ? (
      <Text className={styles.text({ type })} size={size}>
        {children}
      </Text>
    ) : (
      children
    )}
  </Box>
)
