import { Suspense, useState } from 'react'
import {
  type ActionFunctionArgs,
  Await,
  defer,
  isRouteErrorResponse,
  json,
  redirect,
  useFetcher,
  useLoaderData,
  useRouteError,
} from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import { type QueryClient, useQuery } from '@tanstack/react-query'
import {
  type BillingAccount,
  type CreateBillingAccountData,
  type CreateOrganizationData,
  isZodError,
} from 'api-client'
import { authorize } from 'keycloak'
import { type typeToFlattenedError, z } from 'zod'

import { CreditCardForm } from '~/features/addPaymentMethod'
import { notify } from '~/features/notifications'

import { ADD_PAYMENT_METHOD, CREATE_BILLING_ACCOUNT } from '~/entities/billing'
import { CREATE_ORGANIZATION, organizationsOwnedQuery } from '~/entities/organizations'
import { userOrganizationsQuery } from '~/entities/users'

import { apiClient } from '~/shared/api'
import { getFormData } from '~/shared/lib/parseRequest'
import { useNavigate } from '~/shared/lib/reactRouterWrappers'
import { authStore } from '~/shared/model/auth'
import { isApiError } from '~/shared/model/errors'
import { RouteErrorMessage } from '~/shared/ui/RouteErrorMessage'

import { LANGUAGE } from '~/i18n/utils'

import { Box } from '~/components/Box'
import { Button } from '~/components/Button'
import { Card } from '~/components/Card'
import { HeadTag } from '~/components/HeadTag'
import { Input } from '~/components/Input'
import { Message } from '~/components/Message'
import { PageHeader } from '~/components/PageHeader'
import { Stack } from '~/components/Stack'

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY)
export function loader(queryClient: QueryClient) {
  return async () =>
    authorize(authStore, async () => {
      const ownedOrganizations = await queryClient.ensureQueryData(organizationsOwnedQuery())

      return defer({ ownedOrganizations, stripe: stripePromise })
    })
}

export async function action({ request }: ActionFunctionArgs, queryClient: QueryClient) {
  const { changeLoginType } = authStore.getState().actions
  const data = await getFormData(request)
  const intents = z
    .enum([CREATE_ORGANIZATION, CREATE_BILLING_ACCOUNT, ADD_PAYMENT_METHOD])
    .safeParse(data.intent)

  if (!intents.success) {
    throw json(
      {
        message: 'This operation is not valid',
        description:
          'You tried to perform an invalid operation. Nothing will be changed regarding your billing information. Please reload the page and try again.',
      },
      { status: 400 },
    )
  }

  switch (intents.data) {
    case 'CREATE_BILLING_ACCOUNT': {
      try {
        const billingAccountId = (await apiClient.billing.createBillingAccount(data))?.id

        return json({
          fields: data,
          billingAccountId,
        })
      } catch (error) {
        if (isZodError<CreateBillingAccountData>(error)) {
          return json({ fields: data, errors: error.flatten() }, { status: 400 })
        }

        return json({
          fields: data,
          errors: {
            formErrors: [
              isApiError(error)
                ? error.description
                : 'An error occurred while creating the billing account for your organization.',
            ],
          },
        })
      }
    }
    case 'ADD_PAYMENT_METHOD': {
      await apiClient.billing.attachPaymentMethod(data)

      return json({})
    }
    case 'CREATE_ORGANIZATION': {
      try {
        // gives name and displayName

        const { billingAccountId, displayName } = data

        if (billingAccountId && displayName) {
          const newOrganization = await apiClient.organizations.createOrganization({
            billingAccountId,
            displayName,
          })

          if (newOrganization) {
            await Promise.all([
              queryClient.invalidateQueries(organizationsOwnedQuery()),
              queryClient.invalidateQueries(userOrganizationsQuery()),
            ])

            window.sessionStorage.setItem('orgLogin', 'true')
            await changeLoginType(newOrganization?.id)
            notify.success('Organization created successfully')

            if (newOrganization?.id) {
              return redirect(`/login?organization=${Number(newOrganization.id)}`)
            } else {
              return json({})
            }
          }
        }
      } catch (error) {
        if (isZodError<CreateOrganizationData>(error)) {
          return json({ fields: data, errors: error.flatten() }, { status: 400 })
        }
        return json({
          fields: data,
          billingAccountId: undefined,
          errors: {
            formErrors: [
              isApiError(error)
                ? error.description
                : 'An error occurred while creating the organization.',
            ],
          },
        })
      }
    }
  }
}

type ActionData = {
  billingAccountId?: BillingAccount['id']
  attached?: boolean
  fields?: CreateOrganizationData & CreateBillingAccountData
  errors?: typeToFlattenedError<CreateOrganizationData> &
    typeToFlattenedError<CreateBillingAccountData>
}

export default function CreateOrganizationPage() {
  const navigate = useNavigate()
  const loaderData = useLoaderData() as any
  const fetcher = useFetcher<ActionData>()
  const [orgName, setOrgName] = useState('')
  const [orgNameValid, setOrgNameValid] = useState(false)
  const [validationErrorMessage, setValidationErrorMessage] = useState('')

  const { data: ownedOrganizations = [], isFetching: isFetchingOwnedOrganizations } = useQuery(
    organizationsOwnedQuery(),
  )

  const handleOrgName = (orgName: string) => {
    setOrgName(() => orgName)

    const checkUserRegex = /^[a-zA-Z0-9-_ ]*$/

    if (checkUserRegex.test(orgName)) {
      setOrgName(() => orgName)

      if (orgName.length > 2) {
        setOrgNameValid(() => true)
        setValidationErrorMessage(() => '')
      } else if (orgName.length < 3) {
        setOrgNameValid(() => false)
        setValidationErrorMessage(() => 'Must be at least 3 characters')
      }
    } else {
      setValidationErrorMessage(() => 'Organization name must match A-z 0-9 -_')
      setOrgNameValid(() => false)
    }
  }

  const billingAccountId = fetcher.data?.billingAccountId
  const billingAccountName = `${orgName} billing account`
  const isOrganizationOwner = !isFetchingOwnedOrganizations && Boolean(ownedOrganizations?.length)

  const onSwitchToOrg = () => {
    navigate(`/login?organization=${Number(ownedOrganizations[0].id)}`)
  }

  return (
    <>
      <HeadTag tag="title" headId="title">
        {import.meta.env.MODE !== 'production' ? `(${import.meta.env.VITE_ENVIRONMENT}) ` : ''}
        Create an organization - Gigapipe
      </HeadTag>

      <Box paddingX={{ mobile: 'xxsmall', tablet: 'large', desktop: 'xxlarge' }}>
        <Stack>
          <PageHeader
            title="Create an organization"
            subtitle="Organizations allow you to invite people and collaborate on the same projects to share the resources."
          />

          {isOrganizationOwner && (
            <Message type="warning" size="small">
              <Stack direction="horizontal" justify="between" align="center">
                <span>You already own an organization and cannot create more.</span>
                <Button onPressEnd={onSwitchToOrg}>Switch to {ownedOrganizations[0].name}</Button>
              </Stack>
            </Message>
          )}

          {!isOrganizationOwner && (
            <>
              {/* <Message type="success" size="small">
                <b>30 days trial</b>. Using Gigapipe in Organization mode charges a flat monthly fee
                of $249 per month.
              </Message> */}
              <Card>
                <Card.Header
                  title={
                    billingAccountId
                      ? 'Set the payment method for this organization.'
                      : 'Choose a name for your organization.'
                  }
                />
                <Card.Section>
                  <Stack space="medium">
                    {fetcher.data?.errors?.formErrors.length ? (
                      <Message size="small" type="error">
                        {fetcher.data?.errors?.formErrors.join('\n')}
                      </Message>
                    ) : null}
                    <fetcher.Form method="post">
                      <Stack
                        space="small"
                        align={'center'}
                        justify="between"
                        direction="horizontal"
                      >
                        <Box flex="fill">
                          <Input
                            name="displayName"
                            label="Organization name"
                            value={orgName}
                            onChange={handleOrgName}
                            isReadOnly={Boolean(billingAccountId)}
                            isValidationError={!orgNameValid}
                            validationErrorMessage={validationErrorMessage}
                            errorMessage={fetcher.data?.errors?.fieldErrors?.displayName}
                          />
                        </Box>
                        <input name="name" type="hidden" defaultValue={billingAccountName} />

                        <Box>
                          <Button
                            type="submit"
                            color="brand"
                            name="intent"
                            value={CREATE_BILLING_ACCOUNT}
                            isLoading={fetcher.state === 'submitting'}
                            isDisabled={
                              fetcher.state === 'submitting' ||
                              !orgNameValid ||
                              Boolean(billingAccountId) ||
                              !orgName.trim()
                            }
                          >
                            Create
                          </Button>
                        </Box>
                      </Stack>
                    </fetcher.Form>

                    <div hidden={!billingAccountId}>
                      <Suspense fallback="">
                        <Await resolve={loaderData.stripe}>
                          {stripe => (
                            <Elements options={{ locale: LANGUAGE }} stripe={stripe}>
                              {billingAccountId && orgName ? (
                                <CreditCardForm
                                  billingAccountId={billingAccountId}
                                  onCancel={() => {
                                    localStorage.removeItem('stripeMessage')
                                    window.dispatchEvent(new Event('storage'))
                                    return navigate(-1)
                                  }}
                                  onSuccess={() => {
                                    setTimeout(() => {
                                      return fetcher.submit(
                                        {
                                          intent: CREATE_ORGANIZATION,
                                          billingAccountId,
                                          displayName: orgName,
                                        },
                                        {
                                          method: 'post',
                                          encType: 'application/x-www-form-urlencoded',
                                        },
                                      )
                                    }, 500)
                                  }}
                                  onError={() => {
                                    return notify.error('Error while creating organization')
                                  }}
                                />
                              ) : (
                                'Missing organization name'
                              )}
                            </Elements>
                          )}
                        </Await>
                      </Suspense>
                    </div>
                  </Stack>
                </Card.Section>
              </Card>
            </>
          )}
        </Stack>
      </Box>
    </>
  )
}

export function ErrorBoundary() {
  const error = useRouteError() as Error

  if (isRouteErrorResponse(error)) {
    return <RouteErrorMessage message={error.data.message} description={error.data.description} />
  }
  throw error
}
