import {
  isRouteErrorResponse,
  json,
  type LoaderFunctionArgs,
  useParams,
  useRouteError,
} from 'react-router-dom'
import { type QueryClient, useQuery } from '@tanstack/react-query'
import { authorize } from 'keycloak'
// eslint-disable-next-line import/no-unresolved
import { type MDXComponents, type MDXContent } from 'mdx/types'
import invariant from 'tiny-invariant'

import { qrynConfigQuery } from '~/entities/integrations/qryn'
import { checkProjectSlugParam, useProjectIdParam } from '~/entities/projects'

import { authStore } from '~/shared/model/auth'

import { Box } from '~/components/Box'
import { Icon } from '~/components/Icon'
import { BrokenPipe } from '~/components/Icons'
import { Link } from '~/components/Link'
import { Message } from '~/components/Message'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import '@code-hike/mdx/dist/index.css'

const modules = import.meta.glob<MDXContent>('~/qryn-docs/**/*.mdx', { import: 'default' })

const BASE_PATH = '/src/shared/assets/qryn-docs'

function fetchQrynExamples(signalType: string, ingestionFormat: string): Promise<MDXContent> {
  return modules[`${BASE_PATH}/${signalType}/${ingestionFormat}.mdx`]()
}

const getQrynExamplesQuery = (signalType: string, ingestionFormat: string) => ({
  queryKey: ['qrynExamples', signalType, ingestionFormat],
  queryFn: () => fetchQrynExamples(signalType, ingestionFormat),
})

export function loader(queryClient: QueryClient) {
  return async ({ params }: LoaderFunctionArgs) =>
    authorize(authStore, async () => {
      const { projectId } = checkProjectSlugParam(params)
      const { ingestionMethod } = params

      if (!ingestionMethod) {
        throw json({})
      }

      const [signalType, ingestionFormat] = ingestionMethod.split('--')

      if (!signalType || !ingestionFormat) {
        throw json(
          {
            message: `Invalid ingestion method.`,
            description: `Please select a different method from the list above`,
          },
          { status: 404 },
        )
      }

      try {
        await Promise.all([
          queryClient.ensureQueryData(qrynConfigQuery(projectId)),
          queryClient.ensureQueryData(getQrynExamplesQuery(signalType, ingestionFormat)),
        ])

        return json({})
      } catch (error) {
        throw json(
          {
            message: `Invalid ingestion method.`,
            description: `Please select a different method from the list above`,
          },
          { status: 404 },
        )
      }
    })
}

const components: MDXComponents = {
  h1: ({ children }) => (
    <Box marginY="medium">
      <Text as="h1" font="markdown" size="xxxlarge">
        {children}
      </Text>
    </Box>
  ),
  h2: ({ children }) => (
    <Box marginY="medium">
      <Text as="h2" font="markdown" size="xxlarge">
        {children}
      </Text>
    </Box>
  ),
  h3: ({ children }) => (
    <Box marginY="small">
      <Text as="h3" font="markdown" size="h3">
        {children}
      </Text>
    </Box>
  ),
  h4: ({ children }) => (
    <Box marginY="small">
      <Text as="h4" font="markdown" size="large">
        {children}
      </Text>
    </Box>
  ),
  p: ({ children }) => (
    <Box marginY="small" fontSize={'md'}>
      <Text as="div" size="md" font="markdown" lineHeight={'large'}>
        {children}
      </Text>
    </Box>
  ),
  a: ({ href, children }) => (
    <Link to={href!} type="markdown" target="_blank" rel="noreferrer">
      {children}
    </Link>
  ),
  blockquote: ({ children }) => (
    <Box marginY="small" fontFamily="markdown">
      <Message type="info" size="small">
        {children}
      </Message>
    </Box>
  ),
  code: ({ children }) => (
    <Box
      backgroundColor="gray700"
      display="inline-block"
      fontFamily="mono"
      fontSize="small"
      paddingY="xxsmall"
      paddingX="xsmall"
      borderRadius="small"
    >
      {children}
    </Box>
  ),
}

export default function QrynExamples() {
  const { projectId } = useProjectIdParam()
  const params = useParams()
  invariant(params.ingestionMethod, 'Missing ingestionMethod')
  const [signalType, ingestionFormat] = params.ingestionMethod.split('--')

  const { data: qrynConfig } = useQuery(qrynConfigQuery(projectId))
  const { data: Content } = useQuery(getQrynExamplesQuery(signalType, ingestionFormat))

  if (!Content) return null
  return (
    <Box>
      <Content
        components={components}
        url={qrynConfig?.url}
        apiKey={qrynConfig?.apiKey}
        apiSecret={qrynConfig?.apiSecret}
      />
    </Box>
  )
}

export function ErrorBoundary() {
  const error = useRouteError() as Error

  if (isRouteErrorResponse(error) && error.status === 404) {
    return (
      <Stack space="small" direction="vertical" justify="center" align="center">
        <Icon size="128" color="white">
          <BrokenPipe />
        </Icon>
        <Text as="h3" size="xxlarge">
          {error.data.message}
        </Text>
        <Text>{error.data.description}</Text>
      </Stack>
    )
  }

  throw error
}
