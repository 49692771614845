import { useRef } from 'react'
import {
  type AriaSelectOptions,
  HiddenSelect,
  mergeProps,
  useButton,
  useFocusRing,
  useSelect,
} from 'react-aria'
import {
  Item,
  Section,
  type SelectProps as StatelySelectProps,
  useSelectState,
} from 'react-stately'

import { ChevronDown, Info } from '~/components/Icons'

import { Box } from '../Box'
import { Icon } from '../Icon'
import { ListBox } from '../ListBox'
import { Loading } from '../Loading'
import { Popover } from '../Popover'
import { Stack } from '../Stack'
import { Text } from '../Text'
import { Tooltip, type TooltipProps } from '../Tooltip'

import * as styles from './Select.css'

export type SelectProps<T> = {
  /**
   * The default text shown inside the select when no option has been selected.
   */
  defaultText?: string
  /**
   * Displays an optional info icon that opens a popup with the content passed in this prop
   */
  tooltip?: TooltipProps['content']
  /**
   * Option to display the Select in a more compact way
   */
  variant?: 'default' | 'compact'
  /**
   * Allow to set a specific width for the Select.
   * If not set, the width of the Select will be the width of its parent.
   */
  width?: number
  /**
   * Boolean flag to indicate that the Select items are beeing loaded
   */
  isLoading?: boolean
} & AriaSelectOptions<T> &
  StatelySelectProps<T>

export function Select<T extends object>(props: SelectProps<T>) {
  const {
    label,
    tooltip,
    name,
    errorMessage,
    description,
    defaultText,
    isDisabled,
    isLoading,
    width,
    variant = 'default',
  } = props
  const state = useSelectState(props)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLButtonElement>(null)
  const { labelProps, triggerProps, valueProps, menuProps, errorMessageProps, descriptionProps } =
    useSelect(props, state, ref)

  const { buttonProps } = useButton({ ...triggerProps, isDisabled }, ref)

  const { focusProps, isFocusVisible } = useFocusRing()

  const triggerWidth = wrapperRef.current?.offsetWidth

  return (
    <Box
      ref={wrapperRef}
      display="inline-flex"
      flexDirection="column"
      position="relative"
      style={{ width: width ? `min(100%,${width}px)` : '100%' }}
    >
      {(label ?? tooltip) && (
        <Stack direction="horizontal" space="small" align="center">
          {label && (
            <Text
              {...labelProps}
              as="label"
              weight="light"
              lineHeight="medium"
              color="gray600"
              htmlFor={buttonProps.id}
            >
              {label}
            </Text>
          )}
          {tooltip && (
            <Tooltip content={tooltip} delay={0}>
              <Icon>
                <Info />
              </Icon>
            </Tooltip>
          )}
        </Stack>
      )}

      <HiddenSelect state={state} triggerRef={ref} label={label} name={name} />
      <button
        type="button"
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        className={styles.triggerButton({
          isDisabled,
          isFocused: isFocusVisible,
          isOpen: state.isOpen,
          error: !!errorMessage,
          variant,
        })}
      >
        <span {...valueProps} className={styles.value({ variant })}>
          {state.selectedItem ? state.selectedItem.rendered : defaultText ?? 'Select an option'}
        </span>
        {isLoading ? (
          <Loading />
        ) : (
          <Icon>
            <ChevronDown />
          </Icon>
        )}
      </button>
      {state.isOpen && (
        <Popover state={state} triggerRef={ref} placement="bottom start" width={triggerWidth}>
          <ListBox {...menuProps} state={state} />
        </Popover>
      )}
      {errorMessage ? (
        <div className={styles.error({ variant })} {...errorMessageProps}>
          {errorMessage}
        </div>
      ) : (
        description && (
          <div className={styles.description({ variant })} {...descriptionProps}>
            {description}
          </div>
        )
      )}
    </Box>
  )
}

Select.Item = Item
Select.Section = Section
