import { CopyButton } from '~/shared/ui/CopyButton'

import { Box } from '~/components/Box'
import { Icon } from '~/components/Icon'
import { ArrowUpRight } from '~/components/Icons'
import { Link } from '~/components/Link'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

export type IntegrationLinkProps = {
  /**
   * Label to display next to the link
   */
  label: string
  /**
   * URL to copy to the clipboard on press
   */
  copyURL?: string
  /**
   * URL to open on press
   */
  launchURL?: string
  /**
   * Set text transform styling
   */
  textTransform?: 'uppercase' | 'lowerCase' | 'capitalize' | 'none'

  url?: string

  disabled?: boolean
}

/**
 * Component used to display important links related to the integration.
 */
export const IntegrationLink = ({
  label,
  launchURL,
  disabled = false,
  textTransform = 'capitalize',
}: IntegrationLinkProps) => {
  const renderInnerLinkButton = (launchURL: string) => {
    if (launchURL !== '') {
      return (
        <Link
          isExternal={true}
          to={launchURL}
          buttonType="extended"
          textTransform={textTransform}
          buttonSize="small"
          disabled={disabled}
          type="button"
          target="_blank"
        >
          <Stack direction="horizontal" justify="between" space="xsmall" align="center">
            <Box padding="xsmall">
              <Text color={disabled ? 'whiteTransparent' : 'white'} size={'medium'}>
                {label}
              </Text>
            </Box>
          </Stack>
        </Link>
      )
    }

    return (
      <>
        <Stack direction="horizontal" justify="between" space="xsmall" align="center">
          <Box padding="xsmall">
            <Text>{label}</Text>
          </Box>
        </Stack>
        <Stack direction="horizontal" space="xsmall" align="center">
          <Icon size="medium">
            <ArrowUpRight />
          </Icon>
        </Stack>{' '}
      </>
    )
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      backgroundColor="gigadarkblue"
      borderRadius="medium"
      width="auto"
      padding="xxsmall"
      flex="fill"
    >
      {renderInnerLinkButton(launchURL ?? '')}
    </Box>
  )
}

export const InfoIntegrationLink = ({ label, copyURL, url = '' }: IntegrationLinkProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      backgroundColor="gigadarkblue"
      borderRadius="medium"
      width="auto"
      padding="xxsmall"
      flex="fill"
    >
      <Stack direction="horizontal" justify="between" space="xsmall" align="center">
        <Box
          padding="xsmall"
          display="flex"
          gap="small"
          alignItems={'center'}
          flexDirection={'row'}
        >
          <Stack direction={'vertical'} space="xxsmall">
            <Text size="small">{label}</Text>
            {url && (
              <Text size="small" color="gray600">
                {url}
              </Text>
            )}
          </Stack>
        </Box>
      </Stack>
      <Stack direction={'horizontal'} justify={'between'} space="xsmall" align={'center'}>
        <Box display={'flex'} alignItems={'center'}>
          {copyURL && <CopyButton value={copyURL} />}
        </Box>
      </Stack>
    </Box>
  )
}
