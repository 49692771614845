import { type AllHTMLAttributes, createElement, type ElementType, forwardRef } from 'react'
import { type ClassValue, clsx } from 'clsx'

import * as resetStyles from '~/styles/reset.css'
import { type Sprinkles, sprinkles } from '~/styles/sprinkles.css'

type HTMLProperties = Omit<
  AllHTMLAttributes<HTMLElement>,
  'as' | 'className' | 'color' | 'height' | 'width'
>

type Props = Sprinkles &
  HTMLProperties & {
    /**
     * Defines the rendered HTML element
     * @default 'div'
     */
    as?: ElementType
    /**
     * Allows to override the styles by passing a className
     */
    className?: ClassValue
  }

/**
 * Box is a component that renders the element `as` prop with a limited set of styles defined as sprinkles.
 */
export const Box = forwardRef<HTMLElement, Props>(
  ({ as: Element = 'div', className, ...props }: Props, ref) => {
    const atomProps: Record<string, unknown> = {}
    const nativeProps: Record<string, unknown> = {}

    Object.keys(props).forEach(key => {
      if (sprinkles.properties.has(key as keyof Sprinkles)) {
        atomProps[key] = props[key as keyof typeof props]
      } else {
        nativeProps[key] = props[key as keyof typeof props]
      }
    })

    const styles = clsx(
      resetStyles.base,
      resetStyles.element[Element as keyof typeof resetStyles.element],
      sprinkles({
        ...atomProps,
      }),

      className,
    )

    return createElement(Element, {
      className: styles,
      ...nativeProps,
      ref,
    })
  },
)
export type BoxProps = Parameters<typeof Box>[0]

Box.displayName = 'Box'
