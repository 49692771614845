import { useFetcher } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { ADD_HEPIC_USER, hepicUserGroups, hepicUsersQuery } from '~/entities/integrations/hepic'
import { useProjectIdParam, usersFromProjectQuery } from '~/entities/projects'

import { useDecodedToken } from '~/shared/model/auth/useAuth'

//import { useUser } from '~/shared/model/auth'
import { Button } from '~/components/Button'
import { Grid } from '~/components/Grid'
import { Link } from '~/components/Link'
import { Select } from '~/components/Select'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

type AddUserToHepicProps = {
  projectId: Project['id']
}

export const AddUserToHepic = ({ projectId }: AddUserToHepicProps) => {
  const { projectSlug } = useProjectIdParam()

  //const loggedInUser = useUser()

  const decodedToken = useDecodedToken()
  const isOrgAccount = Boolean(decodedToken?.organization_id)
  const { data: usersFromProject } = useQuery(usersFromProjectQuery(projectId, isOrgAccount))
  const { data: hepicUsers } = useQuery(hepicUsersQuery(projectId, isOrgAccount))

  const fetcher = useFetcher()
  const submittedUserId = fetcher.formData?.get('users[0][email]') as string
  const submittedUserGroup = fetcher.formData?.get('users[0][group]') as string
  const isHepicAdmin =
    hepicUsers?.find(user => user.email === decodedToken?.email)?.group === 'admin'
  const usersToAddToHepic = (usersFromProject ?? []).filter(
    user => !hepicUsers?.some(u => u.email === user.email),
  )
  if (isOrgAccount && usersToAddToHepic && !usersToAddToHepic.length && isHepicAdmin) {
    return (
      <Text>
        All members of this project have already been added to hepic.
        <br />
        Add your teammates to the{' '}
        <Link to={projectSlug ? `/${projectSlug}/settings` : '/settings'} type="text">
          project
        </Link>{' '}
        or invite new ones to your{' '}
        <Link to={projectSlug ? `/${projectSlug}/organization` : '/organization'} type="text">
          organization
        </Link>
        .
      </Text>
    )
  }
  return (
    <fetcher.Form method="post">
      <Grid space="medium" columns={12} align="flex-end">
        <Grid.Item span={{ desktop: 7, tablet: 12, mobile: 12 }}>
          <Select
            key={submittedUserId}
            name="users[0][user_id]"
            label="Select a member"
            items={usersToAddToHepic}
            defaultSelectedKey={submittedUserId}
            isDisabled={!isOrgAccount || !isHepicAdmin}
          >
            {item => (
              <Select.Item key={item.id} textValue={String(item.id)}>
                <Text>
                  {item.givenName + ' ' + item.familyName} ({item.email})
                </Text>
              </Select.Item>
            )}
          </Select>
        </Grid.Item>
        <Grid.Item span={{ desktop: 3, tablet: 9, mobile: 12 }}>
          <Select
            key={submittedUserGroup}
            label="Assign a role"
            name="users[0][group]"
            items={hepicUserGroups.map(item => ({ id: item, name: item }))}
            defaultSelectedKey={submittedUserGroup}
            isDisabled={!isOrgAccount || !isHepicAdmin}
          >
            {item => (
              <Select.Item key={item.id} textValue={item.id}>
                <Text>{item.name}</Text>
              </Select.Item>
            )}
          </Select>
        </Grid.Item>
        <Grid.Item span={{ desktop: 2, tablet: 3, mobile: 12 }}>
          <Stack>
            <Button
              aria-label="Add user to Hepic"
              name="intent"
              value={ADD_HEPIC_USER}
              type="submit"
              isLoading={fetcher.state === 'submitting'}
              isDisabled={fetcher.state === 'submitting' || !isOrgAccount || !isHepicAdmin}
            >
              Add to HEPIC
            </Button>
          </Stack>
        </Grid.Item>
      </Grid>
    </fetcher.Form>
  )
}
