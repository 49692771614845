import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { timeRanges } from '~/features/showQrynMetrics/model'

import { qrynConfigQuery, qrynMetricsQuery } from '~/entities/integrations/qryn'

import { Link } from '~/components/Link'
import { Message } from '~/components/Message'

type AlertQrynFingerprintsLimitAlert = {
  projectId: Project['id']
  projectSlug: Project['slug']
}

export function AlertQrynFingerprintsLimitAlert({
  projectId,
  projectSlug,
}: AlertQrynFingerprintsLimitAlert) {
  const { data: config } = useQuery(qrynConfigQuery(projectId))
  const { data: metrics } = useQuery({
    ...qrynMetricsQuery(projectId, timeRanges.currentMonth.start, timeRanges.currentMonth.end),
    keepPreviousData: true,
  })

  const isFingerprintsLimitReached =
    metrics && config
      ? Number(metrics?.uniqueMetricSeriesCount) >= config.fingerprintLimit &&
        config.fingerprintLimit > 0
      : false

  if (!isFingerprintsLimitReached) return null
  return (
    <Message type="error" size="small">
      The ingestion of data with new unique fingerprints has been stopped since the configured limit
      was reached. However any data received matching an existing fingerprint will still be
      ingested. Increase the limit from the{' '}
      <Link type="text" to={`/${projectSlug}/stack/qryn/config`}>
        configuration page
      </Link>
    </Message>
  )
}
