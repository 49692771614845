import { Button } from '~/components/Button'
import { Icon } from '~/components/Icon'
import { BrokenPipe } from '~/components/Icons'
import { Link } from '~/components/Link'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import * as styles from './RouteErrorMessage.css'

type RouteErrorMessageProps = {
  /**
   * Main message to display.
   */
  message: string
  /**
   * Longer text to display under the message
   */
  description: string
  /**
   * Text for the link button
   */
  goToText?: string
  /**
   * Path for the link button
   */
  goToPath?: string
}

/**
 * Component used when a controlled error is thrown from a route `loader/action`
 */
export function RouteErrorMessage({
  message,
  description,
  goToPath,
  goToText,
}: RouteErrorMessageProps) {
  return (
    <div className={styles.wrapper}>
      <Stack direction="vertical" justify="center" align="center">
        <Icon size="128" color="white">
          <BrokenPipe />
        </Icon>
        <Text as="h3" size="xxlarge">
          {message}
        </Text>
        <Text>{description}</Text>
        {goToPath && goToText ? (
          <Link to={goToPath} type="button" buttonColor="accent">
            {goToText}
          </Link>
        ) : (
          <Button color="accent" onPress={() => window.location.reload()}>
            Reload page
          </Button>
        )}
      </Stack>
    </div>
  )
}
