import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { type Project, type Region } from 'api-client'

import { zonesQuery } from '~/entities/integrations/clickhouse'

import { CheckboxGroup } from '~/components/CheckboxGroup'
import { Grid } from '~/components/Grid'
import { Select } from '~/components/Select'
import { Text } from '~/components/Text'

type ZonesInputProps = {
  region: Region['code']
  errors: { zonal?: string[]; zones?: string[]; controlPlaneZone?: string[] }
  projectId: Project['id']
}

export function ZonesInput({ region, errors, projectId }: ZonesInputProps) {
  const { data: zones, isInitialLoading } = useQuery(zonesQuery(projectId, region))
  const [selectedZones, setSelectedZones] = useState<string[]>([])

  const zonalValue = zones?.length === selectedZones.length ? 1 : 0
  return (
    <>
      <input hidden name="zonal" key={zonalValue} defaultValue={zonalValue} />
      <Grid columns={3}>
        <Grid.Item span={{ mobile: 3, desktop: 2 }}>
          <CheckboxGroup
            label="Zones"
            direction="horizontal"
            isLoading={isInitialLoading}
            onChange={value => setSelectedZones(value)}
            errorMessage={errors.zones}
          >
            <>
              {zones?.map((zone, index) => (
                <CheckboxGroup.Checkbox key={zone} name={`zones[${index}]`} value={zone}>
                  <Text size="small">{zone}</Text>
                </CheckboxGroup.Checkbox>
              ))}
            </>
          </CheckboxGroup>
        </Grid.Item>
        <Grid.Item span={{ mobile: 3, desktop: 1 }}>
          <Select
            name="controlPlaneZone"
            label="Control plane zone"
            aria-label="Control plane zone"
            defaultText="Select a zone"
            items={selectedZones.map(zone => ({
              id: zone,
              name: zone,
            }))}
            isDisabled={selectedZones.length === 0}
            variant="compact"
            errorMessage={errors.controlPlaneZone}
          >
            {item => (
              <Select.Item key={item.id} textValue={item.name}>
                {item.name}
              </Select.Item>
            )}
          </Select>
        </Grid.Item>
      </Grid>
    </>
  )
}
