import { z } from 'zod'

const envVariables: any = z.object({
  // Public env variables exposed to client bundle
  VITE_ENVIRONMENT: z.enum(['production', 'dev', 'local']),
  VITE_QRYN_VIEW_URL_TPL: z.optional(z.string()),
  VITE_SETUP_DOMAIN_NAME: z.optional(z.string()),
  VITE_API_URL: z.string(),
  VITE_STRIPE_PUBLIC_KEY: z.string(),
  VITE_POSTHOG_API_KEY: z.string(),
  VITE_AUTH0_DOMAIN: z.string(), // should be removed
  VITE_AUTH0_CLIENT_ID: z.string(), // should be removed
  VITE_AUTH0_AUDIENCE: z.string(), // should be removed

  // Private env variables
  VITE_KC_URL: z.string(),
  VITE_KC_REALM: z.string(),
  VITE_KC_CLIENT_ID: z.string(),

  VITE_DISABLE_STRICT_MODE: z.string().optional(),
  VITE_ENABLE_API_MOCKING: z.string().optional(),
  VITE_QRYN_COLLECT_URL: z.string(),
  // Custom injected variables
  /**
   * Get the release version from package.json
   */
  PACKAGE_VERSION: z.string(),
  /**
   * Flag used to prevent Auth0 requests while running Storybook
   */
  IS_STORYBOOK: z.string().optional(),

  VITE_STRIPE_MOCK: z.coerce.boolean().optional(),
})

envVariables.parse(import.meta.env)

declare global {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/consistent-type-definitions
  interface ImportMetaEnv extends z.infer<typeof envVariables> {}
}
