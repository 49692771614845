import { useQuery } from '@tanstack/react-query'

import { organizationsTrialDaysQuery } from '~/entities/organizations'

import { useDecodedToken } from '~/shared/model/auth/useAuth'

import { Box } from '~/components/Box'

/**
 * Feature component used to display a banner at the top of the app while the orgaization is in trial mode.
 */
export function OrganizationTrialPeriodIndicator() {
  const decodedToken = useDecodedToken()
  const isOrgAccount = Boolean(decodedToken?.organization_id)
  const { data: trial } = useQuery(organizationsTrialDaysQuery(isOrgAccount))

  if (!trial?.remainingDays) return null
  return (
    <Box
      backgroundColor="purple700"
      fontSize="small"
      paddingX="small"
      paddingY="xxsmall"
      textAlign="center"
    >
      Your trial period for using Gigapipe in organization mode ends in {trial.remainingDays} days.
      After that you will be charged a flat monthly fee of $249 per month.
    </Box>
  )
}
