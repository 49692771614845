import { z } from 'zod'

// Constants
export const REGEX_LOWER_CASE = /(?=.*[a-z])/ // at least one lower case letter exists
export const REGEX_UPER_CASE = /(?=.*[A-Z])/ // at least one upper case letter exists
export const REGEX_DIGITS = /(?=.*\d)/ // at least one digit exists
export const REGEX_SPECIAL_CHARACTERS = /(?=.*[!@#$%^&*])/ // at least one non-word character exists

// Schemas for submit requests
export const updateUserProfileSchema = z.object({
  givenName: z.string().min(1, { message: 'The first name is required.' }).trim(),
  familyName: z.string().min(1, { message: 'The last name is required.' }).trim(),
})
export type UpdateUserProfileData = z.infer<typeof updateUserProfileSchema>

export const updateUserPasswordSchema = z
  .object({
    newPassword: z
      .string()
      .min(1, { message: 'Your new password is required.' })
      .min(8, { message: 'The new password must be at least 8 characters.' })
      .regex(REGEX_LOWER_CASE, {
        message: 'The new password must include at least 1 lower case letter.',
      })
      .regex(REGEX_UPER_CASE, {
        message: 'The new password must include at least 1 upper case letter.',
      })
      .regex(REGEX_DIGITS, {
        message: 'The new password must include at least 1 number digit.',
      })
      .regex(REGEX_SPECIAL_CHARACTERS, {
        message: 'The new password must include at least 1 special character (!@#$%^&*).',
      }),
    newPasswordVerification: z
      .string()
      .min(1, { message: "The new password's confirmation is required." }),
  })
  .refine(({ newPassword, newPasswordVerification }) => newPassword !== newPasswordVerification, {
    message: 'The passwords do not match.',
    path: ['newPassword', 'newPasswordVerification'],
  })
export type UpdateUserPasswordData = z.infer<typeof updateUserPasswordSchema>
export type UpdateUserPasswordErrors = z.inferFlattenedErrors<typeof updateUserPasswordSchema>
