import { memo, type SVGProps } from 'react'

const SvgMetabase = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 31 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.564 13.017c1.26 0 2.28-1.038 2.28-2.318s-1.02-2.317-2.28-2.317c-1.26 0-2.28 1.037-2.28 2.317 0 1.28 1.02 2.318 2.28 2.318ZM2.564 19.505c1.26 0 2.28-1.037 2.28-2.317 0-1.28-1.02-2.318-2.28-2.318-1.26 0-2.28 1.038-2.28 2.318s1.02 2.317 2.28 2.317Z"
      fill="#509EE3"
    />
    <path
      opacity={0.2}
      d="M8.95 13.017c1.26 0 2.281-1.038 2.281-2.318s-1.021-2.317-2.28-2.317c-1.26 0-2.282 1.037-2.282 2.317 0 1.28 1.022 2.318 2.281 2.318Z"
      fill="#509EE3"
    />
    <path
      d="M8.95 19.568c1.26 0 2.281-1.037 2.281-2.317 0-1.28-1.021-2.318-2.28-2.318-1.26 0-2.282 1.038-2.282 2.318s1.022 2.317 2.281 2.317Z"
      fill="#509EE3"
    />
    <path
      opacity={0.2}
      d="M15.337 13.017c1.26 0 2.28-1.038 2.28-2.318s-1.02-2.317-2.28-2.317c-1.26 0-2.28 1.037-2.28 2.317 0 1.28 1.02 2.318 2.28 2.318ZM15.337 5.601c1.26 0 2.28-1.037 2.28-2.317 0-1.28-1.02-2.318-2.28-2.318-1.26 0-2.28 1.038-2.28 2.318s1.02 2.317 2.28 2.317ZM15.337 19.568c1.26 0 2.28-1.037 2.28-2.317 0-1.28-1.02-2.318-2.28-2.318-1.26 0-2.28 1.038-2.28 2.318s1.02 2.317 2.28 2.317ZM21.723 13.017c1.26 0 2.281-1.038 2.281-2.318s-1.021-2.317-2.28-2.317c-1.26 0-2.282 1.037-2.282 2.317 0 1.28 1.022 2.318 2.281 2.318Z"
      fill="#509EE3"
    />
    <path
      d="M28.11 13.017c1.26 0 2.28-1.038 2.28-2.318s-1.02-2.317-2.28-2.317c-1.26 0-2.281 1.037-2.281 2.317 0 1.28 1.02 2.318 2.28 2.318ZM21.723 19.568c1.26 0 2.281-1.037 2.281-2.317 0-1.28-1.021-2.318-2.28-2.318-1.26 0-2.282 1.038-2.282 2.318s1.022 2.317 2.281 2.317ZM28.11 19.568c1.26 0 2.28-1.037 2.28-2.317 0-1.28-1.02-2.318-2.28-2.318-1.26 0-2.281 1.038-2.281 2.318s1.02 2.317 2.28 2.317ZM2.564 25.994c1.26 0 2.28-1.038 2.28-2.318s-1.02-2.317-2.28-2.317c-1.26 0-2.28 1.037-2.28 2.317 0 1.28 1.02 2.318 2.28 2.318Z"
      fill="#509EE3"
    />
    <path
      opacity={0.2}
      d="M8.95 26.057c1.26 0 2.281-1.038 2.281-2.318s-1.021-2.317-2.28-2.317c-1.26 0-2.282 1.037-2.282 2.317 0 1.28 1.022 2.318 2.281 2.318Z"
      fill="#509EE3"
    />
    <path
      d="M15.337 26.057c1.26 0 2.28-1.038 2.28-2.318s-1.02-2.317-2.28-2.317c-1.26 0-2.28 1.037-2.28 2.317 0 1.28 1.02 2.318 2.28 2.318Z"
      fill="#509EE3"
    />
    <path
      opacity={0.2}
      d="M21.723 26.057c1.26 0 2.281-1.038 2.281-2.318s-1.021-2.317-2.28-2.317c-1.26 0-2.282 1.037-2.282 2.317 0 1.28 1.022 2.318 2.281 2.318Z"
      fill="#509EE3"
    />
    <path
      d="M28.11 26.057c1.26 0 2.28-1.038 2.28-2.318s-1.02-2.317-2.28-2.317c-1.26 0-2.281 1.037-2.281 2.317 0 1.28 1.02 2.318 2.28 2.318ZM2.564 32.482c1.26 0 2.28-1.037 2.28-2.317 0-1.28-1.02-2.318-2.28-2.318-1.26 0-2.28 1.038-2.28 2.318s1.02 2.317 2.28 2.317Z"
      fill="#509EE3"
    />
    <path
      opacity={0.2}
      d="M8.95 32.545c1.26 0 2.281-1.037 2.281-2.317 0-1.28-1.021-2.318-2.28-2.318-1.26 0-2.282 1.038-2.282 2.318s1.022 2.317 2.281 2.317ZM15.337 32.545c1.26 0 2.28-1.037 2.28-2.317 0-1.28-1.02-2.318-2.28-2.318-1.26 0-2.28 1.038-2.28 2.318s1.02 2.317 2.28 2.317ZM15.337 39.034c1.26 0 2.28-1.038 2.28-2.318s-1.02-2.317-2.28-2.317c-1.26 0-2.28 1.037-2.28 2.317 0 1.28 1.02 2.318 2.28 2.318ZM21.723 32.545c1.26 0 2.281-1.037 2.281-2.317 0-1.28-1.021-2.318-2.28-2.318-1.26 0-2.282 1.038-2.282 2.318s1.022 2.317 2.281 2.317Z"
      fill="#509EE3"
    />
    <path
      d="M28.11 32.545c1.26 0 2.28-1.037 2.28-2.317 0-1.28-1.02-2.318-2.28-2.318-1.26 0-2.281 1.038-2.281 2.318s1.02 2.317 2.28 2.317Z"
      fill="#509EE3"
    />
  </svg>
)
const Memo = memo(SvgMetabase)
export default Memo
