import { faro } from '@grafana/faro-web-sdk'
import { posthog } from 'posthog-js'

import { authStore } from './authStore'

/**
 * Wrapped logout function used to remove information in the tracking libraries when the user log outs.
 */
export function wrappedLogout() {
  const { logout } = authStore.getState().actions

  faro.api.setUser(undefined)
  posthog.reset(true)

  return logout()
}
