import { slugify, transformSnakeObjectKeysToCamel } from 'plunger'
import { z } from 'zod'

// Shared schemas
export const projectId = z.coerce.string()
export type ProjectId = z.infer<typeof projectId>

// Schemas for fetched requests
export const projectSchema = z
  .preprocess(
    (data: any) => transformSnakeObjectKeysToCamel(data),
    z.object({
      id: projectId,
      name: z.string(),
      organizationId: z.number().nullable(),
      user_id: z.any(),
      billingAccountId: z.number(),
    }),
  )
  .transform(data => ({ ...data, slug: slugify(data.name, data.id) }))

export type Project = z.infer<typeof projectSchema>

// Schemas for submit requests
export const createProjectSchema = z.object({
  name: z.string().min(3, { message: 'The project name must have at least 3 characters' }),
})

export type CreateProjectData = z.infer<typeof createProjectSchema>

export const addUserSchema = z.object({
  userId: z.string().min(1, { message: 'Please select one user.' }),
  projectId: projectId,
})
export type AddUserData = z.infer<typeof addUserSchema>
