import { transformCamelObjectKeysToSnake } from 'plunger'
import { z } from 'zod'

import { type Rooquest } from '../lib/rooquest'
import { organizationSchema } from '../organizations/model'
import { type MessageResponse } from '../utils/types'

import {
  type UpdateUserPasswordData,
  updateUserPasswordSchema,
  type UpdateUserProfileData,
  updateUserProfileSchema,
} from './model'

const organizations = z.array(organizationSchema)

/**
 * The service to interact with all the organization domain endpoints of Gigapipe's API
 */
export default class UsersService {
  private readonly rooquest: Rooquest

  constructor(rooquest: Rooquest) {
    this.rooquest = rooquest
  }

  /**
   * Get the organizations from the authenticated userMessageResponse
   */
  public getOrganizationsFromUser = async () => {
    const res = await this.rooquest.get('users/organizations')
    return organizations.parse(res)
  }

  /**
   * Update User
   */
  public updateUser = async (data: UpdateUserProfileData) => {
    const payload = updateUserProfileSchema.parse(data)

    const res = await this.rooquest.patch('users', {
      body: transformCamelObjectKeysToSnake({
        ...payload,
        name: `${payload.givenName} ${payload.familyName}`,
      }),
    })

    return res as MessageResponse
  }

  /**
   * Send Verification Email to User
   */
  public sendVerificationEmail = async () => {
    const res = await this.rooquest.post('users/resend-verification')

    return res
  }

  /**
   * Change the user password for username-password connections
   */
  public changePassword = async (data: UpdateUserPasswordData) => {
    const payload = updateUserPasswordSchema.parse(data)
    const res = await this.rooquest.patch('users/password', {
      body: { password: payload.newPassword },
    })

    return res as MessageResponse
  }
}
