import { lazy, Suspense } from 'react'
import { type ActionFunctionArgs, json, redirect, useFetcher } from 'react-router-dom'
import { type QueryClient } from '@tanstack/react-query'
import {
  type HepicEnableData,
  isZodError,
  type Product,
  type Project,
  type QrynEnableData,
} from 'api-client'

import { integrationsQuery } from '~/entities/integrations/base'
import { hepicCloudInstancesQuery, hepicPricingPlansQuery } from '~/entities/integrations/hepic'
import { qrynCloudInstancesQuery, qrynRetentionPoliciesQuery } from '~/entities/integrations/qryn'
import { productsWithOnUseProjectsQuery } from '~/entities/products'
import { projectsQuery } from '~/entities/projects'

import { apiClient, handleDataMutationError } from '~/shared/api'
import { getFormData } from '~/shared/lib/parseRequest'
import { useNavigate } from '~/shared/lib/reactRouterWrappers'

import { Button } from '~/components/Button'
import { Icon } from '~/components/Icon'
import { MathPlus } from '~/components/Icons'
import { Modal } from '~/components/Modal'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import { type ActionResponse, baseSchema } from '../model'

import { InfoModal } from './InfoModal'

const QrynEnableFields = lazy(() => import('./QrynEnableFields'))
const HepicEnableFields = lazy(() => import('./HepicEnableFields'))

const ConfigFieldsByProduct = {
  qryn: QrynEnableFields,
  hepic: HepicEnableFields,
}
export type ConfigFieldsByProductNames = keyof typeof ConfigFieldsByProduct

export const RESOURCES_ACTION_ROUTE = '/resources/add-to-project'

type AddToProjectProps = {
  /**
   * The product slug used for navigation
   */
  productSlug: Product['slug']
  /**
   * The id of the project where the product should be added
   */
  projectId: Project['id']
  /**
   * The slug of the project where the product should be added
   */
  projectSlug: Project['slug']
  /**
   * Prop used to control the initial step for the component
   * (ie. to show the config modal if the product has already been enabled)
   */
  initialStep?: ActionResponse['step']
  /**
   * Disable the button
   */
  isDisabled?: boolean
}

export function action(queryClient: QueryClient) {
  return async ({ request }: ActionFunctionArgs) => {
    const response: ActionResponse = {
      product: '',
      enabled: false,
      step: '',
      items: [],
      hasJsonDownload: false,
    }
    const data = await getFormData(request)
    const basePayload = baseSchema.safeParse(data)

    if (data.op === 'dismiss-config') {
      return json(response)
    }

    if (!basePayload.success) {
      throw new Error('There is an error with the selected product')
    }

    const { productSlug, projectId } = basePayload.data
    response.product = productSlug

    switch (productSlug) {
      case 'qryn': {
        response.step = 'show-config'

        if (data.step === 'enable') {
          await Promise.all([
            queryClient.ensureQueryData(qrynCloudInstancesQuery()),
            queryClient.ensureQueryData(qrynRetentionPoliciesQuery()),
          ])
          return json(response)
        }

        try {
          const { apiKey, apiSecret } = await apiClient.qryn.enableQryn({ ...data, projectId })

          response.items = [
            { label: 'API KEY', value: apiKey },
            { label: 'API SECRET', value: apiSecret },
          ]
          response.enabled = true
          response.step = 'show-info'
          response.hasJsonDownload = true

          await queryClient.invalidateQueries(integrationsQuery(projectId))

          return json(response)
        } catch (error) {
          if (isZodError<QrynEnableData>(error)) {
            response.errors = error.format()
            return json(response, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }

      case 'grafana': {
        try {
          await apiClient.grafana.enableGrafana({ projectId })
        } catch (error) {
          return handleDataMutationError(error)
        }
        break
      }

      case 'clickhouse': {
        try {
          await apiClient.clickhouse.enableClickHouse({ projectId })
        } catch (error) {
          return handleDataMutationError(error)
        }
        break
      }

      case 'loggen': {
        try {
          await apiClient.loggen.enableLoggen({ projectId })
        } catch (error) {
          return handleDataMutationError(error)
        }
        break
      }

      case 'hepic': {
        response.step = 'show-config'

        if (data.step === 'enable') {
          await Promise.all([
            queryClient.ensureQueryData(hepicCloudInstancesQuery()),
            queryClient.ensureQueryData(hepicPricingPlansQuery()),
          ])
          return json(response)
        }

        try {
          await apiClient.hepic.enableHepic({ ...data, projectId })
          response.items = []
          response.enabled = true
          response.step = 'show-info'

          await queryClient.invalidateQueries(integrationsQuery(projectId))
          return json(response)
        } catch (error) {
          if (isZodError<HepicEnableData>(error)) {
            response.errors = error.format()
            return json(response, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }

      default:
        throw new Error(`${productSlug} couldn't be enabled`)
    }

    const [projects] = await Promise.all([
      queryClient.ensureQueryData(projectsQuery()),
      queryClient.invalidateQueries(productsWithOnUseProjectsQuery()),
      queryClient.invalidateQueries(integrationsQuery(projectId)),
    ])
    const project = projects.find(project => project.id === projectId)
    return redirect(`/${project?.slug}/stack/${productSlug}`)
  }
}

/**
 * Allows the user to add a product to a project
 */
export const AddToProject = ({
  productSlug,
  projectId,
  projectSlug,
  initialStep,
  isDisabled,
}: AddToProjectProps) => {
  const fetcher = useFetcher<ActionResponse>()
  const navigate = useNavigate()
  const ConfigFields = ConfigFieldsByProduct[productSlug as ConfigFieldsByProductNames]

  const handleDissmissConfig = () =>
    fetcher.submit(
      { op: 'dismiss-config' },
      {
        method: 'post',
        action: RESOURCES_ACTION_ROUTE,
        encType: 'application/x-www-form-urlencoded',
      },
    )

  return (
    <>
      <fetcher.Form method="post" action={RESOURCES_ACTION_ROUTE}>
        <input hidden name="step" defaultValue="enable" />
        <input hidden name="productSlug" defaultValue={productSlug} />
        <Button
          type="submit"
          name="projectId"
          value={projectId}
          isDisabled={isDisabled ?? fetcher.state !== 'idle'}
          isLoading={fetcher.state !== 'idle'}
        >
          <Stack direction="horizontal" space="small" align="center">
            Add to project
            <Icon color={fetcher.state === 'idle' ? 'white' : 'transparent'}>
              <MathPlus />
            </Icon>
          </Stack>
        </Button>
      </fetcher.Form>
      <InfoModal
        jsonDownLoad={fetcher?.data?.hasJsonDownload ?? false}
        isOpen={fetcher.data?.step === 'show-info'}
        name={fetcher.data?.product ?? ''}
        items={fetcher.data?.items ?? []}
        onClose={() => navigate(`/${projectSlug}/stack/${productSlug}`)}
      />
      <Modal
        isOpen={fetcher.data?.step === 'show-config' ?? initialStep === 'show-config'}
        onClose={handleDissmissConfig}
      >
        <Modal.Content>
          <fetcher.Form method="post" action={RESOURCES_ACTION_ROUTE}>
            <Stack space="medium">
              <input hidden name="step" defaultValue="getConfig" />
              <input hidden name="productSlug" defaultValue={productSlug} />
              <Suspense fallback={<Text>Preparing the form...</Text>}>
                {ConfigFields && (
                  <ConfigFields
                    projectId={projectId}
                    errors={fetcher.data?.errors}
                    isLoading={fetcher.state !== 'idle'}
                    onCancel={handleDissmissConfig}
                  />
                )}
              </Suspense>
            </Stack>
          </fetcher.Form>
        </Modal.Content>
      </Modal>
    </>
  )
}
