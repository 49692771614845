import { transformSnakeObjectKeysToCamel } from 'plunger'
import { z } from 'zod'

import { projectId } from '../../projects/model'

// Schemas for fetched requests
export const grafanaConfigSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    id: z.coerce.number(),
    projectId,
    autoAddUsers: z.boolean(),
    grafanaUrl: z.string().url(),
  }),
)

export const grafanaDatasourceSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    id: z.any(),
    orgId: z.number(),
    uid: z.string(),
    name: z.string(),
    type: z.string(),
  }),
)

export const grafanaQrynDatasourceSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    id: z.coerce.number(),
    projectId,
    promUid: z.string(),
    promName: z.string(),
    tempoUid: z.string(),
    tempoName: z.string(),
    clokiUid: z.string(),
    qrynLokiName: z.string(),
  }),
)

export const grafanaUserSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    name: z.string(),
    email: z.string(),
    userId: z.any(),
    orgId: z.number(),
  }),
)

// Schemas for submit requests
export const grafanaEnableSchema = z.object({
  projectId,
})
export type GrafanaEnableData = z.infer<typeof grafanaEnableSchema>

const qrynDatasourcePayload = z.object({
  projectId,
  type: z.literal('qryn'),
  qrynTempoName: z.string().min(1, { message: 'You need to specify a Tempo datasource name.' }),
  qrynLokiName: z.string().min(1, { message: 'You need to specify a Loki datasource name.' }),
  qrynPrometheusName: z
    .string()
    .min(1, { message: 'You need to specify a Prometheus datasource name.' }),
})

const clickHouseDatasourcePayload = z.object({
  projectId,
  type: z.literal('clickhouse'),
  gigapipeClickhouseClusters: z.array(
    z.object({
      id: z.string().min(1, { message: 'You need to specify a Gigapipe Cluster ID.' }),
      name: z.string().min(1, { message: 'You need to specify a name for the datasource.' }),
    }),
  ),
})
export const grafanaAddDatasourceSchema = z.discriminatedUnion('type', [
  qrynDatasourcePayload,
  clickHouseDatasourcePayload,
])
export type GrafanaAddDatasourceData = z.infer<typeof grafanaAddDatasourceSchema>

export const grafanaAddDashboardSchema = z.object({
  projectId,
  gigapipeClickhouseClusters: z.array(z.string()),
})
export type GrafanaAddDashboardData = z.infer<typeof grafanaAddDashboardSchema>

export const grafanaAddUserSchema = z.object({
  projectId,
  users: z
    .array(
      z.object({
        id: z.coerce.number(),
        name: z.string(),
        email: z.string(),
      }),
    )
    .min(1, { message: 'You need to select at least one user' }),
})
export type GrafanaAddUserData = z.infer<typeof grafanaAddUserSchema>
