import { memo, type SVGProps } from 'react'

const SvgMindsdb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.002 16.084c.326 2.596.628 5.035.941 7.472.038.291-.022.394-.343.41-1.937.096-1.935.106-2.635-1.686-.291-.745-.575-1.494-.885-2.232a.615.615 0 0 1 .12-.757c.918-1.018 1.806-2.063 2.802-3.207ZM10.623 18.26c.277 1.43.551 2.773.792 4.123.074.417.41.948.123 1.23-.247.243-.795-.1-1.216-.085-1.119.043-1.64-.483-1.822-1.586-.106-.648-.154-1.098.382-1.6a21.86 21.86 0 0 0 1.741-2.082Z"
      fill="#00B06D"
    />
    <path
      d="M13.781 20.868c0-.802-.012-1.605.006-2.408.008-.315-.08-.422-.415-.432a492.965 492.965 0 0 1-6.852-.275c-.359-.017-.5.085-.576.44-.353 1.641-.753 3.274-1.098 4.916-.096.462-.273.633-.756.58a10.074 10.074 0 0 0-1.612-.036c-.406.02-.483-.148-.478-.502.035-2.465.087-4.931.063-7.396a3.348 3.348 0 0 1 .718-2.092c1.263-1.756 2.483-3.544 3.706-5.327a.64.64 0 0 1 .699-.321c4.565.488 9.131.961 13.7 1.42.286.034.559.138.794.303 2.719 1.766 5.433 3.54 8.163 5.284.408.26.42.41.027.644a.79.79 0 0 0-.224.179c-.787 1.017-1.708.97-2.822.522-1.348-.543-2.77-.903-4.154-1.365a.866.866 0 0 0-.811.084 293.13 293.13 0 0 1-3.83 2.34.805.805 0 0 0-.421.623c-.282 1.695-.603 3.384-.862 5.082-.075.487-.248.64-.732.607a16.048 16.048 0 0 0-1.845-.003c-.365.016-.458-.113-.448-.46.024-.802.007-1.605.007-2.408h.053Z"
      fill="#00B06D"
    />
  </svg>
)
const Memo = memo(SvgMindsdb)
export default Memo
