import {
  isRouteErrorResponse,
  json,
  type LoaderFunctionArgs,
  redirect,
  useRouteError,
} from 'react-router-dom'
import { type QueryClient, useQuery } from '@tanstack/react-query'
import { type GrafanaAddDatasourceData, type GrafanaAddUserData, isZodError } from 'api-client'
import { authorize } from 'keycloak'
import { z } from 'zod'

import {
  AddClickHouseDatasources,
  useAddClickHouseDatasources,
} from '~/features/addClickHouseDatasourcesToGrafana'
import { AddQrynDatasources, useAddQrynDatasources } from '~/features/addQrynDatasourcesToGrafana'
import { AddUserToGrafana } from '~/features/addUserToGrafana'
import { ToogleAutoAddUsers } from '~/features/autoAddUsersToGrafana'
import { CancelSubscriptionButton } from '~/features/cancelSubscriptionToIntegration'
import { ListGrafanaUsers } from '~/features/listGrafanaUsers'
import { notify } from '~/features/notifications'
import { GrafanaLinks } from '~/features/showGrafanaLinks'

import {
  CANCEL_SUBSCRIPTION,
  checkIntegrationStatus,
  IntegrationName,
  integrationsCancellationDateQuery,
  integrationsQuery,
  IntegrationStatus,
} from '~/entities/integrations/base'
import {
  ADD_GRAFANA_DATASOURCE,
  ADD_GRAFANA_USER,
  grafanaConfigQuery,
  grafanaDataSourcesQuery,
  grafanaQrynDataSourcesQuery,
  grafanaUsersQuery,
  TOGGLE_GRAFANA_AUTO_ADD_USERS,
} from '~/entities/integrations/grafana'
import {
  checkProjectSlugParam,
  getProjectIdFromParams,
  projectsQuery,
  useProjectIdParam,
  usersFromProjectQuery,
} from '~/entities/projects'

import { apiClient, handleDataMutationError } from '~/shared/api'
import { getFormData } from '~/shared/lib/parseRequest'
import { authStore } from '~/shared/model/auth'
import { useDecodedToken } from '~/shared/model/auth/useAuth'
import { isApiError } from '~/shared/model/errors'
import { Accordion } from '~/shared/ui/Accordion'
import { RouteErrorMessage } from '~/shared/ui/RouteErrorMessage'

import { formatDate } from '~/i18n/utils'

import { Box } from '~/components/Box'
import { Card } from '~/components/Card'
import { Grid } from '~/components/Grid'
import { HeadTag } from '~/components/HeadTag'
import { Link } from '~/components/Link'
import { Message } from '~/components/Message'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

export type ProjectUser = {
  givenName: string
  familyName: string
  email: string
  id: string
}

export type GrafanaIntent = {
  intent: string
  users: ProjectUser[]
}

export type GrafanaUser = {
  id: number
  name: string
  email: string
}

const SLUG = 'grafana'

export function loader(queryClient: QueryClient) {
  return async ({ params }: LoaderFunctionArgs) =>
    authorize(authStore, async () => {
      const { projectId, projectSlug } = checkProjectSlugParam(params)

      const decodedToken = authStore.getState()?.decodedToken
      const integration = await checkIntegrationStatus(queryClient, {
        projectId,
        projectSlug,
        slug: SLUG,
      })

      try {
        const [datasources, qrynDatasources, config, usersFromGrafana, usersInProject] =
          await Promise.all([
            queryClient.ensureQueryData(grafanaDataSourcesQuery(projectId)),
            queryClient.ensureQueryData(grafanaQrynDataSourcesQuery(projectId)),
            queryClient.ensureQueryData(grafanaConfigQuery(projectId)),
            decodedToken?.organization_id
              ? queryClient.ensureQueryData(grafanaUsersQuery(projectId, true))
              : undefined,
            decodedToken?.organization_id
              ? queryClient.ensureQueryData(usersFromProjectQuery(projectId, true))
              : undefined,
          ])
        return json({
          integration,
          datasources,
          qrynDatasources,
          usersInProject,
          usersFromGrafana,
          config,
        })
      } catch (error) {
        if (isApiError(error) && error.type === 'data_query') {
          throw json(
            {
              message: `We had some issues loading the configuration of the Grafana integration for project ${projectId}`,
              description: `Please go to the Stack page and try again. If the problem persists, please contact support.`,
              goTo: {
                path: `/${projectSlug}/stack`,
                text: 'Go to Stack',
              },
            },
            { status: 400 },
          )
        }
        throw error
      }
    })
}

export function action(queryClient: QueryClient) {
  return async ({ request, params }: LoaderFunctionArgs) => {
    const data = await getFormData(request)
    const projectId = getProjectIdFromParams(params)
    if (!projectId) {
      notify.error('No project has been selected.')
      return json({})
    }
    const intents = z
      .enum([
        ADD_GRAFANA_DATASOURCE,
        ADD_GRAFANA_USER,
        TOGGLE_GRAFANA_AUTO_ADD_USERS,
        CANCEL_SUBSCRIPTION,
      ])
      .safeParse(data.intent)

    if (!intents.success) {
      throw json(
        {
          message: 'This operation is not valid',
          description:
            'You tried to perform an invalid operation. Nothing will be changed regarding your integrations. Please reload the page and try again.',
        },
        { status: 400 },
      )
    }

    switch (intents.data) {
      case 'ADD_GRAFANA_DATASOURCE': {
        try {
          const { message } = await apiClient.grafana.addGrafanaDatasource({
            ...data,
            projectId: projectId,
          })
          notify.success(message)
          await queryClient.invalidateQueries(grafanaQrynDataSourcesQuery(projectId))
          return redirect(`/${params.projectSlug}/stack/${SLUG}`)
        } catch (error) {
          if (isZodError<GrafanaAddDatasourceData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
      case 'ADD_GRAFANA_USER': {
        try {
          let grafanaUsers = [] as GrafanaUser[]
          const grafanaData = data as GrafanaIntent

          if (data?.users?.length > 0) {
            grafanaUsers = grafanaData?.users?.map(
              ({
                givenName,
                familyName,
                email,
                id,
              }: {
                givenName: string
                familyName: string
                email: string
                id: string
              }) => ({ name: `${givenName} ${familyName}`, email, id: parseInt(id) }),
            )
          }

          const { message } = await apiClient.grafana.addGrafanaUser({
            ...{ users: grafanaUsers },
            projectId: projectId,
          })
          notify.success(message)
          await queryClient.invalidateQueries(grafanaUsersQuery(projectId, true))
          return json({})
        } catch (error) {
          if (isZodError<GrafanaAddUserData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
      case 'TOGGLE_GRAFANA_AUTO_ADD_USERS': {
        try {
          const { message } = await apiClient.grafana.toggleGrafanaAutoAddUsers(projectId)
          notify.success(message)
          await queryClient.invalidateQueries(grafanaConfigQuery(projectId))
          return json({})
        } catch (error) {
          return handleDataMutationError(error)
        }
      }
      case 'CANCEL_SUBSCRIPTION': {
        try {
          const { message } = await apiClient.integrations.cancelSubscription({
            projectId: projectId,
            integration: SLUG,
          })
          await queryClient.invalidateQueries(integrationsQuery(projectId))
          notify.success(message)
          return redirect(`/${params.projectSlug}/stack`)
        } catch (error: any) {
          return handleDataMutationError(error)
        }
      }
    }
  }
}

export default function Grafana() {
  const { projectId, projectSlug } = useProjectIdParam()
  //const loggedInUser = useUser()

  const { data: project } = useQuery({
    ...projectsQuery(),
    select: data => data.find(p => p.id === projectId),
  })
  const { data: integration } = useQuery({
    ...integrationsQuery(projectId),
    select: data => data.find(i => i.product.slug === SLUG),
  })
  const { data: cancellationDate, error } = useQuery(
    integrationsCancellationDateQuery(projectId, SLUG, integration?.status),
  )

  const { allowToAddQrynDatasources } = useAddQrynDatasources({ projectId })
  const { allowToAddClickHouseDatasources, availableClusters, numOfClickHouseClusters } =
    useAddClickHouseDatasources({
      projectId,
      projectName: project?.name,
    })

  const alertsCount = numOfClickHouseClusters + (allowToAddQrynDatasources ? 1 : 0)
  const isScheduledForCancellation = integration?.status === 'scheduled for cancellation'

  const decodedToken = useDecodedToken()
  const isOrgAccount = Boolean(decodedToken?.organization_id)

  if (!integration) return null
  return (
    <Stack direction="vertical">
      <HeadTag tag="title" headId="title">
        {import.meta.env.MODE !== 'production' ? `(${import.meta.env.VITE_ENVIRONMENT}) ` : ''}
        {integration.product.name} - Gigapipe
      </HeadTag>
      <Stack space="small">
        <Stack direction="horizontal">
          <IntegrationName name={integration.product.name} slug={integration.product.slug} />
        </Stack>
        <Box>
          <IntegrationStatus
            projectId={projectId}
            slug={integration.product.slug}
            status={integration?.status ?? 'active'}
          />
        </Box>
        {isScheduledForCancellation && (
          <Message size="small" type="info">
            This integration is scheduled for cancellation on the{' '}
            {cancellationDate
              ? formatDate(cancellationDate.cancellationDateTimestamp, undefined, {
                  dateStyle: 'medium',
                  timeStyle: 'short',
                })
              : error
              ? 'not available.'
              : 'loading...'}
          </Message>
        )}
      </Stack>
      <Stack direction="vertical">
        {(allowToAddClickHouseDatasources ?? allowToAddQrynDatasources) && project && (
          <Accordion
            alert={alertsCount}
            title="Connect integrations"
            storageId={`grafana-alerts--project-${projectId}`}
            persistState
          >
            <>
              {allowToAddQrynDatasources && <AddQrynDatasources projectName={project.name} />}
              {allowToAddClickHouseDatasources &&
                availableClusters?.map(cluster => (
                  <AddClickHouseDatasources
                    key={cluster.id}
                    clusterId={cluster.id}
                    clusterName={cluster.name}
                    projectName={project.name}
                  />
                ))}
            </>
          </Accordion>
        )}
        <Grid>
          <Grid.Item span={{ mobile: 12, tablet: 8 }}>
            <Card>
              <Card.Header title="How to use Grafana" />
              <Card.Section>
                <Stack>
                  <Text>
                    Read the{' '}
                    <Link to="https://grafana.com/docs" target="_blank" type="text">
                      Grafana docs
                    </Link>
                  </Text>
                </Stack>
              </Card.Section>
            </Card>
          </Grid.Item>
          <Grid.Item span={{ mobile: 12, tablet: 4 }}>
            <Card>
              <Card.Section>
                <Stack direction="vertical" space="small">
                  <GrafanaLinks projectId={projectId} />
                  <CancelSubscriptionButton
                    integrationSlug="grafana"
                    integrationName="Grafana"
                    projectSlug={projectSlug}
                    cancellationMessage="Grafana will be permanently disabled for ALL the projects in your organization. After your next billable date you will lose access to the integration but any previous data, users, and configuration will be preserved. Please contact support if you need to re-enable subscription."
                  />
                </Stack>
              </Card.Section>
            </Card>
          </Grid.Item>
        </Grid>
        <Card>
          <Card.Header title="Manage users from Grafana" />
          <Card.Section>
            <Stack>
              {!isOrgAccount && (
                <Message type="info" size="small">
                  Adding additional users to your Grafana account is only available in organization
                  mode. Please switch to your organization, or create a new one here:{' '}
                  <Link to="/organization/create" type="text">
                    create organization.
                  </Link>
                </Message>
              )}
              <ListGrafanaUsers projectId={projectId} />
              <ToogleAutoAddUsers projectId={projectId} />
              <AddUserToGrafana projectId={projectId} />
            </Stack>
          </Card.Section>
        </Card>
      </Stack>
    </Stack>
  )
}

export function ErrorBoundary() {
  const error = useRouteError() as Error

  if (isRouteErrorResponse(error)) {
    return (
      <RouteErrorMessage
        message={error.data.message}
        description={error.data.description}
        goToPath={error.data.goTo?.path}
        goToText={error.data.goTo?.text}
      />
    )
  }

  throw error
}
