import { memo, type SVGProps } from 'react'

const SvgHexagon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 21.333V10.667a2.667 2.667 0 0 0-1.333-2.307l-9.334-5.333a2.667 2.667 0 0 0-2.666 0L5.333 8.36A2.667 2.667 0 0 0 4 10.667v10.666a2.667 2.667 0 0 0 1.333 2.307l9.334 5.333a2.666 2.666 0 0 0 2.666 0l9.334-5.333A2.668 2.668 0 0 0 28 21.333Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgHexagon)
export default Memo
