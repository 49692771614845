import { apiClient } from '~/shared/api'
import { EIGHT_HOURS } from '~/shared/config'

const organizationsQueryKeys = {
  users: [{ scope: 'organizations', type: 'users', item: 'active' }] as const,
  invitedUsers: [{ scope: 'organizations', type: 'users', item: 'invited' }] as const,
  trialDays: [{ scope: 'organizations', item: 'trialDays' }] as const,
  ownedOrganizations: [{ scope: 'organizations', item: 'ownedOrganizations' }] as const,
}

/**
 * Query to get all the users from an organization
 */
export const organizationsUsersQuery = (isOrgAccount: boolean) => ({
  queryKey: organizationsQueryKeys.users,
  queryFn: apiClient.organizations.getUsersFromOrganization,
  enabled: isOrgAccount,
})

/**
 * Query to get the pending users invitations
 */
export const organizationsInvitedUsersQuery = (isOrgAccount: boolean) => ({
  queryKey: organizationsQueryKeys.invitedUsers,
  queryFn: apiClient.organizations.getInvitedUsers,
  enabled: isOrgAccount,
})

/**
 * Query to get the remaining trial days for the organization/platform fee
 */
export const organizationsTrialDaysQuery = (isOrgAccount: boolean) => ({
  queryKey: organizationsQueryKeys.trialDays,
  queryFn: apiClient.organizations.getOrganizationTrialDays,
  staleTime: EIGHT_HOURS,
  enabled: isOrgAccount,
})

/**
 * Query to get all the organizations owned by the current user
 */
export const organizationsOwnedQuery = () => ({
  queryKey: organizationsQueryKeys.ownedOrganizations,
  queryFn: apiClient.organizations.getOwnedOrganizations,
  staleTime: EIGHT_HOURS,
})
