import { type AllHTMLAttributes, type RefObject, useRef } from 'react'
import { type AriaButtonProps, mergeProps, useButton, useFocusRing, useHover } from 'react-aria'

import { Box } from '../Box'
import { Icon, type IconProps } from '../Icon'
import { Loading } from '../Loading'

import { type Variants } from './IconButton.css'
import * as styles from './IconButton.css'

type PickedDOMProps = Pick<AllHTMLAttributes<HTMLElement>, 'title' | 'name' | 'value'>
type CommonProps = {
  /**
   * Render this icon inside the IconButton
   */
  icon: IconProps['children']
  /**
   * Add a loading indicator to the button while mantaining the same size it has in the previous state.
   * @default false
   */
  isLoading?: boolean
  /**
   * Controll the button's ref from outside. If undefined defaults to the inner ref.
   */
  buttonRef?: RefObject<HTMLButtonElement>
  /**
   * Controls the size of the icon rendered inside the IconButton
   */
  size?: IconProps['size']
} & Variants
type ConditionalProps =
  | {
      /**
       * Defines a string value that labels the current element.
       * You must provide either `aria-label` or `aria-labeledby` to make the button accessible
       */
      'aria-label': string

      /**
       * Identifies the element (or elements) that labels the current element.
       * You must provide either `aria-label` or `aria-labeledby` to make the button accessible
       */
      'aria-labelledby'?: unknown
    }
  | {
      /**
       * Defines a string value that labels the current element.
       * You must provide either `aria-label` or `aria-labeledby` to make the button accessible
       */
      'aria-label'?: unknown

      /**
       * Identifies the element (or elements) that labels the current element.
       * You must provide either `aria-label` or `aria-labeledby` to make the button accessible
       */
      'aria-labelledby': string
    }
export type IconButtonProps = CommonProps &
  PickedDOMProps &
  AriaButtonProps<'button'> &
  ConditionalProps

/**
 * Renders an actionable element styled as a button with a single icon and with proper a11y defaults.
 */
export const IconButton = (props: IconButtonProps) => {
  const {
    isDisabled = false,
    isLoading = false,
    color = 'neutral',
    shape,
    size = 'medium',
    title,
    name,
    value,
    buttonRef,
    icon,
    ...rest
  } = props
  const innerRef = useRef<HTMLButtonElement>(null)
  const ref = buttonRef ?? innerRef

  const { buttonProps, isPressed } = useButton({ isDisabled, ...rest }, ref)
  const { hoverProps, isHovered } = useHover({})
  const { focusProps, isFocusVisible } = useFocusRing()
  const { color: htmlColor, ...mergedPropsWithoutColor } = mergeProps(
    buttonProps,
    hoverProps,
    focusProps,
  )

  return (
    <Box
      {...mergedPropsWithoutColor}
      as="button"
      ref={ref}
      title={title}
      name={name}
      value={value}
      className={styles.variants({
        color,
        shape,
        size,
        isDisabled,
        isHovered: isHovered && !isDisabled,
        isPressed,
        isFocused: isFocusVisible,
      })}
    >
      {isLoading ? (
        <Loading size={styles.mapButtonSizeVariantToIconSize[size]} />
      ) : (
        <Icon size={styles.mapButtonSizeVariantToIconSize[size]}>{icon}</Icon>
      )}
    </Box>
  )
}
