import { memo, type SVGProps } from 'react'

const SvgGateway = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.375 19.875 30 16.5 15.5 3 1 16.5 15.5 30l9.666-9-3.625-3.375L15.5 23.25 8.25 16.5l7.25-6.75 7.25 6.75 3.625 3.375Z"
      fill="currentColor"
    />
  </svg>
)
const Memo = memo(SvgGateway)
export default Memo
