import { type ReactNode, type RefObject, useRef } from 'react'
import { type AriaPopoverProps, DismissButton, Overlay, usePopover } from 'react-aria'
import { type OverlayTriggerState } from 'react-stately'

import { Box } from '../Box'

import * as styles from './Popover.css'

export type PopoverProps = Omit<AriaPopoverProps, 'popoverRef'> & {
  children: ReactNode
  /**
   * The state object coming from a react-stately hook
   */
  state: OverlayTriggerState
  /**
   * The width of the Popover controlled by its trigger.
   */
  width?: number
  /**
   * The ref for the popover element.
   * Redeclarem to make it optional from `AriaPopoverProps`
   */
  popoverRef?: RefObject<Element>
}

/**
 * Renders a Popover in a React.Portal positioned next to the trigger element.
 */
export const Popover = (props: PopoverProps) => {
  const { state, children, popoverRef, width, offset = 8, ...rest } = props
  const innerRef = useRef<HTMLDivElement>(null)
  const ref = popoverRef ?? innerRef

  const { popoverProps, underlayProps } = usePopover(
    {
      ...rest,
      popoverRef: ref,
      offset,
    },
    state,
  )

  const maxWidth = width && width < 320 ? '320px' : undefined
  const close = () => state.close()

  return (
    <Overlay>
      <Box {...underlayProps} />
      <Box
        {...popoverProps}
        ref={ref as RefObject<HTMLDivElement>}
        className={styles.wrapper}
        style={{ ...popoverProps.style, width: `${width}px`, maxWidth }}
      >
        <DismissButton onDismiss={close} />
        {children}
        <DismissButton onDismiss={close} />
      </Box>
    </Overlay>
  )
}
