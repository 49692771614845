import { BIBIT_UNITS, BIBYTE_UNITS, BIT_UNITS, BYTE_UNITS } from './constants'

type UniteDimensions = 'kilo' | 'mega' | 'giga' | 'tera'
type Options = {
  bits?: boolean
  binary?: boolean
  convertTo?: UniteDimensions
}

const mapDimensionToExponent: Record<UniteDimensions, number> = {
  kilo: 1,
  mega: 2,
  giga: 3,
  tera: 4,
}

export function convertSizeToDimension(number: number, options: Options) {
  const { binary = false, bits = false, convertTo = 'giga' } = options
  const UNITS = bits ? (binary ? BIBIT_UNITS : BIT_UNITS) : binary ? BIBYTE_UNITS : BYTE_UNITS

  const exponent = mapDimensionToExponent[convertTo]
  const base = binary ? 1024 : 1000

  let convertedNumber = number.valueOf()
  convertedNumber /= base ** exponent
  convertedNumber = Number(convertedNumber.toPrecision(3))

  return {
    convertedNumber,
    unit: UNITS[exponent],
  }
}
