import { useFetcher, useSearchParams } from 'react-router-dom'
import { type Project, type QrynCreateTokenData } from 'api-client'
import { type typeToFlattenedError } from 'zod'

import { CREATE_QRYN_TOKEN } from '~/entities/integrations/qryn'

import { useNavigate } from '~/shared/lib/reactRouterWrappers'

import { Button } from '~/components/Button'
import { Card } from '~/components/Card'
import { CheckboxGroup } from '~/components/CheckboxGroup'
import { Input } from '~/components/Input'
import { Modal } from '~/components/Modal'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

type CreateTokenActionData = {
  fields?: QrynCreateTokenData
  errors?: typeToFlattenedError<QrynCreateTokenData>
}

type CreateQrynTokenProps = {
  projectId: Project['id']
  projectSlug: Project['slug']
}

export function CreateToken({ projectId, projectSlug }: CreateQrynTokenProps) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const createToken = useFetcher<CreateTokenActionData>()

  const isCreatingToken = searchParams.get('action') === 'create-token'

  return (
    <>
      <Button
        color="outline"
        onPress={() =>
          navigate('', { searchParams: { action: 'create-token' }, preventScrollReset: true })
        }
      >
        Create token
      </Button>
      <Modal isOpen={isCreatingToken} onClose={() => navigate('.', { preventScrollReset: true })}>
        <createToken.Form method="post" action={`/${projectSlug}/stack/qryn`}>
          <input type="hidden" name="projectId" value={projectId} />
          <Modal.Header title="Create a new scoped token" />
          <Modal.Content>
            <Stack>
              <Input
                name="name"
                label="Name"
                description="Choose a descriptive name that reflects its purpose and makes it easy to identify among other tokens."
                errorMessage={createToken.data?.errors?.fieldErrors.name}
              />

              <CheckboxGroup
                label="Scope"
                direction="horizontal"
                errorMessage={createToken.data?.errors?.fieldErrors.scope}
              >
                <>
                  {['read', 'write'].map((scope, index) => (
                    <CheckboxGroup.Checkbox key={scope} name={`scope[${index}]`} value={scope}>
                      <Text>{scope}</Text>
                    </CheckboxGroup.Checkbox>
                  ))}
                </>
              </CheckboxGroup>
            </Stack>
          </Modal.Content>
          <Card.Divider />
          <Modal.Content>
            <Stack direction="horizontal" space="medium" justify="end">
              <Button
                color="transparent"
                onPress={() => navigate('.', { preventScrollReset: true })}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                name="intent"
                value={CREATE_QRYN_TOKEN}
                isDisabled={createToken.state !== 'idle'}
                isLoading={createToken.state === 'submitting'}
              >
                Create token
              </Button>
            </Stack>
          </Modal.Content>
        </createToken.Form>
      </Modal>
    </>
  )
}
