import { useFetcher } from 'react-router-dom'
import { type HepicRemoveUsersData } from 'api-client/integrations/hepic/model'

import { REMOVE_HEPIC_USER } from '~/entities/integrations/hepic'

import { useDecodedToken } from '~/shared/model/auth/useAuth'

import { IconButton } from '~/components/IconButton'
import { Close } from '~/components/Icons'

type AddUserToHepicProps = HepicRemoveUsersData

export const RemoveUserFromHepic = ({
  users,
  isAdmin,
}: AddUserToHepicProps & { isAdmin: boolean }) => {
  const dt = useDecodedToken()
  const [user] = users

  const fetcher = useFetcher()

  return (
    <fetcher.Form method="post">
      <input type="hidden" name="users[0].user_id" defaultValue={user.user_id} />
      <IconButton
        key={`delete${user.user_id}`}
        aria-label="Remove user from Hepic"
        title="Remove user from Hepic"
        name="intent"
        type="submit"
        value={REMOVE_HEPIC_USER}
        color={'outline'}
        isDisabled={dt?.id === user.user_id || fetcher.state === 'submitting' || !isAdmin}
        isLoading={fetcher.state === 'submitting'}
        icon={<Close />}
      />
    </fetcher.Form>
  )
}
