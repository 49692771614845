import { useFocusRing } from 'react-aria'
import { useLocation } from 'react-router-dom'

import { RouterLink, type RouterLinkProps } from '~/shared/lib/reactRouterWrappers'

import { Icon } from '~/components/Icon'
import { ArrowUpRight } from '~/components/Icons'

import * as styles from './Link.css'

type Props = styles.Variants & RouterLinkProps

export const Link = (props: Props) => {
  const {
    className,
    type,
    children,
    buttonColor,
    buttonType,
    buttonSize,
    textTransform,
    disabled,
    isExternal = false,
    ...linkProps
  } = props
  const { isFocusVisible, focusProps } = useFocusRing()
  const { pathname } = useLocation()
  return (
    <RouterLink
      {...linkProps}
      {...focusProps}
      className={
        className ??
        styles.link({
          type,
          ...(type === 'button' && {
            disabled,
            buttonColor: buttonColor ?? 'neutral',
            buttonSize: buttonSize ?? 'medium',
            buttonType: buttonType ?? 'centered',
            textTransform: textTransform ?? 'uppercase',
          }),
          disabled: disabled ?? false,
          isFocused: isFocusVisible,
        })
      }
      state={{ from: pathname }}
    >
      {children}
      {isExternal ? (
        <Icon color="white" size="xlarge" isInline={true}>
          <ArrowUpRight />
        </Icon>
      ) : null}
    </RouterLink>
  )
}
