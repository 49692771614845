import { transformCamelObjectKeysToSnake } from 'plunger'
import { z } from 'zod'

import { type Rooquest } from '../../lib/rooquest'
import { type ProjectId } from '../../projects/model'
import { type MessageResponse } from '../../utils/types'

import {
  clickHouseClusterMetadataSchema,
  clickHouseClusterMetricsSchema,
  clickHouseClusterSchema,
  type ClickHouseDeleteClusterData,
  clickHouseDeleteClusterSchema,
  type ClickHouseEnableData,
  clickHouseEnableSchema,
  type ClickHouseUpdateIPSafeListData,
  clickHouseUpdateIPSafeListSchema,
  clickHouseVersionSchema,
  type ConnectClickHouseClusterData,
  connectClickHouseClusterSchema,
  type CreateMCHClusterData,
  createMCHClusterSchema,
  diskTypeSchema,
  machineSchema,
  type ProvidersSlug,
  regionSchema,
} from './model'

const clickHouseClusters = z.array(clickHouseClusterSchema)
const clickHouseVersions = z.array(clickHouseVersionSchema)
const regions = z.array(regionSchema)
const machines = z.array(machineSchema)
const disktypes = z.array(diskTypeSchema)

export default class ClickHouseService {
  private readonly rooquest: Rooquest

  constructor(rooquest: Rooquest) {
    this.rooquest = rooquest
  }

  /**
   * Add `ClickHouse` integration to a project
   */
  public enableClickHouse = async (data: ClickHouseEnableData) => {
    const payload = clickHouseEnableSchema.parse(data)

    const res = await this.rooquest.post('integrations/clickhouse/', {
      query: transformCamelObjectKeysToSnake({ projectId: payload.projectId }),
    })

    return res as MessageResponse
  }

  /**
   * Get `ClickHouse` clusters for the project
   */
  public getClickHouseClusters = async (projectId: ProjectId) => {
    const res = await this.rooquest.get('integrations/clickhouse/clusters', {
      query: transformCamelObjectKeysToSnake({ projectId: projectId }),
    })

    return clickHouseClusters.parse(res)
  }

  /**
   * Create a `ClickHouse` cluster for the project
   */
  public createClickHouseCluster = async (data: CreateMCHClusterData) => {
    const { disks, projectId, ...rest } = createMCHClusterSchema.parse(data)
    const res = await this.rooquest.post('integrations/clickhouse/clusters', {
      body: {
        ...transformCamelObjectKeysToSnake({
          ...rest,
          projectId: parseInt(projectId),
        }),
        disks: disks.map(disk => transformCamelObjectKeysToSnake(disk)),
      },
      query: transformCamelObjectKeysToSnake({ projectId }),
    })
    return res as MessageResponse
  }

  /**
   * NOT ACCESSIBLE IN THE UI
   * ISSUE GIGAPIPE-T-98 -- Remove access to "create external cluster" in the UI
   * Connect an external `ClickHouse` cluster to the project
   */
  public connectExternalClickHouseCluster = async (data: ConnectClickHouseClusterData) => {
    const { projectId, ...rest } = connectClickHouseClusterSchema.parse(data)
    const res = await this.rooquest.post('integrations/clickhouse/clusters', {
      body: transformCamelObjectKeysToSnake({
        ...rest,
        projectId: parseInt(projectId),
      }),
      query: transformCamelObjectKeysToSnake({ projectId }),
    })
    return res as MessageResponse
  }

  /**
   * Delete a `ClickHouse` cluster from the project
   */
  public deleteClickHouseCluster = async (data: ClickHouseDeleteClusterData) => {
    const payload = clickHouseDeleteClusterSchema.parse(data)
    const res = await this.rooquest.delete('integrations/clickhouse/clusters', {
      query: transformCamelObjectKeysToSnake(payload),
    })

    return res as MessageResponse
  }

  /**
   * Get`ClickHouse` cluster metadata
   */
  public getClickHouseClusterMetadata = async ({
    projectId,
    clusterName,
  }: {
    projectId: ProjectId
    clusterName: string
  }) => {
    const res = await this.rooquest.get(
      `integrations/clickhouse/clusters/${clusterName}/metadata`,
      {
        query: transformCamelObjectKeysToSnake({ projectId }),
      },
    )

    return clickHouseClusterMetadataSchema.parse(res)
  }

  /**
   * Get `ClickHouse` cluster metrics
   */
  public getClickHouseClusterMetrics = async ({
    projectId,
    clusterName,
    startTime,
    endTime,
    databases,
    nodes,
  }: {
    projectId: ProjectId
    clusterName: string
    startTime: number
    endTime: number
    nodes: string[]
    databases: string[]
  }) => {
    const res = await this.rooquest.get(`integrations/clickhouse/clusters/${clusterName}/metrics`, {
      query: transformCamelObjectKeysToSnake({ projectId, startTime, endTime, nodes, databases }),
    })

    return clickHouseClusterMetricsSchema.parse(res)
  }

  /**
   * Update `ClickHouse` cluster IP safe list
   */
  public updateClickHouseClusterIpSafeList = async (data: ClickHouseUpdateIPSafeListData) => {
    const { projectId, clusterId, ...rest } = clickHouseUpdateIPSafeListSchema.parse(data)
    const res = await this.rooquest.patch('integrations/clickhouse/clusters', {
      body: transformCamelObjectKeysToSnake(rest),
      query: transformCamelObjectKeysToSnake({ projectId, clusterId }),
    })

    return res as MessageResponse
  }

  /**
   * Get the available cloud regions to create `ClickHouse` clusters
   */
  public getRegions = async ({
    projectId,
    providerSlug,
  }: {
    projectId: ProjectId
    providerSlug?: ProvidersSlug
  }) => {
    const res = await this.rooquest.get('integrations/clickhouse/regions', {
      query: transformCamelObjectKeysToSnake({
        projectId,
        providerSlug,
      }),
    })

    return regions.parse(res)
  }

  /**
   * Get the available machines for the `ClickHouse` clusters
   */
  public getMachines = async ({
    projectId,
    providerSlug,
  }: {
    projectId: ProjectId
    providerSlug?: ProvidersSlug
  }) => {
    const res = await this.rooquest.get('integrations/clickhouse/machines', {
      query: transformCamelObjectKeysToSnake({
        projectId,
        providerSlug,
      }),
    })

    return machines.parse(res)
  }

  /**
   * Get the available types of disks for the `ClickHouse` clusters
   */
  public getDisktypes = async ({
    projectId,
    providerSlug,
  }: {
    projectId: ProjectId
    providerSlug?: ProvidersSlug
  }) => {
    const res = await this.rooquest.get('integrations/clickhouse/disk-types', {
      query: transformCamelObjectKeysToSnake({
        projectId,
        providerSlug,
      }),
    })

    return disktypes.parse(res)
  }

  /**
   * Get the available zones for each GCP region
   */
  public getZones = async ({
    projectId,
    providerSlug,
    regionCode,
  }: {
    projectId: ProjectId
    providerSlug?: ProvidersSlug
    regionCode?: string
  }) => {
    const res = await this.rooquest.get('integrations/clickhouse/zones', {
      query: transformCamelObjectKeysToSnake({
        projectId,
        providerName: providerSlug,
        regionCode,
      }),
    })

    return res as string[]
  }

  /**
   * Get the available `ClickHouse` versions
   */
  public getClickHouseVersions = async (projectId: ProjectId) => {
    const res = await this.rooquest.get('integrations/clickhouse/clickhouse-versions', {
      query: transformCamelObjectKeysToSnake({
        projectId,
      }),
    })

    return clickHouseVersions.parse(res)
  }
}
