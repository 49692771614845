import { useCallback, useState } from 'react'
import { useSubmit } from 'react-router-dom'
import { useStripe } from '@stripe/react-stripe-js'
import { type CreatePaymentMethodCardData, type SetupIntentResult } from '@stripe/stripe-js'

import { ADD_PAYMENT_METHOD } from '~/entities/billing'

import { apiClient } from '~/shared/api'

export function useAddPaymentMethod(billingAccountId: string) {
  const stripe = useStripe()
  if (!stripe) throw new Error('There is no Stripe instance saved in StripeStore')

  const [isLoading, setLoading] = useState(false)
  const submit = useSubmit()

  const addPaymentMethod = useCallback(
    async (paymentMethod: Omit<CreatePaymentMethodCardData, 'type'>) => {
      try {
        setLoading(true)
        const [stripeConfig, { clientSecret }] = await Promise.all([
          apiClient.billing.getStripeConfig(),
          apiClient.billing.setupPaymentMethod({ billingAccountId }),
        ])

        if (!clientSecret) {
          setLoading(false)
          throw new Error(
            'The request to `stripe/payment-method/setup` did not return a valid `client_secret`',
          )
        }

        // this response should be mocked if we got sripeMock from env

        const isStripeMock = import.meta.env.VITE_STRIPE_MOCK === 'true'

        let setupIntentResult: SetupIntentResult

        if (isStripeMock) {
          setupIntentResult = (await Promise.resolve({
            setupIntent: {
              id: 'si_1N1LpYKJbqC7n9EYc6R5z6sL',
              payment_method: 'pm_1N1LpYKJbqC7n9EYc6R5z6sL',
            },
          })) as SetupIntentResult
        } else {
          setupIntentResult = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              ...paymentMethod,
              billing_details: {
                ...paymentMethod.billing_details,
                name: stripeConfig.username,
                email: stripeConfig.email,
              },
            },
          })
        }

        if (setupIntentResult?.error) {
          setLoading(false)
          throw new Error(setupIntentResult.error.message)
        }
        if (!setupIntentResult?.setupIntent?.payment_method) {
          setLoading(false)
          throw new Error('User SetupIntent does not have a valid `payment_method`')
        }
        // this goes to attach payment method
        const formData = new FormData()
        formData.append('intent', ADD_PAYMENT_METHOD)
        formData.append('billingAccountId', billingAccountId)
        formData.append('paymentMethodId', setupIntentResult?.setupIntent?.payment_method as string)

        submit(formData, {
          method: 'post',
          encType: 'application/x-www-form-urlencoded',
        })
      } catch (error) {
        setLoading(false)
        throw error
      }
    },
    [billingAccountId, stripe, submit],
  )

  return { addPaymentMethod, isLoading }
}
