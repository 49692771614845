import { apiClient } from '~/shared/api'

const billingQueryKeys = {
  current: [{ scope: 'billing', type: 'billingAccount', item: 'current' }] as const,
}

/**
 * Query to fetch the current billing account for the organization/project
 */
export const currentBillingAccountQuery = {
  queryKey: billingQueryKeys.current,
  queryFn: apiClient.billing.getCurrentBillingAccount,
}
