import { memo, type SVGProps } from 'react'

const SvgEdit2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.667 4A3.77 3.77 0 1 1 28 9.333l-18 18-7.333 2 2-7.333 18-18Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgEdit2)
export default Memo
