import { json, redirect } from 'react-router-dom'
import { type QueryClient } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { notify } from '~/features/notifications'

import { isApiError } from '~/shared/model/errors'

import { integrationsQuery } from '../queries'

type Arguments = {
  /**
   * Slug of the integration.
   */
  slug: string
  /**
   * Project ID.
   */
  projectId: Project['id']
  /**
   * Project slug.
   */
  projectSlug: Project['slug']
}

/**
 * Function that fetches the integration information from the cache/API and validates if it is enabled.
 *
 * - It will throw a Response if the integrations can not be fetched.
 * - It will throw a Response if the integration is not enabled.
 * - It will throw a Redirect if the integration has been suspended.
 * - It returns the integration object.
 * @param slug
 * @param projectId
 * @param projectSlug
 * @param queryClient
 * @returns
 */
export async function checkIntegrationStatus(
  queryClient: QueryClient,
  { slug, projectId, projectSlug }: Arguments,
) {
  let integrations = []
  try {
    integrations = await queryClient.ensureQueryData(integrationsQuery(projectId))
  } catch (error) {
    if (isApiError(error) && error.type === 'data_query') {
      throw json(
        {
          message: `We could not find the integration for project ${projectId}`,
          description: `${slug} might not be properly enabled. Please go to the Stack page and try again.`,
          goTo: {
            path: `/${projectSlug}/stack`,
            text: 'Go to Stack',
          },
        },
        { status: 400 },
      )
    }
    throw error
  }

  const integration = integrations.find(i => i.product.slug === slug)

  if (!integration) {
    throw json(
      {
        message: `${slug} is not enabled for project ${projectId}`,
        description: `To have access to ${slug} you first need to add it to your project. Please go to the integrations page to enable it.`,
        goTo: {
          path: `/${projectSlug}/stack`,
          text: 'Go to Integrations',
        },
      },
      { status: 400 },
    )
  }

  const { status } = integration
  if (status === 'suspended' || status === 'cancelled by user') {
    notify.warning(
      `You're trying to access ${status === 'suspended' ? status : 'cancelled'} product`,
    )
    throw redirect(`/${projectSlug}/stack`)
  }
  return integration
}
