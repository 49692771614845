import { type Project } from 'api-client'

import { InfoIntegrationLink, IntegrationLink } from '~/entities/integrations/base'

import { useClickHouseLinks } from '../model'

type ClickHouseLinksProps = {
  projectId: Project['id']
}

export function ClickHouseLinks({ projectId }: ClickHouseLinksProps) {
  const { view, instance } = useClickHouseLinks(projectId)

  if (!view || !instance) return null
  return (
    <>
      <IntegrationLink label="ClickHouse play" launchURL={view} />
      <InfoIntegrationLink label="Your ClickHouse instance" copyURL={instance} />
    </>
  )
}
