import { transformCamelObjectKeysToSnake } from 'plunger'
import { z } from 'zod'

import { type Rooquest } from '../../lib/rooquest'
import { type ProjectId } from '../../projects/model'
import { type MessageResponse } from '../../utils/types'

import {
  type GrafanaAddDashboardData,
  grafanaAddDashboardSchema,
  type GrafanaAddDatasourceData,
  grafanaAddDatasourceSchema,
  type GrafanaAddUserData,
  grafanaAddUserSchema,
  grafanaConfigSchema,
  grafanaDatasourceSchema,
  type GrafanaEnableData,
  grafanaEnableSchema,
  grafanaQrynDatasourceSchema,
  grafanaUserSchema,
} from './model'

const grafanaDatasources = z.array(grafanaDatasourceSchema)
const grafanaQrynDatasources = z.array(grafanaQrynDatasourceSchema)
const usersFromGrafana = z.array(grafanaUserSchema)

export default class GrafanaService {
  private readonly rooquest: Rooquest

  constructor(rooquest: Rooquest) {
    this.rooquest = rooquest
  }

  /**
   * Add `Grafana` integration to a project
   */
  public enableGrafana = async (data: GrafanaEnableData) => {
    const payload = grafanaEnableSchema.parse(data)
    const res = await this.rooquest.post('integrations/grafana/', {
      query: transformCamelObjectKeysToSnake(payload),
    })

    return res as MessageResponse
  }

  /**
   * Get `Grafana` configuration
   */
  public getGrafanaConfig = async (projectId: ProjectId) => {
    const res = await this.rooquest.get('integrations/grafana/config', {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return grafanaConfigSchema.parse(res)
  }

  /**
   * Get existing `Grafana` datasources
   */
  public getGrafanaDatasource = async (projectId: ProjectId) => {
    const res = await this.rooquest.get('integrations/grafana/datasources', {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return grafanaDatasources.parse(res)
  }

  /**
   * Get existing `Grafana` datasources for `qryn`
   */
  public getGrafanaQrynDatasource = async (projectId: ProjectId) => {
    const res = await this.rooquest.get('integrations/grafana/qryn-datasources', {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return grafanaQrynDatasources.parse(res)
  }

  /**
   * Add `Grafana` datasource
   */
  public addGrafanaDatasource = async (data: GrafanaAddDatasourceData) => {
    const { type, ...rest } = grafanaAddDatasourceSchema.parse(data)
    const res = await this.rooquest.post('integrations/grafana/datasources', {
      body: transformCamelObjectKeysToSnake(rest),
      query: transformCamelObjectKeysToSnake({
        projectId: parseInt(rest.projectId),
      }),
    })

    return res as MessageResponse
  }

  /**
   * Add `Grafana` dashboard
   */
  public addGrafanaDashboard = async (data: GrafanaAddDashboardData) => {
    const payload = grafanaAddDashboardSchema.parse(data)
    const res = await this.rooquest.post('integrations/grafana/dashboards', {
      body: transformCamelObjectKeysToSnake(payload),
      query: transformCamelObjectKeysToSnake({
        projectId: parseInt(data.projectId),
      }),
    })

    return res as MessageResponse
  }

  /**
   * Add `Grafana` user
   */
  public addGrafanaUser = async (data: GrafanaAddUserData) => {
    const payload = grafanaAddUserSchema.parse(data)
    const res = await this.rooquest.post('integrations/grafana/users', {
      body: transformCamelObjectKeysToSnake(payload),
      query: transformCamelObjectKeysToSnake({
        projectId: data.projectId,
      }),
    })

    return res as MessageResponse
  }

  /**
   * Get users from Grafana
   */
  public getGrafanaUsers = async (projectId: ProjectId) => {
    const res = await this.rooquest.get('integrations/grafana/users', {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return usersFromGrafana.parse(res)
  }

  /**
   * Enable/Disable `Grafana` auto-add users feature
   */
  public toggleGrafanaAutoAddUsers = async (projectId: ProjectId) => {
    const res = await this.rooquest.post('integrations/grafana/auto-add', {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return res as MessageResponse
  }
}
