import { type NotificationType } from './Notifications.types'

export const defaultTimeouts: {
  [key in NotificationType]: number
} = {
  default: 5000,
  error: 7000,
  success: 5000,
  warning: 5000,
}
export const NOTIFICATIONS_LIMIT = 5
export const DEFAULT_TIME_TO_REMOVE_NOTIFICATIONS = 1000
