const ERROR_TYPES = ['data_query', 'data_mutation', 'server_error'] as const
type ErrorTypes = (typeof ERROR_TYPES)[number]

class ApiError extends Error {
  type: ErrorTypes | undefined

  description: string

  status: number

  constructor(
    message: string,
    args: { description: string; statusCode: number; type?: ErrorTypes },
  ) {
    super(message)
    this.name = 'ApiError'
    this.type = args.type
    this.description = args.description
    this.status = args.statusCode ?? 400
  }
}

export default ApiError

export function isApiError(error: unknown): error is ApiError {
  return (error as ApiError).name === 'ApiError'
}
