import { useFetcher } from 'react-router-dom'

import { type ActionData } from '~/pages/settings/organization'

import { RESEND_INVITE } from '~/entities/organizations'

import { Button } from '~/components/Button'
// we should get the invitation ID in here
export const ResendInvitationForm = ({ id }: { id: number }) => {
  const fetcher = useFetcher<ActionData>()

  return (
    <fetcher.Form method="post">
      <input type="hidden" value={id} name="id" />
      <Button
        color="brand"
        size="small"
        type="submit"
        name="intent"
        value={RESEND_INVITE}
        isDisabled={fetcher.state === 'submitting'}
        isLoading={fetcher.state === 'submitting'}
        aria-label="Re-send invitation"
      >
        Re-send Invitation
      </Button>
    </fetcher.Form>
  )
}
