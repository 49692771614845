import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { qrynConfigQuery } from '~/entities/integrations/qryn'

import { QrynCodeSnippet } from '~/shared/ui/CodeSnippet'
import { Tabs } from '~/shared/ui/Tabs'

import { Card } from '~/components/Card'
import { Stack } from '~/components/Stack'

import {
  getLogIngestionSnippet,
  getMetricsIngestionSnippet,
  getTelemetryIngestionSnippet,
} from '../model'

type QrynInfoProps = {
  projectId: Project['id']
}

export function QrynInfo({ projectId }: QrynInfoProps) {
  const { data: config } = useQuery(qrynConfigQuery(projectId))

  return (
    <Card>
      <Card.Section>
        <Stack>
          <Tabs>
            <Tabs.Item key="LOGS" title="Log Ingestion">
              <QrynCodeSnippet
                snippet={getLogIngestionSnippet({
                  host: config?.url,
                  apiKey: config?.apiKey,
                  isMultiOrgEnabled: config?.multiOrg,
                })}
                privateSnippet={getLogIngestionSnippet({
                  host: config?.url,
                  apiKey: config?.apiKey,
                  apiSecret: config?.apiSecret,
                  isMultiOrgEnabled: config?.multiOrg,
                })}
                link="data-ingestion/logs--logql"
              />
            </Tabs.Item>
            <Tabs.Item key="METRICS" title="Metric Ingestion">
              <QrynCodeSnippet
                snippet={getMetricsIngestionSnippet({
                  host: config?.url,
                  apiKey: config?.apiKey,
                  isMultiOrgEnabled: config?.multiOrg,
                })}
                privateSnippet={getMetricsIngestionSnippet({
                  host: config?.url,
                  apiKey: config?.apiKey,
                  apiSecret: config?.apiSecret,
                  isMultiOrgEnabled: config?.multiOrg,
                })}
                link="data-ingestion/metrics--influx"
              />
            </Tabs.Item>
            <Tabs.Item key="TELEMETRY" title="Telemetry Ingestion">
              <QrynCodeSnippet
                snippet={getTelemetryIngestionSnippet({
                  host: config?.url,
                  apiKey: config?.apiKey,
                  isMultiOrgEnabled: config?.multiOrg,
                })}
                privateSnippet={getTelemetryIngestionSnippet({
                  host: config?.url,
                  apiKey: config?.apiKey,
                  apiSecret: config?.apiSecret,
                  isMultiOrgEnabled: config?.multiOrg,
                })}
                link="data-ingestion/telemetry--grafana_agent"
              />
            </Tabs.Item>
          </Tabs>
        </Stack>
      </Card.Section>
    </Card>
  )
}
