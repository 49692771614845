import { type ReactNode } from 'react'
import { type ClassValue, clsx } from 'clsx'

import { type ResponsiveValue } from '~/styles/sprinkles.css'

import { Box, type BoxProps } from '../Box'

import { columnsToTemplateColumns, spanToGridColumn } from './utils'

import { type GridColumn, type GridSpan } from './Grid.css'
import * as styles from './Grid.css'

export type GridProps = {
  children: ReactNode | ReactNode[]
  /**
   * Maps to grid-template-columns CSS property
   * @default 12
   */
  columns?: ResponsiveValue<GridColumn>
  /**
   * Maps to gap CSS property
   * @default "large"
   */
  space?: BoxProps['gap']
  /**
   * Control the alignment of items in the block axis
   */
  align?: BoxProps['alignItems']
}

export const Grid = ({
  children,
  align = 'flex-start',
  columns = 12,
  space = 'large',
}: GridProps) => (
  <Box
    style={{ display: 'grid' }}
    gap={space}
    alignItems={align}
    className={styles.gridSprinkles({
      gridTemplateColumns: columnsToTemplateColumns(columns),
    })}
  >
    {children}
  </Box>
)

export type GridItemProps = {
  children: ReactNode | ReactNode[]
  /**
   * Maps to display:contents CSS property
   * @default false
   */
  matchHeight?: boolean
  /**
   * Maps to grid-column CSS property
   * @default 1
   */
  span?: ResponsiveValue<GridSpan>
  /**
   * Allows to add additional styles by passing a className
   */
  className?: ClassValue
}

const GridItem = ({ children, matchHeight = false, span = 1, className }: GridItemProps) => {
  const classNames = clsx(
    styles.gridItem({
      matchHeight,
    }),
    styles.gridSprinkles({
      gridColumn: spanToGridColumn(span),
    }),
    className,
  )
  return <div className={classNames}>{children}</div>
}

Grid.Item = GridItem
