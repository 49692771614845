import { memo, type SVGProps } from 'react'

const SvgHandle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 16.083a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0ZM14.5 10.75a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0ZM14.5 21.417a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0Z"
      fill="currentColor"
    />
    <path
      d="M14.5 16.083a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0ZM14.5 10.75a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0ZM14.5 21.417a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5 16.083a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0ZM19.5 10.75a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0ZM19.5 21.417a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0Z"
      fill="currentColor"
    />
    <path
      d="M19.5 16.083a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0ZM19.5 10.75a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0ZM19.5 21.417a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgHandle)
export default Memo
