// import { transformSnakeObjectKeysToCamel } from 'plunger'
import { jwtDecode } from 'jwt-decode'
import Keycloak from 'keycloak-js'
import { createStore, type StoreApi } from 'zustand'

import {
  type AuthState,
  type DecodedToken,
  type KeyCloakConnectOptions,
  type TokenResponse,
  // type KeyCloakParams,
  type User,
} from './types'

export const createAuthStore = <TUser extends User = User>(options: KeyCloakConnectOptions) => {
  const store = createStore<AuthState<TUser>>()((set, get) => ({
    isLoading: true,
    isAuthenticated: false,
    keyCloakClient: new Keycloak(options),
    keyCloakError: null,
    updatedAt: 0,
    user: {} as TUser,
    keyCloakApi: undefined,
    decodedToken: undefined,
    setKeyCloakApi: api => set({ keyCloakApi: api }),
    setKeyCloakError: error => set({ keyCloakError: error }),
    setUser: kuser => set({ user: kuser }),
    _actions: {
      initialised: user =>
        set(state => ({
          ...state,
          isAuthenticated: !!user,
          user,
          isLoading: false,
          error: undefined,
        })),
    },

    actions: {
      loginWithRedirect: async loginOptions => {
        // this should do the login action

        const { keyCloakClient } = get()

        if (!keyCloakClient) {
          const client = new Keycloak(options)

          if (!client?.authenticated) {
            await client
              .init({ onLoad: 'login-required' })
              .then(authorized => {
                if (authorized) {
                  set(state => ({ ...state, keyCloakClient: client, keyCloakError: null }))
                }

                const kc = get().keyCloakClient

                if (kc && !kc.authenticated) {
                  kc.login(loginOptions).catch((error: Error) => {
                    set(state => ({ ...state, keyCloakError: error }))
                  })
                }
              })
              .catch((error: Error) => {
                set(state => ({ ...state, keyCloakError: error }))
              })
          }
        }

        return null
      },
      logout: async logoutOptions => {
        const { keyCloakClient } = get()
        if (keyCloakClient?.authenticated) {
          await keyCloakClient.logout({
            ...logoutOptions,
          })
        }
        return null
      },
      getAccessTokenSilently: async () => {
        const { keyCloakClient, user } = get()
        if (
          typeof keyCloakClient?.authenticated === 'undefined' &&
          !user?.id &&
          !keyCloakClient?.userInfo
        ) {
          await keyCloakClient
            ?.init({ onLoad: 'login-required' })
            .then(async authorized => {
              if (authorized) {
                set({ isAuthenticated: true })
                set({ keyCloakClient })
                const client = get().keyCloakClient
                await client
                  ?.loadUserInfo()
                  .then(data => {
                    if (data) {
                      set({ user: data as TUser })
                    }
                  })
                  .catch((e: Error) => {
                    set({ keyCloakError: e })
                  })
              }
            })
            .catch((e: Error) => {
              set({ keyCloakError: e })
            })
        }

        return new Promise(res => {
          const client = get().keyCloakClient
          let token = ''
          if (client?.token) {
            token = client.token
            const decodedToken = jwtDecode<DecodedToken>(token)
            set({ decodedToken })
            // localStorage.setItem('orgId', decodedToken?.organization_id?.toString() ?? '')
            res(token)
          } else {
            res('')
          }
        })
      },

      getIdTokenClaims: () => {
        const { keyCloakClient } = get()
        if (keyCloakClient?.authenticated) {
          return keyCloakClient.idTokenParsed ?? undefined
        }
        return undefined
      },
      changeLoginType: async (orgId: string) => {
        const { keyCloakClient } = get()
        if (keyCloakClient?.refreshToken && keyCloakClient.clientId) {
          const suffix = orgId === 'user' ? '' : `?organization_id=${orgId}`
          const url = `${keyCloakClient?.endpoints?.token()}${suffix}`
          const params = new URLSearchParams()
          const paramsToAppend = [
            // grant type : refresh token
            ['grant_type', 'refresh_token'],
            // set refresh token with actual refresh token
            ['refresh_token', keyCloakClient.refreshToken],
            // set keycloak client id
            ['client_id', keyCloakClient.clientId],
            // set client org id (outside keycloak)
            ['organization_id', orgId],
          ]

          for (const param of paramsToAppend) {
            const [key, val] = param
            params.append(key, val)
          }
          const headers = new Headers()
          headers.append('Content-Type', 'application/x-www-form-urlencoded')
          const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: params,
            credentials: 'include',
          })
          if (response.ok) {
            const tokenResponse: TokenResponse = (await response.json()) as TokenResponse
            const decodedToken = jwtDecode<DecodedToken>(tokenResponse.access_token)
            set({ decodedToken })
            localStorage.setItem('orgId', decodedToken?.organization_id?.toString() ?? '')
            keyCloakClient.token = tokenResponse.access_token
            keyCloakClient.idToken = tokenResponse.id_token
            keyCloakClient.refreshToken = tokenResponse.refresh_token
            set({ keyCloakClient })
          } else {
            console.warn('[keycloakApi] Failed to refresh token with custom header')
          }
        }
        return null
      },

      refreshToken: async () => {
        const {
          keyCloakClient,
          actions: { changeLoginType },
        } = get()

        let orgId = ''

        try {
          const org = localStorage.getItem('orgId')! ?? ''
          orgId = JSON.parse(JSON.stringify(org || '')) as string
        } catch (e) {
          orgId = ''
        }

        const actTime = new Date()?.getTime() / 1000
        const reserveTime = actTime - 20

        const expiry = keyCloakClient?.idTokenParsed?.exp ?? 0
        if (expiry > reserveTime) {
          await changeLoginType(orgId)
        }
      },

      updateUser: async (user, ops = { fetchNewToken: false }) => {
        if (ops.fetchNewToken) {
          const { keyCloakClient } = get()
          if (keyCloakClient?.authenticated) {
            await keyCloakClient.updateToken(5)
            return get().user
          }
        }

        const currentUser = get().user

        const newUser = { ...currentUser, ...user } as TUser

        set(state => ({ ...state, user: newUser }))
        return newUser
      },
    },
  }))

  return store
}

export type AuthStore<TUser extends User> = StoreApi<AuthState<TUser>>

export default createAuthStore
