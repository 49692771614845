import { Suspense, useEffect } from 'react'
import {
  isRouteErrorResponse,
  json,
  Outlet,
  ScrollRestoration,
  useLocation,
  useRouteError,
  useSearchParams,
} from 'react-router-dom'
import { FaroErrorBoundary } from '@grafana/faro-react'
import { faro } from '@grafana/faro-web-sdk'
import { type QueryClient, useQuery } from '@tanstack/react-query'
import { authorize } from 'keycloak'
import { type DecodedToken } from 'keycloak/types'
import { posthog } from 'posthog-js'

import { Navbar } from '~/features/layout/Navbar'
import { Sidebar } from '~/features/layout/Sidebar'
import { notify } from '~/features/notifications'
import { OrganizationTrialPeriodIndicator } from '~/features/trialPeriodIndicator'

import { projectsQuery } from '~/entities/projects'
import { getProjectSlugFromPath } from '~/entities/projects/model/getProjectSlugFromPath'
import { userOrganizationsQuery } from '~/entities/users'

import { useNavigate } from '~/shared/lib/reactRouterWrappers'
import storage from '~/shared/lib/storage'
import { authStore, type User } from '~/shared/model/auth'
import { isApiError, isDynamicImportError } from '~/shared/model/errors'
import { ErrorModal } from '~/shared/ui/ErrorModal'
import { RouteErrorMessage } from '~/shared/ui/RouteErrorMessage'

import { Box } from '~/components/Box'
import { Loading } from '~/components/Loading'

export function loader(queryClient: QueryClient) {
  return async () =>
    authorize(
      authStore,
      async ({ user }) => {
        let gp_user: DecodedToken | User = authStore.getState().decodedToken!

        if (user?.sid) {
          gp_user = user
        }

        // If the user has signed up via email and its not yet verified prevent them to access the application
        //if (!user.emailVerified) return redirect('/')
        // If the user do NOT have a givenName or familyName guide them to the application to complete their profile.
        // if (!user.givenName || !user.familyName) return redirect('/')

        try {
          // queryClient
          const [organizations, projects] = await Promise.all([
            queryClient.ensureQueryData(userOrganizationsQuery()),
            queryClient.ensureQueryData(projectsQuery()),
          ])

          const userOrg =
            organizations.find(org => org?.id === String(user?.id))?.name ??
            `${user.email}'s personal account`

          faro.api.setUser({
            id: user.sid,
            email: user.email,
            attributes: {
              organization: userOrg,
            },
          })

          posthog.identify(gp_user?.sid, {
            organization: userOrg,
            email: gp_user?.email,
          })

          return json({
            projects,
            organizations,
          })
        } catch (error) {
          console.trace(error)
          if (isApiError(error) && error.type === 'data_query') {
            throw json(
              {
                message: 'We encountered an error while fetching your information',
                description:
                  'The application could not load your projects and organizations. Please reload the page and try again. If the issue persist please contact support.',
              },
              { status: 400 },
            )
          }
          throw error
        }
      },
      '/welcome',
    )
}

export default function Root() {
  const [searchParams] = useSearchParams()
  const isSidebarOpen = searchParams.get('sidebar') === 'open'
  const navigate = useNavigate()

  const { data: projects } = useQuery(projectsQuery())
  const { data: ownedOrganizations } = useQuery(userOrganizationsQuery())

  const { refreshToken } = authStore.getState().actions
  const location = useLocation()
  const storageProjectSlug = storage.get('selectedProject')

  useEffect(() => {
    // this sends user straight to org creation of there is no org or project created
    const showOnboardUser = projects?.length === 0 && ownedOrganizations?.length === 0
    if (showOnboardUser) {
      navigate('/organization/create')
    }
  }, [])

  useEffect(() => {
    refreshToken().catch(e => {
      console.log(e)
    })
    if (typeof storageProjectSlug !== 'string') {
      storage.remove('selectedProject')
      return
    }
    // eslint-disable-next-line no-underscore-dangle
    if (location.state?.from || location.state?._isRedirect || location.search) {
      return
    }
    const path = getProjectSlugFromPath(location.pathname)
    if (
      projects?.find(project => project.slug === storageProjectSlug) &&
      projects?.find(project => project.slug !== path.projectSlug)
    ) {
      if (location.pathname.includes(storageProjectSlug)) {
        return
      }
      if (path.remainingPath && !path.projectSlug) {
        return
      }
      navigate(`/${storageProjectSlug}/${path.remainingPath ? path.remainingPath : 'stack'}`)
    } else {
      storage.remove('selectedProject')
    }
  }, [storageProjectSlug, navigate, projects, location, refreshToken])

  useEffect(() => {
    if (import.meta.env.VITE_ENVIRONMENT === 'production') {
      posthog.capture('$pageview')
    }
  }, [location])

  // check if inside organizations
  // TODO: re-evaluate this if we are going to have a no-project view

  //const showOnboardUser = projects?.length === 0 && ownedOrganizations?.length === 0
  // should redirect to org creation directly

  // if (showOnboardUser)
  //    return <OnboardUser />
  // redirect to Org creation if none

  return (
    <Box display="flex" flexDirection="column" minHeight="viewHeight">
      <OrganizationTrialPeriodIndicator />
      <Box width="full" display="flex" flexDirection="row" flex="fill">
        <Sidebar isOpen={isSidebarOpen} />
        <Box display="flex" flexDirection="column" width="full">
          <Navbar
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={() =>
              navigate('', {
                searchParams: isSidebarOpen ? undefined : { sidebar: 'open' },
              })
            }
          />
          {/* All errors thrown by the nested routes without ErrorBoundary during rendering and the ones re-thrown by the routes with custom ErrorBoundary will be captured here */}
          {/* This is necessary until we reorganize the layout so we can have a top-level errorElement that does not unmount the main layout */}
          <FaroErrorBoundary
            fallback={error => ErrorModal({ error })}
            onError={error => {
              if (isDynamicImportError(error.message)) {
                notify('The application has been updated. We will reload it now.')
                window.location.reload()
              }
            }}
          >
            {/* Suspense boundary used by the lazy loaded page components */}
            <Suspense fallback={<Loading size="xxxlarge" />}>
              <Box padding="medium">
                <Outlet />
              </Box>
            </Suspense>
          </FaroErrorBoundary>
        </Box>
      </Box>
      {/* TODO: disable it for now until we can add back the payment method functionality */}
      {/* <ConfigCreditCardAlert /> */}
      <ScrollRestoration
        getKey={location => {
          return location.pathname.includes('data-ingestion')
            ? 'agents'
            : location.pathname.includes('stack')
            ? location.pathname
            : location.key
        }}
      />
    </Box>
  )
}

export function ErrorBoundary() {
  const error = useRouteError() as Error
  if (isRouteErrorResponse(error))
    return <RouteErrorMessage message={error.data.message} description={error.data.description} />

  faro.api.pushError(error)

  return <ErrorModal error={error} />
}
