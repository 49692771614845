import { transformCamelObjectKeysToSnake, transformSnakeObjectKeysToCamel } from 'plunger'

import { type Rooquest } from '../lib/rooquest'
import { type MessageResponse } from '../utils/types'

import {
  type AddTaxIdData,
  addTaxIdSchema,
  type AttachPaymentMethodData,
  attachPaymentMethodSchema,
  billingAccountSchema,
  type CreateBillingAccountData,
  createBillingAccountSchema,
  mockStripeBillingAccountSchema,
  type SetupPaymentMethodData,
  setupPaymentMethodSchema,
  stripeConfigSchema,
} from './model'

export default class BillingService {
  private readonly rooquest: Rooquest

  constructor(rooquest: Rooquest) {
    this.rooquest = rooquest
  }

  /**
   * Get organization's current billing account
   */
  public getCurrentBillingAccount = async () => {
    const res = await this.rooquest.get('billing/current')

    return billingAccountSchema.parse(res)
  }

  /**
   *
   * @param data get billing from mock stripe account
   * @returns
   */

  public getMockBillingAccount = async (billingAccountName: string) => {
    // stripe/billing-account-id?name=${billingAccountName}
    const res = await this.rooquest.get('stripe/billing-account-id?name=' + billingAccountName)

    return mockStripeBillingAccountSchema.parse(res)
  }

  /**
   * Create a billing account
   */
  public createBillingAccount = async (data: CreateBillingAccountData) => {
    const payload = createBillingAccountSchema.parse(data)

    const res = await this.rooquest.post('billing/', {
      body: payload,
    })

    return billingAccountSchema.parse(res)
  }

  /**
   * Retrieve the config for the current user from Stripe
   */
  public getStripeConfig = async () => {
    const res = await this.rooquest.get('stripe/config')
    return stripeConfigSchema.parse(res)
  }

  /**
   * Receives the client secret from Stripe through our backend
   */
  public setupPaymentMethod = async (data: SetupPaymentMethodData) => {
    const payload = setupPaymentMethodSchema.parse(data)
    const res = await this.rooquest.post('billing/payment-methods/setup', {
      body: transformCamelObjectKeysToSnake({
        billingAccountId: parseInt(payload.billingAccountId),
      }),
    })
    return transformSnakeObjectKeysToCamel(res as { client_secret: string })
  }

  /**
   * Attach a new payment method to a billing account
   */
  public attachPaymentMethod = async (data: AttachPaymentMethodData) => {
    const payload = attachPaymentMethodSchema.parse(data)
    const res = await this.rooquest.post('billing/payment-methods/attach', {
      body: transformCamelObjectKeysToSnake({
        ...payload,
        billingAccountId: parseInt(payload.billingAccountId),
      }),
    })

    return res as MessageResponse
  }

  /**
   * Create or replace a customer tax id
   */
  public addTaxId = async (data: AddTaxIdData) => {
    const payload = addTaxIdSchema.parse(data)
    const res = await this.rooquest.post('billing/tax-id', {
      body: transformCamelObjectKeysToSnake({
        ...payload,
        billingAccountId: parseInt(payload.billingAccountId),
      }),
    })

    return res as MessageResponse
  }
}
