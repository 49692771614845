export const initialValues: CardInputs = {
  phone: '',
  address: '',
  address2: '',
  city: '',
  postalCode: '',
  country: '',
}

export const validationRules = {
  phone: {
    required: true,
  },
  address: {
    required: true,
  },
  address2: {
    required: false,
  },
  city: {
    required: true,
  },
  postalCode: {
    required: true,
  },
  country: {
    required: true,
  },
}

export type CardInputs = {
  phone?: string
  address?: string
  address2?: string
  city?: string
  postalCode?: string
  country?: string
}
