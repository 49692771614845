import { useFetcher, useSearchParams } from 'react-router-dom'
import { type HepicTokenRequest, type Project } from 'api-client'
import { type typeToFlattenedError } from 'zod'

import { CREATE_HEPIC_TOKEN } from '~/entities/integrations/hepic'

import { useNavigate } from '~/shared/lib/reactRouterWrappers'

import { Button } from '~/components/Button'
import { Card } from '~/components/Card'
import { Input } from '~/components/Input'
import { Modal } from '~/components/Modal'
import { Stack } from '~/components/Stack'

type CreateTokenActionData = {
  fields?: HepicTokenRequest
  errors?: typeToFlattenedError<HepicTokenRequest>
}

type CreateHepicTokenProps = {
  projectId: Project['id']
  projectSlug: Project['slug']
}

export function CreateToken({ projectId, projectSlug }: CreateHepicTokenProps) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const createToken = useFetcher<CreateTokenActionData>()

  const isCreatingToken = searchParams.get('action') === 'create-token'

  return (
    <>
      <Button
        color="outline"
        onPress={() =>
          navigate('', { searchParams: { action: 'create-token' }, preventScrollReset: true })
        }
      >
        Create token
      </Button>
      <Modal isOpen={isCreatingToken} onClose={() => navigate('.', { preventScrollReset: true })}>
        <createToken.Form method="post" action={`/${projectSlug}/stack/hepic`}>
          <input type="hidden" name="projectId" value={projectId} />
          <Modal.Header title="Create a new scoped token" />
          <Modal.Content>
            <Stack>
              <Stack>
                <Input
                  name="name"
                  label="Name"
                  description="Choose a descriptive name that reflects its purpose and makes it easy to identify among other tokens."
                  errorMessage={createToken.data?.errors?.fieldErrors.name}
                />

                <input type="hidden" name="scope" value="read" />
              </Stack>

              <Stack>
                <Input
                  defaultValue="1"
                  name="ttlDays"
                  label=" TTL Days"
                  errorMessage={createToken.data?.errors?.fieldErrors.ttlDays}
                />
              </Stack>
            </Stack>
          </Modal.Content>
          <Card.Divider />
          <Modal.Content>
            <Stack direction="horizontal" space="medium" justify="end">
              <Button
                color="transparent"
                onPress={() => navigate('.', { preventScrollReset: true })}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                name="intent"
                value={CREATE_HEPIC_TOKEN}
                isDisabled={createToken.state !== 'idle'}
                isLoading={createToken.state === 'submitting'}
              >
                Create token
              </Button>
            </Stack>
          </Modal.Content>
        </createToken.Form>
      </Modal>
    </>
  )
}
