import { useState } from 'react'
import { useFetcher, useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { type Project, type QrynUpdateRetentionPoliciesData } from 'api-client'
import { type typeToFlattenedError } from 'zod'

import {
  qrynConfigQuery,
  qrynRetentionPoliciesQuery,
  UPDATE_RETENTION_POLICIES,
} from '~/entities/integrations/qryn'

import { useNavigate } from '~/shared/lib/reactRouterWrappers'

import { formatPrice } from '~/i18n/utils'

import { Loading } from '~/components/Loading'
import { Modal } from '~/components/Modal'
import { Select } from '~/components/Select'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import { getTierName } from '../model'

type UpdateRetentionPoliciesActionData = {
  fields?: QrynUpdateRetentionPoliciesData
  errors?: typeToFlattenedError<QrynUpdateRetentionPoliciesData>
  message?: string
}

type ChangeQrynRetentionPolicyProps = {
  projectId: Project['id']
  projectSlug: Project['slug']
}

export function ChangeQrynRetentionPolicy({
  projectId,
  projectSlug,
}: ChangeQrynRetentionPolicyProps) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const fetcher = useFetcher<UpdateRetentionPoliciesActionData>()
  const { data: qrynConfig } = useQuery(qrynConfigQuery(projectId))
  const { data: qrynRetentionPolicies, isInitialLoading: isLoadingPolicies } = useQuery(
    qrynRetentionPoliciesQuery(),
  )

  const [retentionPolicy, setRetentionPolicy] = useState(qrynConfig?.ttlAddonId)

  const isModalOpen = searchParams.get('modal') === 'open'
  const selectedPolicy = qrynRetentionPolicies?.find(policy => policy.itemId === retentionPolicy)

  return (
    <>
      <Select
        label="Current Retention Plan"
        onSelectionChange={value => {
          setRetentionPolicy(value.toString())
          navigate('', {
            searchParams: { modal: 'open' },
          })
        }}
        items={qrynRetentionPolicies ?? []}
        selectedKey={retentionPolicy}
        isLoading={isLoadingPolicies || fetcher.state !== 'idle'}
        isDisabled={fetcher.state !== 'idle'}
      >
        {item => (
          <Select.Item key={item.itemId} textValue={item.externalName}>
            <Text>{item.externalName.split('-')[1]}</Text>
          </Select.Item>
        )}
      </Select>
      <Modal isOpen={isModalOpen} onClose={() => navigate('')}>
        <Modal.Header title="Change your Retention Policy" />
        <Modal.Content>
          <Stack direction="vertical" space="medium">
            <Text color="gray300">
              Your current Retention Policy is {qrynConfig?.ttlDays} days.
            </Text>
            <Text color="gray300">
              The Retention Policy you selected is {selectedPolicy?.externalName.split('-')[1]}.
            </Text>
            <Text color="gray600" size="small">
              The pricing for the retention policies depends on the amount of ingested data. For a
              Retention Plan of {selectedPolicy?.externalName.split('-')[1]}:
            </Text>
            <Stack space="none">
              {selectedPolicy?.tiers.map((tier, index, tiers) => (
                <Stack
                  key={tier.startingUnit}
                  direction="horizontal"
                  align="center"
                  flex="fill"
                  justify="between"
                >
                  <Text size="small" color="gray600">
                    {getTierName(tier, index, tiers.length)}
                  </Text>
                  <Text size="small" color="gray600">
                    {formatPrice(tier.price / 100)}/GB
                  </Text>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Modal.Content>
        <fetcher.Form method="post" action={`/${projectSlug}/stack/qryn`}>
          <input hidden name="ttlAddonId" defaultValue={selectedPolicy?.itemId} />
          <Modal.Footer>
            <Modal.Action autoFocus onPress={() => navigate('')}>
              Cancel
            </Modal.Action>
            <Modal.Action type="submit" name="intent" value={UPDATE_RETENTION_POLICIES}>
              <Text as="span" color="gigagreen">
                {fetcher.state !== 'idle' ? <Loading /> : 'Confirm'}
              </Text>
            </Modal.Action>
          </Modal.Footer>
        </fetcher.Form>
      </Modal>
    </>
  )
}
