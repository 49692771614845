import { Outlet, useNavigation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { hepicConfigQuery } from '~/entities/integrations/hepic'
import { useProjectIdParam } from '~/entities/projects'

// import { createColumnHelper } from '@tanstack/react-table'
import { RouterNavLink as NavLink, useNavigate } from '~/shared/lib/reactRouterWrappers'
import { CopyButton } from '~/shared/ui/CopyButton'
import { Tabs } from '~/shared/ui/Tabs'

import { Box } from '~/components/Box'
import { Card } from '~/components/Card'
import { Input } from '~/components/Input'
// import { Icon } from '~/components/Icon'
// import { Check } from '~/components/Icons'
// import { Link } from '~/components/Link'
import { Loading } from '~/components/Loading'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

// import { Table } from '~/components/Table'
// import { Text } from '~/components/Text'
// import { HepicLoader } from './hepicExamples'
import * as styles from './qrynDataIngestion.css'

const EXAMPLES_TREE = [
  // {
  //   key: 'info',
  //   title: 'Intro',
  //   link: 'info--intro',
  //   children: [{ key: 'intro', default: true, title: 'How To' }],
  // },
  {
    key: 'agents',
    title: 'Agents',
    link: 'agents--heplify',
    children: [
      {
        key: 'heplify',
        title: 'Heplify',
        default: true,
      },
      { key: 'rtpagent', title: 'RTPAgent' },
      {
        key: 'hepgen',
        title: 'Hepgen.js',
      },
    ],
  },
]

// const SOLUTIONS_COMPATIBILITY = [   this should be set when we got the compatibility table
//   {
//     name: 'Grafana Agent',
//     supportsLogs: true,
//     supportsMetrics: true,
//     supportsTraces: true,
//   },
//   {
//     name: 'OpenTelemetry',
//     supportsLogs: true,
//     supportsMetrics: true,
//     supportsTraces: true,
//   },
//   {
//     name: 'Vector/Datadog',
//     supportsLogs: true,
//     supportsMetrics: true,
//     supportsTraces: false,
//   },
//   {
//     name: 'Telegraf/Influx',
//     supportsLogs: true,
//     supportsMetrics: true,
//     supportsTraces: false,
//   },
//   {
//     name: 'Logstash/Elastic',
//     supportsLogs: true,
//     supportsMetrics: true,
//     supportsTraces: false,
//   },
//   {
//     name: 'paStash',
//     supportsLogs: true,
//     supportsMetrics: true,
//     supportsTraces: false,
//   },
//   {
//     name: 'Promtail',
//     supportsLogs: true,
//     supportsMetrics: false,
//     supportsTraces: false,
//   },
//   {
//     name: 'Promtail Lambda',
//     supportsLogs: true,
//     supportsMetrics: false,
//     supportsTraces: false,
//   },
//   {
//     name: 'Fluentd',
//     supportsLogs: true,
//     supportsMetrics: false,
//     supportsTraces: false,
//   },
//   {
//     name: 'Cloudflare Logpush',
//     supportsLogs: true,
//     supportsMetrics: false,
//     supportsTraces: false,
//   },
// ] satisfies BaseRow[]

// type BaseRow = {
//   name: string
//   supportsLogs: boolean
//   supportsMetrics: boolean
//   supportsTraces: boolean
// }
// const columnHelper = createColumnHelper<BaseRow>()
// const defaultColumOptions = {
//   enableSorting: false,
//   enableResizing: false,
// }
// const solutionsColumns = [
//   columnHelper.accessor('name', {
//     header: 'Solution',
//     minSize: 160,
//     ...defaultColumOptions,
//   }),
//   columnHelper.accessor('supportsLogs', {
//     header: 'Logs',
//     maxSize: 100,
//     cell: ({ getValue }) =>
//       getValue() ? (
//         <Icon color="green500">
//           <Check />
//         </Icon>
//       ) : null,
//     ...defaultColumOptions,
//   }),
//   columnHelper.accessor('supportsMetrics', {
//     header: 'Metrics',
//     maxSize: 100,
//     cell: ({ getValue }) =>
//       getValue() ? (
//         <Icon color="green500">
//           <Check />
//         </Icon>
//       ) : null,
//     ...defaultColumOptions,
//   }),
//   columnHelper.accessor('supportsTraces', {
//     header: 'Traces',
//     maxSize: 100,
//     cell: ({ getValue }) =>
//       getValue() ? (
//         <Icon color="green500">
//           <Check />
//         </Icon>
//       ) : null,
//     ...defaultColumOptions,
//   }),
// ]

export default function HepicDataIngestion() {
  const navigate = useNavigate()
  const navigation = useNavigation()
  const { projectId } = useProjectIdParam()
  const { data: config, isError, isFetching } = useQuery(hepicConfigQuery(projectId))
  const getDefaultSelected = (key: any) => {
    const val = EXAMPLES_TREE.find((f: any) => f.key === key)?.children[0]?.key

    return val
  }

  return (
    <>
      {' '}
      {/* -- this one should be for whenever got this table defined */}
      <Card>
        <Card.Section>
          {config && !isError && !isFetching && (
            <Stack>
              <Text size={'medium'}>
                Use the provided <em>HEP endpoint</em> and <em>Token</em> for sending HEP data to
                hepic.cloud. <br />
              </Text>

              <Input
                isReadOnly
                label="HEP Endpoint"
                name="endpoint"
                value={config.ingressUrl || ''}
                suffix={<CopyButton value={config.ingressUrl || ''} />}
              />
              <Input
                isReadOnly
                label="Token"
                name="token"
                value={config.apiKey || ''}
                suffix={<CopyButton value={config.apiKey || ''} />}
              />
            </Stack>
          )}
          <Card.Divider />
        </Card.Section>
        <Card.Section>
          <Stack space={'small'}>
            <Text>- Follow the examples below to configure you HEP Agents.</Text>
          </Stack>
          <Stack space="small">
            <br />
            <Tabs
              items={EXAMPLES_TREE}
              defaultSelectedKey={'info'}
              onSelectionChange={key => {
                navigate(`${key}--${getDefaultSelected(key)}`)
              }}
            >
              {item => (
                <Tabs.Item key={item.key} title={item.title}>
                  <div className={styles.subNavigation}>
                    <div className={styles.subNavigationContainer}>
                      {EXAMPLES_TREE.find(i => i.key === item.key)?.children.map(subitem => (
                        <NavLink
                          key={`${item.key}--${subitem.key}`}
                          to={`${item.key}--${subitem.key}`}
                          preventScrollReset={true}
                          className={({ isActive, isPending }) =>
                            styles.subitem({
                              isActive: isActive,
                              isPending,
                            })
                          }
                        >
                          {({ isPending }) => (
                            <>
                              {isPending ? (
                                <Box position="absolute">
                                  <Loading color="gray600" />
                                </Box>
                              ) : null}
                              {subitem.title}
                            </>
                          )}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                  <Box opacity={navigation.state === 'idle' ? '100' : '50'}>
                    <Outlet />
                  </Box>
                </Tabs.Item>
              )}
            </Tabs>
          </Stack>
        </Card.Section>
      </Card>
      <Card>
        <Card.Section>
          <Stack align="start" direction={{ mobile: 'vertical', tablet: 'horizontal' }}>
            <Stack>
              {/* <HepicLoader folder="agents" fileName="intro" /> */}

              {/* <Text>
                You can read more about how to migrate from an existing solution{' '}
                <Link
                  type="text"
                  to="https://docs.gigapipe.com/integrations/hepic-cloud/introduction"
                  target="_blank"
                >
                  here
                </Link>
              </Text> */}
            </Stack>
            {/* <Box width="full">
              <Table
                //@ts-expect-error for some reason Typescript doesn't like this
                columns={solutionsColumns}
                data={SOLUTIONS_COMPATIBILITY}
                enableColumnsToggle={false}
                enableDataDownload={false}
              />
            </Box> */}
          </Stack>
        </Card.Section>
      </Card>
    </>
  )
}
