/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { type SnakeCasedProperties } from '../types'

export const camelToSnakeCase = (str: string): string =>
  str.replace(/[A-Z0-9]/g, (letter: string) => `_${letter.toLowerCase()}`)

export function transformCamelObjectKeysToSnake<INPUT extends object>(
  data: INPUT,
): SnakeCasedProperties<INPUT> {
  return Object.entries(data).reduce((acc, [key, val]) => {
    const modifiedKey = camelToSnakeCase(key)
    const modifiedVal =
      typeof val === 'object' && val !== null && !Array.isArray(val)
        ? transformCamelObjectKeysToSnake<typeof val>(val)
        : val
    return { ...acc, ...{ [modifiedKey]: modifiedVal } }
  }, {} as SnakeCasedProperties<INPUT>)
}
