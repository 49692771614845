import { useClipboard } from '~/shared/lib/useClipboard'

import { IconButton } from '~/components/IconButton'
import { type IconButtonProps } from '~/components/IconButton/IconButton'
import { Check, Copy } from '~/components/Icons'

type CopyButtonProps = {
  /**
   * Value to copy to the clipboard
   */
  value: string
} & Partial<IconButtonProps>

/**
 * Custom button that adds the value to the clipboard when pressed
 */
export function CopyButton({ value, ...rest }: CopyButtonProps) {
  const [isCopied, setClipboard] = useClipboard()

  return (
    <IconButton
      {...rest}
      aria-label="Copy the value to clipboard"
      icon={isCopied ? <Check /> : <Copy />}
      onPress={() => void setClipboard(value)}
    />
  )
}
