import { type AllHTMLAttributes, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { type ValidTags } from './HeadTags.types'
import { useHeadTags } from './HeadTagsProvider'

type HeadTagProps = {
  tag: ValidTags
  headId: string
} & AllHTMLAttributes<HTMLTitleElement | HTMLMetaElement | HTMLLinkElement>

export const HeadTag = ({ tag, headId, ...rest }: HeadTagProps) => {
  const { addTag, shouldRenderTag, removeTag } = useHeadTags()
  const [index, setIndex] = useState(-1)

  useEffect(() => {
    const newIndex = addTag(tag, headId)
    setIndex(newIndex)

    return () => {
      removeTag(tag, index)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!shouldRenderTag(tag, index)) return null
  const Tag = tag
  const Component = <Tag {...rest} />
  return createPortal(Component, document.head)
}
