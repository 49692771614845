import { Outlet, useNavigation } from 'react-router-dom'
import { createColumnHelper } from '@tanstack/react-table'

import { RouterNavLink as NavLink, useNavigate } from '~/shared/lib/reactRouterWrappers'
import { Tabs } from '~/shared/ui/Tabs'

import { Box } from '~/components/Box'
import { Card } from '~/components/Card'
import { Icon } from '~/components/Icon'
import { Check } from '~/components/Icons'
import { Link } from '~/components/Link'
import { Loading } from '~/components/Loading'
import { Stack } from '~/components/Stack'
import { Table } from '~/components/Table'
import { Text } from '~/components/Text'

import * as styles from './qrynDataIngestion.css'

const EXAMPLES_TREE = [
  {
    key: 'logs',
    title: 'Log Ingestion',
    link: 'logs--logql',
    children: [
      {
        key: 'logql',
        title: 'Grafana Agent',
      },
      {
        key: 'elastic',
        title: 'Elastic',
      },
      {
        key: 'influx',
        title: 'Influx',
      },
      {
        key: 'curl',
        title: 'cURL',
      },
      {
        key: 'dev',
        title: 'DEV',
      },
    ],
  },
  {
    key: 'metrics',
    title: 'Metrics Ingestion',
    link: 'metrics--prometheus',
    children: [
      {
        key: 'prometheus',
        title: 'Prometheus',
      },
      {
        key: 'influx',
        title: 'Influx',
      },
      {
        key: 'snmp',
        title: 'SNMP',
      },
    ],
  },
  {
    key: 'telemetry',
    title: 'Telemetry / Span Ingestion',
    link: 'telemetry--grafana-agent',
    children: [
      {
        key: 'grafana-agent',
        title: 'Grafana Agent',
      },
      {
        key: 'zipkin',
        title: 'Zipkin',
      },
      {
        key: 'curl',
        title: 'cURL',
      },
    ],
  },
  {
    key: 'agents',
    title: 'Agents',
    link: 'agents--grafana',
    children: [
      {
        key: 'grafana',
        title: 'Grafana',
      },
      {
        key: 'vector',
        title: 'Vector',
      },
    ],
  },
]

const SOLUTIONS_COMPATIBILITY = [
  {
    name: 'Grafana Agent',
    supportsLogs: true,
    supportsMetrics: true,
    supportsTraces: true,
  },
  {
    name: 'OpenTelemetry',
    supportsLogs: true,
    supportsMetrics: true,
    supportsTraces: true,
  },
  {
    name: 'Vector/Datadog',
    supportsLogs: true,
    supportsMetrics: true,
    supportsTraces: false,
  },
  {
    name: 'Telegraf/Influx',
    supportsLogs: true,
    supportsMetrics: true,
    supportsTraces: false,
  },
  {
    name: 'Logstash/Elastic',
    supportsLogs: true,
    supportsMetrics: true,
    supportsTraces: false,
  },
  {
    name: 'paStash',
    supportsLogs: true,
    supportsMetrics: true,
    supportsTraces: false,
  },
  {
    name: 'Promtail',
    supportsLogs: true,
    supportsMetrics: false,
    supportsTraces: false,
  },
  {
    name: 'Promtail Lambda',
    supportsLogs: true,
    supportsMetrics: false,
    supportsTraces: false,
  },
  {
    name: 'Fluentd',
    supportsLogs: true,
    supportsMetrics: false,
    supportsTraces: false,
  },
  {
    name: 'Cloudflare Logpush',
    supportsLogs: true,
    supportsMetrics: false,
    supportsTraces: false,
  },
] satisfies BaseRow[]

type BaseRow = {
  name: string
  supportsLogs: boolean
  supportsMetrics: boolean
  supportsTraces: boolean
}
const columnHelper = createColumnHelper<BaseRow>()
const defaultColumOptions = {
  enableSorting: false,
  enableResizing: false,
}
const solutionsColumns = [
  columnHelper.accessor('name', {
    header: 'Solution',
    minSize: 160,
    ...defaultColumOptions,
  }),
  columnHelper.accessor('supportsLogs', {
    header: 'Logs',
    maxSize: 100,
    cell: ({ getValue }) =>
      getValue() ? (
        <Icon color="green500">
          <Check />
        </Icon>
      ) : null,
    ...defaultColumOptions,
  }),
  columnHelper.accessor('supportsMetrics', {
    header: 'Metrics',
    maxSize: 100,
    cell: ({ getValue }) =>
      getValue() ? (
        <Icon color="green500">
          <Check />
        </Icon>
      ) : null,
    ...defaultColumOptions,
  }),
  columnHelper.accessor('supportsTraces', {
    header: 'Traces',
    maxSize: 100,
    cell: ({ getValue }) =>
      getValue() ? (
        <Icon color="green500">
          <Check />
        </Icon>
      ) : null,
    ...defaultColumOptions,
  }),
]

export default function QrynDataIngestion() {
  const getDefaultSelected = (key: any) => {
    const val = EXAMPLES_TREE.find((f: any) => f.key === key)?.children[0]?.key

    return val
  }
  const navigate = useNavigate()
  const navigation = useNavigation()

  return (
    <>
      <Card>
        <Card.Section>
          <Stack align="start" direction={{ mobile: 'vertical', tablet: 'horizontal' }}>
            <Stack>
              <Text weight="bold" size="large">
                Ingest from existing solution
              </Text>
              <Text lineHeight="large">
                Ingesting data with qryn is easy and straightforward. If you are using one of the
                solutions listed below, simply update the configuration to send your data to your
                URL using the provided key/secret. In most cases, you will include this key/secret
                pair as a header or use basic authentication to identify your request
              </Text>
              <Text>
                You can read more about how to migrate from an existing solution{' '}
                <Link
                  type="text"
                  to="https://docs.gigapipe.com/integrations/qryn-cloud/introduction"
                  target="_blank"
                >
                  here
                </Link>
              </Text>
            </Stack>
            <Box width="full">
              <Table
                //@ts-expect-error for some reason Typescript doesn't like this
                columns={solutionsColumns}
                data={SOLUTIONS_COMPATIBILITY}
                enableColumnsToggle={false}
                enableDataDownload={false}
              />
            </Box>
          </Stack>
        </Card.Section>
      </Card>

      <Card>
        <Card.Header title="Examples" />
        <Card.Section>
          <Stack space="small">
            <Text>For more advanced configurations here there are a bunch of examples.</Text>
            <Tabs
              items={EXAMPLES_TREE}
              defaultSelectedKey={'logs'}
              onSelectionChange={key => {
                navigate(`${key}--${getDefaultSelected(key)}`)
              }}
            >
              {item => (
                <Tabs.Item key={item.key} title={item.title}>
                  <div className={styles.subNavigation}>
                    <div className={styles.subNavigationContainer}>
                      {EXAMPLES_TREE.find(i => i.key === item.key)?.children.map(subitem => (
                        <NavLink
                          key={`${item.key}--${subitem.key}`}
                          to={`${item.key}--${subitem.key}`}
                          preventScrollReset={true}
                          className={({ isActive, isPending }) =>
                            styles.subitem({
                              isActive,
                              isPending,
                            })
                          }
                        >
                          {({ isPending }) => (
                            <>
                              {isPending ? (
                                <Box position="absolute">
                                  <Loading color="gray600" />
                                </Box>
                              ) : null}
                              {subitem.title}
                            </>
                          )}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                  <Box opacity={navigation.state === 'idle' ? '100' : '50'}>
                    <Outlet />
                  </Box>
                </Tabs.Item>
              )}
            </Tabs>
          </Stack>
        </Card.Section>
      </Card>
    </>
  )
}
