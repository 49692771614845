import { useQuery } from '@tanstack/react-query'

import { CancelSubscriptionButton } from '~/features/cancelSubscriptionToIntegration'
import { RotateQrynKeysButton } from '~/features/rotateQrynKeys'
import { QrynInfo } from '~/features/showQrynInfo'
import { QrynLinks } from '~/features/showQrynLinks'
import { QrynMetrics } from '~/features/showQrynMetrics'

import { integrationsQuery } from '~/entities/integrations/base'
import { useProjectIdParam } from '~/entities/projects'

import { Card } from '~/components/Card'
import { Grid } from '~/components/Grid'
import { Stack } from '~/components/Stack'

const SLUG = 'qryn'
export default function QrynOverview() {
  const { projectId, projectSlug } = useProjectIdParam()

  const { data: integration } = useQuery({
    ...integrationsQuery(projectId),
    select: data => data.find(i => i.product.slug === SLUG),
  })

  if (!integration) return null
  return (
    <Grid>
      <Grid.Item span={{ mobile: 12, tablet: 8 }}>
        <Stack>
          <QrynMetrics projectId={projectId} />
          <QrynInfo projectId={projectId} />
        </Stack>
      </Grid.Item>
      <Grid.Item span={{ mobile: 12, tablet: 4 }}>
        <Stack>
          {/* {!isStripeMock && (
            <QrynCost integrationStatus={integration?.status} projectId={projectId} />
          )} */}

          <Card>
            <Card.Section>
              <Stack direction="vertical" space="small">
                <QrynLinks projectId={projectId} />
                <RotateQrynKeysButton projectSlug={projectSlug} />
                <CancelSubscriptionButton
                  integrationSlug={SLUG}
                  integrationName="qryn"
                  projectSlug={projectSlug}
                />
              </Stack>
            </Card.Section>
          </Card>
        </Stack>
      </Grid.Item>
    </Grid>
  )
}
