import { type ReactNode } from 'react'
import { type DiskType, type Machine } from 'api-client'

import { storageUnitToBytes } from '~/shared/config'

import { formatPrice } from '~/i18n/utils'

import { Box } from '~/components/Box'
import { Card } from '~/components/Card'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import { useCreateMCHClusterForm } from '../model/store'

type EstimadedClusterCostProps = {
  machines?: Machine[]
  diskTypes?: DiskType[]
  children: ReactNode
  sticky?: boolean
}

export function EstimadedClusterCost({
  machines,
  diskTypes,
  sticky,
  children,
}: EstimadedClusterCostProps) {
  const region = useCreateMCHClusterForm(state => state.region)
  const disks = useCreateMCHClusterForm(state => state.disks)
  const shards = useCreateMCHClusterForm(state => state.shards)
  const replicas = useCreateMCHClusterForm(state => state.replicas)
  const machine = useCreateMCHClusterForm(state => state.machine)

  const disksWithCost = Object.values(disks).map(disk => ({
    ...disk,
    cost:
      (diskTypes?.find(diskType => diskType.name === disk.type)?.monthlyRatePrice ?? 0) *
      (disk.size * storageUnitToBytes[disk.unit]),
  }))

  const nodes = shards * replicas
  const selectedMachine = machines?.find(m => m.name === machine)
  const totalMachineCost = nodes * (selectedMachine?.monthlyCost ?? 0)
  const totalDisksCost = disksWithCost.reduce((acc, cur) => acc + cur.cost, 0)

  const totalMonthlyCost = totalMachineCost + totalDisksCost

  return (
    <div style={sticky ? { position: 'sticky', top: 0 } : {}}>
      <Card>
        <Card.Section>
          <Text weight="bold" size="xlarge" align="center">
            Estimated costs
          </Text>
        </Card.Section>
        <Card.Divider />
        <Card.Section>
          <Stack direction="horizontal" justify="between">
            <Text>Machine</Text>
            <Text>{formatPrice(totalMachineCost)}</Text>
          </Stack>
          {selectedMachine && (
            <Stack direction="horizontal" justify="between">
              <Text color="gray600" size="small" weight="light">
                {selectedMachine.title}
              </Text>
              <Text color="gray600" size="small" weight="light">
                {nodes} x {formatPrice(selectedMachine.monthlyCost)}
              </Text>
            </Stack>
          )}
          <Text color="gray600" size="small" weight="light">
            {region}
          </Text>
        </Card.Section>
        <Card.Divider />
        <Card.Section>
          <Stack direction="horizontal" justify="between">
            <Text>Disks</Text>
            <Text>{formatPrice(totalDisksCost)}</Text>
          </Stack>
          {disksWithCost.map(disk => (
            <Stack key={disk.name} direction="horizontal" justify="between">
              <Text color="gray600" size="small" weight="light">
                {disk.type} {disk.size} {disk.unit}
              </Text>
              <Text color="gray600" size="small" weight="light">
                {formatPrice(disk.cost)}
              </Text>
            </Stack>
          ))}
        </Card.Section>
        <Card.Divider />
        <Card.Section>
          <Stack>
            <Box>
              <Text color="gigagreen" size="xxlarge" align="center">
                {formatPrice(totalMonthlyCost)}
                <Text as="span" color="gigagreen" size="small">
                  /month
                </Text>
              </Text>
              <Text color="gray600" size="small" align="center">
                estimated depending on usage (incl. VAT)
              </Text>
            </Box>
            {children}
          </Stack>
        </Card.Section>
      </Card>
    </div>
  )
}
