import { memo, type SVGProps } from 'react'

const SvgLoader = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2.667V8m0 16v5.333M6.573 6.573l3.774 3.774m11.306 11.306 3.774 3.774M2.667 16H8m16 0h5.333m-22.76 9.427 3.774-3.774m11.306-11.306 3.774-3.774"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgLoader)
export default Memo
