import {
  isRouteErrorResponse,
  json,
  type LoaderFunctionArgs,
  Outlet,
  redirect,
  useRouteError,
} from 'react-router-dom'
import { type QueryClient, useQuery } from '@tanstack/react-query'
import {
  isZodError,
  type QrynCreateTokenData,
  type QrynDeleteTokenData,
  type QrynUpdateCacheServerData,
  type QrynUpdateFingerprintsLimitData,
  type QrynUpdateRetentionPoliciesData,
} from 'api-client'
import { authorize } from 'keycloak'
import { z } from 'zod'

import { AlertQrynFingerprintsLimitAlert } from '~/features/manageQrynFingerprintsLimit'
import { notify } from '~/features/notifications'

import {
  CANCEL_SUBSCRIPTION,
  checkIntegrationStatus,
  IntegrationName,
  integrationsCancellationDateQuery,
  integrationsQuery,
  IntegrationStatus,
} from '~/entities/integrations/base'
import {
  CREATE_QRYN_TOKEN,
  DELETE_QRYN_TOKEN,
  qrynConfigQuery,
  QrynNavBar,
  qrynTokensQuery,
  ROTATE_QRYN_KEYS,
  UPDATE_CACHE_SERVER,
  UPDATE_FINGERPRINTS_LIMIT,
  UPDATE_RETENTION_POLICIES,
} from '~/entities/integrations/qryn'
import {
  checkProjectSlugParam,
  getProjectIdFromParams,
  useProjectIdParam,
} from '~/entities/projects'

import { apiClient, handleDataMutationError } from '~/shared/api'
import { getFormData } from '~/shared/lib/parseRequest'
import { authStore } from '~/shared/model/auth'
import { isApiError } from '~/shared/model/errors'
import { RouteErrorMessage } from '~/shared/ui/RouteErrorMessage'

import { formatDate } from '~/i18n/utils'

import { Box } from '~/components/Box'
import { Grid } from '~/components/Grid'
import { HeadTag } from '~/components/HeadTag'
import { Message } from '~/components/Message'
import { Stack } from '~/components/Stack'

const SLUG = 'qryn'

export function loader(queryClient: QueryClient) {
  return async ({ params }: LoaderFunctionArgs) =>
    authorize(authStore, async () => {
      const { projectId, projectSlug } = checkProjectSlugParam(params)
      const integration = await checkIntegrationStatus(queryClient, {
        projectId,
        projectSlug,
        slug: SLUG,
      })
      try {
        const [config, tokens] = await Promise.all([
          queryClient.ensureQueryData(qrynConfigQuery(projectId)),
          queryClient.ensureQueryData(qrynTokensQuery(projectId)),
        ])

        return json({ config, integration, tokens })
      } catch (error) {
        if (isApiError(error) && error.type === 'data_query') {
          throw json(
            {
              message: `We had some issues loading the configuration of the qryn integration for project ${projectId}`,
              description: `Please go to the Stack page and try again. If the problem persists, please contact support.`,
              goTo: {
                path: `/${projectSlug}/stack`,
                text: 'Go to Stack',
              },
            },
            { status: 400 },
          )
        }
        throw error
      }
    })
}

export function action(queryClient: QueryClient) {
  return async ({ request, params }: LoaderFunctionArgs) => {
    const data = await getFormData(request)
    const projectId = getProjectIdFromParams(params)
    if (!projectId) {
      notify.error('No project has been selected.')
      return json({})
    }

    const intents = z
      .enum([
        ROTATE_QRYN_KEYS,
        CANCEL_SUBSCRIPTION,
        CREATE_QRYN_TOKEN,
        DELETE_QRYN_TOKEN,
        UPDATE_RETENTION_POLICIES,
        UPDATE_FINGERPRINTS_LIMIT,
        UPDATE_CACHE_SERVER,
      ])
      .safeParse(data.intent)

    if (!intents.success) {
      throw json(
        {
          message: 'This operation is not valid',
          description:
            'You tried to perform an invalid operation. Nothing will be changed regarding your integrations. Please reload the page and try again.',
        },
        { status: 400 },
      )
    }

    switch (intents.data) {
      case 'ROTATE_QRYN_KEYS': {
        try {
          await apiClient.qryn.rotateAPIKeys(projectId)
          await queryClient.invalidateQueries(qrynConfigQuery(projectId))
          notify.success('API keys rotated successfully')
          return json({ hasConfirmed: true })
        } catch (error) {
          return handleDataMutationError(error)
        }
      }
      case 'CANCEL_SUBSCRIPTION': {
        try {
          const { message } = await apiClient.integrations.cancelSubscription({
            projectId: projectId,
            integration: SLUG,
          })
          await queryClient.invalidateQueries(integrationsQuery(projectId))
          notify.success(message)
          return redirect(`/${params.projectSlug}/stack`)
        } catch (error: any) {
          return handleDataMutationError(error)
        }
      }
      case 'CREATE_QRYN_TOKEN': {
        type scopeArray = 'read' | 'write'

        try {
          const tokenDataRaw = data as { name: string; scope: scopeArray[] }
          const tokenData = {
            name: tokenDataRaw.name,
            scope: 'read',
          } as { name: string; scope: 'read' | 'write' | 'read write' }

          if (
            Array.isArray(tokenDataRaw?.scope) &&
            tokenDataRaw?.scope?.length > 0 &&
            tokenDataRaw?.scope?.includes('write' || 'read')
          ) {
            tokenData.scope =
              (tokenDataRaw?.scope?.join(' ') as 'write' | 'read' | 'read write') || 'read'
          }
          await apiClient.qryn.createQrynToken({ ...tokenData, projectId: projectId })
          await queryClient.invalidateQueries(qrynTokensQuery(projectId))
          notify.success('The new token has been created!')
          return redirect(`/${params.projectSlug}/stack/qryn/auth`)
        } catch (error) {
          if (isZodError<QrynCreateTokenData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
      case 'DELETE_QRYN_TOKEN': {
        try {
          await apiClient.qryn.deleteQrynToken({ ...data, projectId: projectId })
          await queryClient.invalidateQueries(qrynTokensQuery(projectId))
          notify.success('The token has been deleted!')
          return redirect(`/${params.projectSlug}/stack/qryn/auth`)
        } catch (error) {
          if (isZodError<QrynDeleteTokenData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
      case 'UPDATE_RETENTION_POLICIES': {
        try {
          const { message } = await apiClient.qryn.updateQrynRetentionPolicies({
            ...data,
            projectId: projectId,
          })
          notify.success(message)
          await queryClient.invalidateQueries(qrynConfigQuery(projectId))
          return redirect(`/${params.projectSlug}/stack/qryn/config`)
        } catch (error) {
          if (isZodError<QrynUpdateRetentionPoliciesData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
      case 'UPDATE_FINGERPRINTS_LIMIT': {
        try {
          const { message } = await apiClient.qryn.updateQrynFingerprintsLimit({
            ...data,
            projectId: projectId,
          })
          notify.success(message)
          await queryClient.invalidateQueries(qrynConfigQuery(projectId))

          return redirect(`/${params.projectSlug}/stack/qryn/config`)
        } catch (error) {
          if (isZodError<QrynUpdateFingerprintsLimitData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
      case 'UPDATE_CACHE_SERVER': {
        try {
          const { message } = await apiClient.qryn.updateQrynCacheServer({
            ...data,
            projectId: projectId,
          })

          notify.success(message)
          await queryClient.invalidateQueries(qrynConfigQuery(projectId))
          return json({})
        } catch (error) {
          if (isZodError<QrynUpdateCacheServerData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
    }
  }
}

export default function Qryn() {
  const { projectId, projectSlug } = useProjectIdParam()
  const { data: integration } = useQuery({
    ...integrationsQuery(projectId),
    select: data => data.find(i => i.product.slug === SLUG),
  })
  const { data: cancellationDate, error } = useQuery(
    integrationsCancellationDateQuery(projectId, SLUG, integration?.status),
  )
  const isScheduledForCancellation = integration?.status === 'scheduled for cancellation'

  if (!integration) return null
  return (
    <Stack direction="vertical">
      <HeadTag tag="title" headId="title">
        {import.meta.env.MODE !== 'production' ? `(${import.meta.env.VITE_ENVIRONMENT}) ` : ''}
        {integration.product.name} - Gigapipe
      </HeadTag>
      <Stack space="small">
        <Stack direction="horizontal">
          <IntegrationName name={integration.product.name} slug={integration.product.slug} />
        </Stack>
        <Box>
          <IntegrationStatus
            projectId={projectId}
            slug={integration.product.slug}
            status={integration?.status ?? 'active'}
          />
        </Box>
        <Grid columns={{ mobile: 1, tablet: 2 }}>
          {isScheduledForCancellation && (
            <Grid.Item>
              <Message size="small" type="info">
                This integration is scheduled for cancellation on the{' '}
                {cancellationDate
                  ? formatDate(cancellationDate.cancellationDateTimestamp, undefined, {
                      dateStyle: 'medium',
                      timeStyle: 'short',
                    })
                  : error
                  ? 'not available.'
                  : 'loading...'}
              </Message>
            </Grid.Item>
          )}
          <Grid.Item>
            <AlertQrynFingerprintsLimitAlert projectId={projectId} projectSlug={projectSlug} />
          </Grid.Item>
        </Grid>
      </Stack>
      <Stack direction="vertical">
        <QrynNavBar />
        <Outlet />
      </Stack>
    </Stack>
  )
}

export function ErrorBoundary() {
  const error = useRouteError() as Error

  if (isRouteErrorResponse(error)) {
    return (
      <RouteErrorMessage
        message={error.data.message}
        description={error.data.description}
        goToPath={error.data.goTo?.path}
        goToText={error.data.goTo?.text}
      />
    )
  }

  throw error
}
