import { type Params } from 'react-router-dom'

/**
 * Returns the project ID extracted from the given params object.
 *
 * @param {Params<'projectSlug'>} params - Object containing the project slug.
 * @return {string} The project ID extracted from the project slug as in `slugified name-:id `, or an
 * empty string if the project slug is undefined or empty.
 */
export function getProjectIdFromParams(params: Params<'projectSlug'>) {
  const segments = params.projectSlug?.split('-')
  const projectId = segments?.[segments.length - 1] ?? ''

  return projectId
}
