import { OverlayProvider } from 'react-aria'
import {
  createBrowserRouter,
  createRoutesFromElements,
  // redirect,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { KeycloakProvider } from 'keycloak'
import { mountStoreDevtool } from 'simple-zustand-devtools'

import Auth, { ErrorBoundary as AuthErrorBoundary, loader as authLoader } from '~/pages/auth'
import CreateOrganizationPage, {
  action as createOrganizationAction,
  ErrorBoundary as CreateOrganizationErrorBoundary,
  loader as createOrganizationLoader,
} from '~/pages/createOrganization'
// import Integrations, {
//   ErrorBoundary as IntegrationsErrorBoundary,
//   loader as integrationsLoader,
// } from '~/pages/integrations'
import Login, { ErrorBoundary as LoginErrorBoundary, loader as loginLoader } from '~/pages/login'
import Logout, {
  ErrorBoundary as LogoutErrorBoundary,
  loader as logoutLoader,
} from '~/pages/logout'
import NotFound from '~/pages/NotFound'
import Overview from '~/pages/overview'
import Settings, {
  action as settingsAction,
  ErrorBoundary as SettingsErrorBoundary,
  loader as settingsLoader,
} from '~/pages/projects/settings'
import Root, { ErrorBoundary as RootBoundary, loader as rootLoader } from '~/pages/root'
import Account, {
  action as accountAction,
  ErrorBoundary as AccountErrorBoundary,
} from '~/pages/settings/account'
import Billing, {
  action as billingAction,
  ErrorBoundary as BillingErrorBoundary,
  loader as billingLoader,
} from '~/pages/settings/billing'
import DeleteAccount, {
  action as deleteAccountAction,
  ErrorBoundary as DeleteAccountErrorBoundary,
} from '~/pages/settings/deleteAccount'
import Organization, {
  action as organizationAction,
  ErrorBoundary as OrganizationErrorBoundary,
  loader as organizationLoader,
} from '~/pages/settings/organization'
import ClickHouse, {
  action as clickHouseAction,
  ErrorBoundary as ClickHouseBoundary,
  indexLoader as clickHouseIndexLoader,
  loader as clickHouseLoader,
} from '~/pages/stack/clickhouse'
import ClickHouseCluster, {
  loader as clickHouseClusterLoader,
} from '~/pages/stack/clickhouseCluster'
import CreateMCHCluster, {
  action as createMCHAction,
  loader as createMCHLoader,
} from '~/pages/stack/createManagedClickHouseCluster'
import Grafana, {
  action as grafanaAction,
  ErrorBoundary as GrafanaErrorBoundary,
  loader as grafanaLoader,
} from '~/pages/stack/grafana'
import Hepic, {
  action as hepicAction,
  ErrorBoundary as HepicErrorBoundary,
  loader as hepicLoader,
} from '~/pages/stack/hepic'
import Loggen, {
  action as loggenAction,
  ErrorBoundary as LoggenErrorBoundary,
  loader as loggenLoader,
} from '~/pages/stack/loggen'
import Qryn, {
  action as qrynAction,
  ErrorBoundary as QrynErrorBoundary,
  loader as qrynLoader,
} from '~/pages/stack/qryn'
import QrynAuth from '~/pages/stack/qrynAuth'
import QrynConfig from '~/pages/stack/qrynConfig'
import QrynDataIngestion from '~/pages/stack/qrynDataIngestion'
import QrynExamples, {
  ErrorBoundary as QrynExamplesErrorBoundary,
  loader as qrynExamplesLoader,
} from '~/pages/stack/qrynExamples'
import QrynOverview from '~/pages/stack/qrynOverview'
import Stack, {
  ErrorBoundary as StackErrorBoundary,
  loader as projectStackLoader,
} from '~/pages/stack/stack'
import SystemStatus, { loader as systemStatusLoader } from '~/pages/status'
import Verify, { ErrorBoundary as VerifyErrorBoundary } from '~/pages/verify'

import { addProductToProjectAction } from '~/features/addProductToProject'
import { NotificationsContainer } from '~/features/notifications'
import { CREATE_PROJECT_RESOURCES_ROUTE, createProjectAction } from '~/features/selectProject'

import { checkProjectSlugParam } from '~/entities/projects'

import { TEN_SECONDS } from '~/shared/config'
import { authStore } from '~/shared/model/auth'
import { isApiError } from '~/shared/model/errors'

import { HeadTagsProvider } from '~/components/HeadTag'
import { Loading } from '~/components/Loading'

import { ThemeProvider } from '~/styles/ThemeProvider'

import { action as inviteAction, Invite } from './pages/invite'
import HepicAuth from './pages/stack/hepicAuth'
import { HepicConfig } from './pages/stack/hepicConfig'
import HepicDataIngestion from './pages/stack/hepicDataIngestion'
import HepicExamples, { loader as hepicExamplesLoader } from './pages/stack/hepicExamples'
import { HepicOverview } from './pages/stack/hepicOverview'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: TEN_SECONDS,
      retry: false,
      useErrorBoundary: error => {
        if (isApiError(error)) {
          return !(error.type === 'server_error' || error.type === 'data_query')
        }

        return true
      },
    },
    mutations: {
      useErrorBoundary: false,
    },
  },
})

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/auth"
        element={<Auth />}
        loader={authLoader}
        errorElement={<AuthErrorBoundary />}
      />
      <Route
        path="/login"
        element={<Login />}
        loader={loaderFunctionArgs => {
          return loginLoader(loaderFunctionArgs, queryClient)
        }}
        errorElement={<LoginErrorBoundary />}
      />
      <Route
        path="/logout"
        element={<Logout />}
        loader={() => {
          return logoutLoader(queryClient)
        }}
        errorElement={<LogoutErrorBoundary />}
      />
      {
        // TODO: Re-enable /welcome when we done with login
      }
      {/* <Route
        path="/welcome"
        element={<WelcomePage />}
        loader={welcomeLoader(queryClient)}
        action={welcomeAction}
        errorElement={<WelcomeErrorBoundary />}
      /> */}
      <Route path="/verify" element={<Verify />} errorElement={<VerifyErrorBoundary />} />
      <Route path="/invitations" element={<Invite />} action={inviteAction} />,
      <Route
        path="/"
        element={<Root />}
        loader={rootLoader(queryClient)}
        errorElement={<RootBoundary />}
      >
        <Route index element={<Overview />} />
        <Route
          path=":projectSlug"
          loader={({ params }) => {
            checkProjectSlugParam(params)
            return null
          }}
        >
          {/* <Route
            path="integrations"
            element={<Integrations />}
            loader={integrationsLoader(queryClient)}
            errorElement={<IntegrationsErrorBoundary />}
            handle={{ title: 'Integrations' }}
          /> */}
          <Route
            path="settings"
            element={<Settings />}
            handle={{ title: 'Settings' }}
            loader={settingsLoader(queryClient)}
            action={settingsAction(queryClient)}
            errorElement={<SettingsErrorBoundary />}
          />
          <Route
            path="stack"
            element={<Stack />}
            loader={projectStackLoader(queryClient)}
            errorElement={<StackErrorBoundary />}
          >
            <Route
              path="qryn"
              element={<Qryn />}
              loader={qrynLoader(queryClient)}
              action={qrynAction(queryClient)}
              errorElement={<QrynErrorBoundary />}
            >
              <Route index element={<QrynOverview />} />
              <Route path="auth" element={<QrynAuth />} />
              <Route path="data-ingestion" element={<QrynDataIngestion />}>
                <Route
                  path=":ingestionMethod"
                  element={<QrynExamples />}
                  loader={qrynExamplesLoader(queryClient)}
                  errorElement={<QrynExamplesErrorBoundary />}
                />
              </Route>
              <Route path="config" element={<QrynConfig />} />
            </Route>
            <Route
              path="hepic"
              element={<Hepic />}
              loader={hepicLoader(queryClient)}
              action={hepicAction(queryClient)}
              errorElement={<HepicErrorBoundary />}
            >
              <Route index element={<HepicOverview />} />

              <Route
                path="auth"
                loader={hepicLoader(queryClient)}
                action={hepicAction(queryClient)}
                element={<HepicAuth />}
              />

              <Route
                path="config"
                loader={hepicLoader(queryClient)}
                action={hepicAction(queryClient)}
                element={<HepicConfig />}
              />
              {/* hepicDataingestion should be the tabs container for the injestion methods */}
              <Route path="data-ingestion" element={<HepicDataIngestion />}>
                {/* subpages inside hepic, here should load the mdx modules */}
                <Route
                  path=":ingestionMethod"
                  element={<HepicExamples />}
                  loader={hepicExamplesLoader(queryClient)}
                  errorElement={<HepicErrorBoundary />}
                />
              </Route>
            </Route>
            <Route
              path="grafana"
              element={<Grafana />}
              loader={grafanaLoader(queryClient)}
              action={grafanaAction(queryClient)}
              errorElement={<GrafanaErrorBoundary />}
            />
            <Route
              path="loggen"
              element={<Loggen />}
              loader={loggenLoader(queryClient)}
              action={loggenAction()}
              errorElement={<LoggenErrorBoundary />}
            />
            <Route
              path="clickhouse"
              element={<ClickHouse />}
              loader={clickHouseLoader(queryClient)}
              action={clickHouseAction(queryClient)}
              errorElement={<ClickHouseBoundary />}
            >
              <Route index loader={clickHouseIndexLoader(queryClient)} />
              <Route
                path="create"
                element={<CreateMCHCluster />}
                loader={createMCHLoader(queryClient)}
                action={createMCHAction(queryClient)}
              />
              <Route
                path=":clusterId"
                element={<ClickHouseCluster />}
                loader={clickHouseClusterLoader(queryClient)}
              />
            </Route>
          </Route>
        </Route>
        {['system-status', ':projectSlug/system-status'].map(path => {
          return (
            <Route
              key={path}
              path={path}
              element={<SystemStatus />}
              handle={{ title: 'System Status' }}
              loader={systemStatusLoader(queryClient)}
            />
          )
        })}

        {['account', ':projectSlug/account'].map(path => {
          return (
            <Route
              key={path}
              path={path}
              element={<Account />}
              action={accountAction()}
              handle={{ title: 'Account' }}
              errorElement={<AccountErrorBoundary />}
            />
          )
        })}
        <Route
          path="account/delete"
          element={<DeleteAccount />}
          errorElement={<DeleteAccountErrorBoundary />}
          action={deleteAccountAction()}
        />
        {['organization', ':projectSlug/organization'].map(path => {
          return (
            <Route
              key={path}
              path={path}
              element={<Organization />}
              loader={organizationLoader(queryClient)}
              action={organizationAction(queryClient)}
              handle={{ title: 'Organization' }}
              errorElement={<OrganizationErrorBoundary />}
            />
          )
        })}
        {['organization/create', ':projectSlug/organization/create'].map(path => {
          return (
            <Route
              key={path}
              path={path}
              element={<CreateOrganizationPage />}
              loader={createOrganizationLoader(queryClient)}
              action={args => createOrganizationAction(args, queryClient)}
              errorElement={<CreateOrganizationErrorBoundary />}
            />
          )
        })}
        {['billing', ':projectSlug/billing'].map(path => {
          return (
            <Route
              key={path}
              path={path}
              element={<Billing />}
              loader={billingLoader(queryClient)}
              action={billingAction(queryClient)}
              handle={{ title: 'Billing' }}
              errorElement={<BillingErrorBoundary />}
            />
          )
        })}
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="/resources">
        <Route path={CREATE_PROJECT_RESOURCES_ROUTE} action={createProjectAction(queryClient)} />
        <Route path="add-to-project" action={addProductToProjectAction(queryClient)} />
      </Route>
    </>,
  ),
)

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose())
}

if (import.meta.env.DEV) {
  mountStoreDevtool('AuthStore', authStore)
}

export default function App() {
  const { keyCloakClient } = authStore.getState()
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <HeadTagsProvider>
          <OverlayProvider>
            <KeycloakProvider
              client={keyCloakClient}
              authStore={authStore}
              queryClient={queryClient}
              loadingElement={<Loading size="xxxlarge" cover />}
            >
              <RouterProvider
                router={router}
                fallbackElement={<Loading size="xxxlarge" cover />}
                future={{ v7_startTransition: true }}
              />
              <NotificationsContainer />
            </KeycloakProvider>
          </OverlayProvider>
        </HeadTagsProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </ThemeProvider>
    </QueryClientProvider>
  )
}
