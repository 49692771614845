import { useFetcher } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { type AddUserData } from 'api-client'
import { type typeToFlattenedError } from 'zod'

import { organizationsUsersQuery } from '~/entities/organizations'
import { projectsQuery, useProjectIdParam, usersFromProjectQuery } from '~/entities/projects'
import { userOrganizationsQuery } from '~/entities/users'

import { useDecodedToken } from '~/shared/model/auth/useAuth'

import { Box } from '~/components/Box'
import { Button } from '~/components/Button'
import { Card } from '~/components/Card'
import { Link } from '~/components/Link'
import { Message } from '~/components/Message'
import { Select } from '~/components/Select'
import { Text } from '~/components/Text'

type ActionData = {
  fields?: AddUserData
  errors?: typeToFlattenedError<AddUserData>
}

export const AddUserToProject = () => {
  const { projectId, projectSlug } = useProjectIdParam()
  const decodedToken = useDecodedToken()
  const isOrgAccount = Boolean(decodedToken?.organization_id)
  const { data: orgUsers } = useQuery(organizationsUsersQuery(isOrgAccount))

  const { data: projectUsers } = useQuery(usersFromProjectQuery(projectId, isOrgAccount))
  const activeUsers = (orgUsers ?? []).filter(
    orgUser => !projectUsers?.some(projectUser => projectUser.email === orgUser.email),
  )
  const { data: projects } = useQuery(projectsQuery())
  const currentProjectName = projects?.find(project => project.id === projectId)?.name

  const { data: currentOrganization } = useQuery({
    ...userOrganizationsQuery(),
    select: data => data.find(org => org.id === String(decodedToken?.organization_id)),
  })

  const fetcher = useFetcher<ActionData>()

  if (!isOrgAccount)
    return (
      <Card fill>
        <Card.Header title="Add a member" />
        <Card.Section>
          <Message type="info" size="small">
            Inviting additional users to your project is only available in organization mode. Please
            switch to your organization, or create a new one here:{' '}
            <Link to="/organization/create" type="text">
              create organization.
            </Link>
          </Message>
        </Card.Section>
      </Card>
    )

  return (
    <Card fill>
      <Card.Header title="Add a member" />
      <Card.Section>
        <fetcher.Form method="post">
          <Select
            name="userId"
            aria-label="Select a member"
            label="Select a member"
            defaultText={`${currentOrganization?.name} team`}
            items={activeUsers}
            errorMessage={fetcher.data?.errors?.fieldErrors.userId}
            isDisabled={!activeUsers?.length}
          >
            {item => (
              <Select.Item key={item.id} textValue={item.givenName + ' ' + item.familyName}>
                <Text>
                  {item.givenName + ' ' + item.familyName} ({item.email})
                </Text>
              </Select.Item>
            )}
          </Select>

          <Box paddingY="small">
            <Button
              aria-label="Add user to project"
              type="submit"
              isLoading={fetcher.state === 'submitting'}
              isDisabled={fetcher.state === 'submitting' || !activeUsers?.length}
            >
              Add to Project
            </Button>
          </Box>
        </fetcher.Form>
        <Text size="small">
          Only the members of your organization can be added to {currentProjectName} project.
          <br />
          Add new members to {currentOrganization?.name} team? Go to your{' '}
          <Link to={projectSlug ? `/${projectSlug}/organization` : '/organization'} type="text">
            organization&apos;s settings page
          </Link>
          .
        </Text>
      </Card.Section>
    </Card>
  )
}
