import { apiClient } from '~/shared/api'
import { EIGHT_HOURS, ONE_HOUR } from '~/shared/config'

const hepicQueryKeys = {
  hepicPricingPlans: [{ scope: 'integrations', type: 'hepic', item: 'pricingPlans' }] as const,
  hepicCloudInstances: [{ scope: 'integrations', type: 'hepic', item: 'resources' }] as const,
  hepicConfig: (projectId: string) =>
    [{ scope: 'integrations', type: 'hepic', item: 'config', projectId }] as const,
  hepicUsers: (projectId: string) =>
    [{ scope: 'integrations', type: 'hepic', item: 'users', projectId }] as const,
  hepicStatisics: (projectId: string) =>
    [{ scope: 'integrations', type: 'hepic', item: 'statistics', projectId }] as const,
  hepicToken: (projectId: string) =>
    [{ scope: 'integrations', type: 'hepic', item: 'tokens', projectId }] as const,
}

/**
 * Query to fetch hepic pricing plans
 */
export const hepicPricingPlansQuery = () => ({
  queryKey: hepicQueryKeys.hepicPricingPlans,
  queryFn: apiClient.hepic.getHepicPricingPlans,
  staleTime: EIGHT_HOURS,
})

/**
 * Query to fetch hepic cloud instances
 */
export const hepicCloudInstancesQuery = () => ({
  queryKey: hepicQueryKeys.hepicCloudInstances,
  queryFn: apiClient.hepic.getHepicCloudInstances,
  staleTime: ONE_HOUR,
})

/**
 * Query to fetch hepic integration configuration
 */
export const hepicConfigQuery = (projectId: string) => ({
  queryKey: hepicQueryKeys.hepicStatisics(projectId),
  queryFn: () => apiClient.hepic.getHepicConfig(projectId),
  enabled: Boolean(projectId),
})

/**
 * Query to fetch all users added to hepic
 */
export const hepicUsersQuery = (projectId: string, isOrgAccount: boolean) => ({
  queryKey: hepicQueryKeys.hepicUsers(projectId),
  queryFn: () => apiClient.hepic.getHepicUsers(projectId),
  enabled: Boolean(projectId) && isOrgAccount,
})

/**
 * Query to fetch hepic stats
 */

export const hepicStatisticsQuery = (projectId: string) => ({
  queryKey: hepicQueryKeys.hepicConfig(projectId),
  queryFn: () => apiClient.hepic.getHepicStatistics(projectId),
  enabled: Boolean(projectId),
})

/**
 *
 * Query to fetch hepic tokens
 */

export const hepicTokenQuery = (projectId: string) => ({
  queryKey: hepicQueryKeys.hepicToken(projectId),
  queryFn: () => apiClient.hepic.getHepicTokens(projectId),
  enabled: Boolean(projectId),
})
