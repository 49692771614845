import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { grafanaUsersQuery } from '~/entities/integrations/grafana'
import { usersFromProjectQuery } from '~/entities/projects'

import { useDecodedToken } from '~/shared/model/auth/useAuth'

import { Box } from '~/components/Box'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

type ListGrafanaUsersProps = {
  projectId: Project['id']
}

export function ListGrafanaUsers({ projectId }: ListGrafanaUsersProps) {
  const decodedToken = useDecodedToken()
  const isOrgAccount = Boolean(decodedToken?.organization_id)
  const { data: projectUsers } = useQuery(usersFromProjectQuery(projectId, isOrgAccount))
  const { data: grafanaUsers } = useQuery(grafanaUsersQuery(projectId, isOrgAccount))

  const usersFromProjectInGrafana = (projectUsers ?? []).filter(
    user => grafanaUsers?.some(u => u.email === user.email),
  )

  if (!isOrgAccount) return null
  return (
    <Stack space="xxsmall">
      {usersFromProjectInGrafana?.length ? (
        usersFromProjectInGrafana.map(user => (
          <Stack direction="horizontal" justify="between" align="center" key={user.id}>
            <Box>{user.givenName + ' ' + user.familyName}</Box>
            <Box>{user.email}</Box>
          </Stack>
        ))
      ) : (
        <Text>There are no users yet.</Text>
      )}
    </Stack>
  )
}
