import { json, type LoaderFunctionArgs, useParams } from 'react-router-dom'
import { type QueryClient, useQuery } from '@tanstack/react-query'
import { authorize } from 'keycloak'
import invariant from 'tiny-invariant'

import { CancelSubscriptionButton } from '~/features/cancelSubscriptionToIntegration'
import { ManageClickHouseIPSafeList } from '~/features/manageClickHouseIPSafeList'
import { ClickHouseClusterInfo } from '~/features/showClickHouseInfo'
import { ClickHouseLinks } from '~/features/showClickHouseLinks'
import { ClickHouseMetrics } from '~/features/showClickHouseMetrics'

import { integrationsQuery } from '~/entities/integrations/base'
import { clickHouseClustersQuery } from '~/entities/integrations/clickhouse'
import { checkProjectSlugParam, useProjectIdParam } from '~/entities/projects'

import { authStore } from '~/shared/model/auth'

import { Card } from '~/components/Card'
import { Grid } from '~/components/Grid'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

export function loader(queryClient: QueryClient) {
  return async ({ params }: LoaderFunctionArgs) =>
    authorize(authStore, async () => {
      const { projectId } = checkProjectSlugParam(params)
      const { clusterId } = params

      const cluster = (
        await queryClient.ensureQueryData(clickHouseClustersQuery(projectId))
      ).filter(cl => cl.id === clusterId)

      return json({ cluster })
    })
}

export default function ClickHouseCluster() {
  const { projectId, projectSlug } = useProjectIdParam()
  const { clusterId } = useParams<'clusterId'>()

  const { data: integrations } = useQuery({
    ...integrationsQuery(projectId),
    select: data => data.map(integration => integration.product.slug),
  })
  const { data: cluster } = useQuery({
    ...clickHouseClustersQuery(projectId),
    enabled: integrations?.includes('qryn'),
    select: data =>
      data.filter(
        cluster =>
          (cluster.status === 'active' || cluster.status === 'external') &&
          cluster.id === clusterId,
      )[0],
  })

  invariant(cluster)

  const isExternal = cluster.status === 'external'

  if (!clusterId) return null
  return (
    <>
      <Grid>
        <Grid.Item span={{ mobile: 12, tablet: 8 }}>
          <ClickHouseClusterInfo clusterId={clusterId} projectId={projectId} />
        </Grid.Item>
        <Grid.Item span={{ mobile: 12, tablet: 4 }}>
          <Card>
            <Card.Section>
              <Stack direction="vertical" space="small">
                <ClickHouseLinks projectId={projectId} />
                <CancelSubscriptionButton
                  integrationSlug="clickhouse"
                  integrationName="ClickHouse"
                  projectSlug={projectSlug}
                />
              </Stack>
            </Card.Section>
          </Card>
        </Grid.Item>
      </Grid>
      <ClickHouseMetrics projectId={projectId} clusterId={clusterId} />
      <Card>
        <Card.Section>
          <Stack>
            <Text size="xlarge">Config</Text>
            {isExternal ? null : (
              <ManageClickHouseIPSafeList
                clusterId={clusterId}
                clusterIPSafeList={cluster?.ipSafeList}
              />
            )}
          </Stack>
        </Card.Section>
      </Card>
    </>
  )
}
