import { Suspense } from 'react'
import { json } from 'react-router-dom'
import { type QueryClient, useQuery } from '@tanstack/react-query'
import { type ServiceStatus } from 'api-client'
import { authorize } from 'keycloak'

import { integrationStatusQuery } from '~/entities/integrations/base'

import { authStore } from '~/shared/model/auth'
import { type ProductIconNames, ProductIcons } from '~/shared/ui/ProductIcons'

import { Box } from '~/components/Box'
import { Card } from '~/components/Card'
import { Grid } from '~/components/Grid'
import { HeadTag } from '~/components/HeadTag'
import { Icon } from '~/components/Icon'
import { Message } from '~/components/Message'
import { PageHeader } from '~/components/PageHeader'
import { Stack } from '~/components/Stack'
import { StatusDot, type StatusDotProps } from '~/components/StatusDot'
import { Text } from '~/components/Text'

export function loader(queryClient: QueryClient) {
  return async () =>
    authorize(authStore, async () => {
      const integrationStatus = await queryClient.ensureQueryData(integrationStatusQuery())

      return json({ integrationStatus })
    })
}

export default function SystemStatus() {
  const { data: integrationsStatus = [] } = useQuery(integrationStatusQuery())
  const areAllServicesOperational = integrationsStatus.every(
    integration => getProductMainStatus(integration.services) === 'SUCCESS',
  )

  return (
    <>
      <HeadTag tag="title" headId="title">
        {import.meta.env.MODE !== 'production' ? `(${import.meta.env.VITE_ENVIRONMENT}) ` : ''}
        Create an organization - Gigapipe
      </HeadTag>
      <Box paddingX={{ mobile: 'xxsmall', tablet: 'large', desktop: 'xxlarge' }}>
        <Stack>
          <PageHeader title="System Status" />

          <Message type={areAllServicesOperational ? 'success' : 'warning'}>
            {areAllServicesOperational
              ? 'All services are operational'
              : 'Some services are not operational'}
          </Message>

          <Grid columns={{ mobile: 1, tablet: 2, desktop: 3 }} space="medium" align="stretch">
            {integrationsStatus?.map(integration => (
              <Grid.Item key={integration.slug} matchHeight>
                <ServiceStatus
                  name={integration.product}
                  slug={integration.slug}
                  services={integration.services}
                  status={false}
                />
              </Grid.Item>
            ))}
          </Grid>
        </Stack>
      </Box>
    </>
  )
}

function ServiceStatus({
  slug,
  name,
  services,
}: {
  slug: string
  name: string
  status: boolean
  services: ServiceStatus['services']
}) {
  const ProductIcon = ProductIcons[slug as ProductIconNames]
  const hasSubServices = services && (services.length > 1 || services[0].name !== name)

  return (
    <Card fill>
      <Card.Header>
        <Stack direction="horizontal" space="small" justify="between" align="center">
          <Stack direction="horizontal" space="small">
            <Icon size="xxlarge">
              <Suspense>
                <ProductIcon />
              </Suspense>
            </Icon>
            <Text size="large" weight="bold" color="white">
              {name}
            </Text>
          </Stack>
          <StatusDot status={getProductMainStatus(services)} />
        </Stack>
      </Card.Header>
      <Card.Section>
        {hasSubServices &&
          services.map(subService => (
            <Stack
              key={subService.name}
              direction="horizontal"
              space="small"
              justify="between"
              align="center"
            >
              <Text color="gray600" size="small">
                {subService.name}
              </Text>
              <StatusDot status={subService.running ? 'SUCCESS' : 'ERROR'} />
            </Stack>
          ))}

        {!hasSubServices && services?.length && (
          <Text color="gray600" size="small">
            {getProductMainStatus(services) === 'SUCCESS'
              ? 'Operational'
              : 'Temporarily Down, try again later'}
          </Text>
        )}
      </Card.Section>
    </Card>
  )
}

function getProductMainStatus(
  services: ServiceStatus['services'] | undefined,
): StatusDotProps['status'] {
  if (!services?.length) return 'ERROR'
  if (services.length === 1) {
    return services[0].running ? 'SUCCESS' : 'ERROR'
  }
  if (services.every(ser => ser.running)) return 'SUCCESS'
  if (services.some(ser => !ser.running)) return 'WARNING'
  return 'ERROR'
}
