import { useEffect, useState } from 'react'
import { useFetcher } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { type HepicUpdateUserRoleData, type Project } from 'api-client'
import { type ZodFormattedError } from 'zod'

import { RemoveUserFromHepic } from '~/features/removeUserFromHepic'

import {
  CANCEL_UPDATE_HEPIC_USER_ROLE,
  hepicUserGroups,
  hepicUsersQuery,
  UPDATE_HEPIC_USER_ROLE,
} from '~/entities/integrations/hepic'
import { usersFromProjectQuery } from '~/entities/projects'

import { useNavigate } from '~/shared/lib/reactRouterWrappers'
import { useDecodedToken } from '~/shared/model/auth/useAuth'

import { Box } from '~/components/Box'
import { Grid } from '~/components/Grid'
import { Loading } from '~/components/Loading'
import { Modal } from '~/components/Modal'
import { Select } from '~/components/Select'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import { iconButton } from './ListHepicUsers.css'

export type ActionData = {
  fields?: HepicUpdateUserRoleData
  errors?: ZodFormattedError<HepicUpdateUserRoleData>
  action?: string
}

type ListHepicUsersProps = {
  projectId: Project['id']
}

export function ListHepicUsers({ projectId }: ListHepicUsersProps) {
  const decodedToken = useDecodedToken()
  const email = decodedToken?.email

  const isOrgAccount = Boolean(decodedToken?.organization_id)
  const fetcher = useFetcher<ActionData>()
  const navigate = useNavigate()

  const { data: projectUsersById } = useQuery({
    ...usersFromProjectQuery(projectId, isOrgAccount),
    select: data =>
      data.reduce(
        (acc, user) => {
          acc[user.id] = user
          return acc
        },
        {} as Record<string, (typeof data)[number]>,
      ),
  })

  const { data: hepicUsers } = useQuery(hepicUsersQuery(projectId, isOrgAccount))

  const isHepicAdmin = hepicUsers?.find(user => user.email === email)?.group === 'admin'

  const isModalOpen = fetcher.data?.action === 'confirm'
  const [modalOpen, setModalOpen] = useState(isModalOpen)

  const updatedUser = fetcher.data?.fields?.users?.[0]
  const filteredHepicUsers = hepicUsers?.filter(
    user => projectUsersById?.[user.user_id]?.email === user.email,
  )

  const selectedUserName = updatedUser
    ? projectUsersById?.[updatedUser.user_id].givenName +
      ' ' +
      projectUsersById?.[updatedUser.user_id].familyName
    : ''

  useEffect(() => {
    if (fetcher.state === 'idle' && !fetcher.data?.action) {
      setModalOpen(false)
    }
  }, [fetcher])

  if (!isOrgAccount) return null

  return (
    <Stack space="xsmall">
      {hepicUsers?.length && projectUsersById ? (
        <>
          {filteredHepicUsers?.map(user => (
            <Box key={user.email} padding="small">
              <Grid space="small" columns={12} align="center">
                <Grid.Item span={{ desktop: 8, tablet: 11, mobile: 11 }}>
                  <Stack direction="horizontal" justify="between" align="center">
                    <Text>
                      {projectUsersById[user.user_id]?.givenName +
                        ' ' +
                        projectUsersById[user.user_id]?.familyName ?? 'Unknown user'}
                    </Text>
                    <Text>{projectUsersById[user.user_id]?.email ?? 'Unknown user'} </Text>
                  </Stack>
                </Grid.Item>
                <Grid.Item span={{ desktop: 3, tablet: 11, mobile: 11 }}>
                  <Select
                    aria-label="Change the role"
                    defaultText=""
                    selectedKey={user.group}
                    items={hepicUserGroups.map(item => ({ id: item, name: item }))}
                    onSelectionChange={value => {
                      fetcher.submit(
                        {
                          'users[0].user_id': user.user_id,
                          'users[0].group': value.toString(),
                          intent: UPDATE_HEPIC_USER_ROLE,
                        },
                        { method: 'POST', encType: 'application/x-www-form-urlencoded' },
                      )
                      setModalOpen(() => true)
                    }}
                    defaultSelectedKey={updatedUser?.group ?? user.group}
                    isDisabled={!isHepicAdmin || user.group === 'superadmin'}
                  >
                    {item => (
                      <Select.Item key={item.id} textValue={item.id}>
                        <Text>{item.name}</Text>
                      </Select.Item>
                    )}
                  </Select>
                </Grid.Item>
                <Grid.Item className={iconButton}>
                  {projectUsersById[user?.user_id]?.id && (
                    <RemoveUserFromHepic
                      projectId={projectId}
                      isAdmin={isHepicAdmin}
                      users={[
                        {
                          user_id: user.user_id,
                        },
                      ]}
                    />
                  )}
                </Grid.Item>
              </Grid>
            </Box>
          ))}

          <Modal
            isOpen={modalOpen}
            onClose={() => {
              setModalOpen(() => false)
              return navigate('')
            }}
            dialogProps={{ id: 'change-hepic-user' }}
          >
            <Modal.Header title="Update user role" />
            <Modal.Content>
              <Text color="gray600">{`You will change the role of ${selectedUserName} to ${updatedUser?.group}.`}</Text>
            </Modal.Content>
            <fetcher.Form method="post">
              <input hidden name="hasConfirmed" defaultValue="true" />
              <input
                key={updatedUser?.user_id}
                hidden
                name="users[0].user_id"
                defaultValue={updatedUser?.user_id}
              />
              <input
                key={updatedUser?.group}
                hidden
                name="users[0].group"
                defaultValue={updatedUser?.group}
              />
              <Modal.Footer>
                <Modal.Action
                  name="intent"
                  type="submit"
                  value={CANCEL_UPDATE_HEPIC_USER_ROLE}
                  onPress={() => {
                    const foundUser = hepicUsers.find(
                      user => user.user_id === Number(updatedUser?.user_id),
                    )

                    if (foundUser?.user_id && foundUser.group && foundUser.group !== 'superadmin') {
                      fetcher.submit(
                        {
                          'users[0].user_id': foundUser.user_id,
                          'users[0].group': foundUser.group.toString(),
                          intent: UPDATE_HEPIC_USER_ROLE,
                        },
                        { method: 'POST', encType: 'application/x-www-form-urlencoded' },
                      )
                      setModalOpen(() => false)
                    }
                  }}
                >
                  Cancel
                </Modal.Action>
                <Modal.Action type="submit" name="intent" value={UPDATE_HEPIC_USER_ROLE}>
                  <Text as="span" color="gigagreen">
                    {fetcher.state !== 'idle' ? <Loading /> : 'Confirm'}
                  </Text>
                </Modal.Action>
              </Modal.Footer>
            </fetcher.Form>
          </Modal>
        </>
      ) : (
        <Text>There are no users yet.</Text>
      )}
    </Stack>
  )
}
