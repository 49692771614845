class AuthError extends Error {
  description: string

  status: number

  constructor(message: string, args: { description: string; statusCode: number }) {
    super(message)
    this.name = 'AuthError'
    this.description = args.description
    this.status = args.statusCode ?? 401
  }
}

export default AuthError

export function isAuthError(error: unknown): error is AuthError {
  return (error as AuthError).name === 'AuthError'
}
