import { create } from 'zustand'

import { DEFAULT_TIME_TO_REMOVE_NOTIFICATIONS, NOTIFICATIONS_LIMIT } from './constants'
import { type Notification } from './Notifications.types'

type State = {
  notifications: Notification[]
  pausedAt: number | undefined
  add: (notification: Notification) => void
  update: (notification: Notification) => void
  dismiss: (id: string) => void
  dismissAll: () => void
  remove: (id: string) => void
  removeAll: () => void
  upsert: (notification: Notification) => void
  startPause: (time: number) => void
  endPause: (time: number) => void
}

const removeTimeouts = new Map<Notification['id'], ReturnType<typeof setTimeout>>()
const autodismissTimeouts = new Map<Notification['id'], ReturnType<typeof setTimeout>>()

const addToAutoDismissQueue = (id: string, duration: number, onTimeout: (id: string) => void) => {
  if (autodismissTimeouts.has(id)) {
    return
  }
  const timeout = setTimeout(() => {
    onTimeout(id)
    autodismissTimeouts.delete(id)
  }, duration)
  autodismissTimeouts.set(id, timeout)
}

const addToRemoveQueue = (id: string, onTimeout: (id: string) => void) => {
  if (removeTimeouts.has(id)) {
    return
  }

  const timeout = setTimeout(() => {
    onTimeout(id)
    removeTimeouts.delete(id)
  }, DEFAULT_TIME_TO_REMOVE_NOTIFICATIONS)

  removeTimeouts.set(id, timeout)
}

const removeFromQueues = (id: string) => {
  const removeTimeout = removeTimeouts.get(id)
  const autodismissTimeout = autodismissTimeouts.get(id)
  if (removeTimeout) {
    clearTimeout(removeTimeout)
  }
  if (autodismissTimeout) {
    clearTimeout(autodismissTimeout)
  }
}

export const useNotificationsStore = create<State>()((set, get) => ({
  notifications: [],
  pausedAt: undefined,
  add: notification => {
    const { dismiss } = get()
    addToAutoDismissQueue(notification.id, notification.duration, dismiss)

    set(state => ({
      ...state,
      notifications: [notification, ...state.notifications].slice(0, NOTIFICATIONS_LIMIT),
    }))
  },
  update: notification => {
    const { dismiss } = get()
    removeFromQueues(notification.id)
    addToAutoDismissQueue(notification.id, notification.duration, dismiss)

    set(state => ({
      ...state,
      notifications: state.notifications.map(n =>
        n.id === notification.id ? { ...n, ...notification } : n,
      ),
    }))
  },
  dismiss: id => {
    const { remove } = get()
    addToRemoveQueue(id, remove)

    set(state => ({
      ...state,
      notifications: state.notifications.map(n => (n.id === id ? { ...n, visible: false } : n)),
    }))
  },
  dismissAll: () => {
    const { notifications, remove } = get()

    notifications.forEach(notification => {
      addToRemoveQueue(notification.id, remove)
    })

    set(state => ({
      ...state,
      notifications: state.notifications.map(n => ({ ...n, visible: false })),
    }))
  },
  remove: id => {
    set(state => ({
      ...state,
      notifications: state.notifications.filter(n => n.id !== id),
    }))
  },
  removeAll: () => set(state => ({ ...state, notifications: [] })),
  upsert: notification => {
    const { notifications, update, add } = get()

    if (notifications.some(n => n.id === notification.id)) {
      update(notification)
    } else {
      add(notification)
    }
  },
  startPause: time => {
    autodismissTimeouts.forEach(timeout => clearTimeout(timeout))

    set(state => ({ ...state, pausedAt: time }))
  },
  endPause: time => {
    const pausedTime = time - (get().pausedAt ?? 0)
    const { notifications, dismiss } = get()
    const now = Date.now()

    notifications.forEach(notification => {
      const durationLeft =
        (notification.duration || 0) +
        notification.pauseDuration +
        pausedTime -
        (now - notification.createdAt)

      if (durationLeft < 0 && notification.visible) {
        dismiss(notification.id)
        return
      }
      addToAutoDismissQueue(notification.id, durationLeft, dismiss)
    })

    set(state => ({
      ...state,
      pausedAt: undefined,
      notifications: state.notifications.map(n => ({
        ...n,
        pauseDuration: n.pauseDuration + pausedTime,
      })),
    }))
  },
}))
