import { Suspense } from 'react'
import {
  type ActionFunctionArgs,
  Await,
  defer,
  isRouteErrorResponse,
  json,
  redirect,
  useLoaderData,
  useRouteError,
  useSearchParams,
} from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import { type QueryClient, useQuery } from '@tanstack/react-query'
import { type AddTaxIdData, type AttachPaymentMethodData, isZodError } from 'api-client'
import { authorize } from 'keycloak'
import invariant from 'tiny-invariant'
import { _ } from 'vitest/dist/types-63abf2e0'
import { z } from 'zod'

import { CreditCardForm } from '~/features/addPaymentMethod'
import { notify } from '~/features/notifications'
import { TaxIdForm } from '~/features/taxInformation'

import {
  ADD_PAYMENT_METHOD,
  ADD_TAX_ID,
  BillingAccountCard,
  currentBillingAccountQuery,
} from '~/entities/billing'

import { apiClient, handleDataMutationError } from '~/shared/api'
import { getFormData } from '~/shared/lib/parseRequest'
import { useNavigate } from '~/shared/lib/reactRouterWrappers'
import { authStore } from '~/shared/model/auth'
import { isApiError } from '~/shared/model/errors'
import { RouteErrorMessage } from '~/shared/ui/RouteErrorMessage'

import { LANGUAGE } from '~/i18n/utils'

import { Box } from '~/components/Box'
import { Card } from '~/components/Card'
import { HeadTag } from '~/components/HeadTag'
import { Link } from '~/components/Link'
import { Modal } from '~/components/Modal'
import { PageHeader } from '~/components/PageHeader'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY)

export function loader(queryClient: QueryClient) {
  return async () =>
    authorize(authStore, async () => {
      try {
        const currentBillingAccount = await queryClient.ensureQueryData(currentBillingAccountQuery)

        return defer({ currentBillingAccount, stripe: stripePromise })
      } catch (error) {
        if (isApiError(error) && error.type === 'data_query') {
          throw json(
            {
              message: `We had issues loading your billing information`,
              description: `Please refresh the page and try again. If the problem persists, please contact support.`,
            },
            { status: 400 },
          )
        }
        throw error
      }
    })
}

export function action(queryClient: QueryClient) {
  return async ({ request }: ActionFunctionArgs) => {
    const data = await getFormData(request)
    const intents = z.enum([ADD_PAYMENT_METHOD, ADD_TAX_ID]).safeParse(data.intent)

    if (!intents.success) {
      throw json(
        {
          message: 'This operation is not valid',
          description:
            'You tried to perform an invalid operation. Nothing will be changed regarding your billing information. Please reload the page and try again.',
        },
        { status: 400 },
      )
    }

    switch (intents.data) {
      case 'ADD_PAYMENT_METHOD': {
        try {
          const { message } = await apiClient.billing.attachPaymentMethod(data)
          notify.success(message)
        } catch (error) {
          if (isZodError<AttachPaymentMethodData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
        break
      }
      case 'ADD_TAX_ID': {
        try {
          const res = await apiClient.billing.addTaxId(data)
          notify.success(res.message)
        } catch (error) {
          if (isZodError<AddTaxIdData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
        break
      }
    }

    await queryClient.invalidateQueries(currentBillingAccountQuery)

    return redirect(`/billing`)
  }
}

export default function Billing() {
  const loaderData = useLoaderData() as any
  const { data: currentBillingAccount } = useQuery(currentBillingAccountQuery)
  invariant(currentBillingAccount)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const isEditingPaymentMethod = searchParams.get('edit') === 'payment-method'
  const isEditingTaxId = searchParams.get('edit') === 'tax-id'

  return (
    <>
      <HeadTag tag="title" headId="title">
        {import.meta.env.MODE !== 'production' ? `(${import.meta.env.VITE_ENVIRONMENT}) ` : ''}
        Billing settings - Gigapipe
      </HeadTag>

      <Box paddingX={{ mobile: 'xxsmall', tablet: 'large', desktop: 'xxlarge' }}>
        <Stack>
          <PageHeader title="Billing Settings" />

          <Card>
            <Card.Header
              title="Payment method"
              description="Set the payment method for your billing account."
            />
            <Card.Section>
              <BillingAccountCard {...currentBillingAccount} />
              <Suspense fallback="">
                <Await resolve={loaderData.stripe}>
                  {stripe => (
                    <Elements options={{ locale: LANGUAGE }} stripe={stripe}>
                      <Modal isOpen={isEditingPaymentMethod} onClose={() => navigate('.')}>
                        <Modal.Content>
                          <CreditCardForm
                            billingAccountId={currentBillingAccount.id}
                            onCancel={() => navigate('.')}
                            onError={error => notify.error(error.message)}
                            onSuccess={() => notify.success('Payment method updated')}
                          />
                        </Modal.Content>
                      </Modal>
                    </Elements>
                  )}
                </Await>
              </Suspense>
            </Card.Section>
          </Card>

          <Card>
            <Card.Header
              title="Tax Information"
              description="Set the tax information for your invoices."
            />
            <Card.Section>
              {currentBillingAccount?.paymentMethodId ? (
                isEditingTaxId ? (
                  <TaxIdForm
                    billingAccountId={currentBillingAccount.id}
                    onCancel={() => navigate('.')}
                  />
                ) : (
                  <Link
                    type="button"
                    to="."
                    searchParams={{
                      edit: 'tax-id',
                    }}
                  >
                    Configure the tax id
                  </Link>
                )
              ) : (
                <Text>You need to configure a payment method first.</Text>
              )}
            </Card.Section>
          </Card>
        </Stack>
      </Box>
    </>
  )
}

export function ErrorBoundary() {
  const error = useRouteError() as Error

  if (isRouteErrorResponse(error)) {
    return <RouteErrorMessage message={error.data.message} description={error.data.description} />
  }
  throw error
}
