import { memo, type SVGProps } from 'react'

const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 20a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.867 20a2.201 2.201 0 0 0 .44 2.427l.08.08a2.667 2.667 0 0 1-1.887 4.555 2.666 2.666 0 0 1-1.887-.782l-.08-.08a2.2 2.2 0 0 0-2.426-.44 2.2 2.2 0 0 0-1.334 2.013V28a2.667 2.667 0 1 1-5.333 0v-.12A2.2 2.2 0 0 0 12 25.867a2.2 2.2 0 0 0-2.427.44l-.08.08a2.667 2.667 0 1 1-3.773-3.774l.08-.08a2.2 2.2 0 0 0 .44-2.426 2.2 2.2 0 0 0-2.013-1.334H4a2.667 2.667 0 0 1 0-5.333h.12A2.2 2.2 0 0 0 6.133 12a2.2 2.2 0 0 0-.44-2.427l-.08-.08A2.667 2.667 0 0 1 7.5 4.938a2.667 2.667 0 0 1 1.887.782l.08.08a2.2 2.2 0 0 0 2.426.44H12a2.2 2.2 0 0 0 1.333-2.013V4a2.667 2.667 0 0 1 5.334 0v.12A2.2 2.2 0 0 0 20 6.133a2.2 2.2 0 0 0 2.427-.44l.08-.08a2.668 2.668 0 1 1 3.773 3.774l-.08.08a2.2 2.2 0 0 0-.44 2.426V12a2.2 2.2 0 0 0 2.013 1.333H28a2.667 2.667 0 1 1 0 5.334h-.12A2.2 2.2 0 0 0 25.867 20Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgSettings)
export default Memo
