import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { IntegrationLink } from '~/entities/integrations/base'
import { grafanaConfigQuery } from '~/entities/integrations/grafana'

type GrafanaLinksProps = {
  projectId: Project['id']
}

export function GrafanaLinks({ projectId }: GrafanaLinksProps) {
  const { data: grafanaLink } = useQuery({
    ...grafanaConfigQuery(projectId),
    select: data => data.grafanaUrl,
  })

  return <IntegrationLink label="Launch Grafana" launchURL={grafanaLink} />
}
