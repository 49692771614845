import { useEffect } from 'react'
import {
  isRouteErrorResponse,
  json,
  type LoaderFunctionArgs,
  Outlet,
  redirect,
  useLocation,
  useMatch,
  useRouteError,
} from 'react-router-dom'
import { type QueryClient, useQuery } from '@tanstack/react-query'
import { authorize } from 'keycloak'

import { IntegrationCard, integrationsQuery } from '~/entities/integrations/base'
import { hepicUsersQuery } from '~/entities/integrations/hepic'
import { checkProjectSlugParam, useProjectIdParam } from '~/entities/projects'

import { useNavigate } from '~/shared/lib/reactRouterWrappers'
import { authStore } from '~/shared/model/auth'
import { useDecodedTokenData } from '~/shared/model/auth/useAuth'
import { isApiError } from '~/shared/model/errors'
import { RouteErrorMessage } from '~/shared/ui/RouteErrorMessage'

import { Grid } from '~/components/Grid'
import { HeadTag } from '~/components/HeadTag'
import { Link } from '~/components/Link'
import { PageHeader } from '~/components/PageHeader'
import { Stack as StackComponent } from '~/components/Stack'

export function loader(queryClient: QueryClient) {
  return async ({ params }: LoaderFunctionArgs) =>
    authorize(authStore, async () => {
      const { projectId, projectSlug } = checkProjectSlugParam(params)

      try {
        const integrations = await queryClient.ensureQueryData(integrationsQuery(projectId))

        if (integrations?.length === 0) return redirect(`/${projectSlug}/integrations`)

        return json({ integrations })
      } catch (error) {
        if (isApiError(error) && error.type === 'data_query') {
          throw json(
            {
              message: 'We encountered an error while fetching some data.',
              description:
                'The application could not load your stack. Please reload the page and try again. If the issue persist please contact support.',
            },
            { status: 400 },
          )
        }
        throw error
      }
    })
}

export default function Stack() {
  const { projectId, projectSlug } = useProjectIdParam()
  const { email } = useDecodedTokenData() ?? {}
  const { data: integrations } = useQuery(integrationsQuery(projectId))
  const hasHepicIntegration = integrations?.some(
    integration => integration.product.slug === 'hepic',
  )

  const hepicUsersQ = useQuery(hepicUsersQuery(projectId, true))?.data

  const hepicUsers = hasHepicIntegration && hepicUsersQ

  const isHepicUser = !!email && !!(hepicUsers && hepicUsersQ?.some(user => user.email === email))

  const stackPage = useMatch(':projectId/stack')
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const cleanPathName = pathname.replace(projectSlug, '')
    if (
      integrations?.every(integration => !cleanPathName.includes(integration.product.slug)) &&
      !stackPage
    ) {
      navigate(`/${projectSlug}/integrations`)
    }
  }, [navigate, integrations, stackPage, pathname, projectSlug])

  return stackPage ? (
    <>
      <HeadTag tag="title" headId="title">
        {import.meta.env.MODE !== 'production' ? `(${import.meta.env.VITE_ENVIRONMENT}) ` : ''}
        Stack - Gigapipe
      </HeadTag>
      <StackComponent>
        <PageHeader
          title="Stack"
          subtitle="The products that are enabled in the current project."
        />
        <Grid columns={{ mobile: 1, tablet: 2, desktop: 3 }} space="medium">
          {integrations?.map(integration => (
            <Grid.Item key={integration.id} matchHeight>
              <Link
                disabled={integration.product.name === 'hepic' && !isHepicUser}
                to={`${integration.product.slug}`}
              >
                <IntegrationCard
                  name={integration.product.name}
                  slug={integration.product.slug}
                  isUserActive={isHepicUser}
                  status={integration.status}
                  createdAt={integration.createdAt}
                  projectId={projectId}
                  user={integration.creatorName}
                />
              </Link>
            </Grid.Item>
          ))}
        </Grid>
        <Outlet />
      </StackComponent>
    </>
  ) : (
    <Outlet></Outlet>
  )
}

export function ErrorBoundary() {
  const error = useRouteError() as Error

  if (isRouteErrorResponse(error))
    return <RouteErrorMessage message={error.data.message} description={error.data.description} />

  throw error
}
