import { type HepicEnableData, type Product, type QrynEnableData } from 'api-client'
import { z, type ZodFormattedError } from 'zod'

export const baseSchema = z.object({
  productSlug: z.string().min(1, { message: 'Please select a product' }),
  projectId: z.string().min(1, { message: 'Please select a project' }),
})

export type Schema = z.infer<typeof baseSchema>
export type Errors = z.inferFlattenedErrors<typeof baseSchema>

export type ActionResponse = {
  product: string
  enabled: boolean
  hasJsonDownload?: boolean
  step: 'show-info' | 'show-config' | ''
  items: { label: string; value: string }[]
  errors?: ZodFormattedError<QrynEnableData> | ZodFormattedError<HepicEnableData>
}

/**
 * Compute the unmet dependencies for the given product
 */
export function getUnmetDependencies(
  dependencies: Product['dependencies'],
  enabledIntegrations?: string[],
) {
  if (!dependencies?.length) return []

  const integrationsSet = new Set(enabledIntegrations)
  return dependencies.filter(item => !integrationsSet.has(item))
}
