import { transformCamelObjectKeysToSnake } from 'plunger'
import { z } from 'zod'

import { type Rooquest } from '../../lib/rooquest'
import { type ProjectId } from '../../projects/model'
import { type MessageResponse } from '../../utils/types'

import {
  type CancelIntegrationSubscriptionData,
  cancellationDateSchema,
  integrationSchema,
  serviceStatusSchema,
  trialDaysSchema,
} from './model'

const integrations = z.array(integrationSchema)
const servicesStatus = z.array(serviceStatusSchema)

export default class IntegrationsService {
  private readonly rooquest: Rooquest

  constructor(rooquest: Rooquest) {
    this.rooquest = rooquest
  }

  /**
   * Get existing integrations for a project
   */
  public getIntegrationsByProject = async (projectId: ProjectId) => {
    const res = await this.rooquest.get('integrations/', {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return integrations.parse(res)
  }

  /**
   * Get remaining days from the trial period for the integration
   */
  public getIntegrationTrialDays = async ({
    projectId,
    integration,
  }: {
    projectId: ProjectId
    integration: string
  }) => {
    const res = await this.rooquest.get(`integrations/${integration}/trial`, {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return trialDaysSchema.parse(res)
  }

  /**
   * Get current status of all integration services
   */
  public getIntegrationStatus = async () => {
    const res = await this.rooquest.get('integrations/status')

    return servicesStatus.parse(res)
  }

  /**
   * Cancel the integration subscription
   */
  public cancelSubscription = async ({
    projectId,
    integration,
  }: CancelIntegrationSubscriptionData) => {
    const res = await this.rooquest.post(`integrations/${integration}/cancel`, {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return res as MessageResponse
  }

  /**
   * Get the cancellation date for the integration
   */
  public getCancellationDate = async ({
    projectId,
    integration,
  }: {
    projectId: ProjectId
    integration: string
  }) => {
    const res = await this.rooquest.get(`integrations/${integration}/cancellation`, {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return cancellationDateSchema.parse(res)
  }
}
