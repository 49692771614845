import { useCallback, useRef } from 'react'
import { type AriaNumberFieldProps, useNumberField } from 'react-aria'
import { useNumberFieldState } from 'react-stately'

import { Box } from '../Box'
import { Stack } from '../Stack'
import { Text } from '../Text'

import * as inputStyles from '../Input/Input.css'

export type NumberProps = AriaNumberFieldProps & {
  /** Used in some cases to identify the input - Not required for onChange callback since it gets the value not the event */
  name?: string
}

export const NumberInput = ({ onChange: externalOnChange, ...props }: NumberProps) => {
  const { errorMessage, description, label } = props

  const onChange = useCallback(
    (value: number) => {
      if (Number.isNaN(value)) {
        externalOnChange?.(props.minValue ?? 0)
      } else {
        externalOnChange?.(value)
      }
    },
    [externalOnChange, props.minValue],
  )

  const state = useNumberFieldState({ ...props, locale: `en-US-u-nu-latn`, onChange })
  const inputRef = useRef<HTMLInputElement>(null)

  const { labelProps, inputProps, errorMessageProps, descriptionProps } = useNumberField(
    { ...props, onChange },
    state,
    inputRef,
  )

  return (
    <Stack direction="vertical" space="none">
      {!!label && (
        <Text
          {...labelProps}
          as="label"
          weight="light"
          lineHeight="medium"
          color={{ darkMode: 'gray400', lightMode: 'black' }}
        >
          {label}
        </Text>
      )}

      <Box
        display="flex"
        flexDirection="row"
        className={inputStyles.root({
          disabled: inputProps.disabled,
          readOnly: inputProps.readOnly,
          error: !!errorMessage,
        })}
      >
        <input
          ref={inputRef}
          className={inputStyles.input({
            disabled: inputProps.disabled,
            hasPrefix: false,
            hasSuffix: false,
          })}
          {...inputProps}
        />
      </Box>

      {errorMessage ? (
        <div className={inputStyles.error} {...errorMessageProps}>
          {errorMessage}
        </div>
      ) : (
        description && (
          <div className={inputStyles.description} {...descriptionProps}>
            {description}
          </div>
        )
      )}
    </Stack>
  )
}
