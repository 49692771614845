import {
  isRouteErrorResponse,
  json,
  type LoaderFunctionArgs,
  useRouteError,
} from 'react-router-dom'
import { type QueryClient, useQuery } from '@tanstack/react-query'
import { isZodError, type LoggenConfigPayloadData } from 'api-client'
import { authorize } from 'keycloak'
import { z } from 'zod'

import { notify } from '~/features/notifications'
import { LoggenConfig } from '~/features/updateLoggenConfig'

import {
  checkIntegrationStatus,
  IntegrationLink,
  IntegrationName,
  integrationsQuery,
  IntegrationStatus,
} from '~/entities/integrations/base'
import { loggenConfigQuery, UPDATE_LOGGEN_CONFIG } from '~/entities/integrations/loggen'
import {
  checkProjectSlugParam,
  getProjectIdFromParams,
  useProjectIdParam,
} from '~/entities/projects'

import { apiClient, handleDataMutationError } from '~/shared/api'
import { getFormData } from '~/shared/lib/parseRequest'
import { authStore } from '~/shared/model/auth'
import { isApiError } from '~/shared/model/errors'
import { RouteErrorMessage } from '~/shared/ui/RouteErrorMessage'

import { Box } from '~/components/Box'
import { Card } from '~/components/Card'
import { Grid } from '~/components/Grid'
import { HeadTag } from '~/components/HeadTag'
import { Link } from '~/components/Link'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

const SLUG = 'loggen'

export function loader(queryClient: QueryClient) {
  return async ({ params }: LoaderFunctionArgs) =>
    authorize(authStore, async () => {
      const { projectId, projectSlug } = checkProjectSlugParam(params)
      const integration = await checkIntegrationStatus(queryClient, {
        projectId,
        projectSlug,
        slug: SLUG,
      })
      try {
        const config = await queryClient.ensureQueryData(loggenConfigQuery(projectId))

        return json({ integration, config })
      } catch (error) {
        if (isApiError(error) && error.type === 'data_query') {
          throw json(
            {
              message: `We had issues loading the configuration of the Loggen integration for project ${projectId}`,
              description: `Please go to the Stack page and try again. If the problem persists, please contact support.`,
              goTo: {
                path: `/${projectSlug}/stack`,
                text: 'Go to Stack',
              },
            },
            { status: 400 },
          )
        }
        throw error
      }
    })
}

export function action() {
  return async ({ request, params }: LoaderFunctionArgs) => {
    const data = await getFormData(request)
    const projectId = getProjectIdFromParams(params)
    if (!projectId) {
      notify.error('No project has been selected.')
      return json({})
    }

    const intents = z.enum([UPDATE_LOGGEN_CONFIG]).safeParse(data.intent)

    if (!intents.success) {
      throw json(
        {
          message: 'This operation is not valid',
          description:
            'You tried to perform an invalid operation. Nothing will be changed regarding your integrations. Please reload the page and try again.',
        },
        { status: 400 },
      )
    }

    switch (intents.data) {
      case 'UPDATE_LOGGEN_CONFIG': {
        try {
          const { message } = await apiClient.loggen.updateLoggenConfig({
            ...data,
            projectId: projectId,
          })
          notify.success(message)

          return json({ step: 'run-loggen' })
        } catch (error) {
          if (isZodError<LoggenConfigPayloadData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
    }
  }
}

export default function Loggen() {
  const { projectId } = useProjectIdParam()
  const { data: integration } = useQuery({
    ...integrationsQuery(projectId),
    select: data => data.find(i => i.product.slug === SLUG),
  })

  if (!integration) return null
  return (
    <Stack direction="vertical">
      <HeadTag tag="title" headId="title">
        {import.meta.env.MODE !== 'production' ? `(${import.meta.env.VITE_ENVIRONMENT}) ` : ''}
        {integration.product.name} - Gigapipe
      </HeadTag>
      <Stack space="small">
        <Stack direction="horizontal">
          <IntegrationName name={integration.product.name} slug={integration.product.slug} />
        </Stack>
        <Box>
          <IntegrationStatus
            projectId={projectId}
            slug={integration.product.slug}
            status={integration?.status ?? 'active'}
          />
        </Box>
      </Stack>
      <Stack direction="vertical">
        <Grid>
          <Grid.Item span={{ mobile: 12, tablet: 8 }}>
            <Card>
              <Card.Header title="How to use Loggen" />
              <Card.Section>
                <Stack>
                  <Text>
                    Loggen is a test utility that generates logs based on the configuration
                    parameters.
                  </Text>
                  <Text>
                    For more information, read the{' '}
                    <Link to="https://docs.gigapipe.com/" target="_blank" type="text">
                      Loggen docs
                    </Link>
                  </Text>
                </Stack>
              </Card.Section>
            </Card>
          </Grid.Item>
          <Grid.Item span={{ mobile: 12, tablet: 4 }}>
            <Card>
              <Card.Section>
                <Stack direction="vertical" space="small">
                  <IntegrationLink label="Loggen docs" launchURL="https://docs.gigapipe.com" />
                </Stack>
              </Card.Section>
            </Card>
          </Grid.Item>
        </Grid>
        <Card>
          <Card.Header
            title="Loggen configuration"
            description="Configure Loggen to run your tests."
          />
          <Card.Section>
            <LoggenConfig projectId={projectId} />
          </Card.Section>
        </Card>
      </Stack>
    </Stack>
  )
}

export function ErrorBoundary() {
  const error = useRouteError() as Error

  if (isRouteErrorResponse(error)) {
    return (
      <RouteErrorMessage
        message={error.data.message}
        description={error.data.description}
        goToPath={error.data.goTo?.path}
        goToText={error.data.goTo?.text}
      />
    )
  }

  throw error
}
