import { defaultTimeouts } from './constants'
import {
  type Message,
  type Notification,
  type NotificationOptions,
  type NotificationType,
} from './Notifications.types'
import { useNotificationsStore } from './store'

type NotificationHandler = (message: Message, options?: NotificationOptions) => string

const { upsert, remove, removeAll, dismiss, dismissAll } = useNotificationsStore.getState()

const createNotification = (
  message: Message,
  type: NotificationType = 'default',
  opts?: NotificationOptions,
): Notification => ({
  createdAt: Date.now(),
  visible: true,
  type,
  message,
  pauseDuration: 0,
  duration: opts?.duration ?? defaultTimeouts[type],
  id: opts?.id ?? (Date.now() + Math.floor(Math.random() * 100)).toString(),
  onClose: opts?.onClose,
  onPress: opts?.onPress,
})

const createHandler =
  (type: NotificationType): NotificationHandler =>
  (message, options) => {
    const notification = createNotification(message, type, options)
    upsert(notification)
    return notification.id
  }

/**
 * Create or update (if already exists) a notification of type default
 */
const notify = (message: Message, opts?: NotificationOptions) =>
  createHandler('default')(message, opts)

/**
 * Create or update (if already exists) a notification of type error
 */
notify.error = createHandler('error')

/**
 * Create or update (if already exists) a notification of type success
 */
notify.success = createHandler('success')

/**
 * Create or update (if already exists) a notification of type warning
 */
notify.warning = createHandler('warning')

/**
 * Remove one notification from the state
 * @param notificationId
 */
notify.remove = (notificationId: string) => remove(notificationId)

/**
 * Remove all notifications from the state
 */
notify.removeAll = removeAll

/**
 * Visually hidde one notification
 * @param notificationId
 */
notify.dismiss = (notificationId: string) => {
  dismiss(notificationId)
}
/**
 * Visually hide all notifications
 */
notify.dismissAll = dismissAll

export { notify }
