import { createAuthStore } from 'keycloak'

import { type User } from './types'

// setup the connection to keycloak

export const authStore = createAuthStore<User>({
  url: import.meta.env.VITE_KC_URL,
  realm: import.meta.env.VITE_KC_REALM,
  clientId: import.meta.env.VITE_KC_CLIENT_ID,
})
