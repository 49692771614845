import { useProgressBar } from 'react-aria'

import { Loader } from '~/components/Icons'

import { Box } from '../Box'
import { Icon, type IconProps } from '../Icon'
import { Text } from '../Text'

import * as styles from './Loading.css'

export type LoadingProps = {
  /**
   * Controls the size of the icon
   *
   * default: "large"
   */
  size?: IconProps['size']
  /**
   * Controls the color of the icon
   *
   * default: "gray500"
   */
  color?: IconProps['color']
  text?: string
  label?: string
} & styles.Variants

export const Loading = ({
  label = 'Loading',
  size,
  color,
  cover = false,
  text = '',
}: LoadingProps) => {
  const { progressBarProps } = useProgressBar({ isIndeterminate: true, 'aria-label': label })

  return (
    <Box className={styles.wrapper({ cover })} {...progressBarProps}>
      <Icon size={size} color={color}>
        <Loader className={styles.spinner} />
      </Icon>
      {!!text && <Text className={styles.text}>{text}</Text>}
    </Box>
  )
}
