import { useState } from 'react'
import { useFetcher } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { hepicConfigQuery, TOGGLE_HEPIC_AUTO_ADD_USERS } from '~/entities/integrations/hepic'

type ManageHepicAutoAddUsersProps = {
  projectId: Project['id']
  projectSlug: Project['slug']
}

export const useAutoAddUsers = ({ projectId, projectSlug }: ManageHepicAutoAddUsersProps) => {
  const { data: config } = useQuery(hepicConfigQuery(projectId))

  const [autoAddUsers, setAutoAddUsers] = useState(config?.autoAdd)

  const fetcher = useFetcher()

  const handleAutoAddUsersToggle = (autoAdd: boolean) => {
    setAutoAddUsers(() => autoAdd)

    fetcher.submit(
      {
        intent: TOGGLE_HEPIC_AUTO_ADD_USERS,
      },
      {
        method: 'post',
        action: `/${projectSlug}/stack/hepic`,
        encType: 'application/x-www-form-urlencoded',
      },
    )
  }

  return {
    autoAddUsers,
    handleAutoAddUsersToggle,
  }
}
