import { memo, type SVGProps } from 'react'

const SvgUnlock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.333 14.667V9.333A6.667 6.667 0 0 1 22.533 8M6.667 14.667h18.666A2.667 2.667 0 0 1 28 17.333v9.334a2.667 2.667 0 0 1-2.667 2.666H6.667A2.667 2.667 0 0 1 4 26.667v-9.334a2.667 2.667 0 0 1 2.667-2.666Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgUnlock)
export default Memo
