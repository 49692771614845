import { memo, type SVGProps } from 'react'

const SvgMail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.333 8c0-1.467-1.2-2.667-2.666-2.667H5.333A2.675 2.675 0 0 0 2.667 8m26.666 0v16c0 1.467-1.2 2.667-2.666 2.667H5.333A2.674 2.674 0 0 1 2.667 24V8m26.666 0L16 17.333 2.667 8"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgMail)
export default Memo
