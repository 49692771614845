import { useRef } from 'react'
import {
  type AriaTabListProps,
  type AriaTabPanelProps,
  mergeProps,
  useFocusRing,
  useTab,
  useTabPanel,
} from 'react-aria'
import { type TabListState } from 'react-stately'
import { type Node } from '@react-types/shared'

import * as styles from './Tabs.css'

type TabProps<T> = {
  state: TabListState<T>
  item: Node<T>
  orientation: AriaTabListProps<T>['orientation']
  isNested?: boolean
}

export function Tab<T extends object>({ item, state, isNested }: TabProps<T>) {
  const { key, rendered } = item
  const ref = useRef<HTMLDivElement>(null)
  const { tabProps, isSelected, isDisabled } = useTab({ key }, state, ref)
  const { focusProps, isFocusVisible } = useFocusRing({
    within: true,
  })

  return (
    <div
      {...mergeProps(tabProps, focusProps)}
      ref={ref}
      className={styles.tab({ isSelected, isDisabled, isFocused: isFocusVisible, isNested })}
    >
      {rendered}
    </div>
  )
}

type TabPanelProps<T> = {
  state: TabListState<T>
  isNested?: boolean
} & AriaTabPanelProps

export function TabPanel<T extends object>({ state, isNested, ...props }: TabPanelProps<T>) {
  const ref = useRef<HTMLDivElement>(null)
  const { tabPanelProps } = useTabPanel(props, state, ref)

  return (
    <div {...tabPanelProps} ref={ref} className={styles.tabpanel({ isNested })}>
      {state.selectedItem?.props.children}
    </div>
  )
}
