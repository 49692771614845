import { memo, type SVGProps } from 'react'

const SvgGrid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.333 4H4v9.333h9.333V4ZM28 4h-9.333v9.333H28V4ZM28 18.667h-9.333V28H28v-9.333ZM13.333 18.667H4V28h9.333v-9.333Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgGrid)
export default Memo
