import { transformSnakeObjectKeysToCamel } from 'plunger'
import { z } from 'zod'

import { projectId } from '../../projects/model'

// Constants
export const loggenTimeoutOptions = ['5s', '10s', '30s', '1m', '3m', '5m', '10m'] as const
export const loggenFormatOptions = ['json', 'logfmt'] as const

// Shared schemas
const loggenSpanBaseSchema = z.object({
  kind: z.number(),
  name: z.string(),
  duration: z.string(),
  attributes: z
    .array(
      z.object({
        name: z.string(),
        valueType: z.string(),
        resolveFake: z.string().nullable(),
        resolveFromLogValue: z.string(),
      }),
    )
    .nullable(),
})
const loggenSpanSchema: z.ZodType<LoggenTraceSpan> = loggenSpanBaseSchema.extend({
  spans: z.lazy(() => loggenSpanSchema.array()).optional(),
})
type LoggenTraceSpan = z.infer<typeof loggenSpanBaseSchema> & {
  spans?: LoggenTraceSpan[]
}

// Schemas for fetched requests
export const loggenConfigResponse = z.preprocess(
  (data: any) => {
    // The log_config object doesn't have to be transformed to camelCase because it's custom for the users
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { log_config, ...rest } = data
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const res = { ...transformSnakeObjectKeysToCamel(rest), logConfig: log_config }
    return res
  },
  z.object({
    url: z.string().url(),
    headers: z.record(z.string(), z.string()).optional().nullable(),
    apiKey: z.string(),
    apiSecret: z.string(),
    labels: z.record(z.string(), z.string()),
    rate: z.coerce.number(),
    timeout: z.enum(loggenTimeoutOptions),
    logConfig: z.object({
      format: z.enum(loggenFormatOptions),
      structure: z.record(z.string(), z.string()),
    }),
    enableMetrics: z.coerce.boolean(),
    traces: z.object({
      enabled: z.boolean(),
      custom: z.record(z.string(), z.string()).optional().nullable(),
      spans: z.array(loggenSpanSchema).optional().nullable(),
    }),
    projectId: z.coerce.string(),
    subscriptionId: z.string(),
  }),
)
export type LoggenConfigResponse = z.infer<typeof loggenConfigResponse>

export const loggenLogsConfigSchema = z.object({
  display: z.string(),
  category: z.string(),
  description: z.string(),
  example: z.string(),
  params: z
    .array(
      z.object({
        field: z.string(),
        display: z.string(),
        type: z.string(),
        optional: z.boolean(),
        default: z.string(),
        options: z.array(z.string()).nullable(),
        description: z.string(),
      }),
    )
    .nullable(),
})
export type LoggenLogsConfig = z.infer<typeof loggenLogsConfigSchema>

export const loggenLogsCategoriesResponse = z.array(z.string())

// Schemas for submit requests
export const loggenEnableSchema = z.object({
  projectId: projectId,
})
export type LoggenEnableData = z.infer<typeof loggenEnableSchema>

export const loggenConfigPayload = z.object({
  url: z.string().url(),
  labels: z.record(z.string(), z.string()),
  rate: z.coerce
    .number()
    .min(1, 'The rate must be between 1 and 100')
    .max(100, 'The rate must be between 1 and 100'),
  timeout: z.enum(loggenTimeoutOptions),
  logConfig: z.object({
    format: z.enum(loggenFormatOptions),
    structure: z.record(z.string(), z.string()),
  }),
  enableMetrics: z.coerce.boolean(),
  traces: z
    .object({
      enabled: z.coerce.boolean(),
      custom: z.record(z.string(), z.string()).optional().nullable(),
      spans: z.array(loggenSpanSchema).optional().nullable(),
    })
    .optional(),
  projectId: z.coerce.string(),
})
export type LoggenConfigPayloadData = z.infer<typeof loggenConfigPayload>
