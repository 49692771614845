import { useMemo } from 'react'
import { json } from 'react-router-dom'
import { type QueryClient, useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { currentBillingAccountQuery } from '~/entities/billing'
import { clickHouseClustersQuery } from '~/entities/integrations/clickhouse'
import { useProjectIdParam } from '~/entities/projects'

import { isApiError } from '~/shared/model/errors'

type Arguments = {
  /**
   * Project ID.
   */
  projectId: Project['id']
  /**
   * Project slug.
   */
  projectSlug: Project['slug']
}

/**
 * Checks if a ClickHouse cluster can be created.
 *
 * @param {QueryClient} client - The query client.
 * @param {string} project - The project name.
 * @return {Promise<boolean>} - Returns a promise that resolves to a boolean indicating whether ClickHouse cluster creation is allowed or not.
 * @throws {ErrorResponse} - Throws an ErrorResponse if some of the data required to do the check can not be retrieved.
 */
export async function shouldAllowClickHouseClusterCreation(
  client: QueryClient,
  { projectId, projectSlug }: Arguments,
) {
  try {
    const [clusters, { paymentMethodId }] = await Promise.all([
      client.ensureQueryData(clickHouseClustersQuery(projectId)),
      client.ensureQueryData(currentBillingAccountQuery),
    ])
    const readyClusters = clusters.filter(cluster => cluster.status !== 'deleting')

    if (paymentMethodId) return true
    if (!paymentMethodId && readyClusters.length === 0) return true
    return false
  } catch (error) {
    if (isApiError(error) && error.type === 'data_query') {
      throw json(
        {
          message: `We could not determine if ClickHouse cluster creation is allowed or not for project ${projectId}`,
          description: `There could be an error with your billing information. Please go to the Stack page and try again. If the issue persist please contact support.`,
          goTo: {
            path: `/${projectSlug}/stack`,
            text: 'Go to Stack',
          },
        },
        { status: 400 },
      )
    }
    throw error
  }
}

export function useClickHouseClusterCreation() {
  const { projectId } = useProjectIdParam()
  const { data: hasPaymentMethod } = useQuery({
    ...currentBillingAccountQuery,
    select: data => Boolean(data.paymentMethodId),
  })
  const { data: clusters } = useQuery({
    ...clickHouseClustersQuery(projectId),
    select: data => data.filter(cl => cl.status !== 'deleting'),
  })

  return useMemo(() => {
    if (hasPaymentMethod) return true
    if (!hasPaymentMethod && clusters?.length === 0) return true
    return false
  }, [hasPaymentMethod, clusters?.length])
}
