import {
  mapResponsiveValue,
  type ResponsiveObject,
  type ResponsiveValue,
} from '~/styles/sprinkles.css'

const alignToAlignItemsLookup = {
  stretch: 'stretch',
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  baseline: 'baseline',
} as const

export const alignValues = Object.keys(alignToAlignItemsLookup)
export type Align = keyof typeof alignToAlignItemsLookup

export const alignToAlignItems = (align: ResponsiveValue<Align> | undefined) =>
  align ? mapResponsiveValue(align, value => alignToAlignItemsLookup[value]) : undefined

const justifyToJustifyContentLookup = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  between: 'space-between',
} as const

export const justifyValues = Object.keys(justifyToJustifyContentLookup)
export type Justify = keyof typeof justifyToJustifyContentLookup

export const justifyToJustifyContent = (justify: ResponsiveValue<Justify> | undefined) =>
  justify ? mapResponsiveValue(justify, value => justifyToJustifyContentLookup[value]) : undefined

const directionToFlexDirectionLookup = {
  horizontal: 'row',
  vertical: 'column',
} as const

export const directionValues = Object.keys(directionToFlexDirectionLookup)
export type Direction = keyof typeof directionToFlexDirectionLookup

export const directionToFlexDirection = (
  direction: ResponsiveValue<Direction>,
  reverse: boolean,
) =>
  direction
    ? mapResponsiveValue(direction, value => {
        const flexDirection = directionToFlexDirectionLookup[value]
        return reverse ? `${flexDirection}-reverse` : flexDirection
      })
    : undefined

const wrapToFlexWrapLookup = {
  1: 'wrap',
  0: 'nowrap',
} as const
export type Wrap = boolean

export const wrapToFlexWrap = (wrap: ResponsiveObject<Wrap> | undefined) =>
  wrap
    ? typeof wrap === 'boolean'
      ? wrapToFlexWrapLookup[1]
      : mapResponsiveValue(wrap as any, value => wrapToFlexWrapLookup[value ? 1 : 0])
    : undefined

// TODO: add tests to this utility functions when we are able to config vanilla-extract with Jest
