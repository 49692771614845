import { useCallback, useRef } from 'react'
import { type AriaNumberFieldProps, useButton, useNumberField } from 'react-aria'
import { useNumberFieldState } from 'react-stately'
import { clsx } from 'clsx'

import { Box } from '../Box'
import { Icon } from '../Icon'
import { MathMinus as Minus, MathPlus as Plus } from '../Icons'
import { Text } from '../Text'

import * as inputStyles from '../Input/Input.css'
import * as styles from './Stepper.css'

export type StepperProps = AriaNumberFieldProps & {
  /** Used in some cases to identify the input - Not required for onChange callback since it gets the value not the event */
  name?: string
}

/**
 * Component used to display a number input with a increase/decrease buttons attached.
 *
 * Is has the same format options as the `Number` component.
 */
export const Stepper = ({ onChange: externalOnChange, ...props }: StepperProps) => {
  const { errorMessage, description } = props
  const onChange = useCallback(
    (value: number) => {
      if (Number.isNaN(value)) {
        externalOnChange?.(props.minValue ?? 0)
      } else {
        externalOnChange?.(value)
      }
    },
    [externalOnChange, props.minValue],
  )

  const state = useNumberFieldState({
    ...props,
    locale: `en-US-u-nu-latn`,
    onChange,
  })
  const inputRef = useRef<HTMLInputElement>(null)
  const incrRef = useRef<HTMLButtonElement>(null)
  const decRef = useRef<HTMLButtonElement>(null)
  const {
    labelProps,
    inputProps,
    incrementButtonProps,
    decrementButtonProps,
    errorMessageProps,
    descriptionProps,
  } = useNumberField(
    {
      ...props,

      onChange,
      onFocus: (e: any) => {
        e?.target?.focus()

        e?.target?.select()
      },
    },
    state,
    inputRef,
  )

  const { buttonProps: incrementProps } = useButton(incrementButtonProps, incrRef)
  const { buttonProps: decrementProps } = useButton(decrementButtonProps, decRef)

  return (
    <div>
      {!!props.label && (
        <Text {...labelProps} as="label" weight="light" lineHeight="medium" color="gray600">
          {props.label}
        </Text>
      )}

      <Box className={styles.wrapper({ error: !!errorMessage })}>
        <button
          type="button"
          {...decrementProps}
          ref={incrRef}
          className={styles.button({
            isDisabled: inputProps.disabled ?? decrementProps.disabled,
          })}
        >
          <Icon>
            <Minus />
          </Icon>
        </button>
        <input
          ref={inputRef}
          className={clsx(
            inputStyles.input({ disabled: inputProps.disabled }),
            styles.input({ isDisabled: inputProps.disabled }),
          )}
          {...inputProps}
        />
        <button
          type="button"
          {...incrementProps}
          ref={decRef}
          className={styles.button({
            isDisabled: inputProps.disabled ?? incrementProps.disabled,
          })}
        >
          <Icon>
            <Plus />
          </Icon>
        </button>
      </Box>

      {errorMessage ? (
        <div className={styles.error} {...errorMessageProps}>
          {errorMessage}
        </div>
      ) : (
        description && (
          <div className={styles.description} {...descriptionProps}>
            {description}
          </div>
        )
      )}
    </div>
  )
}
