import { apiClient } from '~/shared/api'

const productsQueryKeys = {
  all: [{ scope: 'products' }] as const,
  allWithProjects: [{ scope: 'products', type: 'with-projects' }] as const,
}

/**
 * Query to fetch all products
 */
export const productsQuery = () => ({
  queryKey: productsQueryKeys.all,
  queryFn: apiClient.products.getProducts,
})

/**
 * Query to fetch all the products with the user's projects that have them enabled
 */
export const productsWithOnUseProjectsQuery = () => ({
  queryKey: productsQueryKeys.allWithProjects,
  queryFn: apiClient.products.getProductsWithUserProjectsOnUse,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
})
