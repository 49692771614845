import { useFetcher } from 'react-router-dom'
import { type ClickHouseCluster, type ClickHouseUpdateIPSafeListData } from 'api-client'
import { type typeToFlattenedError } from 'zod'

import { UPDATE_IPSAFE_LIST_CLICKHOUSE } from '~/entities/integrations/clickhouse'

import { Box } from '~/components/Box'
import { Button } from '~/components/Button'
import { Input } from '~/components/Input'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

type ManageClickHouseIPSafeListProps = {
  clusterId: ClickHouseCluster['id']
  clusterIPSafeList: ClickHouseCluster['ipSafeList']
}

type ActionData = {
  fields?: ClickHouseUpdateIPSafeListData
  errors?: typeToFlattenedError<ClickHouseUpdateIPSafeListData>
}

export function ManageClickHouseIPSafeList({
  clusterId,
  clusterIPSafeList,
}: ManageClickHouseIPSafeListProps) {
  const fetcher = useFetcher<ActionData>()

  return (
    <fetcher.Form method="post">
      <Text id="ipsafelist-label">Safe list IPs</Text>
      <Stack direction="horizontal" align="start" space="small">
        <input hidden name="clusterId" defaultValue={clusterId} />
        <Box flex={1}>
          <Input
            name="ipList"
            aria-labelledby="ipsafelist-label"
            defaultValue={clusterIPSafeList?.join(', ')}
            description="Coma separated list of IPs that can access your cluster. Leave empty to allow all access."
            errorMessage={fetcher.data?.errors?.fieldErrors.ipList}
          />
        </Box>

        <Button
          type="submit"
          color="outline"
          name="intent"
          value={UPDATE_IPSAFE_LIST_CLICKHOUSE}
          isLoading={fetcher.state !== 'idle'}
          isDisabled={fetcher.state !== 'idle'}
        >
          Update
        </Button>
      </Stack>
    </fetcher.Form>
  )
}
