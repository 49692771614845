import {
  type ActionFunctionArgs,
  isRouteErrorResponse,
  json,
  Outlet,
  redirect,
  useRouteError,
} from 'react-router-dom'
import { isZodError, type UpdateUserProfileData } from 'api-client'
import { z } from 'zod'

import { notify } from '~/features/notifications'
import { Profile } from '~/features/settings/Profile'
import { UpdateUserPasswordForm } from '~/features/updateUserPassword'

import { UPDATE_PASSWORD, UPDATE_PROFILE } from '~/entities/users'

import { apiClient, handleDataMutationError } from '~/shared/api'
import { getFormData } from '~/shared/lib/parseRequest'
import { authStore } from '~/shared/model/auth'
import { RouteErrorMessage } from '~/shared/ui/RouteErrorMessage'

import { Box } from '~/components/Box'
import { Card } from '~/components/Card'
import { HeadTag } from '~/components/HeadTag'
import { PageHeader } from '~/components/PageHeader'
import { Stack } from '~/components/Stack'

export function action() {
  return async ({ request }: ActionFunctionArgs) => {
    const {
      user,
      actions: { updateUser },
    } = authStore.getState()
    const data = await getFormData(request)
    const intents = z.enum([UPDATE_PROFILE, UPDATE_PASSWORD]).safeParse(data.intent)

    if (!intents.success) {
      throw json(
        {
          message: 'This operation is not valid',
          description:
            'You tried to perform an invalid operation. Nothing will be changed regarding your account information. Please reload the page and try again.',
        },
        { status: 400 },
      )
    }

    switch (intents.data) {
      case 'UPDATE_PASSWORD': {
        try {
          const { message } = await apiClient.users.changePassword(data)
          notify.success(message)

          return redirect(`/account`)
        } catch (error) {
          if (isZodError<UpdateUserProfileData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
      case 'UPDATE_PROFILE': {
        if (data.givenName === user?.given_name && data.familyName === user?.family_name) {
          notify.error('Please change your info')
          return json({})
        }

        try {
          const { message } = await apiClient.users.updateUser(data)
          notify.success(message)

          await updateUser(data)
          return redirect(`/account`)
        } catch (error) {
          if (isZodError<UpdateUserProfileData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
    }
  }
}

export default function Account() {
  return (
    <>
      <HeadTag tag="title" headId="title">
        {import.meta.env.MODE !== 'production' ? `(${import.meta.env.VITE_ENVIRONMENT}) ` : ''}
        Account settings - Gigapipe
      </HeadTag>

      <Box paddingX={{ mobile: 'xxsmall', tablet: 'large', desktop: 'xxlarge' }}>
        <Stack>
          <PageHeader title="Account Settings" />

          <Card>
            <Card.Header title="Profile" description="Personal account information." />
            <Card.Section>
              <Profile />
            </Card.Section>
          </Card>

          <Card>
            <Card.Header
              title="Security"
              description="Change your password and other security-related settings."
            />
            <Card.Section>
              <UpdateUserPasswordForm />
            </Card.Section>
          </Card>
        </Stack>
      </Box>
      <Outlet />
    </>
  )
}

export function ErrorBoundary() {
  const error = useRouteError() as Error

  if (isRouteErrorResponse(error)) {
    return <RouteErrorMessage message={error.data.message} description={error.data.description} />
  }

  throw error
}
