import { useQuery } from '@tanstack/react-query'

import { ManageQrynTokens } from '~/features/manageQrynTokens'

import { qrynConfigQuery } from '~/entities/integrations/qryn'
import { useProjectIdParam } from '~/entities/projects'

import { CopyButton } from '~/shared/ui/CopyButton'

import { Card } from '~/components/Card'
import { Input } from '~/components/Input'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

export default function QrynAuth() {
  const { projectId, projectSlug } = useProjectIdParam()
  const { data: config } = useQuery(qrynConfigQuery(projectId))

  return (
    <>
      <Card>
        <Card.Header title="Access keys" />
        <Card.Section>
          <Stack>
            <Text>
              In order to send or retrieve logs, metrics, and traces from qryn you need to use the
              API Key and API Secret.
            </Text>
            {config && (
              <>
                <Input
                  isReadOnly
                  label="Host"
                  name="host"
                  value={config.url}
                  suffix={<CopyButton value={config.url} />}
                />
                <Input
                  isReadOnly
                  label="API Key"
                  name="apiKey"
                  value={config.apiKey}
                  suffix={<CopyButton value={config.apiKey} />}
                />
                <Input
                  isReadOnly
                  label="API Secret"
                  name="apiSecret"
                  type="password"
                  value={config.apiSecret}
                  suffix={<CopyButton value={config.apiSecret} />}
                />
              </>
            )}
          </Stack>
        </Card.Section>
      </Card>
      <ManageQrynTokens projectId={projectId} projectSlug={projectSlug} />
    </>
  )
}
