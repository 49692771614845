import { BIBIT_UNITS, BIBYTE_UNITS, BIT_UNITS, BYTE_UNITS } from './constants'

type Options = {
  bits?: boolean
  binary?: boolean
}

export function convertSizeToBiggestUnit(number: number, options: Options) {
  const { binary = false, bits = false } = options
  const UNITS = bits ? (binary ? BIBIT_UNITS : BIT_UNITS) : binary ? BIBYTE_UNITS : BYTE_UNITS

  if (Number.isNaN(number) || !number) return { convertedNumber: 0, unit: UNITS[0] }
  if (number < 1) return { convertedNumber: number, unit: UNITS[0] }

  let convertedNumber = number.valueOf()

  const exponent = Math.min(
    Math.floor(
      binary ? Math.log(convertedNumber) / Math.log(1024) : Math.log10(convertedNumber) / 3,
    ),
    UNITS.length - 1,
  )

  const base = binary ? 1024 : 1000
  convertedNumber /= base ** exponent

  convertedNumber = Number(convertedNumber)

  return {
    convertedNumber,
    unit: UNITS[exponent],
  }
}
