type SnippetFnArgs = {
  host?: string
  token?: string
}

export const getFrontendInstrumentationSnippet = ({ host, token }: SnippetFnArgs) => `fetch('${
  host ?? '<HOST>'
}', {
    method: 'POST',
    headers: {
      'x-api-token':
        '${token ?? '<YOUR-TOKEN>'}',
    },
    body: JSON.stringify({
      streams: [
        { stream: { type: 'test' }, values: [['1683111977', 'hello qryn from the front-end']] },
      ],
    }),
  })`
