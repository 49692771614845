import {
  type ActionFunctionArgs,
  isRouteErrorResponse,
  json,
  type LoaderFunctionArgs,
  useRouteError,
} from 'react-router-dom'
import { type QueryClient } from '@tanstack/react-query'
import { type AddUserData, isZodError } from 'api-client'
import { authorize } from 'keycloak'

import { AddUserToProject } from '~/features/addUserToProject'
import { notify } from '~/features/notifications'

import { organizationsUsersQuery } from '~/entities/organizations'
import {
  checkProjectSlugParam,
  getProjectIdFromParams,
  projectsQuery,
  UsersFromProject,
  usersFromProjectQuery,
} from '~/entities/projects'

import { apiClient, handleDataMutationError } from '~/shared/api'
import { getFormData } from '~/shared/lib/parseRequest'
import { authStore } from '~/shared/model/auth'
import { isApiError } from '~/shared/model/errors'
import { RouteErrorMessage } from '~/shared/ui/RouteErrorMessage'

import { Box } from '~/components/Box'
import { HeadTag } from '~/components/HeadTag'
import { PageHeader } from '~/components/PageHeader'
import { Stack } from '~/components/Stack'

export function loader(queryClient: QueryClient) {
  return async ({ params }: LoaderFunctionArgs) =>
    authorize(authStore, async () => {
      const { projectId } = checkProjectSlugParam(params)
      const decodedToken = authStore.getState()?.decodedToken

      try {
        const [projects, orgUsers, projectUsers] = await Promise.all([
          queryClient.ensureQueryData(projectsQuery()),
          decodedToken?.organization_id
            ? queryClient.ensureQueryData(organizationsUsersQuery(true))
            : undefined,
          decodedToken?.organization_id
            ? queryClient.ensureQueryData(usersFromProjectQuery(projectId, true))
            : undefined,
        ])

        return json({ orgUsers, projectUsers, projects })
      } catch (error) {
        if (isApiError(error) && error.type === 'data_query') {
          throw json(
            {
              message: `We had issues loading the settings information for project ${projectId}`,
              description: `Please refresh the page and try again. If the problem persists, please contact support.`,
            },
            { status: 400 },
          )
        }
        throw error
      }
    })
}

export function action(queryClient: QueryClient) {
  return async ({ request, params }: ActionFunctionArgs) => {
    const data = await getFormData(request)
    const projectId = getProjectIdFromParams(params)
    if (!projectId) {
      notify.error('No project has been selected.')
      return json({})
    }

    try {
      const { message } = await apiClient.projects.addUserToProject({
        ...data,
        projectId: projectId,
      })
      notify.success(message)

      await queryClient.invalidateQueries(usersFromProjectQuery(projectId, true))

      return json({})
    } catch (error) {
      if (isZodError<AddUserData>(error)) {
        return json({ fields: data, errors: error.flatten() }, { status: 400 })
      }
      return handleDataMutationError(error)
    }
  }
}

export default function Settings() {
  return (
    <>
      <HeadTag tag="title" headId="title">
        {import.meta.env.MODE !== 'production' ? `(${import.meta.env.VITE_ENVIRONMENT})` : ''}
        Project settings - Gigapipe
      </HeadTag>

      <Box paddingX={{ mobile: 'xxsmall', tablet: 'large', desktop: 'xxlarge' }}>
        <Stack>
          <PageHeader title="Project Settings" />

          <UsersFromProject />
          <AddUserToProject />
        </Stack>
      </Box>
    </>
  )
}

export function ErrorBoundary() {
  const error = useRouteError() as Error

  if (isRouteErrorResponse(error)) {
    return (
      <RouteErrorMessage
        message={error.data.message}
        description={error.data.description}
        goToPath={error.data.goTo?.path}
        goToText={error.data.goTo?.text}
      />
    )
  }

  throw error
}
