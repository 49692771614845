import { memo, type SVGProps } from 'react'

const SvgLogOut = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 28H6.667A2.667 2.667 0 0 1 4 25.333V6.667A2.667 2.667 0 0 1 6.667 4H12m9.333 18.667L28 16m0 0-6.667-6.667M28 16H12"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgLogOut)
export default Memo
