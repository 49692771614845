import { memo, type SVGProps } from 'react'

const SvgClickHouse = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} height="1em" viewBox="0 0 9 8" width="1em" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 7h1v1H0z" fill="red" />
    <path d="M0 0h1v7H0zm2 0h1v8H2zm2 0h1v8H4zm2 0h1v8H6zm2 3.25h1v1.5H8z" fill="#fc0" />
  </svg>
)
const Memo = memo(SvgClickHouse)
export default Memo
