import { useCallback } from 'react'
import {
  createSearchParams,
  type NavigateOptions,
  type To,
  useLocation,
  useNavigate as useReactRouterNavigate,
  useSearchParams,
} from 'react-router-dom'

type NavigateFnOptions = NavigateOptions & {
  /**
   * Create valid URSearchParams from objects and arrays. Use `createSearchParams` from `react-router-dom`
   */
  searchParams?: Record<string, string | string[]>
}

/**
 * Custom wrapper arround ReactRouter's useNavigate hook
 *
 * It accepts a keepProjectSearchParam that always will try to append the project search param to the url to navigate TO.
 */
export function useNavigate({ keepProjectSearchParam } = { keepProjectSearchParam: true }) {
  const innerNavigate = useReactRouterNavigate()
  const [currentSearchParams] = useSearchParams()

  const project = currentSearchParams.get('project')

  const { pathname } = useLocation()
  /**
   * Custom implementation of the navigate function to keep the project search param and inject extra params from the options field.
   */
  const navigate = useCallback(
    (to: To | number, { searchParams, ...rest }: NavigateFnOptions = {}) => {
      if (typeof to === 'number') innerNavigate(to)
      else {
        const projectSearchParam = keepProjectSearchParam && project ? { project } : undefined
        const searchParamsString = createSearchParams({
          ...projectSearchParam,
          ...searchParams,
        }).toString()
        const options = rest
        if (options.state) {
          options.state.from = pathname
        } else {
          options.state = {
            from: pathname,
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        const innerTo = searchParamsString ? `${to}?${searchParamsString}` : to
        innerNavigate(innerTo, options)
      }
    },
    [innerNavigate, keepProjectSearchParam, project, pathname],
  )

  return navigate
}
