import { type ReactNode } from 'react'

import { Box } from '../Box'
import { Stack } from '../Stack'
import { Text } from '../Text'

export type PageHeaderProps = {
  /**
   * The page title to display
   */
  title: ReactNode
  /**
   * The page subtitle to display
   */
  subtitle?: ReactNode
}

/**
 * A component used to display the page header with title (h1) and subtitle (h2)
 */
export const PageHeader = ({ title, subtitle }: PageHeaderProps) => (
  <Stack align="center">
    <Box maxWidth="small">
      <Text as="h1" size="xxlarge" align="center" weight="light" lineHeight={'medium'}>
        {title}
      </Text>
      {Boolean(subtitle) && (
        <Text as="h2" size="small" align="center" lineHeight={'medium'}>
          {subtitle}
        </Text>
      )}
    </Box>
  </Stack>
)
