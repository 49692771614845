//import { useQuery } from '@tanstack/react-query'

import { ManageHepicTokens } from '~/features/manageHepicTokens'

//import { hepicConfigQuery } from '~/entities/integrations/hepic'
import { useProjectIdParam } from '~/entities/projects'

export default function HepicAuth() {
  const { projectId, projectSlug } = useProjectIdParam()
  // const { data: config } = useQuery(hepicConfigQuery(projectId))

  return <ManageHepicTokens projectId={projectId} projectSlug={projectSlug} />
}
