import { Button } from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Close } from '~/components/Icons'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import { type Notification } from './Notifications.types'
import { notify } from './notify'
import { useNotificationsStore } from './store'

import * as styles from './Notifications.css'

export const NotificationsContainer = () => {
  const notifications = useNotificationsStore(state => state.notifications)
  const pausedAt = useNotificationsStore(state => state.pausedAt)
  const startPause = useNotificationsStore(state => state.startPause)
  const endPause = useNotificationsStore(state => state.endPause)

  const handleNotificationClick = (notification: Notification) => {
    notification.onPress?.()
    notify.dismiss(notification.id)
  }

  const handleCloseClick = (notification: Notification) => {
    notification.onClose?.()
    notify.dismiss(notification.id)
  }

  return (
    <div
      className={styles.container}
      onMouseEnter={() => startPause(Date.now())}
      onMouseLeave={() => {
        if (pausedAt) endPause(Date.now())
      }}
    >
      {notifications.map(notification => (
        <div
          key={notification.id}
          role={notification.onPress ? 'button' : 'status'}
          aria-live="polite"
          aria-hidden={!notification.visible}
          tabIndex={0}
          className={styles.notification({
            type: notification.type,
            isVisible: !!notification.visible,
            hasOnPressHandler: notification.onPress !== undefined,
          })}
          title={notification.message}
          onClick={() => handleNotificationClick(notification)}
          onKeyPress={() => handleNotificationClick(notification)}
        >
          <Stack space="small">
            <Text as="p" weight="bold" size="large">
              {notification.message}
            </Text>
          </Stack>
          <div className={styles.buttonWrapper}>
            <Button
              size="small"
              color="transparent"
              onPress={() => handleCloseClick(notification)}
              aria-label="dismiss notification"
            >
              <Icon size="large">
                <Close />
              </Icon>
            </Button>
          </div>
        </div>
      ))}
    </div>
  )
}
