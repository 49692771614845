import { Link } from '~/components/Link'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

export default function NotFound() {
  return (
    <Stack direction="vertical" align="center" justify="center">
      <Text size="xxlarge">Nothing to see here! 😢</Text>
      <Link to="/" type="button" buttonColor="brand">
        Go to the home page
      </Link>
    </Stack>
  )
}
