import { memo, type SVGProps } from 'react'

const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.667 20H5.333a2.667 2.667 0 0 1-2.666-2.667v-12a2.667 2.667 0 0 1 2.666-2.666h12A2.667 2.667 0 0 1 20 5.333v1.334M14.667 12h12a2.667 2.667 0 0 1 2.666 2.667v12a2.667 2.667 0 0 1-2.666 2.666h-12A2.667 2.667 0 0 1 12 26.667v-12A2.667 2.667 0 0 1 14.667 12Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgCopy)
export default Memo
