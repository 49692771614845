import { transformSnakeObjectKeysToCamel } from 'plunger'
import { z } from 'zod'

// Constants
export const hepicUserGroups = ['admin', 'user'] as const

// Schemas for fetched requests
export const hepicPricingPlanSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    /**
     * Internal id of the pricing plan.
     */
    id: z.string(),
    /**
     * The `name - description` of the pricing plan.
     */
    description: z.string(),
    /**
     * The price in USD.
     */
    price: z.number().transform(price => price / 100),
  }),
)

export const hepicCloudInstanceSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    /**
     * Internal id of the ClickHouse cluster available in this cloud instance.
     */
    clickhouseClusterId: z.coerce.number(),
    /**
     * The region code from the provider.
     */
    regionCode: z.string(),
    /**
     *
     * Region specific name
     */
    regionName: z.string(),
    /**
     * Name of the continent where the instance is located.
     *
     * Use it to display in the UI.
     */
    continent: z.string(),
  }),
)

export const hepicUserSchema = z.preprocess(
  (data: any) => data,
  z.object({
    email: z.string(),
    subdomain_prefix: z.string(),
    group: z.enum([...hepicUserGroups, 'superadmin']),
    user_id: z.coerce.number(),
  }),
)

export const hepicConfigSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    id: z.coerce.number(),
    projectId: z.coerce.string(),
    clickhouseClusterId: z.coerce.number(),
    apiKey: z.string(),
    ingressUrl: z.string(),
    url: z.string().url(),
    subdomainPrefix: z.string(),
    hepicMetricsEnabled: z.boolean(),
    autoAdd: z.boolean().optional(),
  }),
)

// Schemas for submit requests
export const hepicEnableSchema = z.object({
  projectId: z.string().min(1, { message: 'You must be in the context of a project' }),
  priceId: z.string().min(1, { message: 'Please select a pricing plan.' }),
  clusterId: z.coerce.number(),
})
export type HepicEnableData = z.infer<typeof hepicEnableSchema>

export const hepicAddUsersSchema = z.object({
  projectId: z.string(),
  users: z.array(
    z.object({
      user_id: z.coerce.number(),
      group: z.enum(hepicUserGroups),
    }),
  ),
})
export type HepicAddUsersData = z.infer<typeof hepicAddUsersSchema>

export const hepicUpdateUserRoleSchema = z.object({
  projectId: z.string().min(1, { message: 'You must be in the context of a project' }),
  users: z.array(
    z.object({
      // now it should use user_id instead of email
      user_id: z.coerce.number(),
      group: z.enum(hepicUserGroups),
    }),
  ),
})
export type HepicUpdateUserRoleData = z.infer<typeof hepicUpdateUserRoleSchema>

export const hepicRemoveUsersSchema = z.object({
  projectId: z.string().min(1, { message: 'You must be in the context of a project' }),
  users: z.array(
    z.object({
      user_id: z.coerce.number(),
    }),
  ),
})
export type HepicRemoveUsersData = z.infer<typeof hepicRemoveUsersSchema>

export const hepicCreateTokenDataSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    id: z.number(),
    projectId: z.number(),
    token: z.string(),
    name: z.string(),
    integrationType: z.string(),
    status: z.string(),
    scope: z.string(),
    creatorUserId: z.number(),
    cacheEnabled: z.boolean(),
    createdAt: z.string(),
    expires: z.string(),
  }),
)

export type HepicCreateTokenData = z.infer<typeof hepicCreateTokenDataSchema>

export const hepicTokenRequestSchema = z.object({
  projectId: z.coerce.string().min(1, { message: 'You must be in the context of a project' }),
  name: z.string(),
  scope: z.string(),
  ttlDays: z.coerce.number(),
})

export type HepicTokenRequest = z.infer<typeof hepicTokenRequestSchema>
