import { useFetcher } from 'react-router-dom'
import { type Project } from 'api-client'

import { useProjectIdParam } from '~/entities/projects'

import { Loading } from '~/components/Loading'
import { Message } from '~/components/Message'
import { Stack } from '~/components/Stack'
import { Switch } from '~/components/Switch'

import { useQrynCache } from '../model'

type ManageQrynCacheSwitcherProps = {
  projectId: Project['id']
  projectSlug: Project['slug']
}

export function ManageQrynCacheSwitcher({ projectId }: ManageQrynCacheSwitcherProps) {
  const { projectSlug } = useProjectIdParam()
  const fetcher = useFetcher()

  const { cacheEnabled, handleCacheToggle } = useQrynCache({ projectSlug, projectId })

  return (
    <Stack space="small">
      <Switch
        isSelected={cacheEnabled}
        isDisabled={fetcher.state !== 'idle'}
        onChange={(enabled: boolean) => {
          handleCacheToggle(enabled)
        }}
      >
        {fetcher.state === 'submitting' && <Loading />}
        qryn cache
      </Switch>

      <Message type="warning" size="small">
        This option allows you to enable/disable cache usage on the server side
      </Message>
    </Stack>
  )
}
