/**
 * Returns the project slug extracted from the given path.
 *
 * @param {string} path - String containing the project slug.
 * @return {{ projectSlug: string, remainingPath: string }} The project slug extracted from the project slug and whatever path is remaining as in `/:project-slug/stack `, or an
 * empty string in both properties if the project slug is undefined or empty.
 */
export function getProjectSlugFromPath(path: string) {
  const splitPath = path.split('/')
  // splitPath[0] will always be an empty string because path is provided starting with a slash
  if (
    splitPath.length > 1 &&
    splitPath[1] &&
    typeof splitPath[1] === 'string' &&
    /\w+-\d+/.test(splitPath[1])
  ) {
    return { projectSlug: splitPath[1], remainingPath: splitPath.slice(2).join('/') }
  } else if (splitPath.length > 1 && splitPath[1]) {
    return { projectSlug: '', remainingPath: path }
  } else {
    return { projectSlug: '', remainingPath: '' }
  }
}
