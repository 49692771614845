import { memo, type SVGProps } from 'react'

const SvgSun = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 1.333V4m0 24v2.667M5.627 5.627 7.52 7.52m16.96 16.96 1.893 1.893M1.333 16H4m24 0h2.667M5.627 26.373 7.52 24.48M24.48 7.52l1.893-1.893M22.667 16a6.667 6.667 0 1 1-13.334 0 6.667 6.667 0 0 1 13.334 0Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgSun)
export default Memo
