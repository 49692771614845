import { useToggle } from '~/shared/lib/useToogle'

import { Box } from '../Box'
import { Eye, EyeOff } from '../Icons'
import { Input, type InputProps } from '../Input'

import * as styles from './Password.css'

const ToggleIcon = ({ toggle, isVisible }: { toggle: () => void; isVisible: boolean }) => (
  <Box className={styles.iconWrapper} onClick={() => toggle()} data-testid="togglePassword">
    {isVisible ? <EyeOff /> : <Eye />}
  </Box>
)

/**
 * Component used to display an input with a password.
 */
export const Password = (props: InputProps) => {
  const [isVisible, toggle] = useToggle(false)

  return (
    <Input
      {...props}
      type={isVisible ? 'text' : 'password'}
      suffix={<ToggleIcon isVisible={isVisible} toggle={toggle} />}
    />
  )
}
