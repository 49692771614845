import { useCallback, useState } from 'react'

/**
 * Custom hook to manage a boolean value and a toggle function to update it
 */
export function useToggle(initialValue = false) {
  const [state, setState] = useState(initialValue)

  const toggle = useCallback(
    (newState?: boolean) => (newState ? setState(newState) : setState(prevState => !prevState)),
    [],
  )

  return [state, toggle] as const
}
