import { useEffect, useState } from 'react'
import { useHover } from 'react-aria'
import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'
import { type QrynMetricsSchemaData } from 'api-client/integrations/qryn/model'
import dayjs from 'dayjs'

import { qrynMetricsQuery } from '~/entities/integrations/qryn'

import { formatSize } from '~/shared/lib/formatSize'
import { Separator } from '~/shared/ui/Separator'

import { formatLargeNumber, formatNumber } from '~/i18n/utils'

import { Box } from '~/components/Box'
import { Card } from '~/components/Card'
import { Grid } from '~/components/Grid'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import { type TimeRanges, timeRanges } from '../model'

type QrynMetricsProps = {
  projectId: Project['id']
}

export function calcPercent(item: number, total: number) {
  return Math.round((item / total) * 100)
}

export function useRetainingData({ metrics }: { metrics: QrynMetricsSchemaData }) {
  const [oldestDate, setOldestDate] = useState<any>(0)

  const getLastDateInRange = (date1: Date, date2: Date) => {
    const d1 = dayjs(date1)
    const d2 = dayjs(date2)

    if (d1.isBefore(d2)) {
      return date1
    } else {
      return date2
    }
  }

  useEffect(() => {
    const dataLogs = metrics?.retaningDataLogs
    const dataTraces = metrics?.retaningDataTraces

    const lastDate = getLastDateInRange(
      new Date(dataLogs ?? Date.now()),
      new Date(dataTraces ?? Date.now()),
    )
    setOldestDate(lastDate)
  }, [metrics])

  return {
    oldestDate,
    rotationPolicyTtl: metrics?.rotationPolicyTtl,
    sizeLimit: metrics?.sizeLimit,
    dataBytes: metrics?.dataBytes,
    tracesRows: metrics?.tracesRows,
    tracesBytes: metrics?.tracesBytes,
    metricsRows: metrics?.metricsRows,
    metricsBytes: metrics?.metricsBytes,
    logsRows: metrics?.logsRows,
    logsBytes: metrics?.logsBytes,
    fingerprints: metrics?.fingerprints,
    retaningDataLogs: metrics?.retaningDataLogs,
    retaningDataTraces: metrics?.retaningDataTraces,
  }
}

export function QrynMetrics({ projectId }: QrynMetricsProps) {
  const [timeRange] = useState<TimeRanges>('currentMonth')
  const {
    data: metrics,
    isPreviousData,
    isError,
    isFetching,
  } = useQuery({
    ...qrynMetricsQuery(projectId, timeRanges[timeRange].start, timeRanges[timeRange].end),
    keepPreviousData: true,
  })

  const { hoverProps: hoverTracesProps, isHovered: isTracesHover } = useHover({})
  const { hoverProps: hoverLogsProps, isHovered: isLogsHovered } = useHover({})
  const qrynMetrics = metrics!

  const {
    oldestDate,
    //rotationPolicyTtl,
    sizeLimit,
    dataBytes,
    tracesRows,
    tracesBytes,
    metricsRows,
    metricsBytes,
    logsRows,
    logsBytes,
    fingerprints,
  } = useRetainingData({ metrics: qrynMetrics })

  if (!metrics && isError)
    return (
      <Card>
        <Card.Section>
          <Stack direction="horizontal" align="center">
            <Text size="large">Injestion Stats</Text>
          </Stack>
          <Box marginY="medium">
            <Text>Injestion Stats not currently available</Text>
          </Box>
        </Card.Section>
      </Card>
    )
  return (
    <Card>
      <Card.Section>
        <Box marginY="small">
          <Stack direction="vertical" align="center">
            <Text size="xlarge">Ingestion Stats</Text>
          </Stack>
        </Box>

        {/* Previous data indicator  */}
        {qrynMetrics?.bytesCount !== null ? (
          <Box marginY="medium" opacity={isPreviousData || isFetching ? '50' : '100'}>
            <Grid columns={{ mobile: 1, tablet: 2, desktop: 4 }}>
              <Grid.Item>
                <Stack direction="vertical" align="center" space="small">
                  <Text size="xxlarge">
                    {formatSize(metrics?.bytesCount ?? 0, { binary: false })
                      .toLocaleString()
                      .split(',')
                      .join(' ')}
                  </Text>
                  <Text align="center">Ingested</Text>
                </Stack>
              </Grid.Item>
              <Grid.Item>
                <Stack direction="vertical" align="center" space="small">
                  <div {...hoverTracesProps}>
                    <Text size="xxlarge">
                      {isTracesHover
                        ? formatNumber(metrics?.tracesCount ?? 0)
                            .toLocaleString()
                            .split(',')
                            .join(' ')
                        : formatLargeNumber(metrics?.tracesCount ?? 0)}
                    </Text>
                  </div>
                  <Text align="center">Traces</Text>
                </Stack>
              </Grid.Item>
              <Grid.Item>
                <Stack direction="vertical" align="center" space="small">
                  <Text size="xxlarge">
                    {formatNumber(metrics?.uniqueMetricSeriesCount ?? 0)
                      .toLocaleString()
                      .split(',')
                      .join(' ')}
                  </Text>
                  <Text align="center">Total unique fingerprints</Text>
                </Stack>
              </Grid.Item>
              <Grid.Item>
                <Stack direction="vertical" align="center" space="small">
                  <div {...hoverLogsProps}>
                    <Text size="xxlarge">
                      {isLogsHovered
                        ? formatNumber(metrics?.metricsAndLogsCount ?? 0)
                            .toLocaleString()
                            .split(',')
                            .join(' ')
                        : formatLargeNumber(metrics?.metricsAndLogsCount ?? 0)}
                    </Text>
                  </div>
                  <Text align="center">Metrics & logs</Text>
                </Stack>
              </Grid.Item>
            </Grid>
          </Box>
        ) : (
          <>
            <Box marginY="large" opacity={isPreviousData || isFetching ? '50' : '100'}>
              {/* Same items but with metricsSChema */}
              <Grid columns={{ mobile: 1, tablet: 1, desktop: 4 }}>
                {/* <Grid.Item>
                <Stack direction="vertical" align="center" space="xxsmall">
                  <Text size="xlarge">{rotationPolicyTtl} Days</Text>
                  <Text size="small" align="left">
                    Rotation policy
                  </Text>
                </Stack>
              </Grid.Item> */}
                <Grid.Item>
                  <Stack direction="vertical" align="center" space="xxsmall">
                    <Text size="xlarge">{dayjs(oldestDate).format('DD MMM YYYY')}</Text>
                    <Text size="small" align="center">
                      Rotation Point
                    </Text>
                  </Stack>
                </Grid.Item>
                <Grid.Item>
                  <Stack direction="vertical" align="center" space="xxsmall">
                    <Text size="xlarge">
                      {sizeLimit !== 0
                        ? formatSize(sizeLimit ?? 0, {
                            binary: false,
                            showUnit: true,
                          })
                            .toLocaleString()
                            .split(',')
                            .join('.')
                        : 'Unlimited'}
                    </Text>
                    <Text size="small" align="left">
                      Storage Quota
                    </Text>
                  </Stack>
                </Grid.Item>

                <Grid.Item>
                  <Stack direction="vertical" align="center" space="xxsmall">
                    <Text size="xlarge">
                      {formatSize(dataBytes ?? 0, {
                        binary: false,
                        showUnit: true,
                      })
                        .toLocaleString()
                        .split(',')
                        .join('.')}
                    </Text>
                    <Text size="small" align="center">
                      Ingested Data
                    </Text>
                  </Stack>
                </Grid.Item>
                <Grid.Item>
                  <Stack direction="vertical" align="center" space="xxsmall">
                    <Text size="xlarge">{fingerprints?.toLocaleString().split('.').join(' ')}</Text>
                    <Text size="small" align="center">
                      Fingerprints
                    </Text>
                  </Stack>
                </Grid.Item>
              </Grid>
            </Box>
            <Separator />{' '}
            <Box marginY="medium" opacity={isPreviousData || isFetching ? '50' : '100'}>
              <Grid columns={{ mobile: 1, tablet: 2, desktop: 3 }}>
                <Grid.Item>
                  <Stack direction="vertical" align="center" space="xxsmall">
                    <Text size="xlarge">Logs</Text>
                  </Stack>
                </Grid.Item>
                <Grid.Item>
                  <Stack direction="vertical" align="center" space="xxsmall">
                    <Text size="xlarge">Metrics</Text>
                  </Stack>
                </Grid.Item>
                <Grid.Item>
                  <Stack direction="vertical" align="center" space="xxsmall">
                    <Text size="xlarge">Traces</Text>
                  </Stack>
                </Grid.Item>

                {/* Total logs count */}
                <Grid.Item>
                  <Stack direction="vertical" align="center" space="xxsmall">
                    <Text size="xlarge">{logsRows?.toLocaleString().split('.').join(' ')}</Text>
                  </Stack>
                </Grid.Item>
                {/* Total metrics count */}
                <Grid.Item>
                  <Stack direction="vertical" align="center" space="xxsmall">
                    <Text size="xlarge">{metricsRows?.toLocaleString().split('.').join(' ')}</Text>
                  </Stack>
                </Grid.Item>
                {/* Total traces count */}
                <Grid.Item>
                  <Stack direction="vertical" align="center" space="xxsmall">
                    <Text size="xlarge">{tracesRows?.toLocaleString().split('.').join(' ')}</Text>
                  </Stack>
                </Grid.Item>

                {/* Total Logs Stored */}
                <Grid.Item>
                  <Stack direction="vertical" align="center" space="xxsmall">
                    <Text size="xlarge">
                      {formatSize(logsBytes ?? 0, {
                        binary: false,
                        showUnit: true,
                      })
                        .toLocaleString()
                        .split(',')
                        .join('.')}{' '}
                      - {calcPercent(Number(logsBytes), Number(dataBytes))}%
                    </Text>
                  </Stack>
                </Grid.Item>

                {/* Total Metrics Stored */}
                <Grid.Item>
                  <Stack direction="vertical" align="center" space="xxsmall">
                    <Text size="xlarge">
                      {formatSize(metricsBytes ?? 0, {
                        binary: false,
                        showUnit: true,
                      })
                        .toLocaleString()
                        .split(',')
                        .join('.')}{' '}
                      - {calcPercent(Number(metricsBytes), Number(dataBytes))}%
                    </Text>
                  </Stack>
                </Grid.Item>

                {/* Total Traces Stored */}
                <Grid.Item>
                  <Stack direction="vertical" align="center" space="xxsmall">
                    <Text size="xlarge">
                      {formatSize(tracesBytes ?? 0, {
                        binary: false,
                        showUnit: true,
                      })
                        .toLocaleString()
                        .split(',')
                        .join('.')}{' '}
                      - {calcPercent(Number(tracesBytes), Number(dataBytes))}%
                    </Text>
                  </Stack>
                </Grid.Item>
              </Grid>
            </Box>
          </>
        )}
      </Card.Section>
    </Card>
  )
}
