import {
  isRouteErrorResponse,
  json,
  type LoaderFunctionArgs,
  Outlet,
  redirect,
  useRouteError,
} from 'react-router-dom'
import { type QueryClient, useQuery } from '@tanstack/react-query'
import { type HepicAddUsersData, type HepicUpdateUserRoleData, isZodError } from 'api-client'
import {
  type HepicCreateTokenData,
  type HepicRemoveUsersData,
} from 'api-client/integrations/hepic/model'
import { authorize } from 'keycloak'
import { z } from 'zod'

import { notify } from '~/features/notifications'

import {
  CANCEL_SUBSCRIPTION,
  checkIntegrationStatus,
  IntegrationName,
  integrationsCancellationDateQuery,
  integrationsQuery,
  IntegrationStatus,
} from '~/entities/integrations/base'
import {
  ADD_HEPIC_USER,
  CANCEL_UPDATE_HEPIC_USER_ROLE,
  CREATE_HEPIC_TOKEN,
  hepicConfigQuery,
  hepicTokenQuery,
  hepicUsersQuery,
  REMOVE_HEPIC_USER,
  TOGGLE_HEPIC_AUTO_ADD_USERS,
  TOGGLE_HEPIC_METRIC,
  UPDATE_HEPIC_USER_ROLE,
} from '~/entities/integrations/hepic'
import { HepicNavBar } from '~/entities/integrations/hepic/ui/HepicNavBar'
import {
  checkProjectSlugParam,
  getProjectIdFromParams,
  useProjectIdParam,
  usersFromProjectQuery,
} from '~/entities/projects'

import { apiClient, handleDataMutationError } from '~/shared/api'
import { getFormData } from '~/shared/lib/parseRequest'
import { authStore } from '~/shared/model/auth'
import { isApiError } from '~/shared/model/errors'
import { RouteErrorMessage } from '~/shared/ui/RouteErrorMessage'

import { formatDate } from '~/i18n/utils'

import { Box } from '~/components/Box'
import { HeadTag } from '~/components/HeadTag'
import { Message } from '~/components/Message'
import { Stack } from '~/components/Stack'

const SLUG = 'hepic'

export function loader(queryClient: QueryClient) {
  return async ({ params }: LoaderFunctionArgs) =>
    authorize(authStore, async () => {
      const { projectId, projectSlug } = checkProjectSlugParam(params)

      const decodedToken = authStore.getState()?.decodedToken
      const integration = await checkIntegrationStatus(queryClient, {
        projectId,
        projectSlug,
        slug: SLUG,
      })
      try {
        const [config, hepicUsers, usersInProject] = await Promise.all([
          queryClient.ensureQueryData(hepicConfigQuery(projectId)),
          decodedToken?.organization_id
            ? queryClient.ensureQueryData(hepicUsersQuery(projectId, true))
            : undefined,
          decodedToken?.organization_id
            ? queryClient.ensureQueryData(usersFromProjectQuery(projectId, true))
            : undefined,
        ])

        return json({ integration, config, hepicUsers, usersInProject })
      } catch (error) {
        if (isApiError(error) && error.type === 'data_query') {
          throw json(
            {
              message: `We had issues loading the configuration of the Hepic integration for project ${projectId}`,
              description: `Please go to the Stack page and try again. If the problem persists, please contact support.`,
              goTo: {
                path: `/${projectSlug}/stack`,
                text: 'Go to Stack',
              },
            },
            { status: 400 },
          )
        }
        throw error
      }
    })
}

export function action(queryClient: QueryClient) {
  return async ({ request, params }: LoaderFunctionArgs) => {
    const data = await getFormData(request)
    const projectId = getProjectIdFromParams(params)
    if (!projectId) {
      notify.error('No project has been selected.')
      return json({})
    }

    const intents = z
      .enum([
        ADD_HEPIC_USER,
        CANCEL_SUBSCRIPTION,
        UPDATE_HEPIC_USER_ROLE,
        CANCEL_UPDATE_HEPIC_USER_ROLE,
        REMOVE_HEPIC_USER,
        TOGGLE_HEPIC_METRIC,
        TOGGLE_HEPIC_AUTO_ADD_USERS,
        CREATE_HEPIC_TOKEN,
      ])
      .safeParse(data.intent)

    if (!intents.success) {
      throw json(
        {
          message: 'This operation is not valid',
          description:
            'You tried to perform an invalid operation. Nothing will be changed regarding your integrations. Please reload the page and try again.',
        },
        { status: 400 },
      )
    }

    switch (intents.data) {
      case 'ADD_HEPIC_USER': {
        try {
          await apiClient.hepic.addHepicUsers({ ...data, projectId: projectId })
          await queryClient.invalidateQueries(hepicUsersQuery(projectId, true))
          notify.success('User has been successfully added to HEPIC')
          return json({})
        } catch (error) {
          if (isZodError<HepicAddUsersData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
      case 'CANCEL_SUBSCRIPTION': {
        try {
          const { message } = await apiClient.integrations.cancelSubscription({
            projectId: projectId,
            integration: SLUG,
          })
          await queryClient.invalidateQueries(integrationsQuery(projectId))
          notify.success(message)
          return redirect(`/${params.projectSlug}/stack`)
        } catch (error) {
          return handleDataMutationError(error)
        }
      }
      case 'UPDATE_HEPIC_USER_ROLE': {
        if (!data.hasConfirmed) {
          return json({
            fields: data,
            action: 'confirm',
          })
        }

        try {
          await apiClient.hepic.updateHepicUserRole({ ...data, projectId: projectId })
          await queryClient.invalidateQueries(hepicUsersQuery(projectId, true))
          notify.success('The role has been updated')
          return json({})
        } catch (error) {
          if (isZodError<HepicUpdateUserRoleData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
      case 'CANCEL_UPDATE_HEPIC_USER_ROLE': {
        return json({
          fields: data,
          action: 'confirm',
        })
      }
      case 'REMOVE_HEPIC_USER': {
        try {
          await apiClient.hepic.removeHepicUsers({ ...data, projectId: projectId })
          await queryClient.invalidateQueries(hepicUsersQuery(projectId, true))
          notify.success('User has been successfully deleted from HEPIC')
          return json({})
        } catch (error) {
          if (isZodError<HepicRemoveUsersData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
      case 'TOGGLE_HEPIC_METRIC': {
        try {
          const { message } = await apiClient.hepic.toggleHepicMetric(projectId)
          await queryClient.invalidateQueries(hepicConfigQuery(projectId))
          notify.success(message)
          return json({})
        } catch (error) {
          if (isZodError<HepicRemoveUsersData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
      case 'TOGGLE_HEPIC_AUTO_ADD_USERS': {
        try {
          const { message } = await apiClient.hepic.toggleHepicAutoAddUsers(projectId)
          await queryClient.invalidateQueries(hepicConfigQuery(projectId))
          notify.success(message)
          return json({})
        } catch (error) {
          if (isZodError<HepicRemoveUsersData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
      case 'CREATE_HEPIC_TOKEN': {
        try {
          const { message } = await apiClient.hepic.addHepicToken(data)
          await queryClient.invalidateQueries(hepicTokenQuery(projectId))
          notify.success(message)
          return json({})
        } catch (error) {
          if (isZodError<HepicCreateTokenData>(error)) {
            return json({ fileds: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
    }
  }
}

export default function Hepic() {
  const { projectId } = useProjectIdParam()
  const { data: integration } = useQuery({
    ...integrationsQuery(projectId),
    select: data => data.find(i => i.product.slug === SLUG),
  })
  const { data: cancellationDate, error } = useQuery(
    integrationsCancellationDateQuery(projectId, SLUG, integration?.status),
  )

  const isScheduledForCancellation = integration?.status === 'scheduled for cancellation'

  if (!integration) return null
  return (
    <Stack direction="vertical">
      <HeadTag tag="title" headId="title">
        {import.meta.env.MODE !== 'production' ? `(${import.meta.env.VITE_ENVIRONMENT}) ` : ''}
        {integration.product.name} - Gigapipe
      </HeadTag>
      <Stack space="small">
        <Stack direction="horizontal">
          <IntegrationName name={integration.product.name} slug={integration.product.slug} />
        </Stack>
        <Box>
          <IntegrationStatus
            projectId={projectId}
            slug={integration.product.slug}
            status={integration?.status ?? 'active'}
          />
        </Box>
        {isScheduledForCancellation && (
          <Message size="small" type="info">
            This integration is scheduled for cancellation on the{' '}
            {cancellationDate
              ? formatDate(cancellationDate.cancellationDateTimestamp, undefined, {
                  dateStyle: 'medium',
                  timeStyle: 'short',
                })
              : error
              ? 'not available.'
              : 'loading...'}
          </Message>
        )}
      </Stack>
      <Stack direction="vertical">
        <HepicNavBar />
        <Outlet />
      </Stack>
    </Stack>
  )
}

export function ErrorBoundary() {
  const error = useRouteError() as Error

  if (isRouteErrorResponse(error)) {
    return (
      <RouteErrorMessage
        message={error.data.message}
        description={error.data.description}
        goToPath={error.data.goTo?.path}
        goToText={error.data.goTo?.text}
      />
    )
  }

  throw error
}
