import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { useProjectIdParam } from '~/entities/projects'

import { Link } from '~/components/Link'

import { clickHouseClustersQuery } from '../queries'

import * as styles from './ClustersNavBar.css'

export function ClustersNavBar() {
  const { projectId } = useProjectIdParam()
  const { clusterId } = useParams<'clusterId'>()
  const { data: clusters } = useQuery({
    ...clickHouseClustersQuery(projectId),
    select: data => data.filter(cl => cl.status !== 'deleting'),
  })

  if (!clusters?.length || !clusterId) return null
  return (
    <div className={styles.wrapper}>
      {clusters.map(cluster => (
        <Link
          key={cluster.id}
          to={`cluster/${cluster.id}`}
          className={styles.link({ isActive: cluster.id === clusterId })}
        >
          {cluster.name}
        </Link>
      ))}
    </div>
  )
}
