import { memo, type SVGProps } from 'react'

const SvgKafka = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.036 17.293c-.42 0-.837.095-1.22.279a2.972 2.972 0 0 0-1.002.786l-1.392-1.041c.148-.43.233-.891.233-1.374a4.214 4.214 0 0 0-.225-1.351l1.39-1.03c.276.333.617.6 1 .782.382.183.797.278 1.217.278a2.892 2.892 0 0 0 2.093-.918 3.226 3.226 0 0 0 .87-2.21 3.226 3.226 0 0 0-.87-2.212 2.892 2.892 0 0 0-2.093-.918 2.892 2.892 0 0 0-2.094.918 3.226 3.226 0 0 0-.87 2.211c0 .31.045.6.124.889l-1.39 1.03a3.794 3.794 0 0 0-2.37-1.455v-1.77a2.948 2.948 0 0 0 1.69-1.09 3.245 3.245 0 0 0 .664-1.971 3.225 3.225 0 0 0-.871-2.21A2.891 2.891 0 0 0 12.827 4a2.892 2.892 0 0 0-2.094.918 3.226 3.226 0 0 0-.87 2.211c0 1.485.986 2.728 2.302 3.047v1.8C10.369 12.3 9 13.955 9 15.944c0 1.998 1.383 3.66 3.191 3.98v1.893c-1.328.3-2.327 1.56-2.327 3.054.002.83.314 1.624.87 2.21a2.892 2.892 0 0 0 2.093.919 2.892 2.892 0 0 0 2.094-.918 3.226 3.226 0 0 0 .87-2.211c0-1.495-.999-2.745-2.328-3.054v-1.892a3.788 3.788 0 0 0 2.33-1.43l1.401 1.047c-.08.286-.12.582-.121.88.001.83.314 1.624.87 2.21a2.892 2.892 0 0 0 2.093.919 2.892 2.892 0 0 0 2.094-.918 3.226 3.226 0 0 0 .87-2.211 3.226 3.226 0 0 0-.87-2.21 2.892 2.892 0 0 0-2.094-.919Zm0-7.316c.792 0 1.436.681 1.436 1.518 0 .836-.644 1.516-1.436 1.516-.792 0-1.436-.68-1.436-1.516s.644-1.518 1.436-1.518ZM11.391 7.13c0-.836.644-1.516 1.437-1.516.792 0 1.436.68 1.436 1.516s-.644 1.517-1.436 1.517c-.792 0-1.437-.68-1.437-1.517Zm2.872 17.743c0 .837-.644 1.517-1.436 1.517-.792 0-1.437-.68-1.437-1.517 0-.836.644-1.516 1.437-1.516s1.436.68 1.436 1.516Zm-1.437-6.811c-1.105 0-2.003-.949-2.003-2.115 0-1.166.899-2.115 2.003-2.115 1.105 0 2.003.949 2.003 2.115 0 1.166-.898 2.115-2.003 2.115Zm7.21 3.879c-.793 0-1.437-.681-1.437-1.517 0-.836.644-1.517 1.436-1.517.792 0 1.436.68 1.436 1.517 0 .836-.644 1.517-1.436 1.517Z"
      fill="#000"
    />
  </svg>
)
const Memo = memo(SvgKafka)
export default Memo
