import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { loggenConfigQuery } from '~/entities/integrations/loggen'

import { Button } from '~/components/Button'
import { IconButton } from '~/components/IconButton'
import { XCircle as Delete } from '~/components/Icons'
import { Input } from '~/components/Input'
import { Message } from '~/components/Message'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

type LoggenConfigLabelsFieldProps = {
  projectId: Project['id']
}

export function LoggenConfigLabelsField({ projectId }: LoggenConfigLabelsFieldProps) {
  const { data: config } = useQuery(loggenConfigQuery(projectId))
  const [labels, setLabels] = useState<{ id: string; key: string; value: string }[]>([])

  useEffect(() => {
    if (config?.labels)
      setLabels(
        Object.keys(config.labels).map(key => ({
          id: crypto.randomUUID(),
          key,
          value: config.labels[key],
        })),
      )
  }, [config])

  const addLabel = () =>
    setLabels(oldLabels => [
      ...oldLabels,
      {
        id: crypto.randomUUID(),
        key: 'key',
        value: 'value',
      },
    ])

  const updateLabel = (newLabel: { id: string; key: string; value: string }) =>
    setLabels(oldLabels => oldLabels.map(label => (label.id === newLabel.id ? newLabel : label)))

  const removeLabel = (id: string) =>
    setLabels(oldLabels => oldLabels.filter(label => label.id !== id))

  return (
    <>
      <Stack direction="horizontal" justify="between">
        <Text>Labels</Text>
        <Button color="outline" size="small" onPress={() => addLabel()}>
          Add label +
        </Button>
      </Stack>

      {labels.map(label => (
        <Stack key={label.id} direction="horizontal" space="small">
          <input type="hidden" name={`labels.${label.key}`} value={label.value} />
          <div style={{ width: '100%' }}>
            <Input
              placeholder="key"
              aria-label="Label Key"
              defaultValue={label.key}
              onChange={val => updateLabel({ ...label, key: val })}
            />
          </div>
          <div style={{ width: '100%' }}>
            <Input
              placeholder="value"
              aria-label="Label Value"
              defaultValue={label.value}
              onChange={val => updateLabel({ ...label, value: val })}
            />
          </div>
          <IconButton
            aria-label="Delete label"
            color="transparent"
            icon={<Delete />}
            onPress={() => removeLabel(label.id)}
          />
        </Stack>
      ))}

      {!labels.length && (
        <Message size="small" type="muted">
          You need to add at least one label
        </Message>
      )}
    </>
  )
}
