import { type ResponsiveObject, type ResponsiveValue } from '~/styles/sprinkles.css'

import { Box, type BoxProps } from '../Box'

import {
  type Align,
  alignToAlignItems,
  type Direction,
  directionToFlexDirection,
  type Justify,
  justifyToJustifyContent,
  type Wrap,
  wrapToFlexWrap,
} from './utils'

export type StackProps = {
  /** Control the HTML element being rendered */
  as?: 'div'
  children: BoxProps['children']
  /**
   * Maps to align-items CSS property
   * @default "stretch"
   */
  align?: ResponsiveValue<Align>
  /**
   * Maps to flex-direction CSS property
   * @default "vertical"
   */
  direction?: ResponsiveValue<Direction>
  /**
   * Maps to flex CSS property
   */
  flex?: BoxProps['flex']
  /**
   * Maps to justify-content CSS property
   * @default "start"
   */
  justify?: ResponsiveValue<Justify>
  /**
   * Changes the direction to {direction}-reverse
   * @default false
   */
  reverse?: boolean
  /**
   * Maps to gap CSS property
   * @default "large"
   */
  space?: BoxProps['gap']
  /**
   * Maps to flex-wrap CSS property
   */
  wrap?: ResponsiveObject<Wrap>
}

export const Stack = ({
  as = 'div',
  children,
  align = 'stretch',
  direction = 'vertical',
  flex,
  justify = 'start',
  reverse = false,
  space = 'large',
  wrap,
}: StackProps) => (
  <Box
    as={as}
    display="flex"
    flex={flex}
    gap={space}
    alignItems={alignToAlignItems(align)}
    flexDirection={directionToFlexDirection(direction, reverse)}
    flexWrap={wrapToFlexWrap(wrap)}
    justifyContent={justifyToJustifyContent(justify)}
  >
    {children}
  </Box>
)
