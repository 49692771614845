import { type ReactNode, useRef } from 'react'
import {
  type AriaMenuItemProps,
  type AriaMenuProps,
  type AriaMenuTriggerProps,
  mergeProps,
  useMenu,
  useMenuItem,
  useMenuTrigger,
} from 'react-aria'
import {
  Item,
  type MenuTriggerProps,
  Section,
  type TreeState,
  useMenuTriggerState,
  useTreeState,
} from 'react-stately'
import { type Node } from '@react-types/shared'

import { Button, type ButtonProps } from '~/components/Button'
import { ChevronUpDown } from '~/components/Icons'
import { Popover } from '~/components/Popover'
import { Stack } from '~/components/Stack'

import { Box } from '../Box'
import { Icon, type IconProps } from '../Icon'
import { Text } from '../Text'

import * as styles from './Menu.css'

type BaseProps = {
  icon?: IconProps['children']
} & Pick<ButtonProps, 'color' | 'size'>
type ConditionalProps =
  | {
      label: string
      renderContent?: ReactNode
    }
  | {
      label?: string
      renderContent: ReactNode
    }
export type MenuButtonProps<T> = BaseProps &
  ConditionalProps &
  MenuTriggerProps &
  AriaMenuProps<T> &
  AriaMenuTriggerProps

MenuButton.Item = Item
MenuButton.Section = Section
const defaultIcon = <ChevronUpDown />

export function MenuButton<T extends object>(props: MenuButtonProps<T>) {
  const {
    renderContent,
    label,
    isDisabled,
    trigger,
    color = 'transparent',
    size = 'medium',
    type = 'menu',
    icon = defaultIcon,
  } = props
  const state = useMenuTriggerState(props)

  const ref = useRef<HTMLButtonElement>(null)
  const { menuProps, menuTriggerProps } = useMenuTrigger<T>(
    { isDisabled, type, trigger },
    state,
    ref,
  )

  const triggerWidth = ref.current?.offsetWidth

  return (
    <Box position="relative" display="inline-block">
      <Button
        buttonRef={ref}
        {...menuTriggerProps}
        isDisabled={isDisabled}
        color={color}
        size={size}
        transformToUppercase={false}
      >
        <Stack direction="horizontal" space="small" align="center">
          {renderContent ?? (
            <Text as="span" size="inherit" weight="inherit">
              {label}
            </Text>
          )}
          <div className={styles.triggerIcon}>
            <Icon size="xxlarge" aria-hidden="true">
              {icon}
            </Icon>
          </div>
        </Stack>
      </Button>

      {state.isOpen && (
        <Popover state={state} triggerRef={ref} placement="bottom start" width={triggerWidth}>
          <Menu {...props} {...menuProps} />
        </Popover>
      )}
    </Box>
  )
}

function Menu<T extends object>(props: AriaMenuProps<T>) {
  const { selectionMode = 'none' } = props
  const state = useTreeState<T>({ ...props, selectionMode })

  const ref = useRef<HTMLUListElement>(null)
  const { menuProps } = useMenu<T>(props, state, ref)

  return (
    <ul {...menuProps} ref={ref} className={styles.menu}>
      {[...state.collection].map(item => (
        <MenuItem
          key={item.key}
          item={item}
          state={state}
          closeOnSelect={selectionMode !== 'multiple'}
        />
      ))}
    </ul>
  )
}

type MenuItemProps<T> = AriaMenuItemProps & { state: TreeState<T>; item: Node<T> }

function MenuItem<T extends object>({ item, state, ...rest }: MenuItemProps<T>) {
  const ref = useRef<HTMLLIElement>(null)

  const { menuItemProps, isDisabled, isFocused } = useMenuItem<T>(
    {
      ...rest,
      key: item.key,
    },
    state,
    ref,
  )

  return (
    <li
      {...mergeProps(menuItemProps)}
      className={styles.menuItem({ isFocused, isDisabled })}
      ref={ref}
    >
      {item.rendered}
    </li>
  )
}
