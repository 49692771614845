import { useHover } from 'react-aria'
import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'
import dayjs from 'dayjs'

import { hepicStatisticsQuery } from '~/entities/integrations/hepic'

import { formatSize } from '~/shared/lib/formatSize'

import { formatLargeNumber, formatNumber } from '~/i18n/utils'

import { Box } from '~/components/Box'
import { Card } from '~/components/Card'
import { Grid } from '~/components/Grid'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

type QrynMetricsProps = {
  projectId: Project['id']
}

export function HepicMetrics({ projectId }: QrynMetricsProps) {
  const {
    data: stats,
    isPreviousData,
    isError,
    isFetching,
  } = useQuery({
    ...hepicStatisticsQuery(projectId),
  })

  const { hoverProps: hoverTracesProps, isHovered: isTracesHover } = useHover({})

  const isNewStats = !!stats?.storage_quota

  if (!stats && isError) {
    return (
      <Card>
        <Card.Section>
          <Stack direction="horizontal" align="center">
            <Text size="large">Statistics</Text>
          </Stack>
          <Box marginY="medium">
            <Text>Statistics not currently available</Text>
          </Box>
        </Card.Section>
      </Card>
    )
  } else if (stats && isNewStats) {
    return (
      <Card>
        <Card.Section>
          <Stack direction="horizontal" align="center">
            <Text size="large">Statistics</Text>
          </Stack>
          <Box marginY="medium" opacity={isPreviousData || isFetching ? '50' : '100'}>
            <Grid columns={{ mobile: 1, tablet: 3, desktop: 3 }}>
              <Grid.Item>
                <Stack direction="vertical" align="center" space="xsmall">
                  <Text size="xxlarge">
                    {formatSize(stats?.storage_quota ?? 0, { binary: false })}
                  </Text>
                  <Text align="center">Storage Quota</Text>
                </Stack>
              </Grid.Item>
              <Grid.Item>
                <Stack direction="vertical" align="center" space="xsmall">
                  <div {...hoverTracesProps}>
                    <Text size="xxlarge">
                      {formatSize(stats?.storage_usage_bytes ?? 0, { binary: false })}
                    </Text>
                  </div>
                  <Text align="center">Ingested</Text>
                </Stack>
              </Grid.Item>
              <Grid.Item>
                <Stack direction="vertical" align="center" space="xsmall">
                  <div {...hoverTracesProps}>
                    <Text size="xxlarge">
                      {dayjs(Number(stats?.rotation_point_sec) * 1000).format('DD MMM YYYY')}
                    </Text>
                  </div>
                  <Text align="center">Rotation Point</Text>
                </Stack>
              </Grid.Item>
            </Grid>
          </Box>
        </Card.Section>
      </Card>
    )
  } else {
    return (
      <Card>
        <Card.Section>
          <Stack direction="horizontal" align="center">
            <Text size="large">Statistics</Text>
          </Stack>
          <Box marginY="medium" opacity={isPreviousData || isFetching ? '50' : '100'}>
            <Grid columns={{ mobile: 1, tablet: 3, desktop: 3 }}>
              <Grid.Item>
                <Stack direction="vertical" align="center" space="xsmall">
                  <Text size="xxlarge">
                    {formatSize(stats?.storage_usage_bytes ?? 0, { binary: false })}
                  </Text>
                  <Text align="center">Stored</Text>
                </Stack>
              </Grid.Item>
              <Grid.Item>
                <Stack direction="vertical" align="center" space="xsmall">
                  <div {...hoverTracesProps}>
                    <Text size="xxlarge">
                      {isTracesHover
                        ? formatNumber(stats?.storage_usage_percentage ?? 0)
                        : formatLargeNumber(stats?.storage_usage_percentage ?? 0)}{' '}
                      %
                    </Text>
                  </div>
                  <Text align="center">Usage</Text>
                </Stack>
              </Grid.Item>
            </Grid>
          </Box>
        </Card.Section>
      </Card>
    )
  }
}
