import { useQuery } from '@tanstack/react-query'

import { projectsQuery } from '~/entities/projects'

import { Card } from '~/components/Card'
import { Grid } from '~/components/Grid'
import { Icon } from '~/components/Icon'
import { ChevronRight } from '~/components/Icons'
import { Link } from '~/components/Link'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

export default function Overview() {
  const { data: projects } = useQuery(projectsQuery())

  return (
    <Stack>
      <Stack direction="horizontal" justify="between" align="center">
        <Stack space="xsmall">
          <Text size="xxlarge">Projects</Text>
          <Text>All the projects you have access to.</Text>
        </Stack>
        <Link
          type="button"
          buttonColor="brand"
          to={'.'}
          searchParams={{ 'select-project': 'open' }}
        >
          Create a new project
        </Link>
      </Stack>

      <Grid columns={{ mobile: 1, tablet: 2, desktop: 3 }} space="medium" align="stretch">
        {projects?.map(project => (
          <Grid.Item key={project.id}>
            <Link key={project.name} to={`/${project.slug}/stack`}>
              <Card>
                <Card.Section>
                  <Stack direction="horizontal" justify="between" align="center">
                    <Text size="large">{project.name}</Text>
                    <Icon>
                      <ChevronRight />
                    </Icon>
                  </Stack>
                </Card.Section>
              </Card>
            </Link>
          </Grid.Item>
        ))}
      </Grid>
    </Stack>
  )
}
