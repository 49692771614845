// import { useEffect } from 'react'
import { useState } from 'react'
import {
  type ActionFunctionArgs,
  json,
  redirect,
  useFetcher,
  useSearchParams,
} from 'react-router-dom'
import { isZodError } from 'api-client'
import {
  type AcceptInvititationData,
  type OrganizationInviteResponse,
} from 'api-client/organizations/model'
import { transformSnakeObjectKeysToCamel } from 'plunger'
import { type typeToFlattenedError, z } from 'zod'

import { getFormData } from '~/shared/lib/parseRequest'
import { authStore } from '~/shared/model/auth'
// import { useNavigate } from '~/shared/lib/reactRouterWrappers'
import { isApiError } from '~/shared/model/errors'

import { Box } from '~/components/Box'
import { Button } from '~/components/Button'
import { Checkbox } from '~/components/Checkbox'
import { HeadTag } from '~/components/HeadTag'
import { Icon } from '~/components/Icon'
import { Mail } from '~/components/Icons'
import { Link } from '~/components/Link'
import { Message } from '~/components/Message'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import * as styles from '~/styles/login-signup.css'

type ActionData = {
  fields?: AcceptInvititationData
  errors?: typeToFlattenedError<AcceptInvititationData>
}
const ACCEPT_INVITATION = 'ACCEPT_INVITATION'
export async function action({ request }: ActionFunctionArgs) {
  const data = await getFormData(request)
  const { changeLoginType } = authStore.getState().actions
  const intents = z.enum([ACCEPT_INVITATION]).safeParse(data.intent)
  if (!intents.success) {
    throw json(
      {
        message: 'This operation is not valid',
        description:
          'You tried to perform an invalid operation. Nothing will be changed regarding your billing information. Please reload the page and try again.',
      },
      { status: 400 },
    )
  }

  switch (intents.data) {
    case 'ACCEPT_INVITATION': {
      try {
        const url = import.meta.env.VITE_API_URL
        const token = authStore.getState().keyCloakClient.token
        const headers = new Headers()
        headers.append('Content-Type', 'application/x-www-form-urlencoded')
        headers.append('Authorization', `Bearer ${token}`)
        const newOrganization = async () => {
          return await fetch(`${url}organizations/invitations/accept?code=${data.code}`, {
            method: 'post',
            headers,
          })
        }

        const newOrg = (await newOrganization()
          .then(res => res.json())
          .then(data => {
            if (data) {
              return transformSnakeObjectKeysToCamel(data)
            }
          })) as OrganizationInviteResponse

        if (!newOrg?.error) {
          window.sessionStorage.setItem('orgLogin', 'true')
          await changeLoginType(newOrg?.organizationId)

          return redirect(`/login?organization=${Number(newOrg?.organizationId)}`)
        } else {
          return json(
            {
              fields: data,
              errors: {
                formErrors: ['Invitation expired or invalid. '],
              },
            },
            { status: 400 },
          )
        }
      } catch (error) {
        if (isZodError<AcceptInvititationData>(error)) {
          return json({ fields: data, errors: error.flatten() }, { status: 400 })
        }
        return json({
          fields: data,
          billingAccountId: undefined,
          errors: {
            formErrors: [
              isApiError(error)
                ? error.description
                : 'Invitation expired or invalid. Inviting user must re-send invitation.',
            ],
          },
        })
      }
    }
  }
}
export function Invite() {
  //   const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [cbTerms, setCbTerms] = useState(false)
  const code = searchParams.get('invite') ?? ''
  const orgName = searchParams.get('organization_name') ?? ''

  const fetcher = useFetcher<ActionData>()

  return (
    <>
      <HeadTag tag="title" headId="title">
        {import.meta.env.MODE !== 'production' ? `(${import.meta.env.VITE_ENVIRONMENT}) ` : ''}
        Organization invitation - Gigapipe
      </HeadTag>
      <Box className={styles.container}>
        <Box className={[styles.column, styles.leftColumn]}>
          <Stack space="small" align={{ mobile: 'center', tablet: 'start' }}>
            <img
              src="/assets/img/gigapipe-full-logo.svg"
              alt="Gigapipe"
              className={styles.logoContainer}
            />
            <Text className={styles.headline} weight="bold">
              Organization invitation to {orgName}
            </Text>
          </Stack>
        </Box>
        <Box className={styles.column}>
          <Stack align="center">
            <Icon color="white" size="xxxlarge">
              <Mail />
            </Icon>
            <Text size="xxxlarge" align="center">
              You have recieved an invitation to organization {orgName}
            </Text>

            <Box marginY={'medium'}>
              <Checkbox name="enableTerms" onChange={e => setCbTerms(e)}>
                <Text size="small">
                  {' '}
                  I have read and accept the{' '}
                  <Link type="text" target="_blank" to="https://gigapipe.com/privacy-policy/">
                    Privacy Policy{' '}
                  </Link>
                  and the{' '}
                  <Link type="text" target="_blank" to="https://gigapipe.com/terms/">
                    Terms and Conditions
                  </Link>{' '}
                  of Gigapipe.
                </Text>
              </Checkbox>
            </Box>

            <Stack direction="vertical">
              <fetcher.Form method="post">
                {fetcher.data?.errors?.formErrors ? (
                  <Box marginY="small">
                    <Message size="small" type="error">
                      {fetcher.data?.errors?.formErrors?.join(', ')} If you have already accepted
                      the invitation, please{' '}
                      <Link type="text" to="/">
                        login
                      </Link>{' '}
                      or{' '}
                      <Link
                        type="text"
                        to={`mailto:info@gigapipe.com?subject=Problem accesing Org ${orgName}`}
                      >
                        contact us.
                      </Link>
                    </Message>
                  </Box>
                ) : null}
                {/* <input hidden name="email" defaultValue={email} /> */}
                <input hidden name="code" defaultValue={code} />
                {fetcher.data?.errors?.formErrors ? null : (
                  <Button
                    aria-label="Add user to project"
                    type="submit"
                    name="intent"
                    value={ACCEPT_INVITATION}
                    isLoading={fetcher.state === 'submitting'}
                    isDisabled={
                      fetcher.state === 'submitting' || !cbTerms || Boolean(fetcher?.data?.errors)
                    }
                  >
                    {' '}
                    Accept invitation
                  </Button>
                )}
              </fetcher.Form>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </>
  )
}
