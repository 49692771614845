import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { integrationsQuery } from '~/entities/integrations/base'
import { clickHouseClustersQuery } from '~/entities/integrations/clickhouse'
import { grafanaDataSourcesQuery } from '~/entities/integrations/grafana'

type Params = {
  projectId: Project['id']
  projectName?: Project['name']
}
/**
 * Determines if the user can add ClickHouse datasources
 * to their project. This is determined by checking if the project already has ClickHouse
 * datasources added for their clusters, if the project has an enabled ClickHouse integration in a valid state, and
 * if the project has active ClickHouse clusters.
 */
export function useAddClickHouseDatasources({ projectId, projectName }: Params) {
  const { data: integrations } = useQuery({
    ...integrationsQuery(projectId),
    select: data =>
      data
        .map(
          integration =>
            ['active', 'trial', 'scheduled for cancellation'].includes(integration.status) &&
            integration.product.slug,
        )
        .filter(Boolean),
  })

  const { data: clickHouseDataSources } = useQuery({
    ...grafanaDataSourcesQuery(projectId),
    select: data =>
      new Set(
        data
          .filter(datasource => datasource.type === 'grafana-clickhouse-datasource')
          .map(datasource => datasource.name),
      ),
  })

  const { data: availableClusters } = useQuery({
    ...clickHouseClustersQuery(projectId),
    enabled: integrations?.includes('clickhouse'),
    select: data =>
      data
        .filter(cluster => cluster.status === 'active' || cluster.status === 'external')
        .filter(
          cluster => !clickHouseDataSources?.has(`ClickHouse (${projectName} - ${cluster.name})`),
        ),
  })

  const allowToAddClickHouseDatasources =
    availableClusters && availableClusters.length > 0 && integrations?.includes('clickhouse')

  return {
    allowToAddClickHouseDatasources,
    availableClusters,
    numOfClickHouseClusters: allowToAddClickHouseDatasources ? availableClusters.length : 0,
  }
}
