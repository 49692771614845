import { lazy } from 'react'

const clickhouse = lazy(() => import('~/components/Icons/ClickHouse'))
const qryn = lazy(() => import('~/components/Icons/Qryn'))
const decodable = lazy(() => import('~/components/Icons/Decodable'))
const grafana = lazy(() => import('~/components/Icons/Grafana'))
const gateway = lazy(() => import('~/components/Icons/Gateway'))
const kafka = lazy(() => import('~/components/Icons/Kafka'))
const prometheus = lazy(() => import('~/components/Icons/Prometheus'))
const mindsdb = lazy(() => import('~/components/Icons/Mindsdb'))
const redpanda = lazy(() => import('~/components/Icons/Redpanda'))
const hepic = lazy(() => import('~/components/Icons/Hepic'))
const metabase = lazy(() => import('~/components/Icons/Metabase'))
const imply = lazy(() => import('~/components/Icons/Imply'))
const loggen = lazy(() => import('~/components/Icons/Loggen'))

export const ProductIcons = {
  clickhouse,
  decodable,
  gateway,
  grafana,
  kafka,
  prometheus,
  qryn,
  mindsdb,
  redpanda,
  hepic,
  metabase,
  imply,
  loggen,
}

export type ProductIconNames = keyof typeof ProductIcons
