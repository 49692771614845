import { useEffect } from 'react'
import { type Disk, type DiskType } from 'api-client'
import { type z } from 'zod'

import { storageUnitToBytes } from '~/shared/config'

import { Grid } from '~/components/Grid'
import { IconButton } from '~/components/IconButton'
import { XCircle } from '~/components/Icons'
import { Input } from '~/components/Input'
import { NumberInput } from '~/components/Number'
import { Select } from '~/components/Select'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import { useCreateMCHClusterForm, useCreateMCHClusterFormActions } from '../model/store'

type DiskInputProps = {
  index: number
  id: string
  diskTypes: DiskType[]
  isDisabled: boolean
  isDefault: boolean
  errors?: z.ZodFormattedError<Disk, string>
}

export function DiskInput({ index, id, diskTypes, isDisabled, isDefault, errors }: DiskInputProps) {
  const disk = useCreateMCHClusterForm(state => state.disks[id])
  const { updateDisk, deleteDisk } = useCreateMCHClusterFormActions()

  useEffect(() => {
    if (diskTypes.length) {
      updateDisk(id, 'type', diskTypes[0].name)
      updateDisk(id, 'unit', diskTypes[0].unitNames[0])
    }
  }, [diskTypes, id, updateDisk])

  const diskData = {
    name: disk.name,
    type: {
      name: disk.type,
    },
    size: disk.size * storageUnitToBytes[disk.unit],
    unitType: diskTypes.find(type => type.name === disk.type)?.unit ?? 'Decimal',
  }

  const disksValue = JSON.stringify(diskData)

  return (
    <>
      <Grid key={id} columns={12} space="small">
        <input hidden key={disksValue} name={`disks[${index}]`} defaultValue={disksValue} />
        <Grid.Item span={4}>
          <Input
            aria-label="Disk name"
            value={disk?.name}
            onChange={v => updateDisk(id, 'name', v)}
            isDisabled={isDefault || isDisabled}
          />
        </Grid.Item>
        <Grid.Item span={2}>
          <Select
            defaultText="-"
            aria-label="Disk type"
            items={diskTypes}
            onSelectionChange={v => updateDisk(id, 'type', v)}
            selectedKey={disk?.type}
            isDisabled={isDisabled}
          >
            {item => (
              <Select.Item key={item.name} textValue={item.name}>
                <Text>{item.name}</Text>
              </Select.Item>
            )}
          </Select>
        </Grid.Item>
        <Grid.Item span={3}>
          <NumberInput
            aria-label="Disk size"
            value={disk?.size}
            onChange={v => updateDisk(id, 'size', v)}
            isDisabled={isDisabled}
            formatOptions={{ maximumFractionDigits: 2 }}
          />
        </Grid.Item>
        <Grid.Item span={2}>
          <Select
            defaultText="-"
            aria-label="Disk unit"
            items={diskTypes[0]?.unitNames.map(unit => ({ id: unit, name: unit })) ?? []}
            isDisabled={isDisabled}
            onSelectionChange={v => updateDisk(id, 'unit', v)}
            selectedKey={disk.unit}
          >
            {item => (
              <Select.Item key={item.id} textValue={item.name}>
                <Text>{item.name}</Text>
              </Select.Item>
            )}
          </Select>
        </Grid.Item>
        <Grid.Item span={1}>
          <Stack justify="center" align="end">
            <IconButton
              icon={<XCircle />}
              aria-label="Delete disk"
              isDisabled={isDefault || isDisabled}
              color="transparent"
              onPress={() => deleteDisk(id)}
            />
          </Stack>
        </Grid.Item>
      </Grid>
      {errors && (
        <Text color="red500">
          {Object.values(errors)
            .flatMap(error => (Array.isArray(error) ? error : error._errors))
            .join('\n')}
        </Text>
      )}
    </>
  )
}
