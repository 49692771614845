import { CopyButton } from '~/shared/ui/CopyButton'

import { Box } from '~/components/Box'
import { Link } from '~/components/Link'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import * as styles from './QrynCodeSnippet.css'

export function QrynCodeSnippet({
  snippet,
  privateSnippet,
  link,
}: {
  snippet: string
  privateSnippet: string
  link?: string
}) {
  return (
    <Stack>
      <Box position="relative">
        <Box className={styles.copyToClipboard}>
          <CopyButton aria-label="Copy code snippet" value={privateSnippet} />
        </Box>
        <pre className={styles.codeSnippet}>{snippet}</pre>
      </Box>
      {!!link && (
        <Text>
          You can find more complex examples in the{' '}
          <Link type="text" to={link}>
            data ingestion
          </Link>{' '}
          page.
        </Text>
      )}
    </Stack>
  )
}
