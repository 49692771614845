import { apiClient } from '~/shared/api'
import { ONE_HOUR } from '~/shared/config'

const loggenQueryKeys = {
  loggenConfig: (projectId: string) =>
    [{ scope: 'integrations', type: 'loggen', item: 'config', projectId }] as const,
  loggenLogsConfig: (categories?: string[] | string) =>
    [{ scope: 'integrations', type: 'loggen', item: 'logs', categories }] as const,
  loggenLogsCategories: [{ scope: 'integrations', type: 'loggen', item: 'categories' }] as const,
}

/**
 * Query to fetch `Loggen` integration configuration
 */
export const loggenConfigQuery = (projectId: string) => ({
  queryKey: loggenQueryKeys.loggenConfig(projectId),
  queryFn: () => apiClient.loggen.getLoggenConfig(projectId),
  enabled: Boolean(projectId),
})

/**
 * Query to fetch the `Loggen` log structure configuration
 */
export const loggenLogsConfigQuery = (categories?: string[] | string) => ({
  queryKey: loggenQueryKeys.loggenLogsConfig(categories),
  queryFn: () => apiClient.loggen.getLoggenLogsConfig(categories),
  enabled: Boolean(categories),
})

/**
 * Query to fetch the `Loggen` categories
 */
export const loggenLogsCategoriesQuery = {
  queryKey: loggenQueryKeys.loggenLogsCategories,
  queryFn: apiClient.loggen.getLoggenLogsCategories,
  staleTime: ONE_HOUR,
}
