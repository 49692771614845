import { memo, type SVGProps } from 'react'

const SvgCpu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.583.833v2.5m5.5-2.5v2.5m-5.5 13.334v2.5m5.5-2.5v2.5M18.667 7.5h2.75m-2.75 4.167h2.75M1.25 7.5H4m-2.75 4.167H4m1.833-8.334h11c1.013 0 1.834.747 1.834 1.667v10c0 .92-.821 1.667-1.834 1.667h-11C4.821 16.667 4 15.92 4 15V5c0-.92.82-1.667 1.833-1.667ZM8.583 7.5h5.5v5h-5.5v-5Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgCpu)
export default Memo
