import { transformCamelObjectKeysToSnake } from 'plunger'
import { z } from 'zod'

import { type Rooquest } from '../../lib/rooquest'
import { type ProjectId } from '../../projects/model'
import { type MessageResponse } from '../../utils/types'

import {
  qrynCacheServerSchema,
  qrynCloudResourcesSchema,
  qrynConfigSchema,
  type QrynCreateTokenData,
  qrynCreateTokenSchema,
  qrynCurrentCostSchema,
  type QrynDeleteTokenData,
  qrynDeleteTokenSchema,
  type QrynEnableData,
  qrynEnableSchema,
  qrynFingerprintsLimitSchema,
  type QrynInstrumentClickHouseClusterData,
  qrynInstrumentClickHouseClusterSchema,
  qrynInstrumentedApplicationsSchema,
  qrynMetricsSchema,
  qrynRetentionPolicySchema,
  qrynTokensSchema,
  type QrynUpdateCacheServerData,
  type QrynUpdateFingerprintsLimitData,
  type QrynUpdateRetentionPoliciesData,
  qrynUpdateRetentionPoliciesSchema,
} from './model'

const qrynCloudResources = z.array(qrynCloudResourcesSchema)
const qrynTokens = z.array(qrynTokensSchema)
const qrynRetentionPolicies = z.array(qrynRetentionPolicySchema)

export default class QrynService {
  private readonly rooquest: Rooquest

  constructor(rooquest: Rooquest) {
    this.rooquest = rooquest
  }

  /**
   * Add `qryn` integration to a project
   */
  public enableQryn = async (data: QrynEnableData) => {
    const { projectId, ...rest } = qrynEnableSchema.parse(data)

    const res = await this.rooquest.post('integrations/qryn/', {
      query: transformCamelObjectKeysToSnake({ projectId }),
      body: transformCamelObjectKeysToSnake(rest),
    })

    return qrynConfigSchema.parse(res)
  }

  /**
   * Get `qryn` configuration
   */
  public getQrynConfig = async (projectId: ProjectId) => {
    const res = await this.rooquest.get('integrations/qryn/config', {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return qrynConfigSchema.parse(res)
  }

  /**
   * Get `qryn` metrics
   */
  public getQrynMetrics = async ({
    projectId,
    start,
    end,
  }: {
    projectId: ProjectId
    start: number
    end: number
  }) => {
    const res: any = await this.rooquest.get('integrations/qryn/metrics', {
      query: transformCamelObjectKeysToSnake({
        projectId,
        start: Math.floor(start / 1000),
        end: Math.floor(end / 1000),
      }),
    })

    return qrynMetricsSchema.parse(res)
  }

  /**
   * Get the available cloud instances to store qryn logs
   */
  public getQrynCloudInstances = async () => {
    const res = await this.rooquest.get('integrations/qryn/cloud/resources')

    return qrynCloudResources.parse(res)
  }

  /**
   * Instrument applications with `qryn`
   */
  public addQrynInstrumentation = async ({
    projectId,
    ...data
  }: QrynInstrumentClickHouseClusterData) => {
    const payload = qrynInstrumentClickHouseClusterSchema.parse(data)
    const res = await this.rooquest.post('integrations/qryn/instrument', {
      body: transformCamelObjectKeysToSnake(payload),
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return res as MessageResponse
  }

  /**
   * Get `qryn` instrumented applications
   */
  public getQrynInstrumentedApplications = async ({
    projectId,
    appName,
  }: {
    projectId: ProjectId
    appName?: string
  }) => {
    const res = await this.rooquest.get('integrations/qryn/instrumented-applications', {
      query: transformCamelObjectKeysToSnake({ projectId, appName }),
    })

    return qrynInstrumentedApplicationsSchema.parse(res)
  }

  /**
   * Rotate the API keys for a qryn integration
   */
  public rotateAPIKeys = async (projectId: string) => {
    const res = await this.rooquest.get('integrations/qryn/config/credentials/rotate', {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return res as MessageResponse
  }

  /**
   * Get `qryn` current cost
   */
  public getQrynCurrentCost = async (projectId: ProjectId) => {
    const res = await this.rooquest.get('integrations/qryn/costs', {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })
    return qrynCurrentCostSchema.parse(res)
  }

  /**
   * Get `qryn` scoped tokens
   */
  public getQrynTokens = async (projectId: ProjectId) => {
    const res = await this.rooquest.get('integrations/qryn/tokens', {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return qrynTokens.parse(res)
  }

  /**
   * Create a `qryn` scoped token
   */
  public createQrynToken = async (data: QrynCreateTokenData) => {
    const { projectId, ...rest } = qrynCreateTokenSchema.parse(data)
    const res = await this.rooquest.post('integrations/qryn/tokens', {
      body: transformCamelObjectKeysToSnake(rest),
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return res as MessageResponse
  }

  /**
   * Delete a `qryn` scoped token
   */
  public deleteQrynToken = async (data: QrynDeleteTokenData) => {
    const { projectId, tokenId } = qrynDeleteTokenSchema.parse(data)
    const res = await this.rooquest.delete(`integrations/qryn/tokens/${tokenId}`, {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return res as MessageResponse
  }

  /**
   * Get the available `qryn` retention policies
   */
  public getQrynRetentionPolicies = async () => {
    const res = await this.rooquest.get('integrations/qryn/retention/policies')

    return qrynRetentionPolicies.parse(res)
  }

  /**
   * Update the `qryn` retention policies
   */
  public updateQrynRetentionPolicies = async (data: QrynUpdateRetentionPoliciesData) => {
    const { projectId, ...rest } = qrynUpdateRetentionPoliciesSchema.parse(data)
    const res = await this.rooquest.patch('integrations/qryn/retention/policies', {
      body: transformCamelObjectKeysToSnake(rest),
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return res as MessageResponse
  }

  /**
   * Update the `qryn` limit of fingerprints
   */
  public updateQrynFingerprintsLimit = async (data: QrynUpdateFingerprintsLimitData) => {
    const { projectId, ...rest } = qrynFingerprintsLimitSchema.parse(data)
    const res = await this.rooquest.patch('integrations/qryn/fingerprints/limit', {
      body: transformCamelObjectKeysToSnake(rest),
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return res as MessageResponse
  }

  /**
   * Update the `qryn` cache server
   *
   **/

  public updateQrynCacheServer = async (data: QrynUpdateCacheServerData) => {
    const { projectId, ...rest } = qrynCacheServerSchema.parse(data)
    const res = await this.rooquest.patch('integrations/qryn/cache', {
      body: transformCamelObjectKeysToSnake(rest),
      query: transformCamelObjectKeysToSnake({ projectId }),
    })
    return res as MessageResponse
  }
}
