import { transformSnakeObjectKeysToCamel } from 'plunger'
import { z } from 'zod'

import { projectId } from '../../projects/model'
import { zx } from '../../utils/zodExtra'

// Schemas for fetched requests
export const qrynConfigSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z
    .object({
      id: z.coerce.number(),
      projectId,
      apiKey: z.string(),
      apiSecret: z.string(),
      organizationId: z.string(),
      clickhouseClusterId: z.coerce.number(),
      //subscriptionId: z.string(),
      url: z.string().url(),
      multiOrg: z.boolean(),
      ttlAddonId: z.string(),
      ttlDays: z.number(),
      fingerprintLimit: z.number(),
      cacheEnabled: z.boolean(),
      createdAt: z.string(),
    })
    .partial({
      cacheEnabled: true,
    }),
)
export const qrynCloudResourcesSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    clickhouseClusterId: z.coerce.number(),
    regionName: z.string(),
    regionCode: z.string(),
    continent: z.string(),
  }),
)

export const qrynMetricsSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    bytesCount: z.number().nullable(),
    // Total number of Metrics & Logs [Live]
    tracesCount: z.number().nullable(),
    metricsAndLogsCount: z.number().nullable(),
    uniqueMetricSeriesCount: z.number().nullable(),
    // Rotation policy (1TB - 2.5TB depending on the plan they're on) [Fixed, only needs updating when we change their plan so a check once a day]
    rotationPolicyTtl: z.number().nullable(),
    sizeLimit: z.number().nullable(),
    // Total uncompressed data stored [Live]
    dataBytes: z.number().nullable(),
    // number and volume of traces
    tracesRows: z.number().nullable(),
    tracesBytes: z.number().nullable(),
    // number and volume of metrics
    metricsRows: z.number().nullable(),
    metricsBytes: z.number().nullable(),
    // number and volume of logs
    logsRows: z.number().nullable(),
    logsBytes: z.number().nullable(),
    //Total number of unique fingerprints [Live]
    fingerprints: z.number().nullable(),
    //metricsAndLogs: z.number().nullable(),
    retaningDataLogs: z.string().optional().nullable(),
    retaningDataTraces: z.string().optional().nullable(),
  }),
)
export type QrynMetricsSchemaData = z.infer<typeof qrynMetricsSchema>

export const qrynInstrumentedApplicationsSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    instrumentedApplications: z.object({
      clickhouse: z.array(
        z.object({
          id: z.string().uuid(),
          projectId,
          qrynIntegrationId: z.string().uuid(),
          clusterId: z.string().uuid(),
        }),
      ),
    }),
  }),
)

export const qrynCurrentCostSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    totalCost: z.number(),
  }),
)

const qrynRetentionPolicyTierSchema = z.object({
  startingUnit: z.number(),
  endingUnit: z.number().nullable(),
  price: z.number(),
  startingUnitInDecimal: z.string(),
  endingUnitInDecimal: z.string().nullable(),
  priceInDecimal: z.string(),
})
export type QrynRetentionPolicyTier = z.infer<typeof qrynRetentionPolicyTierSchema>
export const qrynRetentionPolicySchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    id: z.string(),
    name: z.string(),
    itemFamilyId: z.string(),
    itemId: z.string(),
    description: z.string(),
    status: z.string(),
    externalName: z.string(),
    tiers: z.array(qrynRetentionPolicyTierSchema),
  }),
)

// Schemas for submit requests
export const qrynEnableSchema = z.object({
  projectId,
  clusterId: z.coerce.number(),
  writerRegionCode: z
    .string()
    .min(1, { message: 'Please select from where qryn data will be ingested' }),
  multiOrg: zx.checkbox(),
  ttlAddonId: z.string().optional(),
})
export type QrynEnableData = z.infer<typeof qrynEnableSchema>

const APP_NAMES = ['clickhouse'] as const
export const qrynInstrumentClickHouseClusterSchema = z.object({
  projectId,
  appName: z.enum(APP_NAMES),
  knownApp: z.boolean().optional(),
  clickhouseClusters: z
    .array(z.string().min(1, { message: 'Invalid ClickHouse cluster id' }))
    .min(1, { message: 'You must select at least one cluster to instrument.' }),
})
export type QrynInstrumentClickHouseClusterData = z.infer<
  typeof qrynInstrumentClickHouseClusterSchema
>

const SCOPE = ['read', 'write', 'read write'] as const
export const qrynTokensSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    projectId,
    id: z.coerce.number(),
    token: z.string(),
    name: z.string(),
    scope: z.enum(SCOPE),
  }),
)

export const qrynCreateTokenSchema = z.object({
  projectId,
  name: z.string().min(1, { message: 'You must provide a name.' }),
  scope: z.enum(SCOPE),
})

export type QrynCreateTokenData = z.infer<typeof qrynCreateTokenSchema>

export const qrynDeleteTokenSchema = z.object({
  projectId,
  tokenId: z.coerce.number(),
})

export type QrynDeleteTokenData = z.infer<typeof qrynDeleteTokenSchema>

export const qrynUpdateRetentionPoliciesSchema = z.object({
  projectId,
  ttlAddonId: z.string(),
})

export type QrynUpdateRetentionPoliciesData = z.infer<typeof qrynUpdateRetentionPoliciesSchema>

export const qrynFingerprintsLimitSchema = z.object({
  projectId,
  fingerprintLimit: z.coerce.number(),
})
export type QrynUpdateFingerprintsLimitData = z.infer<typeof qrynFingerprintsLimitSchema>
export const qrynCacheServerSchema = z.object({
  projectId,
  enabled: zx.formDataBoolean,
})

export type QrynUpdateCacheServerData = z.infer<typeof qrynCacheServerSchema>
