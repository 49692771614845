import { memo, type SVGProps } from 'react'

const SvgQryn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 29 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m0 12.43 3.207-3.194 12.485 12.43-3.207 3.193L0 12.429Z"
      fill="#FFCC01"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m17.613 13.366 3.207 3.193-8.338 8.301-3.207-3.193 8.338-8.3ZM9.63 2.84l3.208 3.194-9.631 9.588L0 12.429 9.63 2.84Z"
      fill="#FFCC01"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.277 3.193 12.484 0 28.39 15.835l-3.207 3.193L9.277 3.193ZM12.541 8.372l4.037 4.019-3.922 3.905-4.037-4.02 3.922-3.904Z"
      fill="#F90"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m22.747 8.48 3.747-.65-.653 3.73-3.094-3.08Z"
      fill="#FFCC01"
    />
  </svg>
)
const Memo = memo(SvgQryn)
export default Memo
