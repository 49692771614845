import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { integrationsQuery } from '~/entities/integrations/base'
import { grafanaQrynDataSourcesQuery } from '~/entities/integrations/grafana'

type Params = {
  projectId: Project['id']
}

/**
 * Returns a boolean indicating whether the user can add qryn datasources
 * to their project. This is determined by checking if the project already has Qryn
 * datasources and if the project has an enabled qryn integration in a valid state.
 */
export function useAddQrynDatasources({ projectId }: Params) {
  const { data: hasQrynDatasources } = useQuery({
    ...grafanaQrynDataSourcesQuery(projectId),
    select: data => data?.length,
  })

  const { data: integrations } = useQuery({
    ...integrationsQuery(projectId),
    select: data =>
      data
        .map(
          integration =>
            ['active', 'trial', 'scheduled for cancellation'].includes(integration.status) &&
            integration.product.slug,
        )
        .filter(Boolean),
  })

  const allowToAddQrynDatasources = !hasQrynDatasources && integrations?.includes('qryn')

  return {
    allowToAddQrynDatasources,
  }
}
