import { memo, type SVGProps } from 'react'

const SvgImply = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.144 65.558c18.067 0 32.713-14.577 32.713-32.558C65.857 15.018 51.211.44 33.144.44S.431 15.018.431 33c0 17.981 14.646 32.558 32.713 32.558Zm-.056-18.1-.985 2.151 4.587 1.849.985-2.152c1.412-3.084 3.955-5.985 7.321-8.633a44.808 44.808 0 0 1 6.158-4.04c.734-.402 1.414-.75 2.02-1.04.354-.17.598-.28.713-.329l-.044-4.28c-.112-.045-.353-.149-.704-.31a39.597 39.597 0 0 1-2.004-.994 42.133 42.133 0 0 1-6.113-3.928c-3.39-2.64-5.94-5.576-7.332-8.757l-.948-2.166-4.617 1.78.948 2.166c1.053 2.404 2.585 4.64 4.51 6.717H14.72v4.683h28.204c1.862 1.357 2.89 1.936 4.838 2.969-1.842 1.008-2.807 1.6-4.577 2.885H14.72v4.684h23.054c-1.994 2.099-3.586 4.345-4.685 6.745Z"
      fill="#189AE0"
    />
  </svg>
)
const Memo = memo(SvgImply)
export default Memo
