import { memo, type SVGProps } from 'react'

const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx={16.335} cy={16.335} r={12.335} stroke="currentColor" strokeWidth={2} />
    <path fill="currentColor" d="M15.335 15.335h2v7h-2z" />
    <circle cx={16.335} cy={11.335} r={1} fill="currentColor" />
  </svg>
)
const Memo = memo(SvgInfo)
export default Memo
