import { CancelSubscriptionButton } from '~/features/cancelSubscriptionToIntegration'
import { HepicInfo } from '~/features/showHepicInfo'
import { HepicLinks } from '~/features/showHepicLinks'
import { HepicMetrics } from '~/features/ShowHepicMetrics'

import { useProjectIdParam } from '~/entities/projects'

import { Box } from '~/components/Box'
import { Card } from '~/components/Card'
import { Grid } from '~/components/Grid'
import { Stack } from '~/components/Stack'

export const HepicOverview = () => {
  const { projectId, projectSlug } = useProjectIdParam()
  return (
    <Stack direction="vertical">
      <Grid>
        <Grid.Item span={{ mobile: 12, tablet: 8 }}>
          <Box>
            <HepicMetrics projectId={projectId} />
          </Box>
          <Box marginY={'medium'}>
            <HepicInfo projectId={projectId} />
          </Box>
        </Grid.Item>
        <Grid.Item span={{ mobile: 12, tablet: 4 }}>
          <Card>
            <Card.Section>
              <Stack direction="vertical" space="small">
                <HepicLinks projectId={projectId} />
                <CancelSubscriptionButton
                  integrationSlug="hepic"
                  integrationName="Hepic"
                  projectSlug={projectSlug}
                />
              </Stack>
            </Card.Section>
          </Card>
        </Grid.Item>
      </Grid>
    </Stack>
  )
}
