import { useEffect, useState } from 'react'

const SUCCESS_DURATION = 2000

/**
 * Custom hoook to add a string to the browser clipboard
 *
 * Returns a boolean to display some notification cleared out after a timeout
 */
export function useClipboard() {
  const [isCopied, setIsCopied] = useState(false)

  const setClipboard = async (value: string) => {
    await navigator.clipboard.writeText(value)
    setIsCopied(true)
  }

  useEffect(() => {
    if (isCopied) {
      const id = setTimeout(() => {
        setIsCopied(false)
      }, SUCCESS_DURATION)

      return () => {
        clearTimeout(id)
      }
    }
  }, [isCopied])

  return [isCopied, setClipboard] as const
}
