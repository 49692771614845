import {
  Form,
  isRouteErrorResponse,
  useActionData,
  useNavigate,
  useNavigation,
  useRouteError,
} from 'react-router-dom'

import { logout } from '~/shared/model/auth'
import { isApiError } from '~/shared/model/errors'

import { Link } from '~/components/Link'
import { Loading } from '~/components/Loading'
import { Modal } from '~/components/Modal'
import { Text } from '~/components/Text'

export function action() {
  return async () => logout()
  // const result = await AuthService.delete()
  // if (result.accountDeleted) {
  //   return logout()
  // }
  // if (result.paymentLink) {
  //   return json(result.paymentLink)
  // }
  // // eslint-disable-next-line @typescript-eslint/no-throw-literal
  // throw json(result.message, { status: 400 })
}

export default function DeleteAccount() {
  const navigate = useNavigate()
  const navigation = useNavigation()
  const paymentLink = useActionData() as string | undefined

  const status = paymentLink ? 'pendingPayments' : 'initial'
  return (
    <Modal isOpen onClose={() => navigate('/account')} dialogProps={{ id: 'delete-user' }}>
      <Form method="delete">
        <Modal.Header
          title={
            status === 'initial'
              ? 'Are you sure you want to delete your account?'
              : 'You have pending payments'
          }
        />
        <Modal.Footer>
          {status === 'initial' ? (
            <>
              <Modal.Action onPress={() => navigate('/account')} autoFocus>
                No
              </Modal.Action>
              <Modal.Action isDisabled={navigation.state === 'submitting'} type="submit">
                <Text color="gigagreen">Confirm</Text>
                {navigation.state === 'submitting' && <Loading />}
              </Modal.Action>
            </>
          ) : (
            <Modal.Action>
              <Link to={paymentLink!} type="text">
                <Text color="white">Pay now</Text>
              </Link>
            </Modal.Action>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export function ErrorBoundary() {
  const navigate = useNavigate()
  const error = useRouteError()

  if (isRouteErrorResponse(error))
    return (
      <Modal
        isOpen
        onClose={() => navigate('/account')}
        dialogProps={{ id: 'delete-user-error', role: 'alertdialog' }}
      >
        <Modal.Header title={error.data} />
        <Modal.Footer>
          <Modal.Action onPress={() => navigate('/account')}>
            <Text color="gigagreen">Close</Text>
          </Modal.Action>
        </Modal.Footer>
      </Modal>
    )
  if (isApiError(error)) {
    return (
      <Modal
        isOpen
        onClose={() => navigate('/account')}
        dialogProps={{ id: 'delete-user-error', role: 'alertdialog' }}
      >
        <Modal.Header title={error.description} />
        <Modal.Footer>
          <Modal.Action onPress={() => navigate('/account')}>
            <Text color="gigagreen">Close</Text>
          </Modal.Action>
        </Modal.Footer>
      </Modal>
    )
  }
  throw error
}
