import { useEffect, useRef } from 'react'
import { useOverlayTrigger } from 'react-aria'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useOverlayTriggerState } from 'react-stately'
import { useQuery } from '@tanstack/react-query'

import { useProjectIdParam } from '~/entities/projects'
import { userOrganizationsQuery } from '~/entities/users'

import { useUser } from '~/shared/model/auth'
import { useDecodedToken } from '~/shared/model/auth/useAuth'
import { Separator } from '~/shared/ui/Separator'

import { Box } from '~/components/Box'
import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'
import { Check, ChevronDown, Edit, MathPlus } from '~/components/Icons'
import { Link } from '~/components/Link'
import { Popover } from '~/components/Popover'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import * as styles from './UserMenu.css'

export function UserMenu() {
  const user = useUser()
  const decodedToken = useDecodedToken()
  const isOrgAccount = Boolean(decodedToken?.organization_id)
  const [searchParams, setSearchpParams] = useSearchParams()
  const { projectSlug } = useProjectIdParam()
  const isOpen = searchParams.get('user-menu') === 'open'
  const isStripeMock = import.meta.env.VITE_STRIPE_MOCK === 'true'
  const navigate = useNavigate()

  const { data: organizations } = useQuery(userOrganizationsQuery())
  const currentOrganization = organizations?.find(
    org => org.id === String(decodedToken?.organization_id),
  )

  const userGivenName = user?.given_name ?? ''
  const organizationName = currentOrganization ? currentOrganization.name : `Personal account`

  const ref = useRef<HTMLButtonElement>(null)
  const state = useOverlayTriggerState({
    isOpen,
    onOpenChange: newState => setSearchpParams(newState ? { 'user-menu': 'open' } : undefined),
  })
  const { triggerProps } = useOverlayTrigger({ type: 'dialog' }, state, ref)

  useEffect(() => {
    if (organizationName === `Personal account` && organizations && organizations?.length > 0) {
      navigate(`/login?organization=${organizations[0]?.id}`)
    }
  }, [])

  return (
    <Box data-testid="underlayElement">
      <Stack direction="horizontal" space="small" align="center" justify="end">
        <Text>{userGivenName}</Text>
        <Text color="gray600">{organizationName}</Text>
        <IconButton
          aria-label="open accounts settings menu"
          icon={<ChevronDown />}
          color="transparent"
          buttonRef={ref}
          {...triggerProps}
        />
      </Stack>
      {state.isOpen && (
        <Popover triggerRef={ref} state={state} placement="bottom end" offset={16}>
          <Stack space="xxsmall">
            <Box paddingX="medium" paddingY="xsmall">
              <Text
                size="xsmall"
                weight="bold"
                color="gray700"
                transform="uppercase"
                letterSpacing="xlarge"
              >
                Account settings
              </Text>
            </Box>
            <Link className={styles.link} to={projectSlug ? `${projectSlug}/account` : 'account'}>
              Account
            </Link>
            {!isStripeMock && (
              <Link className={styles.link} to={projectSlug ? `${projectSlug}/billing` : 'billing'}>
                Billing
              </Link>
            )}

            {isOrgAccount && (
              <Link
                className={styles.link}
                to={projectSlug ? `${projectSlug}/organization` : 'organization'}
              >
                Organization
              </Link>
            )}

            <Separator color="default" />

            {organizations && organizations.length > 0 && (
              <>
                <Box paddingX="medium" paddingY="xsmall">
                  <Text
                    size="xsmall"
                    weight="bold"
                    color="gray700"
                    transform="uppercase"
                    letterSpacing="xlarge"
                  >
                    Switch account
                  </Text>
                </Box>
                {organizations.map(org => (
                  <Link
                    key={org?.name}
                    className={styles.link}
                    to="/login"
                    searchParams={{
                      organization: org.id,
                    }}
                  >
                    <Stack direction="horizontal" align="center" justify="between">
                      {org.name}
                      {currentOrganization?.id === org.id && (
                        <Icon color="gigagreen">
                          <Check />
                        </Icon>
                      )}
                    </Stack>
                  </Link>
                ))}
              </>
            )}

            {/* <Link
              className={styles.link}
              to="/login"
              searchParams={{
                personalAccount: 'yes',
              }}
            >
              <Stack direction="horizontal" align="center" justify="between">
                Personal account
                {!currentOrganization && (
                  <Icon color="gigagreen">
                    <Check />
                  </Icon>
                )}
              </Stack>
            </Link> */}

            <Separator color="default" />

            <Link
              className={styles.link}
              to={projectSlug ? `${projectSlug}/organization/create` : 'organization/create'}
            >
              <Stack direction="horizontal" align="center" justify="between">
                Create organization
                <Icon color="white">
                  <MathPlus />
                </Icon>
              </Stack>
            </Link>

            <Separator color="default" />

            <Link className={styles.link} to="mailto:tickets@gigapipe.com">
              <Stack direction="horizontal" align="center" justify="between">
                Request support
                <Icon color="white">
                  <Edit />
                </Icon>
              </Stack>
            </Link>

            <Separator color="default" />

            <Link className={styles.link} to="/logout">
              Logout
            </Link>
          </Stack>
        </Popover>
      )}
    </Box>
  )
}
