import { transformCamelObjectKeysToSnake } from 'plunger'
import { z } from 'zod'

import { type Rooquest } from '../lib/rooquest'
import { orgUserSchema } from '../organizations/model'
import { type MessageResponse } from '../utils/types'

import {
  type AddUserData,
  addUserSchema,
  type CreateProjectData,
  createProjectSchema,
  type ProjectId,
  projectSchema,
} from './model'

const projects = z.array(projectSchema)
const users = z.array(orgUserSchema)

/**
 * The service to interact with all the projects domain endpoints of Gigapipe's API
 */
export default class ProjectsService {
  private readonly rooquest: Rooquest

  constructor(rooquest: Rooquest) {
    this.rooquest = rooquest
  }

  /**
   * Get projects
   */
  public getProjects = async () => {
    const res = await this.rooquest.get('projects/')
    return projects.parse(res)
  }

  /**
   * Create a project
   */
  public createProject = async (data: CreateProjectData) => {
    const payload = transformCamelObjectKeysToSnake(createProjectSchema.parse(data))
    const res = await this.rooquest.post('projects/', {
      body: payload,
    })

    return projectSchema.parse(res)
  }

  /**
   * Add user to project
   */
  public addUserToProject = async (data: AddUserData) => {
    const payload = addUserSchema.parse(data)

    const res = await this.rooquest.post('projects/users', {
      body: transformCamelObjectKeysToSnake({
        userId: parseInt(payload.userId),
      }),
      query: transformCamelObjectKeysToSnake({
        projectId: parseInt(payload.projectId),
      }),
    })

    return res as MessageResponse
  }

  /**
   * Get users from project
   */
  public getUsersFromProject = async (projectId: ProjectId) => {
    const res = await this.rooquest.get('projects/users', {
      query: transformCamelObjectKeysToSnake({ projectId }),
    })

    return users.parse(res)
  }
}
