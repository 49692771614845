import { transformSnakeObjectKeysToCamel } from 'plunger'
import { z } from 'zod'

import { projectId } from '../../projects/model'
import { zx } from '../../utils/zodExtra'

// Constants
const clickHouseClusterStatus = [
  'active',
  'creating',
  'deleting',
  'failed',
  'stopped',
  'external',
] as const
const MINUTES_IN_YEAR = 365 * 24 * 60
const storageUnitToBytes = {
  GB: 1_000_000_000,
  TB: 1_000_000_000_000,
  GiB: 1.073741824 * 1_000_000_000,
  TiB: 1.099511627776 * 1_000_000_000_000,
}

// Cloud resources schemas and types
const providerSlugs = ['aws', 'gcp', 'unknown'] as const
export type ProvidersSlug = (typeof providerSlugs)[number]
export const PROVIDERS: { slug: ProvidersSlug; name: string }[] = [
  { slug: 'aws', name: 'AWS' },
  { slug: 'gcp', name: 'GCP' },
]

const storageMemoryUnitType = ['Decimal', 'Binary'] as const
const continents = [
  'Africa',
  'Americas',
  'Asia Pacific',
  'Europe',
  'Middle East',
  'Oceania',
] as const

export const regionSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    name: z.string(),
    code: z.string(),
    continent: z.enum(continents),
  }),
)
const BinaryStorageMemoryUnitNames = ['GiB', 'TiB'] as const
const DecimalStorageMemoryUnitNames = ['GB', 'TB'] as const
const storageMemoryUnitTypeToNameMap = {
  Binary: BinaryStorageMemoryUnitNames,
  Decimal: DecimalStorageMemoryUnitNames,
}

export const machineSchema = z
  .preprocess(
    (data: any) => transformSnakeObjectKeysToCamel(data),
    z.object({
      name: z.string(),
      ram: z.number(),
      cpu: z.number(),
      price: z.number().default(0),
      ramUnit: z.enum(storageMemoryUnitType),
    }),
  )
  .transform(data => {
    const ramUnitName = storageMemoryUnitTypeToNameMap[data.ramUnit][0]

    return {
      ...data,
      ramUnitName,
      title: `CPUs: ${data.cpu} - RAM: ${data.ram} ${ramUnitName}`,
      monthlyCost: (MINUTES_IN_YEAR * data.price) / 12,
    }
  })

export const diskTypeSchema = z
  .preprocess(
    (data: any) => transformSnakeObjectKeysToCamel(data),
    z.object({
      name: z.string(),
      price: z.number(),
      unit: z.enum(storageMemoryUnitType),
    }),
  )
  .transform(data => {
    const diskTypeUnitNames = storageMemoryUnitTypeToNameMap[data.unit]
    const priceInBytes = data.price / storageUnitToBytes[data.unit === 'Decimal' ? 'GB' : 'GiB']
    const monthlyRatePrice = (MINUTES_IN_YEAR * priceInBytes) / 12

    return {
      ...data,
      unitNames: diskTypeUnitNames,
      monthlyRatePrice,
    }
  })

const diskSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    name: z.string(),
    type: z.object({ name: z.string() }),
    size: z.number(),
    unitType: z.enum(storageMemoryUnitType),
  }),
)

export const clickHouseVersionSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    name: z.string(),
    imageTag: z.string(),
  }),
)

export type Region = z.infer<typeof regionSchema>
export type Machine = z.infer<typeof machineSchema>
export type ClickHouseVersion = z.infer<typeof clickHouseVersionSchema>
export type DiskType = z.infer<typeof diskTypeSchema>
export type Disk = z.infer<typeof diskSchema>

// Schemas for fetched requests
export const clickHouseClusterSchema = z.preprocess(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  (data: any) => transformSnakeObjectKeysToCamel({ ...data, isExternal: data.external }),
  z.object({
    id: z.string(),
    projectId: projectId,
    name: z.string(),
    createdAt: z
      .string()
      // Necessary transformation because the API does not provide a timezone offset or if it is UTC
      .transform(value => `${value}Z`)
      .pipe(z.string().datetime()),
    status: z.enum(clickHouseClusterStatus),
    host: z.string(),
    httpPort: z.number(),
    tcpPort: z.number(),
    provider: z
      .string()
      .nullable()
      .transform(pr => pr ?? 'unknown')
      .pipe(z.enum(providerSlugs)),
    region: regionSchema.nullable(),
    machine: machineSchema.nullable(),
    shards: z.number(),
    replicas: z.number(),
    disks: z.array(diskSchema).nullable(),
    version: z.string().nullable(),
    zonal: z.boolean(),
    zones: z.array(z.string()).nullable(),
    controlPlaneZone: z.string().nullable(),
    isExternal: z.boolean(),
    ipSafeList: z.array(z.string()).nullable(),
  }),
)
export type ClickHouseCluster = z.infer<typeof clickHouseClusterSchema>

export const clickHouseClusterMetadataSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    shards: z.number(),
    replicas: z.number(),
    nodes: z.array(
      z.object({
        shard: z.number(),
        shardWeight: z.number(),
        replica: z.number(),
        host: z.string(),
      }),
    ),
    databases: z.array(z.string()),
  }),
)

export const clickHouseClusterMetricsSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    totalTables: z.number(),
    totalRows: z.number(),
    disks: z.array(
      z.object({
        host: z.string(),
        freeSpace: z.number(),
        totalSpace: z.number(),
        type: z.string(),
      }),
    ),
    timeSeriesMetrics: z.record(
      z.array(
        z.object({
          bytesPerSecond: z.number(),
          writtenRowsPerSecond: z.number(),
          readRowsPerSecond: z.number(),
          averageQueryTime: z.number(),
          averageQueryMemory: z.number(),
          time: z.string(),
        }),
      ),
    ),
  }),
)

// Schemas for submit requests
export const clickHouseEnableSchema = z.object({
  projectId,
})
export type ClickHouseEnableData = z.infer<typeof clickHouseEnableSchema>

const BANNED_USERNAMES = ['default', 'gigapipe', 'grafana']
export const createMCHClusterSchema = z
  .object({
    projectId,
    name: z
      .string()
      .min(1, { message: 'The cluster name is required.' })
      .max(15, { message: 'The cluster name cannot exceed 15 characters.' })
      .regex(/^[a-z]+[-a-z0-9]*[a-z0-9]$/, {
        message:
          'The cluster name must be all lowercase, end with an alphanumeric character and contain no special characters other than dashes',
      }),
    provider: z.enum(providerSlugs, {
      invalid_type_error: 'The provider is not valid.',
      required_error: 'The provider is required.',
    }),
    region: z
      .string()
      .min(1, { message: 'The region is required.' })
      .transform(val => JSON.parse(val))
      .pipe(
        z.object({
          name: z.string(),
          code: z.string(),
          continent: z.enum(continents),
        }),
      ),
    zonal: z.preprocess(val => Number(val), z.coerce.boolean().optional()),
    zones: z.array(z.string()).optional(),
    controlPlaneZone: z
      .string()
      .min(1, { message: 'You need to select a zone for the control plane.' })
      .optional(),
    machine: z
      .string()
      .min(1, { message: 'The machine is required.' })
      .transform(val => JSON.parse(val))
      .pipe(
        z.object({
          name: z.string(),
          ram: z.number(),
          cpu: z.number(),
          ramUnit: z.enum(storageMemoryUnitType),
        }),
      ),
    shards: z.coerce.number(),
    replicas: z.coerce.number(),
    disks: z
      .array(
        z
          .string()
          .transform(val => JSON.parse(val))
          .pipe(
            z.object({
              name: z
                .string()
                .min(1, { message: 'The name is required' })
                .regex(
                  /^[a-z]+[a-z0-9_]*[a-z0-9]$/,
                  'Disk names must only contain lowercase alphanumeric characters or "_". Start with a letter & end with a letter or number.',
                ),
              type: z.object({
                name: z.string(),
              }),
              size: z
                .number()
                .min(10_000_000_000, { message: 'Disks must be at least 10 GB/GiB in size' }),
              unitType: z.enum(storageMemoryUnitType),
            }),
          ),
      )
      .min(1, { message: 'You need at least one disk' })
      .refine(
        disks => {
          const names = disks.map(disk => disk.name)
          return new Set(names).size === disks.length
        },
        {
          message: 'All the disks must have a unique name.',
        },
      ),
    version: z.string().min(1, { message: 'The ClickHouse version is required.' }),
    adminUser: z
      .string()
      .min(5, { message: 'The username must be at least 5 characters.' })
      .refine(val => !BANNED_USERNAMES.includes(val), { message: 'This username is not allowed.' }),
    adminPassword: z.string().min(8, { message: 'The password must be at least 8 characters.' }),
    confirmPassword: z.string().min(1, { message: 'Please repeat the password.' }),
    ipSafeList: z.preprocess(
      val => (val === '' ? undefined : val),
      z
        .string()
        .transform(val => val?.split(','))
        .pipe(
          z.array(z.string().trim()).refine(
            IPs =>
              IPs.every(ip =>
                // Regex to test that the ip is an IPv4 address with or without CIDR block
                /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/.test(ip),
              ),
            {
              message: 'Some IP address is not valid.',
            },
          ),
        )
        .optional(),
    ),
  })
  .refine(
    s => {
      const st1Disks = s.disks.filter(disk => disk.type.name === 'st1')
      return st1Disks.length
        ? st1Disks.every(disk => disk.size >= s.shards * s.replicas * 150_000_000_000)
        : true
    },
    s => ({
      message: `For st1 disks on AWS, the size should be at least 150 GB per node (${
        s.shards * s.replicas * 150
      } GB for ${s.shards * s.replicas} nodes).`,
      path: ['disks'],
    }),
  )
  .refine(
    s => {
      const gp2Disks = s.disks.filter(disk => disk.type.name === 'gp2')
      return gp2Disks.length
        ? gp2Disks.every(disk => disk.size >= s.shards * s.replicas * 5_000_000_000)
        : true
    },
    s => ({
      message: `For gp2 disks on AWS, the size should be at least 5 GB per node (${
        s.shards * s.replicas * 5
      } GB for ${s.shards * s.replicas} nodes).`,
      path: ['disks'],
    }),
  )
  .refine(s => s.adminPassword === s.confirmPassword, {
    message: 'The password does not match.',
    path: ['confirmPassword'],
  })
  .refine(s => (s.provider === 'gcp' ? s.zones && s.zones.length > 0 : true), {
    message: 'You need to select at least one zone.',
    path: ['zones'],
  })
  .refine(
    s =>
      s.provider === 'gcp' && s.zones?.length
        ? s.shards >= s.zones.length && s.shards % s.zones.length === 0
        : true,
    {
      message: 'The shards must be a multiple of the number of selected zones.',
      path: ['shards'],
    },
  )
export type CreateMCHClusterData = z.infer<typeof createMCHClusterSchema>

export const connectClickHouseClusterSchema = z.object({
  projectId,
  name: z.preprocess(
    val => String(val).trim(),
    z
      .string()
      .min(1, { message: 'The cluster name is required.' })
      .max(15, { message: 'The cluster name cannot exceed 15 characters.' })
      .regex(/^[a-z]+[-a-z0-9]*[a-z0-9]$/, {
        message:
          'The cluster name must be all lowercase, end with an alphanumeric character and contain no special characters other than dashes.',
      }),
  ),
  host: z.preprocess(
    val => String(val).trim(),
    z.string().min(1, { message: 'The cluster host is required.' }),
  ),
  httpPort: z.coerce
    .number({ invalid_type_error: 'The cluster httpPort must be a number.' })
    .min(1, { message: 'The cluster httpPort is required.' }),
  tcpPort: z.coerce
    .number({ invalid_type_error: 'The cluster tcpPort must be a number.' })
    .min(1, { message: 'The cluster tcpPort is required.' }),
  secure: zx.checkbox(),
  adminUser: z.preprocess(
    val => String(val).trim(),
    z.string().min(1, { message: 'The username is required.' }),
  ),
  adminPassword: z.preprocess(
    val => String(val).trim(),
    z.string().min(1, { message: 'The password is required.' }),
  ),
  external: z.boolean().default(true),
})
export type ConnectClickHouseClusterData = z.infer<typeof connectClickHouseClusterSchema>

export const clickHouseUpdateIPSafeListSchema = z.object({
  projectId,
  clusterId: z.string().min(1, { message: 'The cluster id is required' }),
  replace: z.boolean().default(true),
  ipList: z
    .string()
    .transform(val => (val ? val.split(',') : []))
    .pipe(
      z.array(z.string().trim().optional()).refine(
        IPs =>
          IPs.every(
            ip =>
              // Regex to test that the ip is an IPv4 address with or without CIDR block
              ip && /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/.test(ip),
          ),
        {
          message: 'Some IP address is not valid.',
        },
      ),
    ),
})
export type ClickHouseUpdateIPSafeListData = z.infer<typeof clickHouseUpdateIPSafeListSchema>

export const clickHouseDeleteClusterSchema = z.object({
  projectId,
  clusterId: z.string().min(1, { message: 'The cluster id is required' }),
})
export type ClickHouseDeleteClusterData = z.infer<typeof clickHouseDeleteClusterSchema>
