import { memo, type SVGProps } from 'react'

const SvgCheckCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.333 14.773V16a13.333 13.333 0 1 1-7.906-12.187m7.906 1.52L16 18.68l-4-4"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgCheckCircle)
export default Memo
