import { useSearchParams } from 'react-router-dom'

import { useDecodedToken, useUser } from '~/shared/model/auth/useAuth'

import { Link } from '~/components/Link'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import { UpdateUserProfileForm } from '../updateUserProfile'

export const Profile = () => {
  const user = useUser()
  const dt = useDecodedToken()
  const [searchParams] = useSearchParams()

  const isUsernamePasswordConnection = user?.connection === 'Username-Password-Authentication'
  const isEditing = searchParams.get('edit') === 'profile' && isUsernamePasswordConnection
  return (
    <>
      {isEditing ? (
        <UpdateUserProfileForm title="Basic Information" showCancel />
      ) : (
        <Stack justify="between">
          <Stack space="small">
            <Text size="medium">
              {dt?.given_name} {dt?.family_name}
            </Text>
            <Text color="gray600">{dt?.email}</Text>
          </Stack>
          {isUsernamePasswordConnection && (
            <Stack direction="horizontal">
              <Link
                to="."
                type="button"
                searchParams={{
                  edit: 'profile',
                }}
              >
                Update your profile
              </Link>
            </Stack>
          )}
        </Stack>
      )}
    </>
  )
}
