import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { hepicTokenQuery } from '~/entities/integrations/hepic'

import { CopyButton } from '~/shared/ui/CopyButton'

import { Card } from '~/components/Card'
import { Input } from '~/components/Input'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import { CreateToken } from './CreateToken'

type ManageHepicTokensProps = {
  projectId: Project['id']
  projectSlug: Project['slug']
}

export function ManageHepicTokens({ projectId, projectSlug }: ManageHepicTokensProps) {
  const { data: tokens } = useQuery(hepicTokenQuery(projectId))
  //const { data: config } = useQuery(hepicConfigQuery(projectId))

  return (
    <Card>
      <Card.Header
        title="Scoped tokens"
        description="You can create scoped tokens with read/write permissions to avoid sharing the main API
            key/secret."
      />
      <Card.Section>
        <Stack direction="horizontal" justify="between" align="center">
          <Text size="small">
            This hepic instance has {tokens?.length ?? 0} scoped
            {tokens?.length === 1 ? ' token' : ' tokens'}
          </Text>
          <CreateToken projectId={projectId} projectSlug={projectSlug} />
        </Stack>
        <Stack space="medium">
          {tokens &&
            Boolean(tokens.length) &&
            tokens.map(token => (
              <Input
                key={token.id}
                isReadOnly
                label={`${token.name} (${token.scope})`}
                value={token.token}
                suffix={
                  <Stack direction="horizontal" space="xxsmall">
                    <CopyButton value={token.token} />
                  </Stack>
                }
              />
            ))}
        </Stack>
      </Card.Section>
    </Card>
  )
}
