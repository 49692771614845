import { useState } from 'react'
import { useFetcher } from 'react-router-dom'

import { ADD_TAX_ID } from '~/entities/billing'

import { Box } from '~/components/Box'
import { Button } from '~/components/Button'
import { Grid } from '~/components/Grid'
import { Input } from '~/components/Input'
import { Select } from '~/components/Select'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import taxIdTypes from '../model/taxIDTypes.json'

const normalizedTaxIdTypes = taxIdTypes.reduce(
  (acc, item) => {
    acc[item.country] = item
    return acc
  },
  {} as Record<
    string,
    { country: string; taxIdTypes: { type: string; description: string; example: string }[] }
  >,
)

export const TaxIdForm = ({
  billingAccountId,
  onCancel,
}: {
  billingAccountId: string
  onCancel?: () => void
}) => {
  const fetcher = useFetcher()
  const [taxIdType, setTaxIdType] = useState('')
  const [country, setCountry] = useState('')

  const hasMultipleTaxIdTypes = normalizedTaxIdTypes[country]?.taxIdTypes.length > 1
  const defaultTaxIdType = normalizedTaxIdTypes[country]?.taxIdTypes?.[0]?.type || ''

  if (!billingAccountId) return null

  return (
    <Stack direction="vertical">
      <fetcher.Form method="post">
        <input hidden readOnly name="billingAccountId" value={billingAccountId} />
        <Box marginBottom="medium">
          <Grid columns={3}>
            <Grid.Item>
              <Select
                name="country"
                label="Country"
                defaultText="Select a country"
                items={taxIdTypes}
                selectedKey={country}
                onSelectionChange={v => setCountry(v.toString())}
              >
                {item => (
                  <Select.Item key={item.country} textValue={item.country}>
                    <Text>{item.country}</Text>
                  </Select.Item>
                )}
              </Select>
            </Grid.Item>

            {hasMultipleTaxIdTypes ? (
              <Grid.Item span={2}>
                <Select
                  name="type"
                  label="Tax ID Type"
                  defaultText="Select one"
                  items={normalizedTaxIdTypes[country].taxIdTypes}
                  onSelectionChange={value => setTaxIdType(value.toString())}
                  errorMessage={fetcher.data?.errors?.fieldErrors.type}
                >
                  {item => (
                    <Select.Item key={item.type} textValue={item.description}>
                      <Text>{item.description}</Text>
                    </Select.Item>
                  )}
                </Select>
              </Grid.Item>
            ) : (
              <input hidden readOnly name="type" value={defaultTaxIdType} />
            )}

            {country && (
              <Grid.Item span={hasMultipleTaxIdTypes ? 3 : 2}>
                <Input
                  name="value"
                  placeholder={
                    hasMultipleTaxIdTypes
                      ? normalizedTaxIdTypes[country]?.taxIdTypes.find(
                          item => item.type === taxIdType,
                        )?.example
                      : normalizedTaxIdTypes[country]?.taxIdTypes[0].example
                  }
                  label="Tax ID Number"
                  errorMessage={fetcher.data?.errors?.fieldErrors.value}
                />
              </Grid.Item>
            )}
          </Grid>
        </Box>
        <Stack direction="horizontal" space="small">
          <Button
            color="brand"
            type="submit"
            name="intent"
            value={ADD_TAX_ID}
            isDisabled={fetcher.state === 'submitting'}
            isLoading={fetcher.state === 'submitting'}
          >
            Save
          </Button>
          {onCancel && (
            <Button color="transparent" onPress={onCancel}>
              Cancel
            </Button>
          )}
        </Stack>
      </fetcher.Form>
    </Stack>
  )
}
