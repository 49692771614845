import {
  type AllHTMLAttributes,
  type HTMLAttributes,
  type ReactElement,
  type ReactNode,
  useRef,
} from 'react'
import {
  type AriaButtonProps,
  type AriaDialogProps,
  mergeProps,
  useButton,
  useDialog,
  useFocusRing,
  useHover,
} from 'react-aria'

import { createReactContext } from '~/shared/lib/createReactContext'

import { Box } from '~/components/Box'
import { Text } from '~/components/Text'

import * as styles from './Dialog.css'

export type DialogProps = {
  children:
    | ReactElement<DialogHeaderProps>
    | ReactElement<DialogFooterProps>
    | ReactElement<DialogContentProps>
    | (
        | ReactElement<DialogHeaderProps>
        | ReactElement<DialogFooterProps>
        | ReactElement<DialogContentProps>
      )[]
} & AriaDialogProps

type DialogContextValue = {
  titleProps: HTMLAttributes<HTMLElement>
}

const [useDialogContext, DialogContextProvider] = createReactContext<DialogContextValue>()

export function Dialog(props: DialogProps) {
  const { children, ...rest } = props
  const ref = useRef<HTMLDivElement>(null)
  const { dialogProps, titleProps } = useDialog(rest, ref)

  return (
    <div {...dialogProps} ref={ref} className={styles.wrapper}>
      <DialogContextProvider value={{ titleProps }}>{children}</DialogContextProvider>
    </div>
  )
}

type DialogHeaderProps = {
  /** Title for the dialog to be displayed in the header */
  title?: string
  align?: 'left' | 'center' | 'right'
}
/**
 * Compound component to show the title of the dialog in the header
 */
export const DialogHeader = ({ title, align }: DialogHeaderProps) => {
  const { titleProps } = useDialogContext()

  if (!title) return null
  return (
    <Box padding="large" borderBottomWidth="px" borderStyle="solid" borderColor="whiteTransparent">
      <Text
        {...titleProps}
        align={align ? align : 'left'}
        as="h1"
        size="xxlarge"
        color={{ darkMode: 'gray400', lightMode: 'black' }}
      >
        {title}
      </Text>
    </Box>
  )
}

type DialogActionProps = AriaButtonProps<'button'> &
  Pick<AllHTMLAttributes<HTMLElement>, 'title' | 'name' | 'value'>

type DialogFooterProps = {
  children: ReactElement<DialogActionProps> | (ReactElement<DialogActionProps> | null)[]
}

/**
 * Compound component to show the footer of the dialog
 */
export const DialogFooter = ({ children }: DialogFooterProps) => (
  <div className={styles.footer}>{children}</div>
)

/**
 * Compound component to show a button stretching all space from the footer
 */
export const DialogAction = (props: DialogActionProps) => {
  const { children, autoFocus, title, name, value } = props
  const ref = useRef<HTMLButtonElement>(null)
  const { buttonProps } = useButton({ ...props, elementType: 'button' }, ref)
  const { hoverProps, isHovered } = useHover({})
  const { isFocusVisible, focusProps } = useFocusRing({ autoFocus })
  const { color: unusedColor, ...restProps } = mergeProps(buttonProps, hoverProps, focusProps)

  return (
    <Box
      {...restProps}
      as="button"
      ref={ref}
      className={styles.action({ isHovered, isFocused: isFocusVisible })}
      title={title}
      name={name}
      value={value}
    >
      {children}
    </Box>
  )
}

type DialogContentProps = {
  children: ReactNode
}
/**
 * Compound component to show the main content of the dialog
 */
export const DialogContent = ({ children }: DialogContentProps) => (
  <Box padding="large">{children}</Box>
)
