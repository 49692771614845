import { memo, type SVGProps } from 'react'

const SvgDatabase = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.25 4.167c0 1.38-3.694 2.5-8.25 2.5S.75 5.547.75 4.167m16.5 0c0-1.381-3.694-2.5-8.25-2.5S.75 2.786.75 4.167m16.5 0v11.666c0 1.384-3.667 2.5-8.25 2.5s-8.25-1.116-8.25-2.5V4.167M17.25 10c0 1.383-3.667 2.5-8.25 2.5S.75 11.383.75 10"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgDatabase)
export default Memo
