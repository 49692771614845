import { type ReactNode, useRef } from 'react'
import {
  type AriaTooltipProps,
  mergeProps,
  type TooltipTriggerProps,
  useTooltip,
  useTooltipTrigger,
} from 'react-aria'
import { type TooltipTriggerState, useTooltipTriggerState } from 'react-stately'

import { Box } from '../Box'

import * as styles from './Tooltip.css'

type CommonTooltipProps = {
  /**
   * Controls the position of the popover
   */
  position?: 'top' | 'bottom'
  /**
   * Component that triggers the display of the popover
   */
  children: ReactNode
}

type TooltipBoxProps = {
  state: TooltipTriggerState
} & AriaTooltipProps &
  CommonTooltipProps

const TooltipBox = ({ position, state, ...props }: TooltipBoxProps) => {
  const { tooltipProps } = useTooltip(props, state)

  return (
    <span className={styles.tooltip({ position })} {...mergeProps(props, tooltipProps)}>
      {props.children}
    </span>
  )
}

export type TooltipProps = {
  /**
   * What should be displayed inside the tooltip
   */
  content: ReactNode
} & TooltipTriggerProps &
  CommonTooltipProps

/**
 * Displays a tooltip with custom content when overing a component.
 */
export const Tooltip = ({ position = 'top', children, content, ...props }: TooltipProps) => {
  const state = useTooltipTriggerState(props)
  const ref = useRef(null)

  const { triggerProps, tooltipProps } = useTooltipTrigger(props, state, ref)
  return (
    <Box position="relative">
      <Box ref={ref} {...triggerProps}>
        {children}
      </Box>
      {state.isOpen && content !== '' && (
        <TooltipBox position={position} state={state} {...tooltipProps}>
          {content}
        </TooltipBox>
      )}
    </Box>
  )
}
