import { slugify, transformSnakeObjectKeysToCamel } from 'plunger'
import { z } from 'zod'

import { projectSchema } from '../projects/model'

const dependenciesMap: Record<string, string[]> = {
  loggen: ['qryn'],
}

const product = z.object({
  id: z.coerce.string(),
  name: z.string(),
  description: z.string(),
  isAvailable: z.boolean(),
  tags: z
    .array(z.object({ tag: z.string() }))
    .optional()
    .default([]),
  dependencies: z.array(z.string()).optional().default([]),
})

export const productSchema = z
  .preprocess(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    (data: any) => transformSnakeObjectKeysToCamel({ ...data, isAvailable: data.available }),
    product,
  )
  .transform(data => ({ ...data, slug: slugify(data.name) }))
  .transform(data => ({ ...data, dependencies: dependenciesMap[data.slug] ?? data.dependencies }))

export const productWithProjectsSchema = z
  .preprocess(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    (data: any) => transformSnakeObjectKeysToCamel({ ...data, isAvailable: data.available }),
    product.extend({
      projects: z.array(projectSchema),
    }),
  )
  .transform(data => ({ ...data, slug: slugify(data.name) }))
  .transform(data => ({ ...data, dependencies: dependenciesMap[data.slug] ?? data.dependencies }))

export type Product = z.infer<typeof productSchema>
