import { useFetcher, useSearchParams } from 'react-router-dom'
import { type UpdateUserPasswordData } from 'api-client'
import { type typeToFlattenedError } from 'zod'

import { UPDATE_PASSWORD } from '~/entities/users'

import { Box } from '~/components/Box'
import { Button } from '~/components/Button'
import { Link } from '~/components/Link'
import { Password as PasswordField } from '~/components/Password'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

type ActionData = {
  fields: UpdateUserPasswordData
  message?: string
  errors?: typeToFlattenedError<UpdateUserPasswordData>
}

/**
 * Feature component with a Form to submit a user password update.
 */
export const UpdateUserPasswordForm = () => {
  const fetcher = useFetcher<ActionData>()
  const [searchParams] = useSearchParams()

  const isEditing = searchParams.get('edit') === 'password'

  return (
    <>
      {isEditing ? (
        <fetcher.Form method="patch">
          <Stack space="medium">
            <Text>Change Password</Text>
            {/* Required for accessibility reasons: https://goo.gl/9p2vKq */}
            <input hidden type="text" autoComplete="username" />
            <Stack direction={{ mobile: 'vertical', tablet: 'horizontal' }} space="small">
              <Box flex={1}>
                <PasswordField
                  label="New password"
                  id="newPassword"
                  name="newPassword"
                  placeholder="********"
                  autoComplete="new-password"
                  errorMessage={
                    fetcher.state === 'idle' && fetcher.data?.errors?.fieldErrors?.newPassword?.[0]
                  }
                />
              </Box>
              <Box flex={1}>
                <PasswordField
                  label="Confirm new password"
                  id="newPasswordVerification"
                  name="newPasswordVerification"
                  placeholder="********"
                  autoComplete="new-password"
                  errorMessage={
                    fetcher.state === 'idle' &&
                    fetcher.data?.errors?.fieldErrors.newPasswordVerification
                  }
                />
              </Box>
            </Stack>
            <Stack direction="horizontal" space="small">
              <Button
                color="brand"
                type="submit"
                isDisabled={fetcher.state === 'submitting'}
                isLoading={fetcher.state === 'submitting'}
                name="intent"
                value={UPDATE_PASSWORD}
              >
                Save the password
              </Button>
              <Link to="." type="button" buttonColor="transparent">
                Cancel
              </Link>
            </Stack>
          </Stack>
        </fetcher.Form>
      ) : (
        <Link to="." type="button" searchParams={{ edit: 'password' }}>
          Change your password
        </Link>
      )}
    </>
  )
}
