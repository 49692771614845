/**
 * Custom hook to get the current environment where the app is beeing executed
 * Extracted from VITE_ENVIRONMENT env variable
 */
export function useEnvironment() {
  if (!import.meta.env.VITE_ENVIRONMENT)
    throw new Error('The ENVIRONMENT env variable is not defined. Check your .env file')
  const isProduction = import.meta.env.VITE_ENVIRONMENT === 'production'
  const isDev = import.meta.env.VITE_ENVIRONMENT === 'dev'
  const isLocal = import.meta.env.VITE_ENVIRONMENT === 'local'

  return { isProduction, isLocal, isDev }
}
