// Not possible according to our rules.
// TODO: move the notifications feature into the shared module or split between logic and component/container
import { notify } from '~/features/notifications'

import { isApiError } from '~/shared/model/errors'

/**
 * Handles errors related to data mutation by checking if the error is of type
 * 'data_mutation'. If it is, an error notification is shown and a new Response
 * object is returned with an empty object. If the error is not of type
 * 'data_mutation', it is thrown.
 *
 * @param {any} error - the error object to handle
 * @param {Record<string, any>} returnedData - data to return if the error is of type 'data_mutation'
 * @return {Response} - a new empty Response
 * @throws the original error object if it is not of type 'data_mutation'
 */
export function handleDataMutationError(error: any, returnedData: Record<string, any> = {}) {
  if (isApiError(error) && error.type === 'data_mutation') {
    notify.error(error.message)
    return new Response(JSON.stringify(returnedData), {
      status: 400,
      headers: {
        'Content-Type': 'application/json; utf-8',
      },
    })
  }
  throw error
}
