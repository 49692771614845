import { type AllHTMLAttributes, type RefObject, useRef } from 'react'
import { type AriaButtonProps, mergeProps, useButton, useFocusRing, useHover } from 'react-aria'
import { type Prettify } from 'plunger'

import { Box } from '../Box'
import { Loading } from '../Loading'
import { Text } from '../Text'

import { type Variants } from './Button.css'
import * as styles from './Button.css'

type PickedDOMProps = Pick<AllHTMLAttributes<HTMLElement>, 'title' | 'name' | 'value'>
type CommonProps = {
  /**
   * Polimorphic prop to define the underlying DOM element.
   * @default button
   */
  as?: 'button' | 'a'
  /**
   * Add a loading indicator to the button while mantaining the same size it has in the previous state.
   * @default false
   */
  isLoading?: boolean
  /**
   * Controll the button's ref from outside. If undefined defaults to the inner ref.
   */
  buttonRef?: RefObject<HTMLButtonElement>
} & Variants &
  PickedDOMProps

type ConditionalProps = AriaButtonProps<'button'> | AriaButtonProps<'a'>

export type ButtonProps = Prettify<CommonProps & ConditionalProps>

/**
 * Renders an actionable element styled as a button and with proper a11y defaults.
 */
export const Button = (props: ButtonProps) => {
  const {
    as = 'button',
    children,
    isDisabled = false,
    isLoading = false,
    color = 'neutral',
    size = 'medium',
    transformToUppercase = true,
    title,
    name,
    value,
    buttonRef,
  } = props
  const innerRef = useRef<HTMLButtonElement>(null)
  const ref = buttonRef ?? innerRef
  const { buttonProps, isPressed } = useButton({ ...props, elementType: as }, ref)
  const { hoverProps, isHovered } = useHover({})
  const { focusProps, isFocusVisible } = useFocusRing()
  const mergedProps = mergeProps(buttonProps, hoverProps, focusProps)

  return (
    <Box
      {...mergedProps}
      as={as}
      ref={ref}
      title={title}
      name={name}
      value={value}
      className={styles.variants({
        color,
        size,
        isDisabled,
        isHovered: isHovered && !isDisabled,
        isPressed,
        isFocused: isFocusVisible,
        transformToUppercase,
      })}
    >
      <Text as="span" size="inherit" weight="inherit" color={isLoading ? 'transparent' : undefined}>
        {children}
      </Text>
      {isLoading && (
        <Box position="absolute">
          <Loading
            size={styles.mapButtonSizeVariantToIconSize[size]}
            color={styles.mapButtonColorVariantToIconColor[color]}
          />
        </Box>
      )}
    </Box>
  )
}
