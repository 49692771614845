import { useEffect } from 'react'
import {
  type ActionFunctionArgs,
  Form,
  json,
  type LoaderFunctionArgs,
  redirect,
  useActionData,
  useNavigation,
} from 'react-router-dom'
import { type QueryClient, useQuery } from '@tanstack/react-query'
import { type CreateMCHClusterData, isZodError, PROVIDERS } from 'api-client'
import { authorize } from 'keycloak'
import { type ZodFormattedError } from 'zod'

import {
  DiskInput,
  EstimadedClusterCost,
  useCreateMCHClusterForm,
  useCreateMCHClusterFormActions,
  ZonesInput,
} from '~/features/createClickHouseCluster'
import { isDefaultCluster } from '~/features/createClickHouseCluster/model/store'
import { shouldAllowClickHouseClusterCreation } from '~/features/noPaymentMethodLimitations'
import { notify } from '~/features/notifications'

import { currentBillingAccountQuery } from '~/entities/billing'
import { integrationsQuery } from '~/entities/integrations/base'
import {
  clickHouseClustersQuery,
  clickHouseVersionsQuery,
  diskTypesQuery,
  groupRegionsByContinent,
  machinesQuery,
  ProviderIcons,
  regionsQuery,
} from '~/entities/integrations/clickhouse'
import {
  checkProjectSlugParam,
  getProjectIdFromParams,
  useProjectIdParam,
} from '~/entities/projects'

import { apiClient, handleDataMutationError } from '~/shared/api'
import { getFormData } from '~/shared/lib/parseRequest'
import { authStore } from '~/shared/model/auth'
import { isApiError } from '~/shared/model/errors'

import { Box } from '~/components/Box'
import { Button } from '~/components/Button'
import { Card } from '~/components/Card'
import { Grid } from '~/components/Grid'
import { HeadTag } from '~/components/HeadTag'
import { Icon } from '~/components/Icon'
import { ArrowRightThin } from '~/components/Icons'
import { Input } from '~/components/Input'
import { Message } from '~/components/Message'
import { Password } from '~/components/Password'
import { Radio } from '~/components/Radio'
import { Select } from '~/components/Select'
import { Stack } from '~/components/Stack'
import { Stepper } from '~/components/Stepper'
import { Text } from '~/components/Text'

export function loader(queryClient: QueryClient) {
  return async ({ params }: LoaderFunctionArgs) =>
    authorize(authStore, async () => {
      const { projectId, projectSlug } = checkProjectSlugParam(params)

      if (!(await shouldAllowClickHouseClusterCreation(queryClient, { projectId, projectSlug }))) {
        return redirect(`/${projectSlug}/stack`)
      }

      try {
        const [
          awsRegions,
          gcpRegions,
          awsMachines,
          gcpMachines,
          awsDiskTypes,
          gcpDiskTypes,
          clickHouseVersions,
          billingAccount,
        ] = await Promise.all([
          queryClient.ensureQueryData(regionsQuery(projectId, 'aws')),
          queryClient.ensureQueryData(regionsQuery(projectId, 'gcp')),
          queryClient.ensureQueryData(machinesQuery(projectId, 'aws')),
          queryClient.ensureQueryData(machinesQuery(projectId, 'gcp')),
          queryClient.ensureQueryData(diskTypesQuery(projectId, 'aws')),
          queryClient.ensureQueryData(diskTypesQuery(projectId, 'gcp')),
          queryClient.ensureQueryData(clickHouseVersionsQuery(projectId)),
          queryClient.ensureQueryData(currentBillingAccountQuery),
        ])

        return json({
          regions: { aws: awsRegions, gcp: gcpRegions },
          machines: { aws: awsMachines, gcp: gcpMachines },
          diskTypes: { aws: awsDiskTypes, gcp: gcpDiskTypes },
          clickHouseVersions,
          billingAccount,
        })
      } catch (error) {
        if (isApiError(error) && error.type === 'data_query') {
          throw json(
            {
              message: `We could not fetch the necessary data to create a ClickHouse cluster`,
              description: `Please refresh the page and try again. If the issue persist please contact support.`,
            },
            { status: 400 },
          )
        }
        throw error
      }
    })
}

type ActionData = { fields: CreateMCHClusterData; errors?: ZodFormattedError<CreateMCHClusterData> }

export function action(queryClient: QueryClient) {
  return async ({ request, params }: ActionFunctionArgs) => {
    const projectId = getProjectIdFromParams(params)
    if (!projectId) {
      notify.error('No project has been selected.')
      return json({})
    }
    const data = await getFormData(request)

    try {
      const { message } = await apiClient.clickhouse.createClickHouseCluster({
        ...data,
        projectId: projectId,
      })
      notify.success(message)
      await queryClient.invalidateQueries({
        queryKey: clickHouseClustersQuery(projectId).queryKey,
        type: 'all',
      })

      return redirect(`/${params.projectSlug}/stack/clickhouse`)
    } catch (error) {
      if (isZodError<CreateMCHClusterData>(error)) {
        return json({ fields: data, errors: error.format() }, { status: 400 })
      }
      return handleDataMutationError(error)
    }
  }
}

export default function CreateManagedClickHouseCluster() {
  const { projectId } = useProjectIdParam()
  const navigation = useNavigation()
  const actionData = useActionData() as ActionData | undefined

  const { updateProvider, updateRegion, updateMachine, updateShards, updateReplicas, addDisk } =
    useCreateMCHClusterFormActions()
  const provider = useCreateMCHClusterForm(state => state.provider)
  const region = useCreateMCHClusterForm(state => state.region)
  const machine = useCreateMCHClusterForm(state => state.machine)
  const shards = useCreateMCHClusterForm(state => state.shards)
  const replicas = useCreateMCHClusterForm(state => state.replicas)
  const disks = useCreateMCHClusterForm(state => state.disks)

  const { data: regions } = useQuery(regionsQuery(projectId, provider))
  const { data: machines } = useQuery(machinesQuery(projectId, provider))
  const { data: diskTypes } = useQuery(diskTypesQuery(projectId, provider))
  const { data: clickHouseVersion } = useQuery(clickHouseVersionsQuery(projectId))

  const { data: hasPaymentMethod } = useQuery({
    ...currentBillingAccountQuery,
    select: data => Boolean(data.paymentMethodId),
  })

  const { data: isOnTrial } = useQuery({
    ...integrationsQuery(projectId),
    select: data =>
      data.find(integration => integration.product.slug === 'clickhouse')?.status === 'trial',
  })

  const showGCPZonesFields = provider === 'gcp' && Boolean(region)

  useEffect(() => {
    if (!hasPaymentMethod && machines) {
      updateMachine(machines[0].name)
    }
  }, [hasPaymentMethod, machines, updateMachine])

  return (
    <>
      <HeadTag tag="title" headId="title">
        {import.meta.env.MODE !== 'production' ? `(${import.meta.env.VITE_ENVIRONMENT}) ` : ''}
        Create cluster - Gigapipe
      </HeadTag>
      <Stack direction="vertical" space="large">
        <Text size="xxlarge">Create a Cluster</Text>

        <Form method="post">
          <Grid columns={12}>
            <Grid.Item span={{ mobile: 12, tablet: 6, desktop: 8 }}>
              <Stack space="small">
                <Card>
                  <Card.Section>
                    <Input
                      name="name"
                      label="Cluster name"
                      placeholder="Give it a name"
                      errorMessage={actionData?.errors?.name?._errors.join('\n')}
                    />
                  </Card.Section>
                  <Card.Section>
                    <Radio.Group
                      label="Provider"
                      name="provider"
                      value={provider ?? ''}
                      onChange={updateProvider}
                      errorMessage={actionData?.errors?.provider?._errors}
                    >
                      <Stack direction="horizontal" space="small" wrap>
                        {PROVIDERS.map(p => (
                          <Radio key={p.slug} value={p.slug} hasBorder>
                            <Icon size="xxlarge">{ProviderIcons[p.slug]}</Icon>
                            <Text size="small">{p.name}</Text>
                          </Radio>
                        ))}
                      </Stack>
                    </Radio.Group>
                  </Card.Section>
                  <Card.Section>
                    <Select
                      name="regionCode"
                      label="Region"
                      defaultText="Select a region"
                      items={groupRegionsByContinent(regions)}
                      selectedKey={region}
                      onSelectionChange={updateRegion}
                      isDisabled={!provider}
                      errorMessage={actionData?.errors?.region?._errors}
                    >
                      {section => (
                        <Select.Section key={section.id} title={section.title}>
                          {section.regions.map(option => (
                            <Select.Item key={option.code} textValue={option.title}>
                              <Text>{option.title}</Text>
                            </Select.Item>
                          ))}
                        </Select.Section>
                      )}
                    </Select>
                    <input
                      hidden
                      name="region"
                      defaultValue={JSON.stringify(regions?.find(r => r.code === region))}
                    />
                  </Card.Section>
                  {showGCPZonesFields && (
                    <Card.Section>
                      <ZonesInput
                        key={region}
                        region={region}
                        errors={{
                          zonal: actionData?.errors?.zonal?._errors,
                          zones: actionData?.errors?.zones?._errors,
                          controlPlaneZone: actionData?.errors?.controlPlaneZone?._errors,
                        }}
                        projectId={projectId}
                      />
                    </Card.Section>
                  )}
                </Card>
                <Card>
                  <Card.Section>
                    {!hasPaymentMethod && (
                      <Box marginBottom="small">
                        <Message type="info" size="small">
                          Trial accounts (with no payment method added) are limited to a single
                          cluster on the default configuration: 1 shard, 2 replicas and 2CPU per
                          machine with 1 150GB ssd.
                        </Message>
                      </Box>
                    )}
                    <Select
                      key={provider}
                      name="machineName"
                      label="Machine"
                      defaultText="Select a machine"
                      items={machines ?? []}
                      selectedKey={hasPaymentMethod ? machine : machines?.[0].name ?? ''}
                      onSelectionChange={updateMachine}
                      isDisabled={!provider || !region || !hasPaymentMethod}
                      errorMessage={actionData?.errors?.machine?._errors}
                    >
                      {item => (
                        <Select.Item key={item.name} textValue={item.title}>
                          <Text>{item.title}</Text>
                        </Select.Item>
                      )}
                    </Select>
                    <input
                      hidden
                      name="machine"
                      defaultValue={JSON.stringify(
                        hasPaymentMethod ? machines?.find(m => m.name === machine) : machines?.[0],
                      )}
                    />
                  </Card.Section>
                  <Card.Section>
                    <Stack space="small">
                      <Stack direction={{ mobile: 'vertical', desktop: 'horizontal' }}>
                        <Stepper
                          name="shards"
                          label="Shards"
                          onChange={updateShards}
                          value={shards}
                          formatOptions={{ maximumFractionDigits: 0 }}
                          minValue={1}
                          maxValue={hasPaymentMethod ? 999 : 1}
                          errorMessage={actionData?.errors?.shards?._errors}
                        />
                        <Stepper
                          name="replicas"
                          label="Replicas"
                          onChange={updateReplicas}
                          value={replicas}
                          formatOptions={{ maximumFractionDigits: 0 }}
                          minValue={1}
                          maxValue={hasPaymentMethod ? 999 : 2}
                        />
                      </Stack>
                    </Stack>
                  </Card.Section>
                  <Card.Section>
                    <Stack space="small">
                      <Stack direction="horizontal" justify="between" align="center">
                        <Stack direction="horizontal" space="xxsmall">
                          <Text>Disks</Text>
                        </Stack>
                        <Button
                          size="small"
                          color="outline"
                          onPress={addDisk}
                          isDisabled={!provider || !hasPaymentMethod}
                        >
                          + Add disk
                        </Button>
                      </Stack>
                      <Stack space="xxsmall">
                        <Grid columns={12} space="small">
                          <Grid.Item span={4}>
                            <Text size="small">Name</Text>
                          </Grid.Item>
                          <Grid.Item span={2}>
                            <Text size="small">Type</Text>
                          </Grid.Item>
                          <Grid.Item span={3}>
                            <Text size="small">Total Size</Text>
                          </Grid.Item>
                          <Grid.Item span={2}>
                            <Text size="small">Unit</Text>
                          </Grid.Item>
                        </Grid>
                        {Object.keys(disks).map((id, index) => (
                          <DiskInput
                            index={index}
                            key={`${provider}-${id}`}
                            id={id}
                            diskTypes={diskTypes ?? []}
                            isDefault={index === 0}
                            isDisabled={!provider || !hasPaymentMethod}
                            errors={actionData?.errors?.disks?.[index]}
                          />
                        ))}
                        {actionData?.errors?.disks?._errors && (
                          <Text color="red500">{actionData.errors.disks._errors}</Text>
                        )}
                      </Stack>
                    </Stack>
                  </Card.Section>
                </Card>
                <Card>
                  <Card.Section>
                    <Select
                      name="version"
                      label="ClickHouse version"
                      defaultText="Select a version"
                      items={clickHouseVersion ?? []}
                      defaultSelectedKey={clickHouseVersion?.[0].imageTag}
                    >
                      {item => (
                        <Select.Item key={item.imageTag} textValue={item.name}>
                          <Text>{item.name}</Text>
                        </Select.Item>
                      )}
                    </Select>
                  </Card.Section>
                  <Card.Section>
                    <Input
                      name="ipSafeList"
                      label="Safe list IPs"
                      description="Coma separated list of IPs that can access your cluster. Leave empty to allow all access."
                      errorMessage={actionData?.errors?.ipSafeList?._errors}
                    />
                  </Card.Section>
                  <Card.Section>
                    <Stack space="small">
                      <Input
                        name="adminUser"
                        label="Admin username"
                        autoComplete="username"
                        errorMessage={actionData?.errors?.adminUser?._errors}
                      />
                      <Grid columns={{ mobile: 1, tablet: 2 }}>
                        <Password
                          name="adminPassword"
                          label="Admin password"
                          autoComplete="new-password"
                          errorMessage={actionData?.errors?.adminPassword?._errors}
                        />
                        <Password
                          name="confirmPassword"
                          label="Confirm password"
                          autoComplete="new-password"
                          errorMessage={actionData?.errors?.confirmPassword?._errors.join('\n')}
                        />
                      </Grid>
                    </Stack>
                  </Card.Section>
                </Card>
              </Stack>
            </Grid.Item>
            <Grid.Item span={{ mobile: 12, tablet: 6, desktop: 4 }}>
              <EstimadedClusterCost machines={machines} diskTypes={diskTypes} sticky>
                {isOnTrial &&
                  hasPaymentMethod &&
                  !isDefaultCluster({ machine, shards, replicas, disks }) && (
                    <Message size="small" type="warning">
                      Creating a custom configuration cluster will automatically end the free trial.
                      ClickHouse trials only apply to the default configuration cluster (1 shard, 2
                      replicas & 2CPU per node).
                    </Message>
                  )}
                <Button
                  type="submit"
                  color="brand"
                  isDisabled={navigation.state !== 'idle'}
                  isLoading={navigation.state !== 'idle'}
                >
                  <Stack direction="horizontal" space="xxsmall">
                    <span>Create Cluster</span>
                    <Icon>
                      <ArrowRightThin />
                    </Icon>
                  </Stack>
                </Button>
              </EstimadedClusterCost>
            </Grid.Item>
          </Grid>
        </Form>
      </Stack>
    </>
  )
}
