import { useState } from 'react'
import { useFetcher } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { ADD_GRAFANA_USER, grafanaUsersQuery } from '~/entities/integrations/grafana'
import { useProjectIdParam, usersFromProjectQuery } from '~/entities/projects'

import { useDecodedToken } from '~/shared/model/auth/useAuth'

import { Button } from '~/components/Button'
import { Link } from '~/components/Link'
import { Select } from '~/components/Select'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

type AddUserToGrafanaProps = {
  projectId: Project['id']
}

export const AddUserToGrafana = ({ projectId }: AddUserToGrafanaProps) => {
  const { projectSlug } = useProjectIdParam()
  const decodedToken = useDecodedToken()
  const isOrgAccount = Boolean(decodedToken?.organization_id)
  const { data: usersFromProject } = useQuery(usersFromProjectQuery(projectId, isOrgAccount))
  const fetcher = useFetcher()
  const submittedUserId = fetcher.formData?.get('users') as string
  const [selectedUser, setSelectedUser] = useState(submittedUserId ?? '')

  const { data: usersFromGrafana } = useQuery(grafanaUsersQuery(projectId, isOrgAccount))
  const usersToAddToGrafana = (usersFromProject ?? []).filter(
    user => !usersFromGrafana?.some(u => u.email === user.email),
  )
  if (isOrgAccount && !usersToAddToGrafana.length) {
    return (
      <Text>
        All members in your team have already been added to Grafana.
        <br />
        To add new members? Go to your{' '}
        <Link to={projectSlug ? `/${projectSlug}/organization` : '/organization'} type="text">
          organization&apos;s settings page
        </Link>
        .
      </Text>
    )
  }
  return (
    <fetcher.Form method="post">
      <Stack direction="vertical" space="small" align="start">
        <Select
          key={submittedUserId}
          name="users[0][id]"
          aria-label="Select a member"
          label="Select a member"
          items={usersToAddToGrafana}
          selectedKey={selectedUser}
          onSelectionChange={value => setSelectedUser(value.toString())}
          isDisabled={!isOrgAccount}
        >
          {item => (
            <Select.Item key={item.id} textValue={item.id.toString()}>
              <Text>
                {item.givenName + ' ' + item.familyName} ({item.email})
              </Text>
            </Select.Item>
          )}
        </Select>
        <input
          type="hidden"
          name="users[0][givenName]"
          defaultValue={usersToAddToGrafana.find(u => u.id.toString() === selectedUser)?.givenName}
        />
        <input
          type="hidden"
          name="users[0][familyName]"
          defaultValue={usersToAddToGrafana.find(u => u.id.toString() === selectedUser)?.familyName}
        />
        <input
          type="hidden"
          name="users[0][email]"
          defaultValue={usersToAddToGrafana.find(u => u.id.toString() === selectedUser)?.email}
        />
        <Button
          aria-label="Add user to Grafana"
          name="intent"
          value={ADD_GRAFANA_USER}
          type="submit"
          isLoading={fetcher.state === 'submitting'}
          isDisabled={fetcher.state === 'submitting' || !isOrgAccount}
        >
          Add to Grafana
        </Button>
      </Stack>
    </fetcher.Form>
  )
}
