import { memo, type SVGProps } from 'react'

const SvgBrokenPipe = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512">
    <path
      fill="currentColor"
      d="m23.2 127.3-2.2 2.3v157.1l2.3 2.1c2.3 2.1 3.1 2.2 25.3 2.2 20.2 0 23.4-.3 26.9-1.9C83 285.6 88 278 88 270v-4h77.3c76.9 0 77.4 0 79.3-2.1 2.1-2.4 2.1-5 0-24.4-.8-7.6-1.1-13.4-.5-14.1.4-.5 3.1-2.1 5.8-3.3 5.2-2.5 6.7-4.6 7.7-11.2.4-2.1 1-4.2 1.4-4.4.9-.5 8.8 14.1 8 14.7-.3.2-3.1 2.5-6.2 5.2-4.7 3.9-5.8 5.3-5.8 7.8 0 3.2 10.1 27.1 12.7 30 1.5 1.7 4.6 1.8 37.3 1.8 33.3 0 35.8-.1 37.3-1.8 1.7-1.9 2.2-6.8.9-8.8-2-3.1-5.5-3.4-35.7-3.4h-30.6l-3.4-7.7c-2-4.2-3.4-7.7-3.3-7.7.2-.1 3.1-2.6 6.6-5.5 5-4.3 6.2-5.9 6.2-8.2 0-3.1-18.7-39.6-22.3-43.7-1.1-1.2-3.2-2.2-4.7-2.2-4.9 0-6.6 3.3-9.6 18.7l-2.8 14.1-5.8 2.8c-9.5 4.6-9.4 4.2-7.9 20.7.7 7.8 1.5 15.2 1.8 16.4l.5 2.3H88v-88h16c15.7 0 16.1-.1 18.5-2.5 3-2.9 3.2-6.8.5-9.5-1.8-1.8-3.3-2-18.5-2H88v-4c0-8-5-15.6-12.5-19.1-3.5-1.6-6.8-1.9-27.1-1.9-22.8 0-23.1 0-25.2 2.3zm48.3 14.2 2.5 2.4v128.2l-2.5 2.4C69.1 277 68.8 277 52 277H35V139h17c16.8 0 17.1 0 19.5 2.5z"
    />
    <path
      fill="currentColor"
      d="M436.5 126.9C429 130.4 424 138 424 146v4H316c-106.7 0-108 0-110 2-2.9 2.9-2.6 7.4.6 9.9 2.7 2.1 2.9 2.1 110 2.1H424v88h-25.9c-24.6 0-26 .1-28.5 2.1-3.2 2.5-3.5 7-.6 9.9 1.9 1.9 3.3 2 28.5 2H424v4.2c.1 8.1 5.5 16 13.2 19.2 3 1.3 8.2 1.6 26.6 1.6 22.6 0 22.9 0 25-2.3 2.2-2.3 2.2-2.6 2.2-43.9 0-38.2-.1-41.7-1.7-43.6-2.7-3.1-6.6-3.5-9.7-1.1l-2.6 2V277h-17c-16.8 0-17.1 0-19.5-2.5l-2.5-2.4V143.9l2.5-2.4c2.4-2.5 2.7-2.5 19.5-2.5h17v16.5c0 15.2.2 16.7 2 18.5 2.7 2.7 7.1 2.6 9.8-.3 2.1-2.2 2.2-3.1 2.2-23.3 0-27.8 2.7-25.4-27.6-25.4-20.2 0-23.4.3-26.9 1.9zM149.7 151.8c-1.7 1.9-2.2 6.8-.9 8.8 1.7 2.6 5.6 3.4 15.7 3.4 13.6 0 16.5-1.3 16.5-7.6 0-5.6-2.1-6.4-16.7-6.4-11.3 0-13.2.2-14.6 1.8zM211.2 287.9c-13.2 11.7-15.1 14.9-11.6 19.9 1.1 1.6 2.5 2.2 5 2.2 3.1 0 4.7-1.1 12.2-8.2 10.6-10.1 11.1-10.7 11.2-13.6 0-2.8-4.2-7.2-6.9-7.2-1.2 0-5.6 3.1-9.9 6.9zM286.2 283.3c-4.1 4.4-3.1 6.8 7.4 16.9 10.1 9.8 12.4 11.3 16.2 10.3 2.5-.6 5.3-5.9 4.4-8.4-1.4-4.3-20.2-21.1-23.5-21.1-1.3 0-3.3 1-4.5 2.3zM253.4 293c-3.3 1.3-16 15.5-20.9 23.4-2.5 4.1-6 11.1-7.7 15.7-2.7 7.2-3.1 9.8-3.2 18.4-.1 9.4.1 10.5 3.2 16.7 3.8 7.7 9.2 13 17.1 16.7 4.7 2.2 7 2.6 14.1 2.6s9.4-.4 14.1-2.6c7.9-3.7 13.3-9 17.1-16.7 3.1-6.2 3.3-7.3 3.2-16.7-.1-8.6-.5-11.2-3.2-18.4-4.3-11.4-10.9-21.6-20-31.1-7.9-8.1-10.2-9.5-13.8-8zm8.9 23.6c10.6 13.1 16.3 29.7 13.7 40.4-3.7 15.5-23.1 20.9-34.5 9.6-8.2-8.2-8.4-20.3-.5-36.1 4.3-8.7 13.5-20.8 15.3-20.2.7.2 3.4 3 6 6.3zM183 315.7c-3.3 1.2-9 7.7-9 10.3 0 3.2 3.8 7 6.9 7 4 0 11-7 11.1-11.1 0-3.7-5.5-7.4-9-6.2zM324.3 316c-2.9 1.2-4.6 4.6-3.8 7.7.3 1.4 2.3 4 4.3 5.9 6.1 5.6 13.2 3.9 13.2-3.2 0-2.2-1.1-4-3.7-6.6-4.3-4.1-6.8-5-10-3.8z"
    />
  </svg>
)
const Memo = memo(SvgBrokenPipe)
export default Memo
