import { useMemo } from 'react'

import { useToggle } from '../useToogle'

/**
 * Custom hook to manage a boolean value with an on/off function to update it
 * @param initialValue
 */
export function useBoolean(initialValue = false) {
  const [value, toggle] = useToggle(initialValue)

  const handlers = useMemo(
    () => ({
      on: () => toggle(true),
      off: () => toggle(false),
    }),
    [toggle],
  )

  return [value, handlers] as const
}
