import { type BillingAccount } from 'api-client'

import { Icon } from '~/components/Icon'
import { CheckCircle, XCircle } from '~/components/Icons'
import { Link } from '~/components/Link'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

/**
 * Displays information about the billing account.
 */
export const BillingAccountCard = ({ paymentMethodId }: BillingAccount) => (
  <Stack align="start" space="small">
    <Stack direction="horizontal" space="small" align="center">
      {paymentMethodId ? (
        <>
          <Text>The credit card is configured correctly</Text>
          <Icon color="gigagreen" size="large">
            <CheckCircle />
          </Icon>
        </>
      ) : (
        <>
          <Text>No credit card configured</Text>
          <Icon color="gigared" size="large">
            <XCircle />
          </Icon>
        </>
      )}
    </Stack>
    <Link
      type="button"
      to="."
      searchParams={{
        edit: 'payment-method',
      }}
    >
      {paymentMethodId ? 'Change credit card' : 'Configure credit card'}
    </Link>
  </Stack>
)
