import { memo, type SVGProps } from 'react'

const SvgHepic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
  >
    <g clipPath="url(#hepic_svg__a)">
      <path
        fill="#1DC27C"
        d="M19.99 27h-4.694v-8.653c0-.998-.205-1.731-.616-2.2-.391-.47-.92-.704-1.584-.704-.352 0-.753.117-1.203.352-.43.215-.83.528-1.202.938-.372.411-.655.89-.851 1.438V27H5.147V5.587H9.84v8.594a5.774 5.774 0 0 1 2.23-2.112 6.363 6.363 0 0 1 3.08-.762c1.055 0 1.896.176 2.522.528.645.352 1.134.821 1.467 1.408.332.567.557 1.193.674 1.877.118.685.176 1.36.176 2.024V27Z"
      />
      <path fill="#fff" d="M23.078 27v-4.781h3.843V27h-3.843Z" />
    </g>
    <defs>
      <clipPath id="hepic_svg__a">
        <path fill="#fff" d="M5 5h22v22H5z" />
      </clipPath>
    </defs>
  </svg>
)
const Memo = memo(SvgHepic)
export default Memo
