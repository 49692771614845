import { type ResponsiveValue } from '~/styles/sprinkles.css'

import {
  columnsDefs,
  type GridColumn,
  type GridSpan,
  mapGridResponsiveValue,
  spanDefs,
} from './Grid.css'

export const columnsToTemplateColumns = (columns: ResponsiveValue<GridColumn> | undefined) =>
  columns ? mapGridResponsiveValue(columns, value => columnsDefs[value]) : undefined

export const spanToGridColumn = (columns: ResponsiveValue<GridSpan> | undefined) =>
  columns ? mapGridResponsiveValue(columns, value => spanDefs[value]) : undefined
