const localStorage =
  typeof window === 'undefined'
    ? {
        setItem() {
          return null
        },
        getItem() {
          return null
        },
        removeItem() {
          return null
        },
      }
    : window.localStorage

const storage = {
  get(k: string) {
    try {
      return JSON.parse(localStorage.getItem(k) ?? '')
    } catch (e) {
      return null
    }
  },
  set(k: string, v: any) {
    try {
      return localStorage.setItem(k, JSON.stringify(v))
    } catch (e) {
      return null
    }
  },
  remove(k: string) {
    return localStorage.removeItem(k)
  },
}

export default storage
