import { posthog } from 'posthog-js'

import { RouterNavLink as NavLink } from '~/shared/lib/reactRouterWrappers'

import * as styles from './HepicNavBar.css'

export type HepicLink = {
  key: string
  label: string
  link: string
  end: boolean
}

const SUBPAGES = (): HepicLink[] => {
  let subpages = [
    { key: 'overview', label: 'Overview', link: '.', end: true },
    {
      key: 'data-ingestion',
      label: 'Data Ingestion',
      link: 'data-ingestion/agents--heplify',
      end: false,
    },
    { key: 'config', label: 'Config', link: 'config', end: true },
  ]

  posthog.onFeatureFlags(() => {
    if (posthog.isFeatureEnabled('hepic-token-feature')) {
      subpages = [
        { key: 'overview', label: 'Overview', link: '.', end: true },
        { key: 'auth', label: 'Auth Tokens', link: 'auth', end: true },
        {
          key: 'data-ingestion',
          label: 'Data Ingestion',
          link: 'data-ingestion/agents--heplify',
          end: false,
        },
        { key: 'config', label: 'Config', link: 'config', end: true },
      ]
    }
  })

  return subpages
}

export function HepicNavBar() {
  return (
    <div className={styles.wrapper}>
      {SUBPAGES().map(item => (
        <NavLink
          preventScrollReset={true}
          key={item.key}
          to={`${item.link}`}
          className={({ isActive }) =>
            styles.link({
              isActive: isActive || location.pathname.includes(item.key),
            })
          }
          end={item.end}
        >
          {item.label}
        </NavLink>
      ))}
    </div>
  )
}
