import { useQuery } from '@tanstack/react-query'
import { type Integration, type Project } from 'api-client'

import { Tag, type TagProps } from '~/shared/ui/Tag'

import { integrationTrialDaysQuery } from '../queries'

type IntegrationStatusProps = {
  /**
   * Current project id.
   */
  projectId: Project['id']
  /**
   * Slug for the integration.
   */
  slug: Integration['product']['slug']
  /**
   * Status of the integration.
   */
  status: Integration['status']
}

function getTagColor(status: IntegrationStatusProps['status'], days?: number): TagProps['color'] {
  if (status === 'active') return 'neutral'
  if (status === 'suspended') return 'red'
  if (status === 'cancelled by user') return 'default'
  if (status === 'scheduled for cancellation') return 'neutral'
  if (status === 'trial' && days && days < 5) {
    return 'red'
  }

  return 'neutral'
}

function getStatusText(status: IntegrationStatusProps['status'], days?: number) {
  if (status === 'active') return 'Subscription active'
  if (status === 'suspended') return 'Subscription suspended'
  if (status === 'cancelled by user') return 'Cancelled'
  if (status === 'scheduled for cancellation') return 'Scheduled for cancellation'
  if (status === 'trial' && days) {
    return `Trial period: ${days} ${days > 1 ? `days` : `day`} left`
  }
  return 'Loading...'
}

/**
 * Component to display the status of the integration.
 *
 * If on trial will fetch the remaining trial days from the API.
 */
export function IntegrationStatus({ projectId, slug, status }: IntegrationStatusProps) {
  const { data: trial, error } = useQuery(integrationTrialDaysQuery(projectId, slug, status))

  if (error) return <Tag color="neutral">Error retrieving trial info</Tag>
  return (
    <Tag color={getTagColor(status, trial?.remainingDays)}>
      {getStatusText(status, trial?.remainingDays)}
    </Tag>
  )
}
