import { useEffect } from 'react'
import { useRouteError } from 'react-router-dom'
import { faro } from '@grafana/faro-web-sdk'
import { type QueryClient } from '@tanstack/react-query'

import { projectsQuery } from '~/entities/projects'

import storage from '~/shared/lib/storage'
import { authStore } from '~/shared/model/auth'
import { ErrorModal } from '~/shared/ui/ErrorModal'

export async function loader(queryClient: QueryClient) {
  const { logout } = authStore.getState().actions
  window.sessionStorage.removeItem('orgLogin')
  await queryClient.invalidateQueries(projectsQuery())
  storage.remove('selectedProject')
  return logout()
}

// Dummy element, the user will never see it
export default function Logout() {
  const { logout } = authStore.getState().actions

  useEffect(() => {
    async function rootAction() {
      window.sessionStorage.removeItem('orgLogin')
      storage.remove('selectedProject')
      window.history.pushState({}, '', '/')
      return logout()
    }

    rootAction()
      .then(() => null)
      .catch(e => console.log(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export function ErrorBoundary() {
  const error = useRouteError() as Error

  faro.api.pushError(error)

  return <ErrorModal error={error} />
}
