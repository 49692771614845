import { slugify, transformSnakeObjectKeysToCamel } from 'plunger'
import { z } from 'zod'

import { productSchema } from '../../products/model'

// Constants
const integrationStatuses = [
  'active',
  'trial',
  'suspended',
  'cancelled by user',
  'scheduled for cancellation',
] as const

// Schemas for fetched requests
export const integrationSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    id: z.coerce.string(),
    createdAt: z.string().datetime({ offset: true }),
    creatorName: z.string(),
    status: z.enum(integrationStatuses),
    product: productSchema,
  }),
)
export type Integration = z.infer<typeof integrationSchema>

export const trialDaysSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({ remainingDays: z.number() }),
)

const serviceStatusBaseSchema = z.object({
  name: z.string(),
  running: z.boolean(),
})
const serviceSchema: z.ZodType<ServiceSchema> = serviceStatusBaseSchema.extend({
  services: z
    .lazy(() => serviceSchema.array())
    .optional()
    .nullable(),
})
type ServiceSchema = z.infer<typeof serviceStatusBaseSchema> & {
  services?: ServiceSchema[] | null
}

export const serviceStatusSchema = z
  .object({
    product: z.string(),
    services: z.array(serviceSchema).optional(),
  })
  .transform(data => {
    const flattenedSubServices = data.services?.flatMap(s =>
      s.services && s.services?.length > 1
        ? s.services.flatMap(ss => ({ name: `${s.name} - ${ss.name}`, running: ss.running }))
        : { name: s.name, running: s.running },
    )
    return {
      ...data,
      slug: slugify(data.product),
      services: flattenedSubServices,
    }
  })
export type ServiceStatus = z.infer<typeof serviceStatusSchema>

export const cancellationDateSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    /**
     * The date when the subscription will be automatically canceled.
     *
     * Timestamp in milliseconds
     */
    cancellationDateTimestamp: z.number().transform(timestamp => timestamp * 1000),
  }),
)

// Schemas for submit requests
const cancelIntegrationSubscriptionSchema = z.object({
  projectId: z.coerce.string(),
  integration: z.string(),
})
export type CancelIntegrationSubscriptionData = z.infer<typeof cancelIntegrationSubscriptionSchema>
