import { memo, type SVGProps } from 'react'

const SvgPrometheus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#prometheus_svg__a)">
      <path
        d="M15.249 2.164C7.379 2.164 1 8.394 1 16.08 1 23.768 7.38 30 15.249 30c7.869 0 14.248-6.232 14.248-13.919 0-7.686-6.38-13.917-14.248-13.917Zm0 26.048c-2.24 0-4.054-1.46-4.054-3.262h8.108c0 1.801-1.815 3.262-4.054 3.262Zm6.696-4.343H8.552v-2.372h13.393v2.372Zm-.048-3.593H8.59c-.044-.05-.09-.099-.132-.15-1.371-1.625-1.694-2.474-2.007-3.34-.006-.028 1.662.334 2.844.594 0 0 .609.137 1.499.296-.855-.978-1.362-2.222-1.362-3.493 0-2.79 2.19-5.227 1.4-7.198.77.061 1.592 1.586 1.648 3.97.818-1.104 1.16-3.12 1.16-4.356 0-1.28.863-2.766 1.727-2.817-.77 1.239.199 2.301 1.06 4.937.324.99.282 2.655.532 3.712.082-2.195.469-5.396 1.894-6.501-.629 1.392.093 3.133.586 3.97.797 1.351 1.28 2.375 1.28 4.31a5.31 5.31 0 0 1-1.319 3.476c.941-.173 1.59-.328 1.59-.328l3.057-.583s-.444 1.784-2.15 3.501Z"
        fill="#E6522C"
      />
    </g>
    <defs>
      <clipPath id="prometheus_svg__a">
        <path fill="#fff" transform="translate(1 2)" d="M0 0h29v28H0z" />
      </clipPath>
    </defs>
  </svg>
)
const Memo = memo(SvgPrometheus)
export default Memo
