import { transformSnakeObjectKeysToCamel } from 'plunger'
import { z } from 'zod'
//
// Shared schemas
const billingAccountIdSchema = z.coerce.string()
const paymentMethodId = z.string()

// Schemas for fetched requests
export const billingAccountSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    id: billingAccountIdSchema,
    name: z.string(),
    paymentMethodId: paymentMethodId.nullable(),
  }),
)

export const mockStripeBillingAccountSchema = z.object({
  billingAccountId: z.number(),
})

export const stripeConfigSchema = z.preprocess(
  (data: any) => transformSnakeObjectKeysToCamel(data),
  z.object({
    email: z.string().email(),
    username: z.string(),
  }),
)

export type BillingAccount = z.infer<typeof billingAccountSchema>

// Schemas for submit requests
export const createBillingAccountSchema = z.object({
  name: z
    .string()
    .trim()
    .min(3, { message: 'The billing account name must have at least 3 characters' }),
  default: z.boolean().optional().default(false),
})
export type CreateBillingAccountData = z.infer<typeof createBillingAccountSchema>

export const setupPaymentMethodSchema = z.object({
  billingAccountId: billingAccountIdSchema,
})
export type SetupPaymentMethodData = z.infer<typeof setupPaymentMethodSchema>

export const attachPaymentMethodSchema = z.object({
  billingAccountId: billingAccountIdSchema.min(1, {
    message: 'The billing acccount id is missing',
  }),
  paymentMethodId: paymentMethodId.min(1, { message: 'The payment method is missing' }),
})
export type AttachPaymentMethodData = z.infer<typeof attachPaymentMethodSchema>

export const addTaxIdSchema = z.object({
  billingAccountId: billingAccountIdSchema.min(1, {
    message: 'The billing acccount id is missing',
  }),
  type: z.string().min(1, { message: 'The tax id type is missing' }),
  value: z.string().min(1, { message: 'The tax id value is missing' }),
})
export type AddTaxIdData = z.infer<typeof addTaxIdSchema>
