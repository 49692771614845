import { type Context, type FC, useEffect, useState } from 'react'
import { type KeycloakInitOptions } from 'keycloak-js'

import { type IAuthContextProps, keycloakContext } from './keycloakContext'
import { type KeycloakAuthProviderProps } from './types'

const defaultOptions: KeycloakInitOptions = {
  onLoad: 'login-required',
}

function createAuthProvider(AuthContext: Context<IAuthContextProps>) {
  const KeycloakAuthProvider: FC<KeycloakAuthProviderProps> = props => {
    const { client, children, authStore, queryClient, loadingElement } = props
    const [initialized, setInitialized] = useState<boolean>(false)
    useEffect(() => {
      const { initOptions } = props

      const initialize = () => {
        client
          .init({ ...defaultOptions, ...initOptions })
          .then(() => {
            setInitialized(true)
            authStore.setState({ keyCloakClient: client })
            const orgId = localStorage.getItem('orgId') ?? ''
            if (orgId !== null && orgId !== '') {
              authStore.getState().actions.changeLoginType(orgId)
            }
          })
          .catch((err: string) => {
            throw new Error(err)
          })
      }
      if (typeof client.authenticated === 'undefined') {
        initialize()
      }

      client.onTokenExpired = () => {
        client.updateToken(5).catch((err: string) => {
          console.log(err)
        })
      }
    }, [authStore, client, props, queryClient])

    if (client.authenticated) {
      return <AuthContext.Provider value={{ initialized, client }}>{children}</AuthContext.Provider>
    } else {
      return loadingElement
    }
  }

  return KeycloakAuthProvider
}

export const KeycloakProvider = createAuthProvider(keycloakContext)
export const KeycloakConsumer = keycloakContext.Consumer
