import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { qrynConfigQuery, qrynTokensQuery } from '~/entities/integrations/qryn'

import { QrynCodeSnippet } from '~/shared/ui/CodeSnippet'
import { CopyButton } from '~/shared/ui/CopyButton'
import { Tabs } from '~/shared/ui/Tabs'

import { Card } from '~/components/Card'
import { Input } from '~/components/Input'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import { getFrontendInstrumentationSnippet } from '../model'

import { CreateToken } from './CreateToken'
import { DeleteToken } from './DeleteToken'

type ManageQrynTokensProps = {
  projectId: Project['id']
  projectSlug: Project['slug']
}

export function ManageQrynTokens({ projectId, projectSlug }: ManageQrynTokensProps) {
  const { data: tokens } = useQuery(qrynTokensQuery(projectId))
  const { data: config } = useQuery(qrynConfigQuery(projectId))

  return (
    <Card>
      <Card.Header
        title="Scoped tokens"
        description="You can create scoped tokens with read/write permissions to avoid sharing the main API
            key/secret."
      />
      <Card.Section>
        <Stack>
          <Stack direction="horizontal" justify="between" align="center">
            <Text size="small">
              This qryn instance has {tokens?.length ?? 0} scoped
              {tokens?.length === 1 ? ' token' : ' tokens'}
            </Text>
            <CreateToken projectId={projectId} projectSlug={projectSlug} />
          </Stack>
          <Stack space="medium">
            {tokens &&
              Boolean(tokens.length) &&
              tokens.map(token => (
                <Input
                  key={token.id}
                  isReadOnly
                  label={`${token.name} (${token.scope.split(' ').join('/')})`}
                  value={token.token}
                  suffix={
                    <Stack direction="horizontal" space="xxsmall">
                      <CopyButton value={token.token} />
                      <DeleteToken
                        projectId={projectId}
                        projectSlug={projectSlug}
                        tokenId={token.id}
                      />
                    </Stack>
                  }
                />
              ))}
          </Stack>

          <Text>
            For example, use a write-only token to send logs from your browser apps and avoid
            exposing keys with read permissions
          </Text>

          <Tabs>
            <Tabs.Item key="frontend" title="Front-end App Instrumentation">
              <QrynCodeSnippet
                snippet={getFrontendInstrumentationSnippet({
                  host: `${config?.url}/loki/api/v1/push`,
                })}
                privateSnippet={getFrontendInstrumentationSnippet({
                  host: `${config?.url}/loki/api/v1/push`,
                })}
              />
            </Tabs.Item>
          </Tabs>
        </Stack>
      </Card.Section>
    </Card>
  )
}
