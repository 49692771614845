import { ReactIntegration } from '@grafana/faro-react'
import {
  ErrorsInstrumentation,
  type Faro,
  initializeFaro as coreInit,
  SessionInstrumentation,
  ViewInstrumentation,
} from '@grafana/faro-web-sdk'

export function initializeFaro(): Faro | undefined {
  const faro = coreInit({
    url: `${import.meta.env.VITE_QRYN_COLLECT_URL}/collect`,
    app: {
      name: 'ui',
      environment: import.meta.env.VITE_ENVIRONMENT,
      release: `ui@${import.meta.env.PACKAGE_VERSION}`,
    },
    paused: true,
    preventGlobalExposure: true,
    instrumentations: [
      new ErrorsInstrumentation(),
      new SessionInstrumentation(),
      new ViewInstrumentation(),
      new ReactIntegration(),
    ],
  })
  faro.metas.add(() => ({ sdk: undefined }))

  return faro
}
