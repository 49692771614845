import { useFetcher } from 'react-router-dom'
import { type ClickHouseCluster, type Project } from 'api-client'

import { ADD_GRAFANA_DATASOURCE } from '~/entities/integrations/grafana'

import { Box } from '~/components/Box'
import { Button } from '~/components/Button'
import { Icon } from '~/components/Icon'
import { ClickHouse } from '~/components/Icons'
import { Message } from '~/components/Message'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

type AddClickHouseDatasourcesProps = {
  projectName: Project['name']
  clusterId: ClickHouseCluster['id']
  clusterName: ClickHouseCluster['name']
}

export function AddClickHouseDatasources({
  projectName,
  clusterId,
  clusterName,
}: AddClickHouseDatasourcesProps) {
  const fetcher = useFetcher()

  return (
    <Message type="info" size="small">
      <Stack
        justify="between"
        space="medium"
        direction={{ mobile: 'vertical', desktop: 'horizontal' }}
      >
        <Stack
          space="small"
          direction={{ mobile: 'vertical', desktop: 'horizontal' }}
          align={{ mobile: 'start', desktop: 'center' }}
        >
          <Icon>
            <ClickHouse />
          </Icon>

          <Box>
            <Text>We have detected you have ClickHouse enabled for this project</Text>
            <Text color="gray600">Do you want to add {clusterName} cluster as a datasource?</Text>
          </Box>
        </Stack>
        <Stack space="small" direction="horizontal">
          <fetcher.Form method="post">
            <input hidden name="type" defaultValue="clickhouse" />
            <input
              hidden
              name="gigapipeClickhouseClusters[0][name]"
              defaultValue={`ClickHouse (${projectName} - ${clusterName})`}
            />
            <input hidden name="gigapipeClickhouseClusters[0][id]" defaultValue={clusterId} />
            <Button
              type="submit"
              color="brand"
              name="intent"
              value={ADD_GRAFANA_DATASOURCE}
              isDisabled={fetcher.state !== 'idle'}
              isLoading={fetcher.state !== 'idle'}
            >
              Add {clusterName}
            </Button>
          </fetcher.Form>
        </Stack>
      </Stack>
    </Message>
  )
}
