type Options = {
  omitKey?: boolean
}

export function groupByKey<OBJ extends object, KEY extends keyof OBJ>(
  list: OBJ[],
  key: KEY,
): Record<string, OBJ[]>
export function groupByKey<OBJ extends object, KEY extends keyof OBJ>(
  list: OBJ[],
  key: KEY,
  options: Options,
): Record<string, Omit<OBJ, KEY>[]>
export function groupByKey<OBJ extends object, KEY extends keyof OBJ>(
  list: OBJ[],
  key: KEY,
  options: Options = {},
) {
  const { omitKey = false } = options
  return list.reduce(
    (hash, { [key]: value, ...rest }) => ({
      ...hash,
      [value as unknown as string]: (hash[value as unknown as string] || []).concat(
        // @ts-expect-error: really hard to type correctly
        omitKey ? { ...rest } : { [key]: value, ...rest },
      ),
    }),
    {} as Record<string, OBJ[] | Omit<OBJ, KEY>[]>,
  )
}
