import { ApiClient } from 'api-client'

import { authStore } from '../model/auth'

import { handleResponseError } from './handleResponseError'

export const apiClient = new ApiClient({
  baseUrl: import.meta.env.VITE_API_URL,
  enableAuth: true,
  getAuthHeader: async () => {
    const { loginWithRedirect, logout } = authStore.getState().actions
    const { keyCloakClient } = authStore.getState()

    try {
      let tk = ''
      if (keyCloakClient?.token && keyCloakClient?.token !== '') {
        tk = keyCloakClient?.token
      } else {
        throw 'No token'
      }
      return { Authorization: `Bearer ${tk}` }
    } catch (error: any) {
      console.error(error, 'HERE IS AN ERROR')
      if (error.error === 'login_required') {
        await loginWithRedirect()
      } else {
        await logout()
      }
    }
  },
  onErrorResponse: handleResponseError,
})
