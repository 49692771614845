import BillingService from './billing/service'
import IntegrationsService from './integrations/base/service'
import ClickHouseService from './integrations/clickhouse/service'
import GrafanaService from './integrations/grafana/service'
import HepicService from './integrations/hepic/service'
import LoggenService from './integrations/loggen/service'
import QrynService from './integrations/qryn/service'
import { Rooquest } from './lib/rooquest'
import OrganizationsService from './organizations/service'
import ProductsService from './products/service'
import ProjectsService from './projects/service'
import UsersService from './users/service'
import { type CustomRequestInit } from './types'

export type ApiClientOptions = {
  /**
   * Defines the base URL for all requests
   */
  baseUrl: string
  /**
   * Controls if the `getAuthHeader` function should be called.
   *
   * Used with false values for Storybook and tests environments
   */
  enableAuth?: boolean
  /**
   * Async function that returns the necessary auth headers to be used.
   *
   * For example could call a `getAccessTok function and return a `Authorization: Bearer ...` header.
   */
  getAuthHeader: () => Promise<Record<string, string> | undefined>
  /**
   * Async function executed when the response status code is bigger than 200.
   * @param res
   * @param reqOptions
   */
  onErrorResponse: (res: Response, reqOptions?: CustomRequestInit) => Promise<void>
}

/**
 * A client to interact with Gigapipe's API
 */
export class ApiClient {
  readonly name = 'api-client'

  readonly version = '1.0.0'

  readonly rooquest: Rooquest

  readonly products: ProductsService
  readonly projects: ProjectsService
  readonly organizations: OrganizationsService
  readonly users: UsersService
  readonly billing: BillingService
  readonly integrations: IntegrationsService
  readonly qryn: QrynService
  readonly hepic: HepicService
  readonly loggen: LoggenService
  readonly grafana: GrafanaService
  readonly clickhouse: ClickHouseService

  constructor(options: ApiClientOptions) {
    const rooquest = new Rooquest(options)
    this.rooquest = rooquest
    this.products = new ProductsService(rooquest)
    this.projects = new ProjectsService(rooquest)
    this.organizations = new OrganizationsService(rooquest)
    this.users = new UsersService(rooquest)
    this.billing = new BillingService(rooquest)
    this.integrations = new IntegrationsService(rooquest)
    this.qryn = new QrynService(rooquest)
    this.hepic = new HepicService(rooquest)
    this.loggen = new LoggenService(rooquest)
    this.grafana = new GrafanaService(rooquest)
    this.clickhouse = new ClickHouseService(rooquest)
  }
}
