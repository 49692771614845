import { createSearchParams, Link, type LinkProps as ReactRouterLinkProps } from 'react-router-dom'

export type RouterLinkProps = ReactRouterLinkProps & {
  /**
   * Create valid `URSearchParams` from an object. It accept single values or array of values that are properly converted to multiple search params.
   * Uses `createSearchParams` from `react-router-dom`
   */
  searchParams?: Record<string, string | string[]>
  /**
   * Displays an icon for links that lead to external pages
   */
  isExternal?: boolean
}

/**
 * Custom wrapper arround ReactRouter's Link component
 *
 * It add two extra props to append the project search param to the url and to improve the API for the search params.
 */
export function RouterLink({ searchParams, to, ...rest }: RouterLinkProps) {
  const searchParamsString = createSearchParams({
    ...searchParams,
  }).toString()
  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  const innerTo = searchParamsString ? `${to}?${searchParamsString}` : to

  return <Link to={innerTo} {...rest} />
}
