import { type Key, useRef } from 'react'
import { type AriaSelectOptions, mergeProps, useButton, useFocusRing, useSelect } from 'react-aria'
import {
  Item,
  Section,
  type SelectProps as StatelySelectProps,
  useListState,
  useSelectState,
} from 'react-stately'
import { type MultipleSelection } from '@react-types/shared'

import { ChevronDown, Info } from '~/components/Icons'

import { Box } from '../Box'
import { Icon } from '../Icon'
import { ListBox } from '../ListBox'
import { Loading } from '../Loading'
import { Popover } from '../Popover'
import { Stack } from '../Stack'
import { Text } from '../Text'
import { Tooltip, type TooltipProps } from '../Tooltip'

import * as styles from './MultiSelect.css'

export type SelectProps<T> = MultipleSelection & {
  /**
   * The text to display when no options are selected
   */
  defaultText?: string
  /**
   * A function that returns the text to be displayed when at least 1 option is selected
   */
  selectedText?: (selectedKeys: Set<Key>) => string
  /**
   * The help text to display in a popover
   */
  tooltip?: TooltipProps['content']
  /**
   * Option to display the MultiSelect in a more compact way
   */
  variant?: 'default' | 'compact'
  /**
   * Allow to set a specific width for the Select.
   * If not set, the width of the Select will be the width of its parent.
   */
  width?: number
  /**
   * Boolean flag to indicate that the Select items are beeing loaded
   */
  isLoading?: boolean
} & Omit<AriaSelectOptions<T>, 'selectedKey' | 'defaultSelectedKey'> &
  StatelySelectProps<T>

export function MultiSelect<T extends object>(props: SelectProps<T>) {
  const {
    label,
    tooltip,
    name,
    errorMessage,
    description,
    defaultText,
    selectedText,
    isDisabled,
    isLoading,
    width,
    variant = 'default',
  } = props
  const selectState = useSelectState(props)
  const state = useListState<T>({ ...props, selectionMode: 'multiple' })
  const ref = useRef<HTMLButtonElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { labelProps, triggerProps, valueProps, menuProps, errorMessageProps, descriptionProps } =
    useSelect(props, selectState, ref)

  const { buttonProps } = useButton({ ...triggerProps, isDisabled }, ref)

  const { focusProps, isFocusVisible } = useFocusRing()

  const triggerWidth = wrapperRef.current?.offsetWidth

  return (
    <Box
      ref={wrapperRef}
      display="inline-flex"
      flexDirection="column"
      position="relative"
      style={{ width: width ? `min(100%,${width}px)` : '100%' }}
    >
      {(label ?? tooltip) && (
        <Stack direction="horizontal" space="small" align="center">
          {label && (
            <Text
              {...labelProps}
              as="label"
              weight="light"
              lineHeight="medium"
              color="gray600"
              htmlFor={buttonProps.id}
            >
              {label}
            </Text>
          )}
          {tooltip && (
            <Tooltip content={tooltip} delay={0}>
              <Icon>
                <Info />
              </Icon>
            </Tooltip>
          )}
        </Stack>
      )}

      {[...state.selectionManager.selectedKeys].map(value => (
        <input key={value} type="hidden" name={name} value={value} />
      ))}

      <button
        type="button"
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        className={styles.triggerButton({
          isDisabled,
          isFocused: isFocusVisible,
          isOpen: selectState.isOpen,
          error: !!errorMessage,
          variant,
        })}
      >
        <div {...valueProps} className={styles.value({ variant })}>
          {state.selectionManager.selectedKeys.size > 0
            ? selectedText
              ? selectedText(state.selectionManager.selectedKeys)
              : `${state.selectionManager.selectedKeys.size} ${
                  state.selectionManager.selectedKeys.size === 1 ? 'option' : 'options'
                } selected`
            : defaultText ?? 'No options selected'}
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <Icon>
            <ChevronDown />
          </Icon>
        )}
      </button>
      {selectState.isOpen && (
        <Popover state={selectState} triggerRef={ref} placement="bottom" width={triggerWidth}>
          <ListBox
            {...menuProps}
            selectionMode="multiple"
            selectionBehavior="toggle"
            state={state}
          />
        </Popover>
      )}
      {errorMessage ? (
        <div className={styles.error({ variant })} {...errorMessageProps}>
          {errorMessage}
        </div>
      ) : (
        description && (
          <div className={styles.description({ variant })} {...descriptionProps}>
            {description}
          </div>
        )
      )}
    </Box>
  )
}

MultiSelect.Item = Item
MultiSelect.Section = Section
