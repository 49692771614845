import {
  createSearchParams,
  NavLink,
  type NavLinkProps as ReactRouterLinkProps,
  useLocation,
  useSearchParams,
} from 'react-router-dom'

export type RouterLinkProps = ReactRouterLinkProps & {
  /**
   * Automatically add the current project to search params
   */
  keepProjectSearchParam?: boolean
  /**
   * Create valid `URSearchParams` from an object. It accept single values or array of values that are properly converted to multiple search params.
   * Uses `createSearchParams` from `react-router-dom`
   */
  searchParams?: Record<string, string | string[]>
}

/**
 * Custom wrapper arround ReactRouter's NavLink component
 *
 * It add two extra props to append the project search param to the url and to improve the API for the search params.
 */
export function RouterNavLink({
  keepProjectSearchParam = true,
  searchParams,
  to,
  ...rest
}: RouterLinkProps) {
  const [currentSearchParams] = useSearchParams()
  const project = currentSearchParams.get('project')
  const projectSearchParam = keepProjectSearchParam && project ? { project } : undefined
  const searchParamsString = createSearchParams({
    ...projectSearchParam,
    ...searchParams,
  }).toString()
  const { pathname } = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  const innerTo = searchParamsString ? `${to}?${searchParamsString}` : to

  return <NavLink to={innerTo} state={{ from: pathname }} {...rest} />
}
