import { type LoaderFunctionArgs, redirect, useRouteError } from 'react-router-dom'
import { faro } from '@grafana/faro-web-sdk'
import { type QueryClient } from '@tanstack/react-query'
import { authorize } from 'keycloak'

import { projectsQuery } from '~/entities/projects'

import { authStore } from '~/shared/model/auth'
import { ErrorModal } from '~/shared/ui/ErrorModal'

export async function loader({ request }: LoaderFunctionArgs, queryClient: QueryClient) {
  // here we should have the auth action to login

  const { loginWithRedirect, changeLoginType } = authStore.getState().actions

  const url = new URL(request.url)

  const organization = url.searchParams.get('organization')

  const invitation = url.searchParams.get('invitation')

  const isIndividualLogin = Boolean(url.searchParams.get('personalAccount'))

  if (organization && !invitation) {
    window.sessionStorage.setItem('orgLogin', 'true')
    await changeLoginType(organization)
    await queryClient.invalidateQueries(projectsQuery())
    return redirect('/')
    // add router redirect
  }

  if (organization && invitation) {
    window.sessionStorage.setItem('orgLogin', 'true')

    return loginWithRedirect({
      redirectUri: '/welcome',
    })

    // add router redirect
  }

  if (isIndividualLogin) {
    window.sessionStorage.removeItem('orgLogin')

    await changeLoginType('user')
    await queryClient.invalidateQueries(projectsQuery())
    return redirect('/')
  }

  // eslint-disable-next-line  @typescript-eslint/require-await
  return authorize(authStore, async () => redirect('/welcome'), '/welcome')
}

// Dummy element, the user will never see it
export default function Login() {
  return null
}

export function ErrorBoundary() {
  const error = useRouteError() as Error

  faro.api.pushError(error)

  return <ErrorModal error={error} />
}
