import { parse } from 'qs'

/**
 * The data returned from parsing a URLSearchParams object.
 */
export type ParsedSearchParams = Record<string, string | string[]>

/**
 * Parse the URLSearchParams in string format using `qs` library
 */
export function parseSearchParams(searchParams: string) {
  return parse(searchParams, { allowDots: true })
}

// Legacy detection of FormData or URLSearchParams
// export function getObjectTypeName(value: unknown): string {
//   return toString.call(value).slice(8, -1)
// }
