import { type ReactNode, useCallback, useEffect, useState } from 'react'

import { createReactContext } from '~/shared/lib/createReactContext'
import storage from '~/shared/lib/storage'

const themeValues = ['system', 'dark', 'light'] as const
type ThemeType = (typeof themeValues)[number]

type ThemeContextValue = {
  theme: ThemeType
  toggleTheme: () => void
}

export const [useTheme, ThemeContextProvider] = createReactContext<ThemeContextValue>()

export const ThemeProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const [theme, setTheme] = useState(() => getUserTheme())

  useEffect(() => {
    document.documentElement.classList.remove('light', 'dark')
    document.documentElement.classList.add(theme)
  }, [theme])

  const toggleTheme = useCallback(() => {
    setTheme(t => (t === 'light' ? 'dark' : 'light'))
    storage.set('theme', theme === 'light' ? 'dark' : 'light')
  }, [theme])

  return <ThemeContextProvider value={{ theme, toggleTheme }}>{children}</ThemeContextProvider>
}

function getUserTheme(): ThemeType {
  const localTheme = storage.get('theme')

  return (
    typeof localTheme === 'string' && themeValues.includes(localTheme) ? localTheme : 'dark'
  ) as ThemeType
}
