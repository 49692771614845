import { redirect, useRouteError } from 'react-router-dom'
import { faro } from '@grafana/faro-web-sdk'
import { handleRedirectCallback } from 'keycloak'

import { authStore } from '~/shared/model/auth'
import { ErrorModal } from '~/shared/ui/ErrorModal'

export function loader() {
  // eslint-disable-next-line @typescript-eslint/require-await
  return handleRedirectCallback(authStore, async ({ appState }) =>
    redirect(appState?.returnTo ?? '/'),
  )
}

// Dummy element, the user will never see it
export default function Auth() {
  return null
}

export function ErrorBoundary() {
  const error = useRouteError() as Error

  faro.api.pushError(error)

  return <ErrorModal error={error} />
}
