import { transformCamelObjectKeysToSnake } from 'plunger'
import { z } from 'zod'

import { type Rooquest } from '../lib/rooquest'
import { type MessageResponse } from '../utils/types'

import {
  acceptInvitationSchema,
  type AcceptInvititationData,
  type CreateOrganizationData,
  createOrganizationSchema,
  type DeleteInviteData,
  deleteInviteSchema,
  invitedOrgUserSchema,
  type InviteUserData,
  inviteUserSchema,
  organizationDeleteUserSchema,
  organizationInviteResponseSchema,
  organizationSchema,
  orgUserSchema,
  resendInviteUserSchema,
  trialDaysSchema,
} from './model'

const users = z.array(orgUserSchema)
const invitedUsers = z.array(invitedOrgUserSchema)
const organizations = z.array(organizationSchema)

/**
 * The service to interact with all the organization domain endpoints of Gigapipe's API
 */
export default class OrganizationsService {
  private readonly rooquest: Rooquest

  constructor(rooquest: Rooquest) {
    this.rooquest = rooquest
  }

  /**
   * Get users from organization
   */
  public getUsersFromOrganization = async () => {
    const orgUsers = await this.rooquest.get('organizations/users')

    const keyCloakUsers = users.parse(orgUsers)
    return keyCloakUsers
  }

  /**
   * Get pending users invitations from organization
   */
  public getInvitedUsers = async () => {
    const res = await this.rooquest.get('organizations/invitations')

    return invitedUsers.parse(res)
  }

  public deleteUser = async (data: DeleteInviteData) => {
    const payload = organizationDeleteUserSchema.parse(data)
    const res = await this.rooquest.delete(`organizations/user/${payload.id}`)
    return res as MessageResponse
  }

  /**
   * Get remaining days from the trial period for the organization/platform fee
   */
  public getOrganizationTrialDays = async () => {
    const res = await this.rooquest.get('organizations/trial')

    return trialDaysSchema.parse(res)
  }

  /**
   * Create a new organization
   */
  public createOrganization = async (data: CreateOrganizationData) => {
    const payload = createOrganizationSchema.parse(data)

    const res = await this.rooquest.post('organizations/', {
      body: transformCamelObjectKeysToSnake({
        ...payload,
        billingAccountId: Number(payload.billingAccountId),
      }),
    })

    return organizationSchema.parse(res)
  }

  /**
   * Send an invite to an email to join the organization
   */
  public sendInvite = async (data: InviteUserData) => {
    const payload = inviteUserSchema.parse(data)
    const res = await this.rooquest.post('organizations/invitations', {
      body: transformCamelObjectKeysToSnake(payload),
    })

    return res as MessageResponse
  }

  /**
   *
   * Resend invite to an email to join the organization
   *
   */
  public resendInvite = async (data: InviteUserData) => {
    const payload = resendInviteUserSchema.parse(data)
    const res = await this.rooquest.patch('organizations/invitations', {
      body: transformCamelObjectKeysToSnake(payload),
    })
    return res as MessageResponse
  }

  /**
   * Delete an invite to join the organization
   */
  public deleteInvite = async (data: DeleteInviteData) => {
    const payload = deleteInviteSchema.parse(data)
    const res = await this.rooquest.delete('organizations/invites', {
      query: transformCamelObjectKeysToSnake(payload),
    })

    return res as MessageResponse
  }

  /**
   * Get the organizations owned by the current user
   */
  public getOwnedOrganizations = async () => {
    const res = await this.rooquest.get('organizations/users/owner')

    return organizations.parse(res)
  }
  /**
   * Create a new organization
   */
  public acceptInvitation = async (data: AcceptInvititationData) => {
    const payload = acceptInvitationSchema.parse(data)
    const res = await this.rooquest.post('organizations/invitations/accept', {
      body: transformCamelObjectKeysToSnake({
        ...payload,
      }),
    })

    return organizationInviteResponseSchema.parse(res)
  }
}
