import { type Integration } from 'api-client'

import { apiClient } from '~/shared/api'
import { ONE_HOUR } from '~/shared/config'

const qrynQueryKeys = {
  qrynConfig: (projectId: string) =>
    [{ scope: 'integrations', type: 'qryn', item: 'config', projectId }] as const,
  qrynMetrics: (projectId: string, start: number, end: number) =>
    [{ scope: 'integrations', type: 'qryn', item: 'metrics', projectId, start, end }] as const,
  qrynCloudInstances: [{ scope: 'integrations', type: 'qryn', item: 'instances' }] as const,
  qrynInstrumentedApplications: (projectId: string, appName?: string) =>
    [
      { scope: 'integrations', type: 'qryn', item: 'instrumentedApplications', projectId, appName },
    ] as const,
  qrynCurrentCost: (projectId: string) =>
    [{ scope: 'integrations', type: 'qryn', item: 'currentCost', projectId }] as const,
  qrynTokens: (projectId: string) =>
    [{ scope: 'integrations', type: 'qryn', item: 'tokens', projectId }] as const,
  qrynRetentionPolicies: [
    { scope: 'integrations', type: 'qryn', item: 'retentionPolicies' },
  ] as const,
}

/**
 * Query to fetch `qryn` integration configuration
 */
export const qrynConfigQuery = (projectId: string) => ({
  queryKey: qrynQueryKeys.qrynConfig(projectId),
  queryFn: () => apiClient.qryn.getQrynConfig(projectId),
  enabled: Boolean(projectId),
})

/**
 * Query to fetch `qryn` integration metrics
 */
export const qrynMetricsQuery = (projectId: string, start: number, end: number) => ({
  queryKey: qrynQueryKeys.qrynMetrics(projectId, start, end),
  queryFn: () => apiClient.qryn.getQrynMetrics({ projectId, start, end }),
})

/**
 * Query to fetch `qryn` cloud instances
 */
export const qrynCloudInstancesQuery = () => ({
  queryKey: qrynQueryKeys.qrynCloudInstances,
  queryFn: apiClient.qryn.getQrynCloudInstances,
  staleTime: ONE_HOUR,
})

/**
 * Query to fetch applications instrumented with `qryn`
 */
export const qrynInstrumentedApplicationsQuery = (projectId: string, appName?: string) => ({
  queryKey: qrynQueryKeys.qrynInstrumentedApplications(projectId, appName),
  queryFn: () => apiClient.qryn.getQrynInstrumentedApplications({ projectId, appName }),
})

/**
 * Query to fetch the current cost for `qryn`
 */
export const qrynCurrentCostQuery = (
  projectId: string,
  integrationStatus: Integration['status'],
) => ({
  queryKey: qrynQueryKeys.qrynCurrentCost(projectId),
  queryFn: () => apiClient.qryn.getQrynCurrentCost(projectId),
  enabled: integrationStatus !== 'trial',
})

/**
 * Query to fetch the scoped tokens for `qryn`
 */
export const qrynTokensQuery = (projectId: string) => ({
  queryKey: qrynQueryKeys.qrynTokens(projectId),
  queryFn: () => apiClient.qryn.getQrynTokens(projectId),
})

/**
 * Query to fetch the retention policies for `qryn`
 */
export const qrynRetentionPoliciesQuery = () => ({
  queryKey: qrynQueryKeys.qrynRetentionPolicies,
  queryFn: apiClient.qryn.getQrynRetentionPolicies,
})
