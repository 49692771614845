import { z } from 'zod'

import { type Rooquest } from '../lib/rooquest'

import { productSchema, productWithProjectsSchema } from './model'

const products = z.array(productSchema)
const productsWithProjects = z.array(productWithProjectsSchema)

/**
 * The service to interact with all the products domain endpoints of Gigapipe's API
 */
export default class ProductsService {
  private readonly rooquest: Rooquest

  constructor(rooquest: Rooquest) {
    this.rooquest = rooquest
  }

  /**
   * Get products
   */
  public getProducts = async () => {
    const res = await this.rooquest.get('products')

    return products.parse(res)
  }

  /**
   * Get all products with the user projects that have them enabled
   */
  public getProductsWithUserProjectsOnUse = async () => {
    const res = await this.rooquest.get('users/products')

    return productsWithProjects.parse(res)
  }
}
