import { useRef } from 'react'
import { type AriaTabListProps, useTabList } from 'react-aria'
import { Item, type TabListProps, useTabListState } from 'react-stately'

import { Box } from '~/components/Box'

import { Tab, TabPanel } from './components'

import * as styles from './Tabs.css'

type CustomTabsProps = {
  isNested?: boolean
}
type TabsProps<T> = CustomTabsProps & TabListProps<T> & AriaTabListProps<T>

/**
 * Component used to group content in a tabbed interface.
 */
export function Tabs<T extends object>(props: TabsProps<T>) {
  const { orientation, isNested } = props
  const state = useTabListState<T>(props)
  const ref = useRef<HTMLDivElement>(null)
  const { tabListProps } = useTabList<T>(props, state, ref)

  return (
    <Box>
      <div className={styles.tablistContainer({ isNested })}>
        <div {...tabListProps} ref={ref} className={styles.tablist}>
          {[...state.collection].map(item => (
            <Tab
              key={item.key}
              item={item}
              state={state}
              orientation={orientation}
              isNested={isNested}
            />
          ))}
        </div>
      </div>
      <TabPanel key={state.selectedItem?.key} state={state} isNested={isNested} />
    </Box>
  )
}

Tabs.Item = Item
