import { type Region } from 'api-client'

const continents = [
  'Africa',
  'Americas',
  'Asia Pacific',
  'Europe',
  'Middle East',
  'Oceania',
] as const

/**
 * Group regions by continents
 * @param regions
 */
export function groupRegionsByContinent(regions?: Region[]) {
  if (!regions) return []
  return continents
    .map(continent => ({
      id: continent,
      title: continent,
      regions: regions
        .filter(r => r.continent === continent)
        .map(r => ({ ...r, title: `${r.name} (${r.code})` })),
    }))
    .filter(continent => continent.regions.length)
}
