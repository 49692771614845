import { type ReactNode } from 'react'
import { type Prettify } from 'plunger'

import { Box } from '../Box'
import { Text } from '../Text'

import { type CardVariants } from './Card.css'
import * as styles from './Card.css'

type CardProps = {
  children: ReactNode
  /**
   * Makes the card fill the available space when used inside a flex container.
   */
  fill?: boolean
  /**
   * Prop used to identify the element in the DOM when no a11y selector is available.
   */
  'data-testid'?: string
}

type CardItemProps = Prettify<
  {
    children: ReactNode
  } & CardVariants
>

type CardHeaderProps = Prettify<
  {
    title?: string
    description?: ReactNode
    children?: ReactNode
  } & CardVariants
>

/**
 * Component used to group content in a card.
 */
export const Card = ({ children, fill = false, ...rest }: CardProps) => (
  <Box className={styles.card({ fill })} {...rest}>
    {children}
  </Box>
)

/**
 * A compound component that is used as a header.
 */
const CardHeader = ({ title, description, children }: CardHeaderProps) => (
  <Box className={[styles.cardSection, styles.cardHeader]}>
    {title && (
      <Box marginBottom={description ? 'xsmall' : 'none'}>
        <Text size="large" weight="bold" color="white">
          {title}
        </Text>
      </Box>
    )}
    {description && (
      <Text color="gray600" size="small">
        {description}
      </Text>
    )}
    {children}
  </Box>
)

Card.Header = CardHeader

/**
 * A compound component that's used to create sections within a card.
 */
const CardSection = ({ children }: CardItemProps) => (
  <Box className={styles.cardSection}>{children}</Box>
)

Card.Section = CardSection

/**
 * A compound component that's used to display a footer within a card. Usually used to display actions.
 */
const CardFooter = ({ children }: CardItemProps) => (
  <Box className={[styles.cardSection, styles.cardFooter]}>{children}</Box>
)

Card.Footer = CardFooter

/**
 * A compound component that's used to create a divider within a card.
 */
const CardDivider = () => <Box className={styles.cardDivider} />

Card.Divider = CardDivider
