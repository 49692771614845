import { Children, forwardRef } from 'react'

import { Box, type BoxProps } from '../Box'

import { type TruncateVariants, truncateVariants } from './Text.css'

export type TextProps = {
  as?: 'code' | 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'label' | 'p' | 'span' | 'li'
  children?: React.ReactNode
  className?: BoxProps['className']
  align?: BoxProps['textAlign']
  color?: BoxProps['color']
  font?: BoxProps['fontFamily']
  decoration?: BoxProps['textDecoration']
  id?: BoxProps['id']
  letterSpacing?: BoxProps['letterSpacing']

  lineHeight?: BoxProps['lineHeight']
  size?: BoxProps['fontSize']
  transform?: BoxProps['textTransform']
  weight?: BoxProps['fontWeight']
  noWrap?: boolean
  htmlFor?: string
} & TruncateVariants

export const Text = forwardRef(
  (
    {
      as = 'p',
      children,
      size = 'md',
      weight = 'regular',
      font = 'sans',
      color = { darkMode: 'white', lightMode: 'black' },
      decoration,
      id,
      transform,
      align,
      truncate,
      letterSpacing,
      lineHeight,
      noWrap,
      className,
      htmlFor,
    }: TextProps,
    ref: React.Ref<HTMLElement>,
  ) => {
    let title = ''
    if (truncate) {
      Children.map(children, child => {
        if (typeof child === 'string') {
          title += child
        }
      })
    }
    return (
      <Box
        as={as}
        ref={ref}
        id={id}
        title={truncate ? title : undefined}
        className={className}
        color={color}
        fontFamily={font}
        fontSize={size || 'md'}
        fontWeight={weight}
        letterSpacing={letterSpacing}
        lineHeight={lineHeight}
        textAlign={align}
        textTransform={transform}
        textDecoration={decoration}
        whiteSpace={noWrap ? 'nowrap' : undefined}
        htmlFor={htmlFor}
      >
        {truncate ? <span className={truncateVariants({ truncate })}>{children}</span> : children}
      </Box>
    )
  },
)

Text.displayName = 'Text'
