import { memo, type SVGProps } from 'react'

const SvgEyeOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.827 18.827a4 4 0 1 1-5.654-5.654M1.333 1.333l29.334 29.334M23.92 23.92A13.427 13.427 0 0 1 16 26.667C6.667 26.667 1.333 16 1.333 16A24.6 24.6 0 0 1 8.08 8.08l15.84 15.84ZM13.2 5.653a12.16 12.16 0 0 1 2.8-.32C25.333 5.333 30.667 16 30.667 16a24.657 24.657 0 0 1-2.88 4.253L13.2 5.653Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgEyeOff)
export default Memo
