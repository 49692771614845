import { memo, type SVGProps } from 'react'

const SvgSend = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.333 2.667 14.667 17.333M29.333 2.667 20 29.333l-5.333-12M29.333 2.667 2.667 12l12 5.333"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgSend)
export default Memo
