import { useEffect } from 'react'
import { useFetcher } from 'react-router-dom'
import { type Project } from 'api-client'

import { ROTATE_QRYN_KEYS } from '~/entities/integrations/qryn'

import { useBoolean } from '~/shared/lib/useBoolean'

import { Button } from '~/components/Button'
import { Loading } from '~/components/Loading'
import { Modal } from '~/components/Modal'
import { Text } from '~/components/Text'

type RotateQrynKeysButtonProps = {
  projectSlug: Project['id']
}

export function RotateQrynKeysButton({ projectSlug }: RotateQrynKeysButtonProps) {
  const fetcher = useFetcher()
  const [isOpen, { on: openModal, off: closeModal }] = useBoolean(false)

  useEffect(() => {
    if (fetcher.data?.hasConfirmed) closeModal()
  }, [fetcher.data, closeModal])

  return (
    <>
      <Button color="neutral" isDisabled={fetcher.state !== 'idle'} onPress={openModal}>
        Rotate API keys
      </Button>
      <Modal isOpen={isOpen} onClose={closeModal} dialogProps={{ id: 'rotate-qryn-keys' }}>
        <Modal.Header title="Are you sure you want to rotate the keys?" />
        <Modal.Content>
          <Text color="gray600">
            Remember to update all your applications currently ingesting data into qryn once you
            rotate your keys.
          </Text>
        </Modal.Content>
        <fetcher.Form method="post" action={`/${projectSlug}/stack/qryn`}>
          <Modal.Footer>
            <Modal.Action autoFocus onPress={closeModal}>
              Cancel
            </Modal.Action>
            <Modal.Action type="submit" name="intent" value={ROTATE_QRYN_KEYS}>
              <Text as="span" color="gigagreen">
                {fetcher.state !== 'idle' ? <Loading /> : 'Rotate Keys'}
              </Text>
            </Modal.Action>
          </Modal.Footer>
        </fetcher.Form>
      </Modal>
    </>
  )
}
