import { memo, type SVGProps } from 'react'

const SvgServer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.167 5h.009m-.01 10h.01M3.333 1.667H18c1.013 0 1.833.746 1.833 1.666v3.334c0 .92-.82 1.666-1.833 1.666H3.333c-1.012 0-1.833-.746-1.833-1.666V3.333c0-.92.82-1.666 1.833-1.666Zm0 10H18c1.013 0 1.833.746 1.833 1.666v3.334c0 .92-.82 1.666-1.833 1.666H3.333c-1.012 0-1.833-.746-1.833-1.666v-3.334c0-.92.82-1.666 1.833-1.666Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const Memo = memo(SvgServer)
export default Memo
