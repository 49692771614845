import { apiClient } from '~/shared/api'

const projectsQueryKeys = {
  all: [{ scope: 'projects' }] as const,
  usersFromProject: (projectId: string) =>
    [{ scope: 'projects', type: 'users', projectId }] as const,
}

/**
 * Query to fetch all projects associated to the logged in user
 */
export const projectsQuery = () => ({
  queryKey: projectsQueryKeys.all,
  queryFn: apiClient.projects.getProjects,
})

/**
 * Query to get all users added to a project
 */
export const usersFromProjectQuery = (projectId: string, isOrgAccount: boolean) => ({
  queryKey: projectsQueryKeys.usersFromProject(projectId),
  // Workaround to keep type inference when using `usersFromProjectQuery`. This should be fixed by the query options factory function from @tanstack/query v5`
  queryFn: () => apiClient.projects.getUsersFromProject(projectId),
  enabled: isOrgAccount && Boolean(projectId),
})
