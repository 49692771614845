import { useFetcher } from 'react-router-dom'
import { type Integration, type Project } from 'api-client'

import { CANCEL_SUBSCRIPTION } from '~/entities/integrations/base'

import { useBoolean } from '~/shared/lib/useBoolean'

import { Button } from '~/components/Button'
import { Loading } from '~/components/Loading'
import { Modal } from '~/components/Modal'
import { Text } from '~/components/Text'

type CancelSubscriptionButtonProps = {
  integrationSlug: Integration['product']['slug']
  integrationName: Integration['product']['name']
  cancellationMessage?: string
  projectSlug: Project['id']
}

export function CancelSubscriptionButton({
  integrationSlug,
  integrationName,
  projectSlug,
  cancellationMessage = getDefaultCancellationMessage(integrationName),
}: CancelSubscriptionButtonProps) {
  const fetcher = useFetcher()
  const [isOpen, { on: openModal, off: closeModal }] = useBoolean(false)

  return (
    <>
      <Button color="neutral" isDisabled={fetcher.state !== 'idle'} onPress={openModal}>
        Cancel subscription
      </Button>
      <Modal isOpen={isOpen} onClose={closeModal} dialogProps={{ id: 'cancel-qryn-subscription' }}>
        <Modal.Header title={`Are you sure you want to cancel ${integrationName} subscription?`} />
        <Modal.Content>
          <Text color="gray600">{cancellationMessage}</Text>
        </Modal.Content>
        <fetcher.Form method="post" action={`/${projectSlug}/stack/${integrationSlug}`}>
          <Modal.Footer>
            <Modal.Action autoFocus onPress={closeModal}>
              Cancel
            </Modal.Action>
            <Modal.Action type="submit" name="intent" value={CANCEL_SUBSCRIPTION}>
              <Text as="span" color="gigagreen">
                {fetcher.state !== 'idle' ? <Loading /> : 'Confirm'}
              </Text>
            </Modal.Action>
          </Modal.Footer>
        </fetcher.Form>
      </Modal>
    </>
  )
}

function getDefaultCancellationMessage(integrationName: Integration['product']['name']) {
  return `${integrationName} will run until your next billable date. After that, you will lose
  access to the integration but any previous data, users, and configuration will be
  preserved. To re-enable ${integrationName} subscription, contact support.`
}
