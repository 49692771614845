import { useQuery } from '@tanstack/react-query'

import { useDecodedToken } from '~/shared/model/auth/useAuth'

import { Box } from '~/components/Box'
import { Card } from '~/components/Card'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import { useProjectIdParam } from '../model/useProjectIdParam'
import { usersFromProjectQuery } from '../queries'

export const UsersFromProject = () => {
  const { projectId } = useProjectIdParam()
  const decodedToken = useDecodedToken()
  const isOrgAccount = Boolean(decodedToken?.organization_id)
  const { data: users } = useQuery(usersFromProjectQuery(projectId, isOrgAccount))

  if (!isOrgAccount)
    return (
      <Card fill>
        <Card.Header title="Project Team" />
        <Card.Section>
          <Stack direction="horizontal" justify="between" align="center">
            <Box>
              {decodedToken?.given_name} {decodedToken?.family_name}
            </Box>
            <Box>{decodedToken?.email}</Box>
          </Stack>
        </Card.Section>
      </Card>
    )
  return (
    <Card fill>
      <Card.Header title="Project Team" />
      <Card.Section>
        {users?.length ? (
          users.map(user => (
            <Stack direction="horizontal" justify="between" align="center" key={user.id}>
              <Box>{user.givenName + ' ' + user.familyName}</Box>
              <Box>{user.email}</Box>
            </Stack>
          ))
        ) : (
          <Text>There are no users yet.</Text>
        )}
      </Card.Section>
    </Card>
  )
}
