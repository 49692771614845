import { createContext, useContext } from 'react'

/**
 * Custom hook to create and initialize a type-safe React.Context
 *
 * Returns a hook to access the context and the `<Provider />`
 *
 * @example
 * const [useExample, ExampleProvider] = useReactContext<ExampleContextState>()
 */
export const createReactContext = <A extends object | null>() => {
  const ctx = createContext<A | undefined>(undefined)
  const useCtx = () => {
    const c = useContext(ctx)
    if (c === undefined) throw new Error('useCtx must be inside a Provider with a value')
    return c
  }
  return [useCtx, ctx.Provider] as const
}
