import { useState } from 'react'
import { useSubmit } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { qrynConfigQuery, UPDATE_FINGERPRINTS_LIMIT } from '~/entities/integrations/qryn'

export const FINGERPRINTS_NUMBER_STEP = 1000
export const FINGERPRINTS_SPEND_STEP = 5

const NUMBER_TO_SPEND_LIMIT = FINGERPRINTS_SPEND_STEP / FINGERPRINTS_NUMBER_STEP
const SPEND_TO_NUMBER_LIMIT = FINGERPRINTS_NUMBER_STEP / FINGERPRINTS_SPEND_STEP

type Options = {
  projectId: Project['id']
  projectSlug: Project['slug']
}
export function useFingerprintsLimit({ projectSlug, projectId }: Options) {
  const { data: config } = useQuery(qrynConfigQuery(projectId))
  const [hasFingerprintsLimitEnabled, setHasFingerprintsLimitEnabled] = useState(
    (config?.fingerprintLimit ?? 0) > 0,
  )
  const [fingerprintsNumberLimit, setFingerprintsNumberLimit] = useState(
    config?.fingerprintLimit ?? 0,
  )
  const [fingerprintsSpendLimit, setFingerprintsSpendLimit] = useState(
    (config?.fingerprintLimit ?? 0) * NUMBER_TO_SPEND_LIMIT,
  )

  const submit = useSubmit()

  const handleLimitUpdate = ({
    newNumberLimit,
    newSpendLimit,
  }: {
    newNumberLimit?: number
    newSpendLimit?: number
  }) => {
    if (newNumberLimit) {
      setFingerprintsNumberLimit(newNumberLimit)
      setFingerprintsSpendLimit(newNumberLimit * NUMBER_TO_SPEND_LIMIT)
    }
    if (newSpendLimit) {
      setFingerprintsSpendLimit(newSpendLimit)
      setFingerprintsNumberLimit(newSpendLimit * SPEND_TO_NUMBER_LIMIT)
    }
  }

  const handleFeatureToggle = (isSelected: boolean) => {
    if (!isSelected && config?.fingerprintLimit !== 0) {
      submit(
        { fingerprintLimit: '0', intent: UPDATE_FINGERPRINTS_LIMIT },
        {
          method: 'post',
          action: `/${projectSlug}/stack/qryn`,
          encType: 'application/x-www-form-urlencoded',
        },
      )
      setFingerprintsNumberLimit(0)
      setFingerprintsSpendLimit(0)
    }
    setHasFingerprintsLimitEnabled(isSelected)
  }

  return {
    fingerprintsNumberLimit,
    fingerprintsSpendLimit,
    hasFingerprintsLimitEnabled,
    handleLimitUpdate,
    handleFeatureToggle,
  }
}
