import { deepObject, getLength } from '~/lib/utils'

type Obj = Record<string, any>
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
type RulesEnum = boolean | number | string | object | RuleFn | 'setValueAs'

type RuleFn = (rule: any, field: any, fields: any) => boolean

type Values = Record<string, any>

const regex: Obj = {
  email:
    // eslint-disable-next-line no-control-regex
    /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
}

const defaultRules: Record<string, RuleFn> = {
  required: (field: any, fields: any, ruleValue: any) =>
    ruleValue === false ||
    (Array.isArray(field?.value)
      ? !!field?.value.length
      : field?.value !== null &&
        field?.value !== undefined &&
        field?.value !== false &&
        field?.value !== ''),
  minLength: (field: any, fields: any, ruleValue: any) => getLength(field.value) >= ruleValue,
  maxLength: (field: any, fields: any, ruleValue: any) => getLength(field.value) <= ruleValue,
  match: (field: any, fields: any, ruleValue: any) => field?.value === fields?.[ruleValue],
  pattern: (field: any, fields: any, ruleValue: any) =>
    (regex[ruleValue] || ruleValue).test(field?.value),

  email: (field: any) => regex.email.test(field?.value),
}

/**
 * @deprecated Use Zod to validate data
 * @param rules
 * @param values
 */
function validate<FIELDSOBJECT>(
  rules: Record<keyof FIELDSOBJECT, Record<string, RulesEnum>>,
  values: Values,
) {
  // Populate the errors from all the fields
  const errors = Object.keys(rules).reduce(
    (formErrors, fieldName) => {
      const fieldRules = rules[fieldName as keyof FIELDSOBJECT]
      const field = { name: fieldName, value: values[fieldName] }

      // Support for dot-notation to access nested object keys
      if (fieldName.includes('.')) {
        field.value = deepObject(fieldName, values)
      }

      // Loop through the field rules and set field errors if any
      const fieldErrors = Object.keys(fieldRules).reduce(
        (acc, ruleName) => {
          const ruleValue = fieldRules[ruleName]
          const fn = typeof ruleValue === 'function' ? ruleValue : defaultRules[ruleName]

          // @todo Log some message when the fn() is not defined and the field validation will pass silently

          // Validate the field only if a rule is available (built-in or custom)
          if (fn && !fn(field, values, ruleValue)) {
            return { ...acc, [ruleName]: true }
          }

          return acc
        },
        {} as Record<string, RulesEnum>,
      )

      if (Object.keys(fieldErrors).length) {
        return { ...formErrors, [fieldName]: fieldErrors }
      }

      return formErrors
    },
    {} as Record<keyof FIELDSOBJECT, Record<string, boolean>>,
  )

  const result = {
    valid: !Object.keys(errors).length,
    errors,
  }

  return result
}

export default validate
