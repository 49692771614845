import { memo, type SVGProps } from 'react'

const SvgDecodable = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 28.195v-5.352a6.245 6.245 0 1 0 0-12.49V5a11.598 11.598 0 0 1 0 23.195ZM25.608 28.195l-7.337-7.338a6.027 6.027 0 0 1 0-8.515l7.337-7.339 4.06 4.06-7.539 7.538 7.539 7.538-4.06 4.056Z"
      fill="#2F75FA"
    />
  </svg>
)
const Memo = memo(SvgDecodable)
export default Memo
