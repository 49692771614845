import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { IntegrationLink } from '~/entities/integrations/base'
import { hepicConfigQuery, hepicUsersQuery } from '~/entities/integrations/hepic'

import { useDecodedToken } from '~/shared/model/auth/useAuth'

import { Tooltip } from '~/components/Tooltip'

type HepicLinksProps = {
  projectId: Project['id']
}

export function HepicLinks({ projectId }: HepicLinksProps) {
  const { data: config } = useQuery(hepicConfigQuery(projectId))
  const decodedToken = useDecodedToken()
  const isOrgAccount = Boolean(decodedToken?.organization_id)
  const { data: hepicUsers } = useQuery(hepicUsersQuery(projectId, isOrgAccount))

  const hasHepicUsers = Boolean(hepicUsers?.length)

  return (
    <>
      <Tooltip content={!hasHepicUsers ? 'Please set hepic users at config tab to lunch app' : ''}>
        <IntegrationLink
          textTransform="none"
          disabled={!hasHepicUsers}
          label="Launch hepic"
          launchURL={config?.url}
        />
      </Tooltip>
      <IntegrationLink
        textTransform="none"
        label="hepic documentation"
        launchURL="https://helpdesk.qxip.net"
      />
    </>
  )
}
