import { useState } from 'react'
import { useFetcher } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { qrynConfigQuery, UPDATE_CACHE_SERVER } from '~/entities/integrations/qryn'

type Options = {
  projectId: Project['id']
  projectSlug: Project['slug']
}
export function useQrynCache({ projectSlug, projectId }: Options) {
  const { data: config } = useQuery(qrynConfigQuery(projectId))

  const [cacheEnabled, setCacheEnabled] = useState(config?.cacheEnabled ?? false)

  const fetcher = useFetcher()

  const handleCacheToggle = (enabled: boolean): void => {
    setCacheEnabled(prev => (prev ? false : true))

    fetcher.submit(
      { intent: UPDATE_CACHE_SERVER, enabled },
      {
        method: 'post',
        action: `/${projectSlug}/stack/qryn`,
        encType: 'application/x-www-form-urlencoded',
      },
    )
  }

  return {
    cacheEnabled,
    handleCacheToggle,
  }
}
