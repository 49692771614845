const date = new Date()
export const timeRanges = {
  currentMonth: {
    start: new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0).getTime(),
    end: Date.now(),
  },
  sinceCreation: {
    start: new Date(2022, 1, 1, 0, 0, 0).getTime(),
    end: Date.now(),
  },
}

export type TimeRanges = keyof typeof timeRanges
