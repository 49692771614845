import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { clickHouseClustersQuery } from '~/entities/integrations/clickhouse'

export function useClickHouseLinks(projectId: Project['id']) {
  const { clusterId } = useParams<'clusterId'>()
  const { data: cluster } = useQuery({
    ...clickHouseClustersQuery(projectId),
    select: data => data.find(cl => cl.id === clusterId),
  })

  if (!cluster || !(cluster.status === 'active' || cluster.status === 'external'))
    return { view: undefined, instance: undefined }
  return {
    view: `https://${cluster.host}/play`,
    instance: `https://${cluster.host}`,
  }
}
