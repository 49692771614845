import { useCallback, useMemo, useState } from 'react'
import {
  type Cell,
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  type SortingState,
  type TableMeta,
  useReactTable,
  type VisibilityState,
} from '@tanstack/react-table'
import { clsx } from 'clsx'

import { downloadFile, type Options as DownloadOptions } from '~/shared/lib/downloadFile'
import { useClipboard } from '~/shared/lib/useClipboard'

import { Box } from '../Box'
import { Button } from '../Button'
import { Checkbox } from '../Checkbox'
import { Icon } from '../Icon'
import { CheckCircle, ChevronDown, ChevronUp, Copy } from '../Icons'
import { MenuButton } from '../Menu'
import { Stack } from '../Stack'

import * as styles from './Table.css'

export type TableProps<D extends object> = {
  /**
   * The array of data to be displayed. Needs memoization (useMemo)
   */
  data: D[]
  /**
   * Columns definition. Needs memoization (useMemo)
   */
  columns: ColumnDef<D, string>[]
  /**
   * Control which columns are hidden initially
   */
  initialHiddenColumns?: string[]
  /**
   * Control if the toggle visible columns feature is enabled
   * @default true
   */
  enableColumnsToggle?: boolean
  /**
   * Control if the download data feature is enabled
   * @default true
   */
  enableDataDownload?: boolean
  /**
   * Think of this option as an arbitrary "context" for your table.
   * Use module enhancement to add type information
   *
   * @example
   * declare module '@tanstack/table-core' {
   *  interface TableMeta {
   *    someTableCtx?: {
   *      item: string
   *      handler: (value:string) => void
   *    }
   *  }
   * }
   */
  meta?: TableMeta<D>
  /**
   * The default definition fo the columns.
   */
  defaultColumnDef?: Partial<ColumnDef<D>>
  /**
   * Controls if the table element fills the entire container. Usefull when the default size of the table is not enough.
   * @default false
   */
  fillWidth?: boolean
}

/**
 * Component used to display a large ammount of data in a table format.
 */
export function Table<D extends object>({
  data,
  columns,
  initialHiddenColumns = [],
  enableColumnsToggle = true,
  enableDataDownload = true,
  meta,
  defaultColumnDef,
  fillWidth = false,
}: TableProps<D>) {
  const defaultColumn = useMemo<Partial<ColumnDef<D>>>(
    () => ({
      minSize: 80, // minWidth is only used as a limit for resizing
      size: 140, // width is used for both the flex-basis and flex-grow
      maxSize: 500, // maxWidth is only used as a limit for resizing
      ...defaultColumnDef,
    }),
    [defaultColumnDef],
  )

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    initialHiddenColumns.reduce((acc, id) => ({ ...acc, [id]: false }), {}),
  )
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      sorting,
    },
    defaultColumn,
    columnResizeMode: 'onChange',
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    meta,
  })

  const handleDownload = useCallback(
    (format: DownloadOptions['format']) => {
      if (format === 'json') {
        downloadFile(JSON.stringify({ data }), { filename: `YourData.json`, format })
      } else if (format === 'csv') {
        const headers = Object.keys(data[0])
        const csvData = [
          headers.join(','),
          ...data.map((row: Record<string, any>) =>
            headers
              .map(fieldName =>
                JSON.stringify(row[fieldName], (_key, value) => (value === null ? '' : value)),
              )
              .join(','),
          ),
        ].join('\r\n')

        downloadFile(csvData, { filename: 'YourData.csv', format })
      } else {
        throw Error('Invalid format')
      }
    },
    [data],
  )

  return (
    <Stack space={enableDataDownload || enableColumnsToggle ? 'medium' : 'none'}>
      <Stack direction="horizontal" justify="end">
        {enableDataDownload && (
          <MenuButton
            label="Download"
            selectionMode="single"
            aria-label="Download the data from the table as JSON or CSV file"
            onAction={key => handleDownload(key.toString() as DownloadOptions['format'])}
          >
            <MenuButton.Item key="json" textValue="JSON">
              JSON
            </MenuButton.Item>
            <MenuButton.Item key="csv" textValue="CSV">
              CSV
            </MenuButton.Item>
          </MenuButton>
        )}
        {enableColumnsToggle && (
          <MenuButton
            label="Toggle Columns"
            selectionMode="multiple"
            aria-label="Toggle columns from the table"
          >
            {[
              <MenuButton.Item key="all" textValue="All columns">
                <Checkbox
                  isSelected={table.getIsAllColumnsVisible()}
                  isIndeterminate={table.getIsSomeColumnsVisible()}
                  onChange={table.toggleAllColumnsVisible}
                >
                  All
                </Checkbox>
              </MenuButton.Item>,
              ...table.getAllLeafColumns().map(col => (
                <MenuButton.Item key={col.id} textValue={col.id} aria-label={col.id}>
                  <Checkbox isSelected={col.getIsVisible()} onChange={col.toggleVisibility}>
                    {col.id}
                  </Checkbox>
                </MenuButton.Item>
              )),
            ]}
          </MenuButton>
        )}
      </Stack>
      <div className={styles.tableWrapper}>
        <table
          className={styles.table}
          style={{
            width: fillWidth ? '100%' : table.getTotalSize(),
          }}
          aria-label="table"
        >
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} className={styles.headerRow}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      width: header.getSize(),
                    }}
                    className={styles.headerCell}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div
                      className={clsx(
                        styles.headerCellWrapper,
                        header.column.getCanSort() && styles.sortableHeaderCell,
                      )}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}

                      {header.column.getIsSorted() ? (
                        <Icon>
                          {header.column.getIsSorted() === 'desc' ? <ChevronDown /> : <ChevronUp />}
                        </Icon>
                      ) : null}
                    </div>
                    <div
                      {...(header.column.getCanResize() && {
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                      })}
                      className={clsx(
                        styles.headerColumnSeparator,
                        header.column.getCanResize() && styles.resizableSeparator,
                        header.column.getIsResizing() && styles.isResizing,
                      )}
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id} className={styles.row}>
                {row.getVisibleCells().map(cell => (
                  <td
                    key={cell.id}
                    className={styles.cell}
                    style={{ width: cell.column.getSize() }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Stack>
  )
}

export type CustomCellProps<D extends object> = ReturnType<Cell<D, string>['getContext']>

/**
 * Use this component to render the regular value of the cell with a copy to clipboard button that is displayed when the cell is hovered
 */
export function CellWithCopyButton<D extends object>({ getValue }: CustomCellProps<D>) {
  const [isCopied, setCopied] = useClipboard()
  return (
    <>
      {getValue()}
      <Box as="span" className={styles.copyButton}>
        <Button
          aria-label="Copy cell to clipboard"
          size="small"
          color="neutral"
          onPress={() => void setCopied(getValue())}
        >
          <Icon color={isCopied ? 'gigagreen' : undefined}>
            {isCopied ? <CheckCircle /> : <Copy />}
          </Icon>
        </Button>
      </Box>
    </>
  )
}
