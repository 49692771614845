import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { hepicUsersQuery } from '~/entities/integrations/hepic'

import { useDecodedToken } from '~/shared/model/auth/useAuth'

import { Stack } from '~/components/Stack'
import { Switch } from '~/components/Switch'

import { useAutoAddUsers } from './model'

type ManageHepicAutoAddUsersProps = {
  projectId: Project['id']
  projectSlug: Project['slug']
}

const ManageHepicAutoAddUsers = ({ projectId, projectSlug }: ManageHepicAutoAddUsersProps) => {
  const { autoAddUsers, handleAutoAddUsersToggle } = useAutoAddUsers({ projectSlug, projectId })
  const decodedToken = useDecodedToken()
  const isOrgAccount = Boolean(decodedToken?.organization_id)
  const { data: hepicUsers } = useQuery(hepicUsersQuery(projectId, isOrgAccount))
  const isHepicAdmin =
    hepicUsers?.find(user => user.email === decodedToken?.email)?.group === 'admin'

  return (
    <Stack space="small">
      {isHepicAdmin && (
        <Switch
          disabled={isHepicAdmin}
          defaultSelected={autoAddUsers}
          onChange={handleAutoAddUsersToggle}
        >
          Automatically add users in the current project to hepic.
        </Switch>
      )}
    </Stack>
  )
}

export default ManageHepicAutoAddUsers
