import { memo, type SVGProps } from 'react'

const SvgGigapipe = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 14.666 29.333 22 22 29.333 14.666 22 22 14.666ZM38.5 27.5 44 22 22 0 0 22l22 22 14.666-14.666-5.5-5.5L22 33 11 22l11-11 11 11 5.5 5.5Z"
      fill="currentColor"
    />
  </svg>
)
const Memo = memo(SvgGigapipe)
export default Memo
