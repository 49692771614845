export const TEN_SECONDS = 10 * 1000
export const FIVE_MINUTES = 5 * 60 * 1000
export const ONE_HOUR = 60 * 60 * 1000
export const EIGHT_HOURS = 8 * ONE_HOUR
export const ONE_WEEK = 7 * 24 * ONE_HOUR

export const MINUTES_IN_YEAR = 365 * 24 * 60

export const storageUnitToBytes = {
  GB: 1_000_000_000,
  TB: 1_000_000_000_000,
  GiB: 1.073741824 * 1_000_000_000,
  TiB: 1.099511627776 * 1_000_000_000_000,
}

export const REGEX_LOWER_CASE = /(?=.*[a-z])/ // at least one lower case letter exists
export const REGEX_UPER_CASE = /(?=.*[A-Z])/ // at least one upper case letter exists
export const REGEX_DIGITS = /(?=.*\d)/ // at least one digit exists
export const REGEX_SPECIAL_CHARACTERS = /(?=.*[!@#$%^&*])/ // at least one non-word character exists
