import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { type LoggenLogsConfig, type Project } from 'api-client'

import {
  loggenConfigQuery,
  loggenLogsCategoriesQuery,
  loggenLogsConfigQuery,
} from '~/entities/integrations/loggen'

import { useBoolean } from '~/shared/lib/useBoolean'
import { Accordion } from '~/shared/ui/Accordion'

import { Box } from '~/components/Box'
import { Button } from '~/components/Button'
import { Card } from '~/components/Card'
import { Checkbox } from '~/components/Checkbox'
import { IconButton } from '~/components/IconButton'
import { XCircle as Delete } from '~/components/Icons'
import { Input } from '~/components/Input'
import { Loading } from '~/components/Loading'
import { Message } from '~/components/Message'
import { Modal } from '~/components/Modal'
import { Select } from '~/components/Select'
import { Stack } from '~/components/Stack'
import { Stepper } from '~/components/Stepper'
import { Text } from '~/components/Text'

type LoggenConfigStructureFieldProps = {
  projectId: Project['id']
}

const convertDisplayNameToFunctionName = (displayName: string) =>
  displayName.replaceAll(' ', '').toLocaleLowerCase()

export function LoggenConfigStructureField({ projectId }: LoggenConfigStructureFieldProps) {
  const { data: config } = useQuery(loggenConfigQuery(projectId))
  const [logsConfigStructure, setLogConfigStructure] = useState<
    { id: string; key: string; value: string }[]
  >([])
  const [isNewFieldModalOpen, { on: openNewFieldModal, off: closeNewFieldModal }] =
    useBoolean(false)

  useEffect(() => {
    if (config?.logConfig?.structure)
      setLogConfigStructure(
        Object.keys(config.logConfig.structure).map(key => ({
          id: crypto.randomUUID(),
          key,
          value: config.logConfig.structure[key],
        })),
      )
  }, [config])

  const addLogsConfigStructure = (key: string, value: string) =>
    setLogConfigStructure(oldStructure => [
      ...oldStructure,
      {
        id: crypto.randomUUID(),
        key,
        value,
      },
    ])

  const updateLogsConfigStructure = (newStructure: { id: string; key: string; value: string }) =>
    setLogConfigStructure(oldStructure =>
      oldStructure.map(struct => (struct.id === newStructure.id ? newStructure : struct)),
    )

  const removeLogsConfigStructure = (id: string) =>
    setLogConfigStructure(oldStructure => oldStructure.filter(struct => struct.id !== id))

  return (
    <>
      <Stack direction="horizontal" justify="between">
        <Text>Logs structure</Text>
        <Button color="outline" size="small" onPress={openNewFieldModal}>
          Add field +
        </Button>
      </Stack>

      {logsConfigStructure.map(entry => (
        <Stack key={entry.id} direction="horizontal" space="small">
          <input type="hidden" name={`logConfig.structure.${entry.key}`} value={entry.value} />
          <div style={{ width: '100%' }}>
            <Input
              placeholder="key"
              aria-label="Log Config Key"
              defaultValue={entry.key}
              onChange={val => updateLogsConfigStructure({ ...entry, key: val })}
            />
          </div>
          <div style={{ width: '100%' }}>
            <Input
              placeholder="value"
              aria-label="Log Config Value"
              defaultValue={entry.value}
              onChange={val => updateLogsConfigStructure({ ...entry, value: val })}
            />
          </div>
          <IconButton
            aria-label="Delete field"
            color="transparent"
            icon={<Delete />}
            onPress={() => removeLogsConfigStructure(entry.id)}
          />
        </Stack>
      ))}

      {!logsConfigStructure.length && (
        <Message size="small" type="muted">
          You need to add at least one field
        </Message>
      )}

      <Modal isOpen={isNewFieldModalOpen} onClose={closeNewFieldModal}>
        <Modal.Header title="New field" />
        <Modal.Content>
          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
          <LoggenNewField
            onSubmit={(key, val) => {
              addLogsConfigStructure(key, val)
              closeNewFieldModal()
            }}
          />
        </Modal.Content>
        <Modal.Footer>
          <Modal.Action onPress={closeNewFieldModal} autoFocus>
            Cancel
          </Modal.Action>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const LoggenNewField = ({ onSubmit }: { onSubmit: (key: string, value: string) => void }) => {
  const { data: logsCategories } = useQuery(loggenLogsCategoriesQuery)
  const [newFieldName, setNewFieldName] = useState<string>('new_field')
  const [newFieldCategory, setNewFieldCategory] = useState<string>('')
  const [newFieldParams, setNewFieldParams] = useState<Record<string, string>>({})
  const logsConfig = useQuery(loggenLogsConfigQuery(newFieldCategory))

  useEffect(() => {
    setNewFieldParams({})
  }, [newFieldCategory])

  const submitNewField = (functionName: string, params: LoggenLogsConfig['params']) => {
    const newParams = (params ?? []).map(
      param => newFieldParams[param.display.toLowerCase()] ?? param.default,
    )

    onSubmit(
      newFieldName,
      `${convertDisplayNameToFunctionName(functionName)}${params ? ':' : ''}${newParams.join(',')}`,
    )
  }

  return (
    <Stack>
      <Input
        name="fieldName"
        label="Field name"
        defaultValue={newFieldName}
        onChange={setNewFieldName}
      />

      <Select
        label="Field category"
        defaultText="Select a category"
        items={(logsCategories ?? []).sort().map(item => ({
          id: item,
          name: item,
        }))}
        isDisabled={!logsCategories?.length}
        onSelectionChange={val => setNewFieldCategory(val.toString())}
      >
        {item => (
          <Select.Item key={item.id} textValue={item.name}>
            <Text transform="capitalize">{item.name}</Text>
          </Select.Item>
        )}
      </Select>

      {logsConfig.isFetching && <Loading />}

      <Stack>
        {logsConfig.data
          ?.sort((a, b) => a.display.localeCompare(b.display))
          .map(item => (
            <Card key={item.display}>
              <Card.Header title={item.display} description={item.description} />

              <Accordion title="View details" defaultExpanded={false}>
                <Box padding="small">
                  <Stack space="small">
                    <Stack space="xxsmall">
                      <Text size="small" color="gray600">
                        Example input:
                      </Text>
                      <Text size="small" truncate>
                        {convertDisplayNameToFunctionName(item.display)}
                        {item.params ? ':' : ''}
                        {(item.params ?? []).map(param => param.default).join(',')}
                      </Text>
                    </Stack>

                    <Card.Divider />

                    <Stack space="xxsmall">
                      <Text size="small" color="gray600">
                        Example output:
                      </Text>
                      <Text size="small" truncate>
                        {item.example}
                      </Text>
                    </Stack>

                    <Card.Divider />

                    {item.params?.length && (
                      <Stack space="medium">
                        {(item.params || []).map(param => (
                          <div key={param.display}>
                            {(() => {
                              switch (param.type) {
                                case 'bool':
                                  return (
                                    <Checkbox
                                      defaultSelected={param.default === 'true'}
                                      onChange={isSelected =>
                                        setNewFieldParams(oldFieldParams => ({
                                          ...oldFieldParams,
                                          [param.display.toLocaleLowerCase()]: isSelected
                                            ? 'true'
                                            : 'false',
                                        }))
                                      }
                                    >
                                      <Stack space="none">
                                        {param.display}
                                        <Text size="small" color="gray600">
                                          {param.description}
                                        </Text>
                                      </Stack>
                                    </Checkbox>
                                  )
                                case 'int':
                                case 'float':
                                  return (
                                    <Stack space="none">
                                      <Stepper
                                        label={param.display}
                                        onChange={val =>
                                          setNewFieldParams(oldFieldParams => ({
                                            ...oldFieldParams,
                                            [param.display.toLocaleLowerCase()]: val.toString(),
                                          }))
                                        }
                                        defaultValue={parseInt(param.default)}
                                        formatOptions={{
                                          maximumFractionDigits: param.type === 'float' ? 15 : 0,
                                        }}
                                        minValue={1}
                                      />
                                      <Text size="small" color="gray600">
                                        {param.description}
                                      </Text>
                                      <Text size="small" color="gray600">
                                        {param.type}
                                      </Text>
                                    </Stack>
                                  )
                                default:
                                  return (
                                    <Stack space="none">
                                      <Input
                                        label={param.display}
                                        defaultValue={param.default}
                                        onChange={val =>
                                          setNewFieldParams(oldFieldParams => ({
                                            ...oldFieldParams,
                                            [param.display.toLocaleLowerCase()]: val,
                                          }))
                                        }
                                      />
                                      <Text size="small" color="gray600">
                                        {param.description}
                                      </Text>
                                    </Stack>
                                  )
                              }
                            })()}
                          </div>
                        ))}
                      </Stack>
                    )}

                    <Stack align="stretch">
                      <Button
                        color="brand"
                        onPress={() => submitNewField(item.display, item.params)}
                      >
                        Add &quot;{item.display}&quot; field
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              </Accordion>
            </Card>
          ))}
      </Stack>
    </Stack>
  )
}
