import { memo, type SVGProps } from 'react'

const SvgGcp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 30 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m19.951 6.62 2.608-2.593.174-1.092C17.98-1.36 10.427-.873 6.143 3.952 4.953 5.292 4.07 6.962 3.6 8.688l.934-.13 5.215-.856.403-.41c2.32-2.532 6.242-2.873 8.92-.718l.88.046Z" />
    <path d="M26.274 8.612a11.67 11.67 0 0 0-3.541-5.677l-3.66 3.64a6.453 6.453 0 0 1 2.388 5.13v.646a3.248 3.248 0 0 1 3.258 3.238c0 1.789-1.459 3.202-3.258 3.202h-6.524l-.64.691v3.885l.64.61h6.524c4.68.035 8.502-3.67 8.539-8.322a8.41 8.41 0 0 0-3.726-7.043Z" />
    <path d="M8.422 23.976h6.515v-5.185H8.422c-.464 0-.913-.1-1.336-.291l-.924.282-2.626 2.593-.229.882c1.473 1.105 3.27 1.727 5.115 1.72Z" />
    <path d="M8.422 7.156C3.742 7.184-.028 10.978 0 15.63a8.406 8.406 0 0 0 3.307 6.627l3.78-3.757a3.23 3.23 0 0 1-1.628-4.285 3.265 3.265 0 0 1 5.937 0l3.78-3.756a8.481 8.481 0 0 0-6.754-3.303Z" />
  </svg>
)
const Memo = memo(SvgGcp)
export default Memo
