const DATE_LOCALE = 'en-GB'
const PRICE_LOCALE = 'en-US'
const NUMBER_LOCALE = 'en-US'
export const LANGUAGE = 'en'
const CURRENCY = 'USD'

/**
 * Function to format a numbers as pricing units
 * @default
 * locale "en-US"
 */
export const formatPrice = (
  price: number,
  locale: string = PRICE_LOCALE,
  options?: Intl.NumberFormatOptions,
) =>
  price.toLocaleString(locale, {
    style: 'currency',
    currency: options?.currency ?? CURRENCY,
    ...options,
  })

/**
 * Function to format a dates
 * @default
 * locale "en-GB"
 */
export const formatDate = (
  date: string | number,
  locale: string = DATE_LOCALE,
  options?: Intl.DateTimeFormatOptions,
) =>
  new Date(date).toLocaleString(locale, {
    ...options,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  })

/**
 * Function to format numbers
 * @default
 * locale "en-GB"
 */
export const formatNumber = (
  number: number,
  locale: string = NUMBER_LOCALE,
  options?: Intl.NumberFormatOptions,
) => {
  if (Number.isNaN(number) || number === null) return ''
  return number.toLocaleString(locale, {
    maximumFractionDigits: 2,
    ...options,
  })
}

/**
 * Function to format large numbers
 * @default
 * locale "en-GB"
 */
export const formatLargeNumber = (
  number: number,
  locale: string = NUMBER_LOCALE,
  options?: Intl.NumberFormatOptions,
) => {
  if (Number.isNaN(number) || number === null) return ''
  return number.toLocaleString(locale, {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: 2,
    ...options,
  })
}

export function formatMillisecondsToHumanTime(
  time: number,
  locale: string = NUMBER_LOCALE,
): string {
  const ms = time >= 1 ? Math.floor(time % 1000) : time
  const s = Math.floor((time / 1000) % 60)
  const m = Math.floor((time / (1000 * 60)) % 60)
  const h = Math.floor((time / (1000 * 60 * 60)) % 24)

  const hours =
    h > 0
      ? h.toLocaleString(locale, {
          unit: 'hour',
          style: 'unit',
        })
      : ''

  const minutes =
    m > 0
      ? m.toLocaleString(locale, {
          unit: 'minute',
          style: 'unit',
        })
      : ''

  const seconds =
    s > 0
      ? (s + ms / 1000).toLocaleString(locale, {
          unit: 'second',
          style: 'unit',
        })
      : ms > 0
      ? ms.toLocaleString(locale, {
          unit: 'millisecond',
          style: 'unit',
        })
      : ''

  return `${hours}${hours && ' '}${minutes}${minutes && ' '}${seconds}`
}
