import { type SyntheticEvent, useEffect, useState } from 'react'
import { CardElement, useElements } from '@stripe/react-stripe-js'
import { type BillingAccount } from 'api-client'

import { StripeCardElement, type StripeCardEvent } from '~/entities/billing'

import useForm from '~/lib/useForm'

import { Box } from '~/components/Box'
import { Button } from '~/components/Button'
import { Checkbox } from '~/components/Checkbox'
import { Input } from '~/components/Input'
import { Link } from '~/components/Link'
import { Select } from '~/components/Select'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'
import { Tooltip } from '~/components/Tooltip'

import { type CardInputs, initialValues, validationRules } from '../model'
import { countries } from '../model/countries'
import { useAddPaymentMethod } from '../model/useAddPaymentMethod'

type CreditCardFormProps = {
  /**
   * The id for the billing account where the payment method should be attached
   */
  billingAccountId: BillingAccount['id']
  /**
   * Callback executed when the payment method is successfully added.
   */
  onSuccess: () => void
  /**
   * Callback executed when the payment method could not be added.
   */
  onError: (error: any) => void
  /**
   * Callback executed when the user press the cancels button.
   */
  onCancel?: () => void
}

/**
 * Form to collect credit card information and add it as a payment method to Stripe.
 */
export const CreditCardForm = ({
  billingAccountId,
  onSuccess,
  onError,
  onCancel,
}: CreditCardFormProps) => {
  const [cbTerms, setCbTerms] = useState(false)
  const elements = useElements()
  const { addPaymentMethod, isLoading } = useAddPaymentMethod(billingAccountId)
  const { values, errors, setValue, handleSubmit } = useForm<CardInputs>(
    validationRules,
    initialValues,
  )

  const [validRules, setValidRules] = useState(false)

  const isStripeMock = import.meta.env.VITE_STRIPE_MOCK === 'true'

  const [cardError, setCardError] = useState('')

  useEffect(() => {
    const vals = values as any
    const rules = Object.keys(validationRules) as any
    const vRules = validationRules as any

    const allFields = rules.every((rule: string) => {
      return (
        vRules[rule].required === false || (vRules[rule].required === true && vals[rule] !== '')
      )
    })
    setValidRules(() => (isStripeMock ? true : allFields))
  }, [values, isStripeMock])

  const onCardChange = (event: StripeCardEvent) => {
    if (event.error) {
      setCardError(event.error.message)
    } else if (!event.complete) {
      setCardError('Please fill all the fields')
    } else {
      setCardError('')
    }
  }

  const onFormChange = (field: keyof CardInputs, value: string) => {
    setValue(field, value)
  }

  const handleFormSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!elements) {
      return
    }

    const cardElement = isStripeMock ? null : elements.getElement(CardElement)

    const details = {
      billing_details: {
        phone: values.phone,
        address: {
          line1: values.address,
          line2: values.address2,
          city: values.city,
          postal_code: values.postalCode,
          country: values.country,
        },
      },
    } as any

    if (cardElement) {
      details.card = cardElement
    }

    addPaymentMethod(details).then(onSuccess, onError)
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack space="small">
        <Input
          autoFocus
          name="address"
          label="Address"
          placeholder="Address"
          onChange={val => onFormChange('address', val)}
          errorMessage={errors?.address?.required ? 'The address is required' : null}
        />

        <Input
          name="address2"
          label="Address second line"
          placeholder="Optional second line"
          onChange={val => onFormChange('address2', val)}
        />
        <Input
          name="city"
          label="City"
          placeholder="City"
          onChange={val => onFormChange('city', val)}
          errorMessage={errors?.city?.required ? 'The city is required' : null}
        />

        <Input
          name="postalCode"
          label="Postal code"
          placeholder="Postal code"
          onChange={val => onFormChange('postalCode', val)}
          errorMessage={errors?.postalCode?.required ? 'The postal code is required' : null}
        />

        <Select
          name="country"
          label="Country"
          defaultText="Select a country"
          items={countries}
          onSelectionChange={val => onFormChange('country', String(val))}
          errorMessage={errors?.country?.required ? 'The country is required.' : null}
        >
          {item => (
            <Select.Item key={item.id} textValue={item.name}>
              <Text>{item.name}</Text>
            </Select.Item>
          )}
        </Select>

        <Input
          name="phone"
          label="Phone number"
          placeholder="Phone number"
          onChange={val => onFormChange('phone', val)}
          errorMessage={errors?.phone?.required ? 'The phone is required' : null}
        />
        {!isStripeMock && (
          <Box>
            <Text weight="light" color="gray600" lineHeight="medium">
              Card details
            </Text>

            <>
              <StripeCardElement onCardChange={onCardChange} />
              {cardError && (
                <Text color="red600" size="small">
                  {cardError}
                </Text>
              )}
            </>
          </Box>
        )}
        <Box marginY={'medium'}>
          <Checkbox name="enableTerms" onChange={e => setCbTerms(e)}>
            <Text size="small">
              {' '}
              I have read and accept the{' '}
              <Link type="text" target="_blank" to="https://gigapipe.com/privacy-policy/">
                Privacy Policy{' '}
              </Link>
              and the{' '}
              <Link type="text" target="_blank" to="https://gigapipe.com/terms/">
                Terms and Conditions
              </Link>{' '}
              of Gigapipe.
            </Text>
          </Checkbox>
        </Box>
        <Stack direction="horizontal" space="small">
          <Tooltip
            content={
              !cbTerms
                ? 'Please read and accept privacy policy and terms and conditions.'
                : 'Save your credit card details.'
            }
          >
            <Button
              type="submit"
              color="brand"
              isDisabled={!cbTerms || cardError !== '' || isLoading || !validRules}
              isLoading={isLoading}
            >
              Save
            </Button>
          </Tooltip>
          {onCancel && (
            <Button color="transparent" onPress={onCancel}>
              Cancel
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  )
}
