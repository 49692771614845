type SnippetFnArgs = {
  host?: string
  apiKey?: string
  apiSecret?: string
  isMultiOrgEnabled?: boolean
}

export const getLogIngestionSnippet = ({
  host,
  apiKey,
  apiSecret,
  isMultiOrgEnabled,
}: SnippetFnArgs) => `curl -X POST -H "Content-Type: application/json" \\
-H "X-API-Key: ${apiKey ?? '<YOUR-API-KEY>'}" \\
-H "X-API-Secret: ${apiSecret ?? '<YOUR-API-SECRET>'}" \\
${isMultiOrgEnabled ? `-H "X-Scope-Org-Id: <ORG-ID>" \\` : ''}${
  '' + host ?? '<HOST>'
}/loki/api/v1/push \\
--data '{"streams":[{"stream":{"type":"test"},"values":[["'$(date +"%s%N")'", "hello qryn"]]}]}'`

export const getMetricsIngestionSnippet = ({
  isMultiOrgEnabled,
  host,
  apiKey,
  apiSecret,
}: SnippetFnArgs) =>
  `curl -i -X POST -H "X-API-Key: ${apiKey ?? '<YOUR-API-KEY>'}" \\
-H "X-API-Secret: ${apiSecret ?? '<YOUR-API-SECRET>'}" \\${
    isMultiOrgEnabled ? `-H "X-Scope-Org-Id: <ORG-ID>" \\` : ''
  }
${host ?? '<HOST>'}/influx/api/v2/write \\
--data 'cpu_load_short,host=server01,region=us-west value=0.64'`

export const getTelemetryIngestionSnippet = ({
  host,
  apiKey,
  apiSecret,
  isMultiOrgEnabled,
}: SnippetFnArgs) =>
  `curl -X POST -H "Content-Type: application/json" \\
-H "X-API-Key: ${apiKey ?? '<YOUR-API-KEY>'}" \\
-H "X-API-Secret: ${apiSecret ?? '<YOUR-API-SECRET>'}" \\
${isMultiOrgEnabled ? `-H "X-Scope-Org-Id: <ORG-ID>" \\` : ''}${host ?? '<HOST>'}/tempo/api/push \\
-d '[{"id": "1234", "traceId": "d6e9329d67b6146b", "timestamp": '$(date +%s%N | cut -b1-16)', "duration": 1000, "name": "1st span from bash!", "tags": { "http.method": "POST", "http.path": "/parent" }, "localEndpoint": { "serviceName": "shell script child 1" } }]'`
