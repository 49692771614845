import { faro } from '@grafana/faro-web-sdk'
import { type CustomRequestInit } from 'api-client'

import { logout } from '~/shared/model/auth'
import { ApiError, AuthError, ValidationError } from '~/shared/model/errors'

type ErrorBody = {
  /**
   * Some responses have an object with an error key in their body
   */
  error?: string
  /**
   * Some responses have an object with a message key in their body
   */
  message?: string
  /**
   * Some responses have an object with a details key in their body
   */
  details?: string
  /**
   * The responses when a validation error happens contain an object with a detail key in their body
   */
  detail?: unknown[]
}

export async function handleResponseError(res: Response, reqOptions?: CustomRequestInit) {
  let error

  try {
    error = (await res.json()) as ErrorBody
  } catch (e) {
    throw new Error('Some data could not be parsed', { cause: e })
  }

  const message = error.message ?? error.error ?? error.details ?? 'Unknown error'
  const args = { ...error, description: message, statusCode: res.status }

  let exception: ApiError | ValidationError | Error | AuthError

  if (reqOptions?.method === 'GET') {
    switch (res.status) {
      case 400:
      case 404:
        exception = new ApiError(message, { ...args, type: 'data_query' })
        faro.api.pushError(exception)
        break
      case 401:
      case 403:
        await logout()
        exception = new AuthError(message, args)
        break
      case 422:
        exception = new ValidationError(message, { statusCode: res.status, details: error.detail })
        break
      case 500:
      case 502:
        exception = new ApiError(message, { ...args, type: 'server_error' })
        break
      default:
        exception = new Error(message)
        break
    }
  } else {
    switch (res.status) {
      case 400:
      case 500:
        exception = new ApiError(message, { ...args, type: 'data_mutation' })
        faro.api.pushError(exception)
        break
      case 404:
        exception = new ApiError(message, args)
        break
      case 422:
        exception = new ValidationError(message, { statusCode: res.status, details: error.detail })
        break
      default:
        exception = new Error(message)
        break
    }
  }

  throw exception
}
