import { useParams } from 'react-router-dom'

import { getProjectIdFromParams } from '~/entities/projects/model/getProjectIdFromParams'

/**
 * Returns an object containing project ID and project slug parsed from the URL params.
 *
 * @return {Object} An object containing project ID and project slug.
 */
export function useProjectIdParam() {
  const params = useParams<'projectSlug'>()

  return { projectId: getProjectIdFromParams(params), projectSlug: params.projectSlug ?? '' }
}
