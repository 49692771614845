import { VisuallyHidden } from 'react-aria'

import { Loading } from '~/components/Loading'
import { Modal } from '~/components/Modal'
import { Text } from '~/components/Text'

import { useEnvironment } from '../../lib/useEnvironment'
import { isDynamicImportError } from '../../model/errors'

type ErrorModalProps = {
  error: Error
  eventId?: string
}

export const ErrorModal = ({ error }: ErrorModalProps) => {
  const { isProduction } = useEnvironment()

  if (isDynamicImportError(error?.message ?? '')) {
    return <Loading />
  }

  return (
    <Modal isOpen={!!error} dialogProps={{ role: 'alertdialog' }}>
      <Modal.Header title={isProduction ? '🐛 Ups! You found a bug' : error?.message} />
      <Modal.Content>
        {isProduction
          ? 'The issue has been sent to our qryn Cloud and the team will be alerted. For now try to reload the page, if the error persists please contact support.'
          : `${error?.name} - ${error?.message}`}

        <VisuallyHidden>{JSON.stringify(error)}</VisuallyHidden>
      </Modal.Content>
      <Modal.Footer>
        <Modal.Action>
          <a href="mailto:tickets@gigapipe.com" type="text" target="_blank" rel="noreferrer">
            <Text color="white">Contact support</Text>
          </a>
        </Modal.Action>
        <Modal.Action
          autoFocus
          onPress={() =>
            isProduction
              ? window.location.replace(`${window.location.origin}/`)
              : window.location.reload()
          }
        >
          <Text color="gigagreen">Reload page</Text>
        </Modal.Action>
      </Modal.Footer>
    </Modal>
  )
}
