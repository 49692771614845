export type Options = {
  /**  A string to define the filename prompted to the user */
  filename: string
  /** The format of the file to be downloaded */
  format: 'json' | 'csv'
}

/**
 * Force download of a file generated in the client
 *
 * @param content The content to be placed in the file
 * @param options Control the filename and format of the file
 *
 */
export const downloadFile = (content: string, { filename, format }: Options) => {
  const element = document.createElement('a')
  const encodedContent = `data:text/${
    format === 'json' ? 'plain' : 'csv'
  };charset=utf-8,${encodeURIComponent(content)}`
  element.setAttribute('href', encodedContent)
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}
