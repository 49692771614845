import { useFetcher, useSearchParams } from 'react-router-dom'
import { type Project } from 'api-client'

import { ADD_GRAFANA_DATASOURCE } from '~/entities/integrations/grafana'

import { Box } from '~/components/Box'
import { Button } from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Qryn } from '~/components/Icons'
import { Input } from '~/components/Input'
import { Link } from '~/components/Link'
import { Message } from '~/components/Message'
import { Modal } from '~/components/Modal'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

type AddQrynDataSourcesProps = {
  projectName: Project['name']
}

export function AddQrynDatasources({ projectName }: AddQrynDataSourcesProps) {
  const [searchParams, setSearchParams] = useSearchParams()
  const showAddQrynDatasourceModal = searchParams.get('modal') === 'add-qryn-datasource'
  const fetcher = useFetcher()

  return (
    <>
      <Message type="info" size="small">
        <Stack
          justify="between"
          space="medium"
          direction={{ mobile: 'vertical', desktop: 'horizontal' }}
        >
          <Stack
            space="small"
            direction={{ mobile: 'vertical', desktop: 'horizontal' }}
            align={{ mobile: 'start', desktop: 'center' }}
          >
            <Icon>
              <Qryn />
            </Icon>
            <Box>
              <Text>We have detected you have qryn enabled for this project</Text>
              <Text color="gray600">Do you want to add it as a datasource?</Text>
            </Box>
          </Stack>
          <Stack space="small" direction="horizontal">
            <Link
              to="."
              searchParams={{ modal: 'add-qryn-datasource' }}
              type="button"
              buttonColor="brand"
            >
              Add qryn datasources
            </Link>
          </Stack>
        </Stack>
      </Message>
      <Modal isOpen={showAddQrynDatasourceModal} onClose={() => setSearchParams(undefined)}>
        <Modal.Content>
          <fetcher.Form method="post">
            <input hidden name="type" defaultValue="qryn" />
            <Stack>
              <Text size="xlarge">Grafana datasource names for qryn</Text>
              <Input
                name="qrynLokiName"
                label="Datasource name for qryn Loki"
                defaultValue={`qryn Loki (${projectName})`}
                errorMessage={fetcher.data?.errors?.fieldErrors.qrynLokiName}
              />
              <Input
                name="qrynTempoName"
                label="Datasource name for qryn Tempo"
                defaultValue={`qryn Tempo (${projectName})`}
                errorMessage={fetcher.data?.errors?.fieldErrors.qrynTempoName}
              />
              <Input
                name="qrynPrometheusName"
                label="Datasource name for qryn Prometheus"
                defaultValue={`qryn Prometheus (${projectName})`}
                errorMessage={fetcher.data?.errors?.fieldErrors.qrynPrometheusName}
              />
              <Button
                type="submit"
                color="brand"
                name="intent"
                value={ADD_GRAFANA_DATASOURCE}
                isDisabled={fetcher.state !== 'idle'}
                isLoading={fetcher.state !== 'idle'}
              >
                Add qryn datasources
              </Button>
            </Stack>
          </fetcher.Form>
        </Modal.Content>
      </Modal>
    </>
  )
}
