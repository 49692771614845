import { useRef } from 'react'
import { useOverlayTrigger } from 'react-aria'
import {
  type ActionFunctionArgs,
  json,
  redirect,
  useFetcher,
  useSearchParams,
} from 'react-router-dom'
import { useOverlayTriggerState } from 'react-stately'
import { type QueryClient, useQuery } from '@tanstack/react-query'
import { type CreateProjectData, isZodError } from 'api-client'
import { type typeToFlattenedError } from 'zod'

import { notify } from '~/features/notifications'

import { projectsQuery, useProjectIdParam } from '~/entities/projects'

import { apiClient, handleDataMutationError } from '~/shared/api'
import { getFormData } from '~/shared/lib/parseRequest'

import { Box } from '~/components/Box'
import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'
import { Check, ChevronDown, MathPlus } from '~/components/Icons'
import { Input } from '~/components/Input'
import { Link } from '~/components/Link'
import { Popover } from '~/components/Popover'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import * as styles from './ProjectsMenu.css'

export function action(queryClient: QueryClient) {
  return async ({ request }: ActionFunctionArgs) => {
    const data = await getFormData(request)

    try {
      const project = await apiClient.projects.createProject(data)

      await queryClient.invalidateQueries(projectsQuery().queryKey)
      notify.success('Your project has successfully been created.')

      return redirect(`/${project.slug}/integrations`)
    } catch (error) {
      if (isZodError<CreateProjectData>(error)) {
        return json({ fields: data, errors: error.flatten() }, { status: 400 })
      }
      return handleDataMutationError(error)
    }
  }
}

type ActionData = {
  fields?: CreateProjectData
  errors?: typeToFlattenedError<CreateProjectData>
  success?: boolean
}

export const CREATE_PROJECT_RESOURCES_ROUTE = 'create-project'

/**
 * Displays the currently selected project along side the list of user's projects and a form to create new ones.
 */
export function ProjectsMenu() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { projectId } = useProjectIdParam()

  const fetcher = useFetcher<ActionData>()

  const { data: projects } = useQuery(projectsQuery())

  const fallbackTitle = projects?.length ? 'Choose your project' : 'Create your first project'
  const isOpen = searchParams.get('select-project') === 'open' || fetcher.state === 'submitting'

  const ref = useRef(null)
  const state = useOverlayTriggerState({
    isOpen,
    onOpenChange: newState => setSearchParams(newState ? { 'select-project': 'open' } : undefined),
  })
  const { triggerProps } = useOverlayTrigger({ type: 'dialog' }, state, ref)

  const submittedProjectName = fetcher.formData?.get('name') as string

  return (
    <Box data-testid="underlayElement" ref={ref}>
      <Stack direction="horizontal" space="small" align="center">
        <Text>{projects?.find(project => project.id === projectId)?.name ?? fallbackTitle}</Text>
        <IconButton
          aria-label="open projects menu"
          icon={<ChevronDown />}
          color="transparent"
          {...triggerProps}
        />
      </Stack>
      {state.isOpen && (
        <Popover
          triggerRef={ref}
          state={state}
          placement="bottom start"
          offset={16}
          crossOffset={-8}
        >
          <Box className={styles.wrapper}>
            <Stack space="xsmall">
              {projects?.map(project => (
                <Link key={project.name} to={`${project.slug}/stack`} className={styles.link}>
                  <Stack direction="horizontal" align="center" justify="between">
                    {project.name}
                    {projectId === project.id && (
                      <Icon color="gigagreen">
                        <Check />
                      </Icon>
                    )}
                  </Stack>
                </Link>
              ))}
              <fetcher.Form method="post" action={`/resources/${CREATE_PROJECT_RESOURCES_ROUTE}`}>
                <Box
                  flexDirection="row"
                  display="flex"
                  gap="small"
                  alignItems="flex-start"
                  paddingX="xsmall"
                  paddingBottom="xsmall"
                  paddingTop={projects?.length ? 'none' : 'xsmall'}
                  flex="fill"
                >
                  <Box flex="fill">
                    <Input
                      key={submittedProjectName}
                      aria-label="Add new project"
                      placeholder="add new project"
                      name="name"
                      errorMessage={fetcher.data?.errors?.fieldErrors.name}
                      defaultValue={submittedProjectName}
                    />
                  </Box>
                  <IconButton
                    aria-label="Create new project"
                    icon={<MathPlus />}
                    color="outline"
                    type="submit"
                    isLoading={fetcher.state === 'submitting'}
                    isDisabled={fetcher.state === 'submitting'}
                  />
                </Box>
              </fetcher.Form>
            </Stack>
          </Box>
        </Popover>
      )}
    </Box>
  )
}
