import { Link } from 'react-router-dom'

import ManageHepicAutoAddUsers from '~/features/addProductToProject/ui/ManageHepicAutoAddUsers'
import { AddUserToHepic } from '~/features/addUserToHepic'
import { ListHepicUsers } from '~/features/listHepicUsers'

import { useProjectIdParam } from '~/entities/projects'

import { useDecodedToken } from '~/shared/model/auth/useAuth'

import { Card } from '~/components/Card'
import { Message } from '~/components/Message'
import { Stack } from '~/components/Stack'

export function HepicConfig() {
  const decodedToken = useDecodedToken()
  const isOrgAccount = Boolean(decodedToken?.organization_id)

  const { projectId, projectSlug } = useProjectIdParam()
  return (
    <>
      {' '}
      <Card>
        <Card.Header title="Manage users from hepic" />
        <Card.Section>
          <Stack>
            {!isOrgAccount && (
              <Message type="info" size="small">
                Adding additional users to your hepic account is only available in organization
                mode. Please switch to your organization, or create a new one here:{' '}
                <Link to="/organization/create" type="text">
                  create organization.
                </Link>
              </Message>
            )}
            <ListHepicUsers projectId={projectId} />
            <AddUserToHepic projectId={projectId} />
            <ManageHepicAutoAddUsers projectId={projectId} projectSlug={projectSlug} />
          </Stack>
        </Card.Section>
      </Card>{' '}
    </>
  )
}
