import { useEffect, useState } from 'react'
import { useFetcher } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { type LoggenConfigResponse, type Project } from 'api-client'
import { type typeToFlattenedError } from 'zod'

import { notify } from '~/features/notifications'

import {
  loggenConfigQuery,
  loggenFormatOptions,
  loggenTimeoutOptions,
  UPDATE_LOGGEN_CONFIG,
} from '~/entities/integrations/loggen'

import { apiClient } from '~/shared/api'

import { Button } from '~/components/Button'
import { Checkbox } from '~/components/Checkbox'
import { Input } from '~/components/Input'
import { Select } from '~/components/Select'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import { LoggenConfigLabelsField } from './LoggenConfigLabelsField'
import { LoggenConfigStructureField } from './LoggenConfigStructureField'

type LoggenConfigProps = {
  projectId: Project['id']
}

type ActionData = {
  fields?: LoggenConfigResponse
  errors?: typeToFlattenedError<LoggenConfigResponse>
  step?: string
}

const timeoutOptions = loggenTimeoutOptions.map(option => ({
  key: option,
  label: option,
}))

const logFormatOptions = loggenFormatOptions.map(option => ({
  key: option,
  label: option,
}))

type Chunk = {
  current: number
  max: number
}

function formatChunk(chunk: string): Chunk[] {
  const formattedChunk: string[] = chunk.split('}{').join('} {').split(' ')
  const mapped: Chunk[] = formattedChunk.map(m => JSON.parse(m) as Chunk)
  return mapped
}

export function LoggenConfig({ projectId }: LoggenConfigProps) {
  const fetcher = useFetcher<ActionData>()
  const { data: config } = useQuery(loggenConfigQuery(projectId))
  const { isLoading: isLoggenRunning, mutate: runLoggen } = useMutation({
    mutationFn: apiClient.loggen.runLoggen,
  })

  const [percentage, setPercentage] = useState(0)

  const onChunkRead = (chunk: string) => {
    try {
      const formattedChunk = formatChunk(chunk)
      let newPercentage = 0
      for (const ch of formattedChunk) {
        newPercentage = Math.ceil((ch.current / ch.max) * 100)
        setPercentage(newPercentage)
      }

      if (newPercentage >= 100) {
        notify.success('Loggen is done')
      }
    } catch (error) {
      notify.error('Loggen failed')
    }
  }

  useEffect(() => {
    if (fetcher.data?.step === 'run-loggen') {
      setPercentage(0)
      notify.success('Loggen is running')
      runLoggen({ projectId: projectId, onChunkRead })
    }
  }, [fetcher.data, runLoggen, projectId])

  return (
    <fetcher.Form method="post">
      <Stack>
        {config && (
          <>
            <input type="hidden" name="url" value={config.url} />
            <input type="hidden" name="apiKey" value={config.apiKey} />
            <input type="hidden" name="apiSecret" value={config.apiSecret} />

            <Stack space="xsmall">
              <LoggenConfigLabelsField projectId={projectId} />
              {!!fetcher.data?.errors?.fieldErrors.labels && (
                <Text color="red500">{fetcher.data?.errors?.fieldErrors.labels}</Text>
              )}
            </Stack>

            <Stack direction="horizontal" space="small">
              <Input
                name="rate"
                label="Rate"
                description="Logs per second"
                defaultValue={config.rate.toString()}
                errorMessage={fetcher.data?.errors?.fieldErrors.rate}
              />

              <Select
                name="timeout"
                label="Timeout"
                defaultText="Choose the duration of the test"
                description="The duration of the test in seconds/minutes"
                items={timeoutOptions}
                defaultSelectedKey={config.timeout}
              >
                {item => (
                  <Select.Item key={item.key} textValue={item.label}>
                    <Text>{item.label}</Text>
                  </Select.Item>
                )}
              </Select>
            </Stack>

            <Select
              name="logConfig.format"
              label="Log Format"
              defaultText="Choose the log format"
              description="The log format to use"
              items={logFormatOptions}
              defaultSelectedKey={config.logConfig.format}
            >
              {item => (
                <Select.Item key={item.key} textValue={item.label}>
                  <Text>{item.label}</Text>
                </Select.Item>
              )}
            </Select>

            <Stack space="xsmall">
              <LoggenConfigStructureField projectId={projectId} />
              {!!fetcher.data?.errors?.fieldErrors.logConfig && (
                <Text color="red500">{fetcher.data?.errors?.fieldErrors.logConfig}</Text>
              )}
            </Stack>

            <Checkbox name="enableMetrics" defaultSelected={config.enableMetrics}>
              Enable Prometheus metrics
            </Checkbox>

            <Stack>
              <Button
                type="submit"
                color="brand"
                name="intent"
                value={UPDATE_LOGGEN_CONFIG}
                isDisabled={fetcher.state !== 'idle' || isLoggenRunning}
              >
                {isLoggenRunning ? `Running... ${percentage}%` : 'Save and run'}
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </fetcher.Form>
  )
}
