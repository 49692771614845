import { type Prettify } from 'plunger'

import { type Variants } from './Separator.css'
import * as styles from './Separator.css'

export type SeparatorProps = Prettify<Variants>

/**
 * COmponent used to add a thin line between sections of a page.
 */
export const Separator = (props: SeparatorProps) => (
  <div className={styles.separator({ color: props?.color })} />
)
