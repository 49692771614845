import { convertSizeToBiggestUnit } from './convertSizeToBiggestUnit'
import { convertSizeToDimension } from './convertSizeToDimension'

type UniteDimensions = 'kilo' | 'mega' | 'giga' | 'tera'

type Options = {
  bits?: boolean
  binary?: boolean
  signed?: boolean
  locale?: string | string[]
  localeOptions?: Intl.NumberFormatOptions
  showUnit?: boolean
  convertTo?: UniteDimensions
}

const defaultOptions: Options = {
  bits: false,
  binary: false,
  showUnit: true,
}

export const formatSize = (number: number, options: Options = defaultOptions) => {
  if (Number.isNaN(number) || (!number && number !== 0)) return ''
  const { binary, bits, showUnit, locale, localeOptions, signed, convertTo } = {
    ...defaultOptions,
    ...options,
  }

  // eslint-disable-next-line prefer-const
  let { convertedNumber, unit } = convertTo
    ? convertSizeToDimension(number, { binary, bits, convertTo })
    : convertSizeToBiggestUnit(number, { binary, bits })

  if (signed && convertedNumber === 0) {
    return ` 0 ${unit}`
  }

  const isNegative = number < 0
  const prefix = isNegative ? '-' : signed ? '+' : ''

  if (isNegative) {
    convertedNumber = -convertedNumber
  }

  if (convertedNumber < 1) {
    const numberString = convertedNumber.toLocaleString(locale, localeOptions)
    const unitString = showUnit ? ` ${unit}` : ''

    return `${prefix + numberString}${unitString}`
  }

  const numberString = convertedNumber.toLocaleString(locale, localeOptions)

  const unitString = showUnit ? ` ${unit}` : ''

  return `${prefix + numberString}${unitString}`
}
