import { useQuery } from '@tanstack/react-query'
import { type Project } from 'api-client'

import { hepicConfigQuery } from '~/entities/integrations/hepic'

import { CopyButton } from '~/shared/ui/CopyButton'

import { Card } from '~/components/Card'
import { Input } from '~/components/Input'
import { Link } from '~/components/Link'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

type HepicInfoProps = {
  projectId: Project['id']
}

export function HepicInfo({ projectId }: HepicInfoProps) {
  const { data: config } = useQuery(hepicConfigQuery(projectId))

  return (
    <Card>
      <Card.Header title="Manage your hepic instance" />
      <Card.Section>
        <Stack>
          <Text>
            - Click on{' '}
            <Link to={config?.url ?? '#'} target="_blank" buttonColor="neutral" type="text">
              Launch hepic{' '}
            </Link>{' '}
            to open the hepic application.
          </Text>
          <Text>
            - Use the{' '}
            <Link to="data-ingestion/agents--heplify" buttonColor="neutral" type="text">
              {' '}
              Data Ingestion{' '}
            </Link>{' '}
            tab to capture your traffic.
          </Text>
          <Text>
            - Manage users and roles of your instance using the{' '}
            <Link to="config" buttonColor="neutral" type="text">
              Config tab.
            </Link>{' '}
          </Text>

          <Card.Divider />
          {config && (
            <>
              <Input
                isReadOnly
                label="URL hepic application"
                name="host"
                value={config.url}
                suffix={<CopyButton value={config.url} />}
              />
              {/* <Card.Divider />
              <Text>For Data Ingestion Usage:</Text>
              <Input
                isReadOnly
                label="HEP Endpoint"
                name="endpoint"
                value={config.ingressUrl}
                suffix={<CopyButton value={config.ingressUrl} />}
              />
              <Input
                isReadOnly
                label="Token"
                name="token"
                value={config.apiKey}
                suffix={<CopyButton value={config.apiKey} />}
              /> */}
            </>
          )}
        </Stack>
      </Card.Section>
    </Card>
  )
}
