import { type ReactNode, useCallback, useState } from 'react'

import { createReactContext } from '~/shared/lib/createReactContext'

import { CASCADING_TAGS, type CascadingTags, type ValidTags } from './HeadTags.types'

type ContextValue = {
  addTag: (tag: ValidTags, id: string) => number
  removeTag: (tag: ValidTags, index: number) => void
  shouldRenderTag: (tag: ValidTags, index: number) => boolean
}

type HeadTagsProviderProps = {
  children: ReactNode
}

const indices = new Map<CascadingTags, number>([
  ['meta', -1],
  ['title', -1],
])

export const [useHeadTags, HeadTagsContextProvider] = createReactContext<ContextValue>()

const isCascadingTag = (tag: any): tag is CascadingTags => CASCADING_TAGS.includes(tag)

export const HeadTagsProvider = ({ children }: HeadTagsProviderProps) => {
  const [cascadingTagsIds, setCascadingTagsIds] = useState<
    Record<CascadingTags, (string | null)[]>
  >({ meta: [], title: [] })

  const addTag = useCallback<ContextValue['addTag']>((tag, id) => {
    if (isCascadingTag(tag)) {
      setCascadingTagsIds(state => {
        const tagIds = state[tag]
        return { ...state, [tag]: [...tagIds, id] }
      })
      const index = indices.get(tag)! + 1
      indices.set(tag, index)
      return index
    }
    return -1
  }, [])

  const removeTag = useCallback<ContextValue['removeTag']>((tag, index) => {
    if (isCascadingTag(tag)) {
      setCascadingTagsIds(state => {
        const tagIds = state[tag]
        if (tagIds) {
          tagIds[index] = null
          return { ...state, [tag]: tagIds }
        }
        return state
      })
    }
  }, [])

  const shouldRenderTag = useCallback<ContextValue['shouldRenderTag']>(
    (tag, index) => {
      if (isCascadingTag(tag)) {
        const tagIds = cascadingTagsIds[tag]
        return tagIds && tagIds.lastIndexOf(tagIds[index]) === index
      }
      return true
    },
    [cascadingTagsIds],
  )

  return (
    <HeadTagsContextProvider value={{ addTag, shouldRenderTag, removeTag }}>
      {children}
    </HeadTagsContextProvider>
  )
}
