/**
 * Returns the length of a given value
 * @param value
 * @returns [number] The value length
 */
export const getLength = (value: object | any[] | string | number): number => {
  if (typeof value === 'object') {
    return Object.keys(value).length
  }
  if (typeof value === 'string') {
    return value.length
  }
  return 0
}
