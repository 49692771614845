import { type ReactNode, useRef } from 'react'
import { type AriaButtonProps, mergeProps, useButton, useFocusRing } from 'react-aria'
import { slugify } from 'plunger'

import storage from '~/shared/lib/storage'
import { useToggle } from '~/shared/lib/useToogle'

import { Box } from '~/components/Box'
import { Icon } from '~/components/Icon'
import { ChevronDown, ChevronRight } from '~/components/Icons'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import * as styles from './Accordion.css'

export type AccordionProps = {
  /**
   * Main thext displayed in the header of the accordion
   */
  title: string
  /**
   * The content to be hidden by the accordion
   */
  children: ReactNode
  /**
   * Display a round indicator with the number inside
   */
  alert?: number
  /**
   * Whether the accordion should be expanded by default.
   *
   * If `persistState` is enabled it will take precedence over `defaultExpanded`
   *
   * @default true
   */
  defaultExpanded?: boolean
  /**
   * Whether the accordion should be persisted its state to LocalStorage
   *
   * @default false
   */
  persistState?: boolean
  /**
   * The id used to store state in LocalStorage
   *
   * @default slugify(title)
   */
  storageId?: string
} & AriaButtonProps<'div'>

export function Accordion({
  alert,
  title,
  children,
  defaultExpanded = true,
  persistState = false,
  storageId = slugify(title),
  ...rest
}: AccordionProps) {
  const [isExpanded, toggle] = useToggle(
    persistState ? Boolean(storage.get(storageId)) || defaultExpanded : defaultExpanded,
  )
  const ref = useRef<HTMLDivElement>(null)

  const {
    buttonProps: { color, ...buttonPropsWithoutColor },
  } = useButton(
    {
      ...rest,
      elementType: 'div',
      onPress: () => {
        toggle()
        if (persistState) storage.set(storageId, !isExpanded)
      },
      'aria-label': isExpanded ? 'Hide content' : 'Show content',
      'aria-expanded': isExpanded,
    },
    ref,
  )
  const { focusProps, isFocusVisible } = useFocusRing()
  const mergedProps = mergeProps(buttonPropsWithoutColor, focusProps)

  return (
    <Stack space="none">
      <div
        ref={ref}
        {...mergedProps}
        className={styles.header({ isFocused: isFocusVisible, isExpanded })}
      >
        <Stack direction="horizontal" justify="between" align="center">
          <Stack direction="horizontal" space="xsmall" justify="between" align="center">
            {alert ? (
              <Box
                as="span"
                display="inline-flex"
                borderRadius="full"
                padding="small"
                fontSize="small"
                backgroundColor="gigapurple"
                justifyContent="center"
                alignItems="center"
                width="medium"
                height="medium"
                fontWeight="bold"
              >
                {alert}
              </Box>
            ) : null}

            <Text>{title}</Text>
          </Stack>

          <Icon>{isExpanded ? <ChevronDown /> : <ChevronRight />}</Icon>
        </Stack>
      </div>

      {isExpanded && (
        <Box
          padding="small"
          backgroundColor="blackSemiTransparent"
          borderBottomLeftRadius="large"
          borderBottomRightRadius="large"
        >
          {children}
        </Box>
      )}
    </Stack>
  )
}
