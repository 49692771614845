import { type ChangeEvent, type FormEventHandler, useState } from 'react'

import validate from './validate'

type Values = Record<string, any>

/**
 * Custom hook to manage form state
 * @deprecated Migrate to Zod for validation, action function and Form from React Router for submission, and local state for dependent fields
 * @param initialRules
 * @param initialValues
 */
function useForm<INPUTS extends Values>(initialRules: any = {}, initialValues: any = {}) {
  const [rules, setStateRules] = useState(initialRules)
  const [values, setStateValues] = useState<INPUTS>(initialValues)
  const [result, setResult] = useState<{
    errors: Record<keyof INPUTS, Record<string, boolean>> | null
    valid: boolean | null
  }>({
    valid: null,
    errors: null,
  })

  const getValue = (name: keyof INPUTS) => values[name]

  const getValues = () => values

  const validateForm = (v?: any) => {
    const newResult = validate<INPUTS>(rules, v || values)
    setResult(newResult)
    return newResult
  }

  const setValues = (v: any) => {
    setStateValues(v)

    // Revalidate the form only if it has been submitted already
    if (result.valid !== null) {
      validateForm(v)
    }
  }

  const setValue = (name: keyof INPUTS, value: any) =>
    setValues(() => ({ ...values, [name]: value }))

  /**
   * Helper to set the value from an HTML element event
   */
  const setElementValue = (e: ChangeEvent<any>) =>
    setValue(e.target.name, e.target.type === 'checkbox' ? e.target.checked : e.target.value)

  const setRules = (r: any) => setStateRules(r)

  const handleSubmit = (fn?: FormEventHandler<HTMLFormElement>) => (e: any) => {
    e.preventDefault()
    const { valid: isValid } = validateForm(values)
    return isValid && fn ? fn(e) : isValid
  }

  const resetForm = () => {
    setResult({ errors: null, valid: null })
    setStateValues(initialValues)
  }

  return {
    values,
    result,
    errors: result.errors,
    valid: result.valid,
    getValue,
    getValues,
    setValue,
    setValues,
    setElementValue,
    setRules,
    validateForm,
    handleSubmit,
    resetForm,
  }
}

export default useForm
