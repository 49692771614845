import { memo, type SVGProps } from 'react'

const SvgRedpanda = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 161 193"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M114.255 126.538a158.42 158.42 0 0 1-7.047-14.656 62.097 62.097 0 0 0 20.525-14.515c9.041-8.91 13.562-21.487 13.562-37.734 0-18.81-6.134-33.429-18.4-43.854A65.938 65.938 0 0 0 78.686.14H0v179.686h38.309v-64.518h14.568l8.892-.225c3.328 8.058 10.654 25.774 14.96 35.207l37.526-23.752ZM38.309 36.049h35.26c17.691 0 26.546 7.178 26.565 21.535 0 15.423-9.88 23.125-29.64 23.106H38.308V36.05ZM153.85 166.967l-10.039-7.131-41.524 27.009 14.54 5.671 25.334-.056 18.372-13.139-6.683-12.354ZM120.63 136.954l-38.504 24.342 10.822 17.014 40.965-25.914-13.283-15.442Z"
      fill="#E14226"
    />
  </svg>
)
const Memo = memo(SvgRedpanda)
export default Memo
