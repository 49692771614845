import {
  type ActionFunctionArgs,
  isRouteErrorResponse,
  json,
  type LoaderFunctionArgs,
  Outlet,
  redirect,
  useParams,
  useRouteError,
} from 'react-router-dom'
import { type QueryClient, useQuery } from '@tanstack/react-query'
import {
  type ClickHouseDeleteClusterData,
  type ClickHouseUpdateIPSafeListData,
  isZodError,
  type QrynInstrumentClickHouseClusterData,
} from 'api-client'
import { authorize } from 'keycloak'
import { z } from 'zod'

import { notify } from '~/features/notifications'

import {
  CANCEL_SUBSCRIPTION,
  checkIntegrationStatus,
  IntegrationName,
  integrationsCancellationDateQuery,
  integrationsQuery,
  IntegrationStatus,
} from '~/entities/integrations/base'
import {
  clickHouseClustersQuery,
  ClustersNavBar,
  DELETE_CLICKHOUSE,
  UPDATE_IPSAFE_LIST_CLICKHOUSE,
} from '~/entities/integrations/clickhouse'
import {
  INSTRUMENT_CLICKHOUSE_WITH_QRYN,
  qrynInstrumentedApplicationsQuery,
} from '~/entities/integrations/qryn'
import {
  checkProjectSlugParam,
  getProjectIdFromParams,
  useProjectIdParam,
} from '~/entities/projects'

import { apiClient, handleDataMutationError } from '~/shared/api'
import { getFormData } from '~/shared/lib/parseRequest'
import { authStore } from '~/shared/model/auth'
import { isApiError } from '~/shared/model/errors'
import { RouteErrorMessage } from '~/shared/ui/RouteErrorMessage'

import { formatDate } from '~/i18n/utils'

import { Box } from '~/components/Box'
import { HeadTag } from '~/components/HeadTag'
import { Link } from '~/components/Link'
import { Message } from '~/components/Message'
import { Stack } from '~/components/Stack'

const SLUG = 'clickhouse'

export function indexLoader(queryClient: QueryClient) {
  return async ({ params }: LoaderFunctionArgs) =>
    authorize(authStore, async () => {
      const { projectId, projectSlug } = checkProjectSlugParam(params)
      try {
        const clusters = await queryClient.ensureQueryData(clickHouseClustersQuery(projectId))
        const readyClusters = clusters.filter(cluster => cluster.status !== 'deleting')

        if (readyClusters.length === 0) return redirect(`/${projectSlug}/stack/${SLUG}/create`)

        return redirect(`/${projectSlug}/stack/${SLUG}/${readyClusters[0].id}`)
      } catch (error) {
        if (isApiError(error) && error.type === 'data_query') {
          throw json(
            {
              message: `We could not find any ClickHouse cluster for project ${projectId}`,
              description: `The ClickHouse integration might not be properly enabled. Please go to the Stack page and try again. If the issue persist please contact support.`,
              goTo: {
                path: `/${projectSlug}/stack`,
                text: 'Go to Stack',
              },
            },
            { status: 400 },
          )
        }
        throw error
      }
    })
}

export function loader(queryClient: QueryClient) {
  return async ({ params }: LoaderFunctionArgs) =>
    authorize(authStore, async () => {
      const { projectId, projectSlug } = checkProjectSlugParam(params)
      const integration = await checkIntegrationStatus(queryClient, {
        projectId,
        projectSlug,
        slug: SLUG,
      })

      return json({ integration })
    })
}

export function action(queryClient: QueryClient) {
  return async ({ request, params }: ActionFunctionArgs) => {
    const data = await getFormData(request)
    const projectId = getProjectIdFromParams(params)
    if (!projectId) {
      notify.error('No project has been selected.')
      return json({})
    }

    const intents = z
      .enum([
        DELETE_CLICKHOUSE,
        INSTRUMENT_CLICKHOUSE_WITH_QRYN,
        UPDATE_IPSAFE_LIST_CLICKHOUSE,
        CANCEL_SUBSCRIPTION,
      ])
      .safeParse(data.intent)

    if (!intents.success) {
      throw json(
        {
          message: 'This operation is not valid',
          description:
            'You tried to perform an invalid operation. Nothing will be changed regarding your integrations. Please reload the page and try again.',
        },
        { status: 400 },
      )
    }

    switch (intents.data) {
      case 'DELETE_CLICKHOUSE': {
        try {
          const { message } = await apiClient.clickhouse.deleteClickHouseCluster({
            ...data,
            projectId: projectId,
          })
          notify.success(message)
          await queryClient.invalidateQueries({
            queryKey: clickHouseClustersQuery(projectId).queryKey,
            refetchType: 'all',
          })
          return redirect(`/${params.projectSlug}/stack`)
        } catch (error) {
          if (isZodError<ClickHouseDeleteClusterData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
      case 'INSTRUMENT_CLICKHOUSE_WITH_QRYN': {
        try {
          const { message } = await apiClient.qryn.addQrynInstrumentation({
            ...data,
            projectId: projectId,
          })
          notify.success(message)
          await queryClient.invalidateQueries(qrynInstrumentedApplicationsQuery(projectId))
          return json({})
        } catch (error) {
          if (isZodError<QrynInstrumentClickHouseClusterData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
      case 'UPDATE_IPSAFE_LIST_CLICKHOUSE': {
        try {
          const { message } = await apiClient.clickhouse.updateClickHouseClusterIpSafeList({
            ...data,
            projectId: projectId,
          })
          notify.success(message)
          await queryClient.invalidateQueries(clickHouseClustersQuery(projectId))
          return json({})
        } catch (error) {
          if (isZodError<ClickHouseUpdateIPSafeListData>(error)) {
            return json({ fields: data, errors: error.flatten() }, { status: 400 })
          }
          return handleDataMutationError(error)
        }
      }
      case 'CANCEL_SUBSCRIPTION': {
        try {
          const { message } = await apiClient.integrations.cancelSubscription({
            projectId: projectId,
            integration: SLUG,
          })
          await queryClient.invalidateQueries(integrationsQuery(projectId))
          notify.success(message)
          return redirect(`/${params.projectSlug}/stack}`)
        } catch (error) {
          return handleDataMutationError(error)
        }
      }
    }
  }
}

export default function ClickHouse() {
  const { projectId, projectSlug } = useProjectIdParam()
  const { clusterId } = useParams<'clusterId'>()
  const { data: integration } = useQuery({
    ...integrationsQuery(projectId),
    select: data => data.find(i => i.product.slug === SLUG),
  })
  const { data: cancellationDate, error } = useQuery(
    integrationsCancellationDateQuery(projectId, SLUG, integration?.status),
  )
  const isScheduledForCancellation = integration?.status === 'scheduled for cancellation'

  if (!integration) return null
  return (
    <Stack direction="vertical">
      <HeadTag tag="title" headId="title">
        {import.meta.env.MODE !== 'production' ? `(${import.meta.env.VITE_ENVIRONMENT}) ` : ''}
        {integration.product.name} - Gigapipe
      </HeadTag>
      <Stack space="small">
        <Stack direction="horizontal">
          <IntegrationName name={integration.product.name} slug={integration.product.slug} />
          {clusterId && (
            <Link
              type="button"
              buttonColor="neutral"
              buttonSize="small"
              to={`/${projectSlug}/stack/${SLUG}/create`}
            >
              new cluster
            </Link>
          )}
        </Stack>
        <Box>
          <IntegrationStatus
            projectId={projectId}
            slug={integration.product.slug}
            status={integration?.status ?? 'active'}
          />
        </Box>
        {isScheduledForCancellation && (
          <Message size="small" type="info">
            This integration is scheduled for cancellation on the{' '}
            {cancellationDate
              ? formatDate(cancellationDate.cancellationDateTimestamp, undefined, {
                  dateStyle: 'medium',
                  timeStyle: 'short',
                })
              : error
              ? 'not available.'
              : 'loading...'}
          </Message>
        )}
      </Stack>
      <ClustersNavBar />
      <Outlet />
    </Stack>
  )
}

export function ErrorBoundary() {
  const error = useRouteError() as Error

  if (isRouteErrorResponse(error)) {
    return (
      <RouteErrorMessage
        message={error.data.message}
        description={error.data.description}
        goToPath={error.data.goTo?.path}
        goToText={error.data.goTo?.text}
      />
    )
  }
  throw error
}
