import { useRef } from 'react'
import { type AriaModalOverlayProps, Overlay, useModalOverlay, usePreventScroll } from 'react-aria'
import { useOverlayTriggerState } from 'react-stately'

import {
  Dialog,
  DialogAction,
  DialogContent,
  DialogFooter,
  DialogHeader,
  type DialogProps,
} from './Dialog'

import * as styles from './Modal.css'

export type ModalProps = {
  children: DialogProps['children']
  /**
   * Flag used to allow the content from the modal to extend beyond its limits.
   */
  allowContentOverflow?: boolean
  /** Whether the overlay is open by default (controlled). */
  isOpen: boolean
  /** Function called when the internal state changes to closed */
  onClose?: () => void
  /**
   * Props object to spread to the `Dialog` component.
   */
  dialogProps?: Omit<DialogProps, 'children'>
} & AriaModalOverlayProps

/**
 * A component that displays a temporally overlay, render a Dialog inside it.
 */
export const Modal = (props: ModalProps) => {
  const {
    children,
    isOpen,
    onClose,
    dialogProps,
    allowContentOverflow = false,
    isDismissable = true,
    isKeyboardDismissDisabled = false,
  } = props
  const ref = useRef<HTMLDivElement>(null)
  const state = useOverlayTriggerState({
    isOpen,
    onOpenChange: newStateValue => {
      if (newStateValue === false) {
        onClose?.()
      }
    },
  })
  const { modalProps, underlayProps } = useModalOverlay(
    { isDismissable, isKeyboardDismissDisabled },
    state,
    ref,
  )

  usePreventScroll({ isDisabled: !state.isOpen })

  if (!state.isOpen) return null
  return (
    <Overlay>
      <div className={styles.backdrop} {...underlayProps}>
        <div {...modalProps} ref={ref} className={styles.wrapper({ allowContentOverflow })}>
          <Dialog {...dialogProps}>{children}</Dialog>
        </div>
      </div>
    </Overlay>
  )
}

Modal.Header = DialogHeader
Modal.Footer = DialogFooter
Modal.Content = DialogContent
Modal.Action = DialogAction
