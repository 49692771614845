import { memo, type SVGProps } from 'react'

const SvgLoggen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path fill="#EC5555" d="M7.332 4.61 2.41 8.655l15.95 17.768 4.922-4.043L7.332 4.611Z" />
    <path fill="#EC5555" d="m18.351 26.42-4.232-4.724 12.332-10.108 4.232 4.724L18.351 26.42Z" />
  </svg>
)
const Memo = memo(SvgLoggen)
export default Memo
