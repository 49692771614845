export class AuthError extends Error {
  constructor(
    public error: string,
    public error_description?: string,
  ) {
    super(error_description ?? error)

    Object.setPrototypeOf(this, AuthError.prototype)
  }
}

const normalizeErrorFn =
  (fallbackMessage: string) =>
  (error: Error | { error: string; error_description?: string } | ProgressEvent): Error => {
    if ('error' in error) {
      return new AuthError(error.error, error.error_description)
    }
    if (error instanceof Error) {
      return error
    }
    return new Error(fallbackMessage)
  }

export const tokenError = normalizeErrorFn('Get access token failed')

// For now we default to return the user to the root route after handling the redirect. At some point we could make it customizable.
export const defaultReturnTo = '/'
export const defaultLogoutReturnTo = `${window.location.origin}`
