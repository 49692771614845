import { useFetcher, useSearchParams } from 'react-router-dom'
import { type Project, type QrynDeleteTokenData } from 'api-client'
import { type typeToFlattenedError } from 'zod'

import { DELETE_QRYN_TOKEN } from '~/entities/integrations/qryn'

import { useNavigate } from '~/shared/lib/reactRouterWrappers'

import { IconButton } from '~/components/IconButton'
import { Close } from '~/components/Icons'
import { Loading } from '~/components/Loading'
import { Modal } from '~/components/Modal'
import { Text } from '~/components/Text'

type DeleteTokenActionData = {
  fields?: QrynDeleteTokenData
  errors?: typeToFlattenedError<QrynDeleteTokenData>
}

type DeleteTokenProps = {
  projectId: Project['id']
  projectSlug: Project['slug']
  tokenId: number
}

export function DeleteToken({ projectId, projectSlug, tokenId }: DeleteTokenProps) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const deleteToken = useFetcher<DeleteTokenActionData>()

  const isDeletingToken = searchParams.get('action') === 'delete-token'

  return (
    <>
      <IconButton
        icon={<Close />}
        aria-label="Delete token"
        color="neutral"
        isLoading={deleteToken.state !== 'idle'}
        isDisabled={deleteToken.state !== 'idle'}
        onPress={() =>
          navigate('', {
            searchParams: { action: 'delete-token' },
            preventScrollReset: true,
          })
        }
      />
      <Modal
        isOpen={isDeletingToken}
        onClose={() => navigate('.', { preventScrollReset: true })}
        dialogProps={{ id: 'delete-qryn-token' }}
      >
        <Modal.Header title="Are you sure you want to delete the selected token?" />
        <Modal.Content>
          <Text color="gray600">
            This operation cannot be undone and all applications using the token will stop working.
          </Text>
          {Boolean(deleteToken.data?.errors?.fieldErrors.tokenId) && (
            <Text color="red500">{deleteToken.data?.errors?.fieldErrors.tokenId}</Text>
          )}
        </Modal.Content>
        <Modal.Footer>
          <Modal.Action autoFocus onPress={() => navigate('.', { preventScrollReset: true })}>
            Cancel
          </Modal.Action>
          <Modal.Action
            isDisabled={deleteToken.state !== 'idle'}
            onPress={() =>
              deleteToken.submit(
                {
                  intent: DELETE_QRYN_TOKEN,
                  projectId: projectId,
                  tokenId: tokenId,
                },
                {
                  method: 'post',
                  action: `/${projectSlug}/stack/qryn`,
                },
              )
            }
          >
            <Text as="span" color="gigagreen">
              {deleteToken.state !== 'idle' ? <Loading /> : 'Delete Token'}
            </Text>
          </Modal.Action>
        </Modal.Footer>
      </Modal>
    </>
  )
}
