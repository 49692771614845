import { useFetcher, useSearchParams } from 'react-router-dom'

import { useNavigate } from '~/shared/lib/reactRouterWrappers'

import { IconButton } from '~/components/IconButton'
import { Close } from '~/components/Icons'
import { Loading } from '~/components/Loading'
import { Modal } from '~/components/Modal'
import { Text } from '~/components/Text'

import { DELETE_ORGANIZATION_USER } from '../actions'

type DeleteOrgUserProps = {
  userId: number
}

export function DeleteUser({ userId }: DeleteOrgUserProps) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const deleteOrgUser = useFetcher()

  const isDeletingUser = searchParams.get('action') === `delete-org-user-${userId}`

  return (
    <>
      <IconButton
        icon={<Close />}
        aria-label="Delete user"
        color="neutral"
        isLoading={deleteOrgUser.state !== 'idle'}
        isDisabled={deleteOrgUser.state !== 'idle'}
        onPress={() => {
          return navigate('', {
            searchParams: { action: `delete-org-user-${userId}` },
            preventScrollReset: true,
          })
        }}
      />
      <Modal
        isOpen={isDeletingUser}
        onClose={() => navigate('.', { preventScrollReset: true })}
        dialogProps={{ id: `delete-org-user-${userId}` }}
      >
        <Modal.Header title="Are you sure you want to delete the selected user?" />
        <Modal.Content>
          <Text color="gray600">
            This operation cannot be undone and the user will be removed from all projects and
            integrations.
          </Text>
          {Boolean(deleteOrgUser.data?.errors?.fieldErrors.id) && (
            <Text color="red500">{deleteOrgUser.data?.errors?.fieldErrors.id}</Text>
          )}
        </Modal.Content>
        <Modal.Footer>
          <Modal.Action autoFocus onPress={() => navigate('.', { preventScrollReset: true })}>
            Cancel
          </Modal.Action>
          <Modal.Action
            isDisabled={deleteOrgUser.state !== 'idle'}
            onPress={() => {
              return deleteOrgUser.submit(
                {
                  intent: DELETE_ORGANIZATION_USER,
                  id: userId,
                },
                {
                  method: 'post',
                  action: `/organization`,
                },
              )
            }}
          >
            <Text as="span" color="gigagreen">
              {deleteOrgUser.state !== 'idle' ? <Loading /> : 'Delete User'}
            </Text>
          </Modal.Action>
        </Modal.Footer>
      </Modal>
    </>
  )
}
