import { useQuery } from '@tanstack/react-query'

import { ChangeQrynRetentionPolicy } from '~/features/changeQrynRetentionPolicy'
import { ManageQrynCacheSwitcher } from '~/features/manageQrynCacheSwitcher'
import { ManageQrynFingerprintsLimit } from '~/features/manageQrynFingerprintsLimit'

import { integrationsQuery } from '~/entities/integrations/base'
import { useProjectIdParam } from '~/entities/projects'

import { Card } from '~/components/Card'
import { Grid } from '~/components/Grid'
import { Stack } from '~/components/Stack'

const SLUG = 'qryn'

export default function QrynConfig() {
  const { projectId, projectSlug } = useProjectIdParam()
  const baseUrl = import.meta.env.VITE_API_URL
  const isTrials = baseUrl.includes('trials')

  const { data: integration } = useQuery({
    ...integrationsQuery(projectId),
    select: data => data.find(i => i.product.slug === SLUG),
  })

  if (!integration) return null
  return (
    <Grid>
      <Grid.Item span={{ mobile: 12, tablet: 8 }}>
        <Stack>
          {!isTrials && (
            <Card>
              <Card.Header title="Manage you retention policy" />
              <Card.Section>
                <ChangeQrynRetentionPolicy projectId={projectId} projectSlug={projectSlug} />
              </Card.Section>
            </Card>
          )}
          <Card>
            <Card.Header title="Manage qryn cache on server side" />
            <Card.Section>
              <ManageQrynCacheSwitcher
                projectId={projectId}
                projectSlug={projectSlug}
              ></ManageQrynCacheSwitcher>
            </Card.Section>
          </Card>
          {!isTrials && (
            <Card>
              <Card.Header title="Manage your fingerprints limit" />
              <Card.Section>
                <ManageQrynFingerprintsLimit projectId={projectId} projectSlug={projectSlug} />
              </Card.Section>
            </Card>
          )}
        </Stack>
      </Grid.Item>
      {/* <Grid.Item span={{ mobile: 12, tablet: 4 }}>
        <QrynCost integrationStatus={integration?.status} projectId={projectId} />
      </Grid.Item> */}
    </Grid>
  )
}
