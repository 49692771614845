import { Children, cloneElement, forwardRef, type ReactElement } from 'react'

import { Box, type BoxProps } from '../Box'

export type IconProps = {
  /**
   * An instance of an SVG icon component
   */
  children: ReactElement
  /**
   * Change the stroke color of the SVG icon
   */
  color?: BoxProps['color']
  /**
   * Change the size of the SVG icon using the font size scale
   */
  size?: BoxProps['fontSize']
  /**
   * Make icon inline for cases when you want it on the same line as text
   */
  isInline?: boolean
  /**
   * Indicates whether the element is exposed to an accessibility API.
   */
  'aria-hidden'?: boolean | 'false' | 'true'
  /**
   * A screen reader only label for the Icon.
   */
  'aria-label'?: string
}

/**
 * A component used to display an svg icon
 */
export const Icon = forwardRef<HTMLElement, IconProps>(
  (
    {
      children,
      color,
      isInline,
      size = 'medium',
      'aria-label': ariaLabel,
      'aria-hidden': ariaHidden,
      ...rest
    }: IconProps,
    ref,
  ) => (
    <Box
      as="i"
      ref={ref}
      display={isInline ? 'inline-flex' : 'flex'}
      color={color}
      fontSize={size}
      {...rest}
    >
      {cloneElement(Children.only(children), {
        role: 'img',
        'aria-label': ariaLabel,
        'aria-hidden': ariaLabel ? ariaHidden ?? undefined : true,
        focusable: false,
      })}
    </Box>
  ),
)
Icon.displayName = 'Icon'
