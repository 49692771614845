import { z } from 'zod'

type CheckboxOpts = {
  trueValue?: string
}
const checkbox = ({ trueValue = 'on' }: CheckboxOpts = {}) =>
  z.union([z.literal(trueValue).transform(() => true), z.literal(undefined).transform(() => false)])

const formDataBoolean = z
  .enum(['true', 'false'])
  .transform<boolean>(value => value.toLowerCase() === 'true')
export const zx = {
  checkbox,
  formDataBoolean,
}
