import { useFetcher } from 'react-router-dom'
import { type InvitedUser } from 'api-client'

import { DELETE_INVITE } from '~/entities/organizations'

import { Button } from '~/components/Button'

export const DeleteInvitationForm = ({ id }: Pick<InvitedUser, 'id'>) => {
  const fetcher = useFetcher()

  return (
    <fetcher.Form method="delete">
      <input type="hidden" value={id} name="inviteId" />
      <Button
        color="outline"
        size="small"
        type="submit"
        name="intent"
        value={DELETE_INVITE}
        isDisabled={fetcher.state === 'submitting'}
        isLoading={fetcher.state === 'submitting'}
        aria-label="Delete invitation"
      >
        Delete Invitation
      </Button>
    </fetcher.Form>
  )
}
