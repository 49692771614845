import { Fragment } from 'react'
import { useQuery } from '@tanstack/react-query'
import { type ClickHouseCluster, type Project } from 'api-client'
import invariant from 'tiny-invariant'

import {
  clickHouseClustersQuery,
  useClickHouseClusterMetrics,
} from '~/entities/integrations/clickhouse'

import { formatSize } from '~/shared/lib/formatSize'
import { groupByKey } from '~/shared/lib/groupByKey'

import { Box } from '~/components/Box'
import { Card } from '~/components/Card'
import { Grid } from '~/components/Grid'
import { Loading } from '~/components/Loading'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

type ClickHouseMetricsProps = {
  projectId: Project['id']
  clusterId: ClickHouseCluster['id']
}

export function ClickHouseMetrics({ clusterId, projectId }: ClickHouseMetricsProps) {
  const { data: cluster } = useQuery({
    ...clickHouseClustersQuery(projectId),
    select: data => data.find(cl => cl.id === clusterId),
  })
  invariant(cluster)

  const { data: metrics, isInitialLoading } = useClickHouseClusterMetrics(projectId, cluster.name)

  const groupedDisks = groupByKey(metrics?.disks ?? [], 'host')

  if (isInitialLoading) return <Loading />
  if (!metrics) return null
  return (
    <Card>
      <Card.Header title="Available space" />
      <Card.Section>
        <Stack space="small" direction="vertical">
          {Object.keys(groupedDisks)
            .sort()
            .map(host => (
              <Stack key={host}>
                <Box backgroundColor="gigadarkblue" padding="small" borderRadius="large">
                  <Grid columns={12} space="xxsmall">
                    <Grid.Item span={{ mobile: 12, tablet: 1, desktop: 1 }}>
                      <Text size="small" color="gray600">
                        Node
                      </Text>
                    </Grid.Item>

                    <Grid.Item span={{ mobile: 12, tablet: 11, desktop: 11 }}>
                      <Text size="small">{host}</Text>
                    </Grid.Item>

                    {groupedDisks[host].map((disk, index) => (
                      <Fragment key={index}>
                        <Grid.Item span={{ mobile: 12, tablet: 1, desktop: 1 }}>
                          <Text size="small" color="gray600">
                            Disk {index + 1}
                          </Text>
                        </Grid.Item>

                        <Grid.Item span={{ mobile: 12, tablet: 11, desktop: 11 }}>
                          <Text size="small">{`${formatSize(disk.freeSpace, {
                            binary: cluster.disks?.[0].unitType === 'Binary',
                            showUnit: false,
                          })} / ${formatSize(disk.totalSpace, {
                            binary: cluster.disks?.[0].unitType === 'Binary',
                          })}`}</Text>
                        </Grid.Item>
                      </Fragment>
                    ))}
                  </Grid>
                </Box>
              </Stack>
            ))}
        </Stack>
      </Card.Section>
    </Card>
  )
}
