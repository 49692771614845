import { type CamelCasedProperties } from '../types'

export const snakeToCamelCase = (str: string): string =>
  str
    .replace(/\b([-_][a-z0-9])/gi, $0 => $0.replace('_', ''))
    .replace(/\B([-_][a-z0-9])/gi, $1 => $1.toUpperCase().replace('_', ''))

/**
 * Transform all object keys to camelCase including nested objects and arrays
 */
export function transformSnakeObjectKeysToCamel<INPUT extends object>(
  data: INPUT,
): CamelCasedProperties<INPUT> {
  return Object.fromEntries(
    Object.entries(data).map(([key, val]) => [snakeToCamelCase(key), processVal(val)]),
  ) as CamelCasedProperties<INPUT>
}

/** Utility function to transform recursively the value in the object */
function processVal(val: unknown): unknown {
  return typeof val !== 'object' || val === null
    ? val
    : Array.isArray(val)
    ? val.map(processVal)
    : transformSnakeObjectKeysToCamel(val)
}
