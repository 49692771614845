import { parseSearchParams } from './helpers'
/**
 * Extract the FormData from a Request
 * @param request - A Request or FormData
 */
export async function getFormData(request: Request | FormData) {
  const formData = isFormData(request) ? request : await request.formData()

  return parseFormData(formData)
}

/**
 * Check if value is an instance of FormData.
 * The use of `instanceof` might not work in other environments like Nodejs or edge runtime.
 * Look for getObjectTypeName === 'FormData'.
 */
function isFormData(value: unknown): value is FormData {
  return value instanceof FormData
}

/**
 * Get the form data from a request as an object.
 *
 * This is typed as any since we can't know the exact shape and want to use the returned value on function that expects a specific object type
 */
function parseFormData(formData: FormData): any {
  const searchParams = new URLSearchParams(formData as any)
  return parseSearchParams(searchParams.toString())
}
