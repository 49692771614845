import {
  type CamelCasedProperties,
  // type SnakeCasedProperties,
  transformSnakeObjectKeysToCamel,
} from 'plunger'

import { type AuthStore } from './authStore'
import { type User } from './types'
import { defaultReturnTo } from './utils'

/**
 * The state of the application before the user was redirected to the login page.
 */
export type AppState = {
  returnTo?: string
  isOrganizationLogin?: boolean
  isInviteLogin?: boolean

  [key: string]: any
}

/**
 * User object type from Auth0 SDK converted to camelCase notation
 */
export type AuthUser = CamelCasedProperties<User>

/**
 * This is a policy function used to authorize a request in a loader function from react-router
 * @param authStore
 * @param callback
 * @param returnTo
 *
 * @example
 * ```js
 *  async function loader({ request }) {
 *      return authorize(
 *        authStore,
 *        async ({ user }) => {
 *          // here we can get the data for this route and return it.
 *        },
 *        '/welcome'
 *    )
 *  }
 * ```
 */

export const authorize = async <TUser extends AuthUser = AuthUser, TResponse = Response>(
  authStore: AuthStore<TUser>,
  callback: ((input: { user: TUser }) => Promise<Response>) | (() => Promise<TResponse>),
  returnTo = defaultReturnTo,
) => {
  const {
    user,
    keyCloakClient,
    actions: { loginWithRedirect },
    _actions: { initialised },
  } = authStore.getState()

  if (!keyCloakClient.authenticated) {
    await new Promise(res => {
      authStore.subscribe(({ keyCloakClient, decodedToken }) => {
        const orgId = localStorage.getItem('orgId') ?? ''
        if (keyCloakClient.authenticated && keyCloakClient.token) {
          if (!orgId || orgId === String(decodedToken?.organization_id)) {
            res(keyCloakClient.token)
          }
        }
      })
    })
  }

  let callbackContent = { user: {} } as { user: TUser }
  let isExpired = false

  keyCloakClient.onTokenExpired = () => {
    keyCloakClient
      .updateToken(5)
      .then(() => {
        const user = keyCloakClient.userInfo as TUser

        isExpired = true
        callbackContent = { user: transformSnakeObjectKeysToCamel(user) as TUser }
      })
      .catch((err: string) => {
        console.log(err)
      })
  }

  if (isExpired) {
    isExpired = false
    initialised(transformSnakeObjectKeysToCamel(callbackContent.user) as TUser)
    return callback(callbackContent)
  }

  let localUser = user

  if (!user?.id && keyCloakClient?.userInfo?.id) {
    localUser = keyCloakClient?.userInfo as TUser
  }

  if (localUser) {
    return callback({ user: { ...localUser } })
  }

  if (keyCloakClient?.authenticated) {
    const authUser = localUser

    if (!authUser) {
      // we should login in here
      return loginWithRedirect({
        redirectUri: returnTo,
        //   onRedirect: async url => {
        //     window.location.replace(url)
        //     return new Promise(resolve => {
        //       setTimeout(resolve, 1000)
        //     })
        //   },
      })
    }
    //   await getAccessTokenSilently()

    initialised(transformSnakeObjectKeysToCamel(authUser) as TUser)

    return callback({ user: transformSnakeObjectKeysToCamel(authUser) as TUser })
  }
}

/**
 * This is a policy function used to handle the redirection from Auth0
 * @param authStore
 * @param callback
 *
 * @example
 * ```js
 * // loader from route where Auth0 redirects users
 *  async function loader({ request }) {
 *      // handle other flows managed by the same route.
 *
 *      // handle default flow
 *      return handleRedirectCallback(authStore, async ({ appState }) => {
 *          return redirect(appState.returnTo)
 *      })
 *  }
 * ```
 */
export const handleRedirectCallback = async <TUser extends AuthUser = AuthUser>(
  authStore: AuthStore<TUser>,
  callback: (input: { appState?: AppState }) => Promise<Response>,
) => {
  const {
    keyCloakClient,
    _actions: { initialised },
    actions: { loginWithRedirect },
  } = authStore.getState()

  const { appState } = await loginWithRedirect({ redirectUri: window.location.origin }).then(
    () => ({
      appState: { returnTo: defaultReturnTo },
    }),
  )

  let authUser

  if (keyCloakClient?.authenticated) {
    authUser = await keyCloakClient.loadUserProfile()
  }

  initialised(authUser ? (transformSnakeObjectKeysToCamel(authUser) as TUser) : undefined)

  return callback({ appState })
}
