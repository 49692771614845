import { type Params, redirect } from 'react-router-dom'

import { getProjectIdFromParams } from '~/entities/projects/model/getProjectIdFromParams'

/**
 * Validates the projectSlug parameter in the given Params object. If projectSlug is falsy,
 * it throws a redirect error. Otherwise, it returns an object containing the projectId and
 * projectSlug.
 *
 * @param {Params<'projectSlug'>} params - An object containing the projectSlug parameter.
 * @return {{ projectId: string, projectSlug: string }} An object containing the projectId and
 *    projectSlug extracted from the given params object.
 */
export function checkProjectSlugParam(params: Params<'projectSlug'>) {
  if (!params?.projectSlug) {
    throw redirect('/')
  }
  const projectId = getProjectIdFromParams(params)

  if (!projectId || Number.isNaN(Number(projectId))) {
    throw redirect('/')
  }

  return { projectId, projectSlug: params.projectSlug }
}
