import { useEffect } from 'react'
import { OverlayProvider } from 'react-aria'
import {
  type ActionFunctionArgs,
  createMemoryRouter,
  json,
  type LoaderFunctionArgs,
  RouterProvider,
} from 'react-router-dom'
import { type Decorator } from '@storybook/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { ThemeProvider } from '~/styles/ThemeProvider'

import { sleep } from '../lib/sleep'

declare type RouterParameters = {
  router?: {
    /**
     *  Makes the router match the rendering of the story with this path
     *
     * @example
     * If you set it as `:param1` then your component will have access to param1 in useParams()
     */
    routerPath: string
    /**
     * Sets the "browser URL" to this value
     *
     * @example
     * If defined as `my-page` the param1 property will have a value of `my-page`
     */
    routerURL: string
  }
}
declare type Context = Parameters<Decorator>['1'] & {
  parameters: RouterParameters
}
/**
 * Custom decorator to kind of integrate ReactRouter 6.4 with Storybook
 * You can pass two parameters to your stories (routerPath and routerURL) to simulate real user navigation under the router key.
 *
 * - `routerPath`: makes the router match the rendering of the story with this path (ie. if you set it as `:param1` then your component will have access to param1 in useParams() )
 * - `routerURL`: sets the "browser URL" to this value (ie. if defined as `my-page` the param1 property will have a value of `my-page`)
 *
 * A part from that it will log the request and params that the loader for that route will recieve.
 * And will parse and log the formData recieved by the action that the route would have.
 */
export const withDataRouter = (
  Story: Parameters<Decorator>[0],
  ctx: Context,
): ReturnType<Decorator> => (
  <RouterProvider
    router={createMemoryRouter(
      [
        {
          path: ctx.parameters.router?.routerPath ?? '/',
          element: <Story />,
          loader: ({ request, params }: LoaderFunctionArgs) => {
            console.log(`[Story Loader] ${ctx.id}\nRequest: `, request, `\nParams: `, params)
            return json({})
          },
          action: async ({ request }: ActionFunctionArgs) => {
            const formData = await request.formData()
            const payload = Object.fromEntries(formData)
            console.log(`[Story Action] ${ctx.id}\nRequest: \n`, request, `\nPayload: \n`, payload)
            await sleep(2000)
            return json({})
          },
        },
      ],
      { initialEntries: [ctx.parameters.router?.routerURL ?? '/'] },
    )}
  />
)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
})
/**
 * Custom decorator to wrapp your stories with the QueryClientProvider.
 * Required if your components use any react-query hooks
 */
export const withReactQuery: Decorator = Story => (
  <QueryClientProvider client={queryClient}>
    <Story />
  </QueryClientProvider>
)

/**
 * Custom decorator to wrapp you stories in the React Aria OverlayProvider
 * Required if your components render a Modal
 */
export const withOverlayProvider: Decorator = Story => (
  <OverlayProvider>
    <Story />
  </OverlayProvider>
)

/**
 * Custom decorator to wrapp your stories with the Vanilla Extract theme provider
 */
export const WithThemeProvider: Decorator = (Story, context) => {
  const { theme } = context.globals
  useEffect(() => {
    document.documentElement.classList.remove('light', 'dark')
    document.documentElement.classList.add(theme as string)
  }, [theme])

  return (
    <ThemeProvider>
      <Story {...context} />
    </ThemeProvider>
  )
}
