import { type FormProps, useFetcher } from 'react-router-dom'
import { type UpdateUserProfileData } from 'api-client'
import { type typeToFlattenedError } from 'zod'

import { UPDATE_PROFILE } from '~/entities/users'

import { useUser } from '~/shared/model/auth'

import { Box } from '~/components/Box'
import { Button } from '~/components/Button'
import { Input } from '~/components/Input'
import { Link } from '~/components/Link'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

type UpdateUserProfileFormProps = {
  /**
   * Title of the form.
   */
  title: string
  /**
   * Flag to control if the cancel button is shown
   */
  showCancel?: boolean
  /**
   * Route where the form should be submitted.
   */
  action?: FormProps['action']
}

type ActionData = {
  fields?: UpdateUserProfileData
  errors?: typeToFlattenedError<UpdateUserProfileData>
}

/**
 * Feature component with a Form to submit a user profile update.
 */
export function UpdateUserProfileForm({
  action,
  title,
  showCancel = false,
}: UpdateUserProfileFormProps) {
  const user = useUser()
  const fetcher = useFetcher<ActionData>()

  return (
    <fetcher.Form method="patch" action={action}>
      <Stack space="medium">
        <Text>{title}</Text>
        <Stack direction={{ mobile: 'vertical', tablet: 'horizontal' }} space="small">
          <Box flex={1}>
            <Input
              key={fetcher.data?.fields?.givenName}
              name="givenName"
              label="First Name"
              defaultValue={user?.given_name ?? fetcher.data?.fields?.givenName}
              errorMessage={fetcher.data?.errors?.fieldErrors.givenName}
            />
          </Box>
          <Box flex={1}>
            <Input
              key={fetcher.data?.fields?.familyName}
              name="familyName"
              label="Last Name"
              defaultValue={user?.family_name ?? fetcher.data?.fields?.familyName}
              errorMessage={fetcher.data?.errors?.fieldErrors.familyName}
            />
          </Box>
        </Stack>
        <Stack direction="horizontal" space="small">
          <Button
            type="submit"
            color="brand"
            name="intent"
            value={UPDATE_PROFILE}
            isLoading={fetcher.state === 'submitting'}
            isDisabled={fetcher.state === 'submitting'}
          >
            Update
          </Button>
          {showCancel ? (
            <Link to="." type="button" buttonColor="transparent">
              Cancel
            </Link>
          ) : null}
        </Stack>
      </Stack>
    </fetcher.Form>
  )
}
