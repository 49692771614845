import { apiClient } from '~/shared/api'

const usersQueryKeys = {
  organizations: [{ scope: 'users', type: 'organizations' }] as const,
}

/**
 * Query to get the organizations from the authenticated user
 */
export const userOrganizationsQuery = () => ({
  queryKey: usersQueryKeys.organizations,
  queryFn: apiClient.users.getOrganizationsFromUser,
})
