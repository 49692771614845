import { type ReactNode } from 'react'

import { Box } from '~/components/Box'

import { type Variants } from './Tag.css'
import * as styles from './Tag.css'

export type TagProps = {
  /**
   * The content of the tag.
   */
  children: ReactNode
} & Variants

/**
 * Visual component used to display some short text inside a tag.
 */
export const Tag = ({ children, color }: TagProps) => (
  <Box className={styles.tag({ color })}>{children}</Box>
)
