import { type ProvidersSlug } from 'api-client'

import { Aws, Gcp, Hexagon } from '~/components/Icons'

/**
 * Component to render the icons for the available cloud providers
 */
export const ProviderIcons: { [key in ProvidersSlug]: JSX.Element } = {
  aws: <Aws />,
  gcp: <Gcp />,
  unknown: <Hexagon />,
}
