import { type HTMLAttributes, type RefObject, useMemo, useRef } from 'react'
import {
  type AriaListBoxOptions,
  useListBox,
  useListBoxSection,
  useOption,
  useSeparator,
} from 'react-aria'
import { type ListState } from 'react-stately'
import { type Node } from '@react-types/shared'

import { createReactContext } from '~/shared/lib/createReactContext'

import { Box } from '../Box'

import * as styles from './ListBox.css'

type ListBoxProps = AriaListBoxOptions<unknown> & {
  listBoxRef?: RefObject<HTMLUListElement>
  state: ListState<unknown>
}

type ListBoxSectionProps = {
  state: ListState<unknown>
  section: Node<unknown>
}

type OptionProps = {
  item: Node<unknown>
  state: ListState<unknown>
}

type OptionContextValue = {
  labelProps: HTMLAttributes<HTMLElement>
  descriptionProps: HTMLAttributes<HTMLElement>
}

// We can import the useOptions hook to create custom components use inside <Item /> (see Story for <Select />)
// The children of Item are rendered using item.rendered
// This way the option is ARIA labelled by the label, and
// described by the description, which makes for better announcements
// for screen reader users.
export const [useOptions, OptionsProvider] = createReactContext<OptionContextValue>()

export const ListBox = (props: ListBoxProps) => {
  const ref = useRef<HTMLUListElement>(null)
  const { listBoxRef = ref, state } = props
  const { listBoxProps } = useListBox(props, state, listBoxRef)
  return (
    <ul className={styles.list()} {...listBoxProps} ref={listBoxRef}>
      {[...state.collection].map(item =>
        item.hasChildNodes ? (
          <ListBoxSection key={item.key} state={state} section={item} />
        ) : (
          <Option key={item.key} item={item} state={state} />
        ),
      )}
    </ul>
  )
}

function ListBoxSection({ section, state }: ListBoxSectionProps) {
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  })

  const { separatorProps } = useSeparator({
    elementType: 'li',
  })

  return (
    <>
      {section.key !== state.collection.getFirstKey() && (
        <li {...separatorProps} className={styles.sectionSeparator}>
          &nbsp;
        </li>
      )}
      <li {...itemProps}>
        {section.rendered && (
          <div {...headingProps} className={styles.sectionTitle}>
            {section.rendered}
          </div>
        )}
        <ul {...groupProps} className={styles.list({ limitHeight: false })}>
          {[...section.childNodes].map(node => (
            <Option key={node.key} item={node} state={state} />
          ))}
        </ul>
      </li>
    </>
  )
}

function Option({ item, state }: OptionProps) {
  const ref = useRef<HTMLLIElement>(null)
  const { optionProps, labelProps, descriptionProps, isSelected, isFocused } = useOption(
    {
      key: item.key,
    },
    state,
    ref,
  )
  const contextValue = useMemo(
    () => ({ labelProps, descriptionProps }),
    [labelProps, descriptionProps],
  )

  return (
    <li className={styles.listItem({ isFocused, isSelected })} {...optionProps} ref={ref}>
      <Box display="flex" alignItems="center">
        <OptionsProvider value={contextValue}>{item.rendered}</OptionsProvider>
      </Box>
    </li>
  )
}
