class ValidationError extends Error {
  status: number

  details: unknown[]

  constructor(
    message: string,
    args: {
      statusCode: number
      details?: unknown[]
    },
  ) {
    super(message)
    this.name = 'ValidationError'
    this.status = args.statusCode ?? 422
    this.details = args.details ?? []
  }
}

export default ValidationError

export function isValidationErrorr(error: ValidationError): error is ValidationError {
  return error.name === 'ValidationError'
}
