import { type AriaProgressBarProps, useProgressBar } from 'react-aria'

import { Text } from '../Text'

import { type ProgressCircleStatuses } from './ProgressCircle.css'
import * as styles from './ProgressCircle.css'

type ProgressCircleProps = {
  value: number
  /** Controls how the value is displayed.
   * @default 'lowIsGood'
   * - lowIsGood: the displayed value will be the remaining percentage up to 100.
   * - highIsGood: the displayed value will be the actual value.
   * */
  mode?: 'highIsGood' | 'lowIsGood'
} & AriaProgressBarProps

const UPPER_THRESHOLD = 70
const LOWER_THRESHOLD = 40
const PERCENTAGE_COLORS = Object.keys(styles.progressStatus) as ProgressCircleStatuses[]

export const ProgressCircle = (props: ProgressCircleProps) => {
  const { label, mode = 'lowIsGood', value = 0 } = props
  const percentageValue = mode === 'highIsGood' ? value : 100 - value
  const { progressBarProps, labelProps } = useProgressBar({
    ...props,
    value: percentageValue,
    isIndeterminate: false,
  })

  const status = PERCENTAGE_COLORS[value >= UPPER_THRESHOLD ? 0 : value >= LOWER_THRESHOLD ? 1 : 2]

  return (
    <div className={styles.wrapper}>
      <svg viewBox="0 0 120 120" className={styles.progressBar} {...progressBarProps}>
        <circle cx="60" cy="60" r="54" fill="none" className={styles.progressBackground} />
        <circle
          cx="60"
          cy="60"
          r="54"
          fill="none"
          strokeDashoffset={100 - percentageValue}
          pathLength="100"
          className={styles.progressForeground({ status })}
        />
      </svg>

      <div className={styles.content}>
        <Text
          as="p"
          size="xsmall"
          color={{ lightMode: 'gray300', darkMode: 'gray400' }}
          align="center"
          lineHeight="none"
          transform="uppercase"
          {...labelProps}
        >
          {label}
        </Text>
        <Text
          as="p"
          size="xsmall"
          weight="bolder"
          align="center"
          lineHeight="none"
          color={{ lightMode: 'gray300', darkMode: 'gray400' }}
        >
          {percentageValue}
          <Text as="span" size="xsmall" color={{ lightMode: 'gray300', darkMode: 'gray600' }}>
            %
          </Text>
        </Text>
      </div>
    </div>
  )
}
