import { RouterNavLink as NavLink } from '~/shared/lib/reactRouterWrappers'

import * as styles from './QrynNavBar.css'

const SUBPAGES = [
  { key: 'overview', label: 'Overview', link: '.', end: true },
  { key: 'auth', label: 'Auth Tokens', link: 'auth', end: true },
  {
    key: 'data-ingestion',
    label: 'Data Ingestion',
    link: 'data-ingestion/logs--logql',
    end: false,
  },
  { key: 'config', label: 'Config', link: 'config', end: true },
]

export function QrynNavBar() {
  return (
    <div className={styles.wrapper}>
      {SUBPAGES.map(item => (
        <NavLink
          key={item.key}
          to={`${item.link}`}
          className={({ isActive }) =>
            styles.link({
              isActive: isActive || location.pathname.includes(item.key),
            })
          }
          end={item.end}
        >
          {item.label}
        </NavLink>
      ))}
    </div>
  )
}
