import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { integrationsQuery } from '~/entities/integrations/base'
import { useProjectIdParam } from '~/entities/projects'
import { userOrganizationsQuery } from '~/entities/users'

import { RouterNavLink as NavLink } from '~/shared/lib/reactRouterWrappers'
import { useDecodedToken } from '~/shared/model/auth/useAuth'
import { Separator } from '~/shared/ui/Separator'

import { Box } from '~/components/Box'
import { Icon } from '~/components/Icon'
import {
  Book,
  Check,
  Edit,
  Gigapipe,
  Grafana as GrafanaIcon,
  Hepic as HepicIcon,
  Loggen as LoggenIcon,
  LogOut,
  MathPlus,
  Qryn as QrynIcon,
  Settings,
  // ShoppingBag,
} from '~/components/Icons'
import { Link } from '~/components/Link'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import * as styles from './Sidebar.css'

type SidebarProps = {
  isOpen?: boolean
}

export const ExtIcons = ({ name }: { name: string }) => {
  if (name.toLocaleLowerCase() === 'grafana') {
    return <GrafanaIcon />
  } else if (name.toLocaleLowerCase() === 'qryn') {
    return <QrynIcon />
  } else if (name.toLocaleLowerCase() === 'hepic') {
    return <HepicIcon />
  } else if (name.toLocaleLowerCase() === 'loggen') {
    return <LoggenIcon />
  } else return null
}

export const Sidebar = ({ isOpen = false }: SidebarProps) => {
  const location = useLocation()
  const { projectId, projectSlug } = useProjectIdParam()
  const isStripeMock = import.meta.env.VITE_STRIPE_MOCK === 'true'

  const { data: integrations } = useQuery(integrationsQuery(projectId))

  const decodedToken = useDecodedToken()
  const isOrgAccount = Boolean(decodedToken?.organization_id)

  const filteredIntegrations = useMemo(() => {
    return integrations?.filter(integration => integration?.status !== 'cancelled by user')
  }, [integrations])

  const { data: organizations } = useQuery(userOrganizationsQuery())
  const currentOrganization = organizations?.find(
    org => org.id === String(decodedToken?.organization_id),
  )

  return (
    <Box className={[styles.sidebar, isOpen ? styles.sidebarOpen : null]}>
      <Stack space="xsmall">
        <Stack align="center">
          <Link to="/">
            <Gigapipe className={styles.logo} />
          </Link>
        </Stack>

        {filteredIntegrations?.map(integration => (
          <Box
            key={integration.product.slug}
            className={
              projectId
                ? styles.item({
                    active: !!matchPath(
                      { path: `:projectSlug/stack/${integration.product.slug}`, end: false },
                      location.pathname,
                    ),
                  })
                : styles.disabledItem
            }
          >
            <NavLink
              to={projectSlug ? `${projectSlug}/stack/${integration.product.slug}` : '/'}
              className={({ isActive }) =>
                styles.navlink({
                  active: isActive,
                  disabled: !projectSlug,
                })
              }
            >
              <Icon color="white" size="xlarge">
                <ExtIcons name={integration.product.name} />
              </Icon>
              {integration.product.name.toLocaleLowerCase() === 'qryn'
                ? 'Observability'
                : integration.product.name}
            </NavLink>
          </Box>
        ))}

        {/* <Box
          className={
            projectId
              ? styles.item({
                  active: !!matchPath(
                    { path: ':projectSlug/integrations', end: false },
                    location.pathname,
                  ),
                })
              : styles.disabledItem
          }
        >
          <NavLink
            to={projectSlug ? `${projectSlug}/integrations` : '/'}
            className={({ isActive }) =>
              styles.navlink({
                active: isActive,
                disabled: !projectSlug,
              })
            }
          >
            <Icon color="white" size="xlarge">
              <ShoppingBag />
            </Icon>
            INTEGRATIONS
          </NavLink>
        </Box> */}

        <Box
          className={
            projectId
              ? styles.item({
                  active: !!matchPath(
                    { path: ':projectSlug/settings', end: false },
                    location.pathname,
                  ),
                })
              : styles.disabledItem
          }
        >
          <NavLink
            to={projectSlug ? `${projectSlug}/settings` : '/'}
            className={({ isActive }) =>
              styles.navlink({
                active: isActive,
                disabled: !projectSlug,
              })
            }
          >
            <Icon color="white" size="xlarge">
              <Settings />
            </Icon>
            SETTINGS
          </NavLink>
        </Box>

        {/* <Box
          className={styles.item({
            active: !!matchPath({ path: 'system-status', end: false }, location.pathname),
          })}
        >
          <Link
            to={projectSlug ? `${projectSlug}/system-status` : 'system-status'}
            className={styles.navlink({
              active: !!matchPath({ path: 'system-status', end: false }, location.pathname),
            })}
          >
            <Icon color="white" size="xlarge">
              <Server />
            </Icon>
            SYSTEM STATUS
          </Link>
        </Box> */}

        <Box className={styles.item({ active: false })}>
          <Link
            to="https://gigapipe.com/docs.html"
            target="_target"
            className={styles.navlink()}
            isExternal={true}
          >
            <Icon color="white" size="xlarge">
              <Book />
            </Icon>
            DOCS
          </Link>
        </Box>

        <div className={styles.extraItems}>
          <Stack space="none">
            <Separator color="default" />
            <Box paddingX="medium" paddingY="xsmall">
              <Text
                size="xsmall"
                weight="bold"
                color="gray700"
                transform="uppercase"
                letterSpacing="xlarge"
              >
                Account settings
              </Text>
            </Box>

            <Link
              to="account"
              className={styles.link({
                active: !!matchPath({ path: 'account', end: false }, location.pathname),
              })}
            >
              Account
            </Link>
            {!isStripeMock && (
              <Link
                to="billing"
                className={styles.link({
                  active: !!matchPath({ path: 'billing', end: false }, location.pathname),
                })}
              >
                Billing
              </Link>
            )}

            {isOrgAccount && (
              <Link
                to="organization"
                className={styles.link({
                  active: !!matchPath({ path: 'organization', end: false }, location.pathname),
                })}
              >
                Organization
              </Link>
            )}

            <Separator color="default" />

            {organizations && organizations.length > 0 && (
              <>
                <Box paddingX="medium" paddingY="xsmall">
                  <Text
                    size="xsmall"
                    weight="bold"
                    color="gray700"
                    transform="uppercase"
                    letterSpacing="xlarge"
                  >
                    Switch account
                  </Text>
                </Box>
                {organizations.map(org => (
                  <Link
                    key={org?.name}
                    className={styles.link()}
                    to="/login"
                    searchParams={{
                      organization: org.id,
                    }}
                  >
                    <Stack direction="horizontal" align="center" justify="between">
                      {org.name}
                      {currentOrganization?.id === org.id && (
                        <Icon color="gigagreen">
                          <Check />
                        </Icon>
                      )}
                    </Stack>
                  </Link>
                ))}
              </>
            )}

            {/* <Link
              className={styles.link()}
              to="/login"
              searchParams={{
                personalAccount: 'yes',
              }}
            >
              <Stack direction="horizontal" align="center" justify="between">
                Personal account
                {!currentOrganization && (
                  <Icon color="gigagreen">
                    <Check />
                  </Icon>
                )}
              </Stack>
            </Link> */}

            <Separator color="default" />

            <Link className={styles.link()} to="/organization/create">
              <Stack direction="horizontal" align="center" justify="between">
                Create organization
                <Icon color="white">
                  <MathPlus />
                </Icon>
              </Stack>
            </Link>

            <Separator color="default" />

            <Link className={styles.link()} to="mailto:tickets@gigapipe.com">
              <Stack direction="horizontal" align="center" justify="between">
                Request support
                <Icon color="white">
                  <Edit />
                </Icon>
              </Stack>
            </Link>

            <Separator color="default" />

            <Box
              className={styles.item({
                active: false,
              })}
            >
              <Link to="/logout" className={styles.navlink()}>
                <Icon color="white" size="xlarge">
                  <LogOut />
                </Icon>
                LOGOUT
              </Link>
            </Box>
          </Stack>
        </div>
      </Stack>
    </Box>
  )
}
