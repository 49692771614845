import { type Integration } from 'api-client'

import { apiClient } from '~/shared/api'
import { EIGHT_HOURS } from '~/shared/config'

const integrationsQueryKeys = {
  allByProject: (projectId: string) => [{ scope: 'integrations', projectId }] as const,
  trialDays: (projectId: string, integration: string) =>
    [{ scope: 'integrations', item: 'trialDays', projectId, integration }] as const,
  status: [{ scope: 'integrations', item: 'status' }] as const,
  cancellationDate: (projectId: string, integration: string) =>
    [{ scope: 'integrations', item: 'cancellationDate', projectId, integration }] as const,
}

/**
 * Query to fetch all integrations enabled in a project
 */
export const integrationsQuery = (projectId: string) => ({
  queryKey: integrationsQueryKeys.allByProject(projectId),
  queryFn: () => apiClient.integrations.getIntegrationsByProject(projectId),
  enabled: Boolean(projectId),
})

/**
 * Query to fetch remaining trial days for each integration
 */
export const integrationTrialDaysQuery = (
  projectId: string,
  integration: string,
  status: Integration['status'],
) => ({
  queryKey: integrationsQueryKeys.trialDays(projectId, integration),
  queryFn: () => apiClient.integrations.getIntegrationTrialDays({ projectId, integration }),
  enabled: Boolean(projectId) && Boolean(integration) && status === 'trial',
  staleTime: EIGHT_HOURS,
})

/**
 * Query to fetch current status of all integration services
 */
export const integrationStatusQuery = () => ({
  queryKey: integrationsQueryKeys.status,
  queryFn: apiClient.integrations.getIntegrationStatus,
})

/**
 * Query to fetch the cancellation date for each integration
 */
export const integrationsCancellationDateQuery = (
  projectId: string,
  integration: string,
  status?: Integration['status'],
) => ({
  queryKey: integrationsQueryKeys.cancellationDate(projectId, integration),
  queryFn: () => apiClient.integrations.getCancellationDate({ projectId, integration }),
  enabled: Boolean(projectId) && Boolean(integration) && status === 'scheduled for cancellation',
  staleTime: EIGHT_HOURS,
})
