import { useQuery, useQueryClient } from '@tanstack/react-query'
import { type ProvidersSlug } from 'api-client'

import { apiClient } from '~/shared/api'
import { ONE_HOUR } from '~/shared/config'

const clickHouseQueryKeys = {
  clickHouseClusters: (projectId: string) =>
    [{ scope: 'integrations', type: 'clickhouse', item: 'clusters', projectId }] as const,
  clickHouseClustersMetadata: (projectId: string, clusterName: string) =>
    [
      { scope: 'integrations', type: 'clickhouse', item: 'metadata', projectId, clusterName },
    ] as const,
  clickHouseClustersMetrics: (
    projectId: string,
    clusterName: string,
    options: { startTime: number; endTime: number; nodes: string[]; databases: string[] },
  ) =>
    [
      {
        scope: 'integrations',
        type: 'clickhouse',
        item: 'metrics',
        projectId,
        clusterName,
        ...options,
      },
    ] as const,
}

const cloudResourcesKeys = {
  regions: (projectId: string, providerSlug?: ProvidersSlug) =>
    [{ scope: 'cloudResources', type: 'regions', projectId, providerSlug }] as const,
  machines: (projectId: string, providerSlug?: ProvidersSlug) =>
    [{ scope: 'cloudResources', type: 'machines', projectId, providerSlug }] as const,
  diskTypes: (projectId: string, providerSlug?: ProvidersSlug) =>
    [{ scope: 'cloudResources', type: 'diskTypes', projectId, providerSlug }] as const,
  zones: (projectId: string, regionCode?: string, providerSlug: ProvidersSlug = 'gcp') =>
    [{ scope: 'cloudResources', type: 'zones', projectId, providerSlug, regionCode }] as const,
  clickHouseVersions: (projectId: string) =>
    [{ scope: 'cloudResources', type: 'clickHouseVersions', projectId }] as const,
}

/**
 * Query to fetch the existing `ClickHouse` cluster by project
 */
export const clickHouseClustersQuery = (projectId: string) => ({
  queryKey: clickHouseQueryKeys.clickHouseClusters(projectId),
  queryFn: () => apiClient.clickhouse.getClickHouseClusters(projectId),
  enabled: Boolean(projectId),
})

/**
 * Query to fetch the existing `ClickHouse` cluster metadata by project
 */
export const clickHouseClusterMetadataQuery = (
  projectId: string,
  clusterName: string,
  isActive: boolean,
) => ({
  queryKey: clickHouseQueryKeys.clickHouseClustersMetadata(projectId, clusterName),
  queryFn: () => apiClient.clickhouse.getClickHouseClusterMetadata({ projectId, clusterName }),
  enabled: Boolean(projectId) && Boolean(clusterName) && isActive,
})

/**
 * Query to fetch the existing `ClickHouse` cluster metrics by project
 */
export const clickHouseClusterMetricsQuery = (
  projectId: string,
  clusterName: string,
  isActive: boolean,
  options: { startTime: number; endTime: number; nodes: string[]; databases: string[] },
) => ({
  queryKey: clickHouseQueryKeys.clickHouseClustersMetrics(projectId, clusterName, options),
  queryFn: () =>
    apiClient.clickhouse.getClickHouseClusterMetrics({ projectId, clusterName, ...options }),
  enabled:
    Boolean(clusterName) &&
    isActive &&
    Boolean(options.nodes.length) &&
    Boolean(options.databases.length) &&
    Boolean(options.startTime) &&
    Boolean(options.endTime),
})

const defaultStartTime = Date.now() - 1
const defaultEndTime = Date.now()
export function useClickHouseClusterMetrics(
  projectId: string,
  clusterName: string,
  startTime = defaultStartTime,
  endTime = defaultEndTime,
) {
  const queryClient = useQueryClient()
  const status = queryClient
    .getQueryData<Awaited<ReturnType<typeof apiClient.clickhouse.getClickHouseClusters>>>(
      clickHouseQueryKeys.clickHouseClusters(projectId),
    )
    ?.find(cluster => cluster.name === clusterName)?.status
  // For external clusters we don't know the real status so we assume it's active and let the request fail.
  const isActive = status === 'active' || status === 'external'

  const { data: metadata } = useQuery(
    clickHouseClusterMetadataQuery(projectId, clusterName, isActive),
  )

  return useQuery(
    clickHouseClusterMetricsQuery(projectId, clusterName, isActive, {
      startTime,
      endTime,
      nodes: metadata?.nodes.map(node => node.host) ?? [],
      databases: metadata?.databases ?? [],
    }),
  )
}

/**
 * Query to fetch the available cloud regions to deploy `ClickHouse` clusters
 */
export const regionsQuery = (projectId: string, providerSlug?: ProvidersSlug) => ({
  queryKey: cloudResourcesKeys.regions(projectId, providerSlug),
  queryFn: () => apiClient.clickhouse.getRegions({ projectId, providerSlug }),
  enabled: Boolean(projectId) && Boolean(providerSlug),
  staleTime: ONE_HOUR,
})

/**
 * Query to fetch the available machines for the `ClickHouse` clusters
 */
export const machinesQuery = (projectId: string, providerSlug?: ProvidersSlug) => ({
  queryKey: cloudResourcesKeys.machines(projectId, providerSlug),
  queryFn: () => apiClient.clickhouse.getMachines({ projectId, providerSlug }),
  enabled: Boolean(projectId) && Boolean(providerSlug),
  staleTime: ONE_HOUR,
})

/**
 * Query to fetch the available types of disks for the `ClickHouse` clusters
 */
export const diskTypesQuery = (projectId: string, providerSlug?: ProvidersSlug) => ({
  queryKey: cloudResourcesKeys.diskTypes(projectId, providerSlug),
  queryFn: () => apiClient.clickhouse.getDisktypes({ projectId, providerSlug }),
  enabled: Boolean(projectId) && Boolean(providerSlug),
  staleTime: ONE_HOUR,
})

/**
 * Query to fetch the available zones in a GCP region to deploy the `ClickHouse` clusters
 */
export const zonesQuery = (projectId: string, regionCode?: string) => ({
  queryKey: cloudResourcesKeys.zones(projectId, regionCode),
  queryFn: () => apiClient.clickhouse.getZones({ projectId, regionCode, providerSlug: 'gcp' }),
  enabled: Boolean(projectId) && Boolean(regionCode),
  staleTime: ONE_HOUR,
})

/**
 * Query to fetch the available versions of `ClickHouse` to use in the managed cluster
 */
export const clickHouseVersionsQuery = (projectId: string) => ({
  queryKey: cloudResourcesKeys.clickHouseVersions(projectId),
  queryFn: () => apiClient.clickhouse.getClickHouseVersions(projectId),
  enabled: Boolean(projectId),
  staleTime: ONE_HOUR,
})
